import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import { TypedEpic } from '../types';
import { InvoiceType } from '../../../../types/InvoiceType';

// Actions
export enum Actions {
  GET_MY_INVOICES = 'naf/myInvoices/GET_MY_INVOICES',
  GET_MY_INVOICES_SUCCESS = 'naf/myInvoices/GET_MY_INVOICES_SUCCESS',
  GET_MY_INVOICES_FAIL = 'naf/myInvoices/GET_MY_INVOICES_FAIL',
  GET_MY_INVOICES_CANCEL = 'naf/myInvoices/GET_MY_INVOICES_CANCEL',
}

export interface State {
  data: {
    invoices: Record<InvoiceType['invoiceNumber'], InvoiceType>;
    unpaid: Record<InvoiceType['invoiceNumber'], InvoiceType>;
    paid: Record<InvoiceType['invoiceNumber'], InvoiceType>;
    directDebetUrl: string | null;
  };
  isUpdating: boolean;
  errorState?: any;
}

export const initialState: State = {
  data: {
    invoices: {},
    unpaid: {},
    paid: {},
    directDebetUrl: null,
  },
  isUpdating: false,
};

export const invoiceActions = {
  getMyInvoices: createAsyncAction(
    Actions.GET_MY_INVOICES, // request payload creator
    Actions.GET_MY_INVOICES_SUCCESS, // success payload creator
    Actions.GET_MY_INVOICES_FAIL, // failure payload creator
    Actions.GET_MY_INVOICES_CANCEL, // optional cancel payload creator
  )<string, InvoiceType[], Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(invoiceActions.getMyInvoices.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(invoiceActions.getMyInvoices.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.data.invoices = action.payload.reduce(
        (acc: Record<InvoiceType['invoiceNumber'], InvoiceType>, cur) => {
          acc[cur.invoiceNumber] = cur;
          return acc;
        },
        {},
      );
      draftState.data.directDebetUrl = action.payload.length > 0 ? action.payload[0].directDebetUrl : null;
      draftState.data.unpaid = action.payload.reduce((acc: Record<InvoiceType['invoiceNumber'], InvoiceType>, cur) => {
        if (cur.invoiceStatus === 'open') {
          acc[cur.invoiceNumber] = cur;
        }
        return acc;
      }, {});
      draftState.data.paid = action.payload.reduce((acc: Record<InvoiceType['invoiceNumber'], InvoiceType>, cur) => {
        if (cur.invoiceStatus === 'paid') {
          acc[cur.invoiceNumber] = cur;
        }
        return acc;
      }, {});
    }),
  )
  .handleAction(invoiceActions.getMyInvoices.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(invoiceActions.getMyInvoices.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

export const getMyInvoicesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MY_INVOICES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof invoiceActions.getMyInvoices.request>[]
    >,
    switchMap(([action]: { payload?: string }[]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.payload) {
        headers.Authorization = `Bearer ${action.payload}`;
      }
      return ajax<InvoiceType[]>({
        url: `${apimBaseUrl}/${apimMembershipApi}/invoices`,
        headers,
      }).pipe(
        map(({ response }) => invoiceActions.getMyInvoices.success(response)),
        catchError(() =>
          of(
            invoiceActions.getMyInvoices.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente fakturaer! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [getMyInvoicesEpic];
