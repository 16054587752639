import React, { useEffect, useState } from 'react';
import { TabPanel } from '@naf/tabs';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import loadable from '@loadable/component';
import styled from 'styled-components';
import { CarMeta, RangeTest } from '../../../../types/carModelType';
import { TestTabs } from './TestTabs';
import { Season } from '../../../../types/ElPrixContextData';
import { CombinedResultCard } from '../../components/SectionBlocks/Testshowcase/CombinedResultCard';
import BlockContent from '../../components/block-content/BlockContent';
import { ElevationChart } from '../../components/SectionBlocks/blocks/Charts/ElevationChart';
import { useElPrixRouteData } from '../../hooks/useElPrixRouteData';
import { VehicleVariantRadio } from './VehicleVariantRadio';
import { InfoBox } from './InfoBox';
import { SectionHeader } from './ModelDetailPage';
import { StyledLegend } from '../../components/SectionBlocks/Testshowcase/Legend';
import useSelector from '../../redux/typedHooks';
import { DataTable } from './DataTable';
import { formattedPrice } from '../../lib/formattedPrice';
import { BlockContentWrapper } from './BlockContentWrapper';

const GoogleMapsWrapper = loadable(() => import('../../components/SectionBlocks/blocks/Charts/GoogleMapsWrapper'), {
  resolveComponent: (components) => components.GoogleMapsWrapper,
});

type Props = Pick<CarMeta, 'rangeTests' | 'cloudinaryImages'> & {
  rangeTestInfoBox: CarMeta['globalContent']['rangeTestInfoBox'];
  slug: string;
};

type TestGroup = Record<`${Season}_${string}`, { tests: { [key: string]: RangeTest } }>;

export const RangeTests: React.FC<Props> = ({ rangeTests, rangeTestInfoBox, cloudinaryImages, slug }) => {
  const testGroups: TestGroup = rangeTests.reduce((acc, rangeTest) => {
    const { season, testDate } = rangeTest.context;
    const testKey = `${season}_${testDate}`;
    if (acc[testKey]) {
      acc[testKey] = {
        ...acc[testKey],
        tests: { ...acc[testKey].tests, [rangeTest.vehicleModelReference.vehicleId]: rangeTest },
      };
    } else {
      acc[testKey] = {
        tests: { [rangeTest.vehicleModelReference.vehicleId]: rangeTest },
      };
    }
    return acc;
  }, {});

  const tabContexts = Object.keys(testGroups)
    .sort((a, b) => {
      const dateA = new Date(a.split('_')[1]).getTime();
      const dateB = new Date(b.split('_')[1]).getTime();
      return dateB - dateA;
    })
    .map((key) => {
      const [season, date] = key.split('_') as [Season, string];
      return {
        key,
        value: formatTestSeason(season, date),
      };
    });

  const [selectedTab, setSelectedTab] = useState(tabContexts[0]);

  useEffect(() => {
    const handleSelectTest = (event: CustomEvent<{ testDate: string; season: Season }>) => {
      const { testDate, season } = event.detail;
      const tabKey = `${season}_${testDate}`;
      const tab = tabContexts.find((t) => t.key === tabKey);
      if (tab) {
        setSelectedTab(tab);
      }
    };

    window.addEventListener('selectRangeTest', handleSelectTest as EventListener);
    return () => {
      window.removeEventListener('selectRangeTest', handleSelectTest as EventListener);
    };
  }, [tabContexts]);

  return (
    <>
      <ScrollAnchorTag id="rekkeviddetester">
        <SectionHeader variant={TextVariant.Header1} tag="h2">
          Test av rekkevidde
        </SectionHeader>
      </ScrollAnchorTag>
      <GoogleMapsWrapper>
        <TestTabs selectedTab={selectedTab} testType="rangeTest" testContexts={tabContexts}>
          {Object.entries(testGroups).map(([key, { tests }]) => (
            <TabPanel item={key} key={key}>
              <RangeTestContent slug={slug} tests={tests} sanityImages={cloudinaryImages} />
            </TabPanel>
          ))}
        </TestTabs>
      </GoogleMapsWrapper>
      {rangeTestInfoBox && <InfoBox title={rangeTestInfoBox.title} text={rangeTestInfoBox.text} />}
    </>
  );
};

const RangeTestContent: React.FC<{
  slug: string;
  tests: { [key: string]: RangeTest };
  sanityImages: CarMeta['cloudinaryImages'];
}> = ({ tests, sanityImages, slug }) => {
  const [selectedVariantId, setSelectedVariantId] = useState<string>(Object.keys(tests)[0]);
  const variant = useSelector((state) =>
    state.cars.mappedData[slug].data.vehicleVariants.find(({ id }) => id === selectedVariantId),
  );

  const {
    context,
    vehicleModelReference,
    statedRangeKm,
    measuredRangeKm,
    statedConsumptionKWhper100km,
    rangeTestSummary,
    rangeTestResultSummary,
    batteryDrainageSummary,
  } = tests[selectedVariantId];

  const { season, testDate, isPartOfSeason } = context;
  const routepoints = isPartOfSeason ? context.routepoints : [];
  const elevation = isPartOfSeason ? context.altitudeProfile : [];
  const { pointsOfInterest } = useElPrixRouteData({ routepoints, elevation });

  return (
    <>
      {Object.keys(tests).length > 1 && (
        <VehicleVariantRadio
          name={`rangeTest_${season}_${testDate}`}
          tests={Object.values(tests)}
          selected={selectedVariantId}
          setSelected={setSelectedVariantId}
          sanityImages={sanityImages}
        />
      )}
      <BlockContentWrapper>
        <BlockContent value={rangeTestSummary} />
      </BlockContentWrapper>
      {(statedConsumptionKWhper100km || statedRangeKm) && (
        <CombinedResultCard
          variant="result"
          context={{
            season,
            testDate,
            tests: { range: true, consumption: true, chargeTime: false, maxEffect: false },
          }}
          data={{
            testData: {
              chargeTest: null,
              image: sanityImages.find((img) => img?.variant?.id && img.variant.id === vehicleModelReference.vehicleId),
              rangeTest: tests[selectedVariantId],
            },
            ...vehicleModelReference,
          }}
        />
      )}
      {isPartOfSeason && elevation.length > 0 && (
        <>
          <ElevationChartContainer>
            <ElevationChart
              display="range"
              season={season}
              testDate={testDate}
              altitudes={elevation}
              pointsOfInterest={pointsOfInterest}
              rangeData={{ measured: measuredRangeKm, stated: statedRangeKm }}
            />
          </ElevationChartContainer>
          <Caption as="figcaption">
            Grafen viser høydeprofilen av testruten, og hvor bilen stanset i forhold til oppgitt WLTP.
          </Caption>
        </>
      )}
      {rangeTestResultSummary && (
        <>
          <ContentHeader variant={TextVariant.Header2}>Testresultater</ContentHeader>
          <BlockContentWrapper>
            <BlockContent value={rangeTestResultSummary} />
          </BlockContentWrapper>
        </>
      )}
      {batteryDrainageSummary && (
        <>
          <ContentHeader variant={TextVariant.Header2}>Slik oppleves det å kjøre tom for strøm</ContentHeader>
          <BlockContentWrapper>
            <BlockContent value={batteryDrainageSummary} />
          </BlockContentWrapper>
        </>
      )}
      {variant && (
        <DataTable
          tableTitle="Om testbilen"
          entries={[
            { label: 'Utstyrsnivå', value: variant?.vehicleEquipment.equipmentComment },
            { label: 'Chassis', value: variant?.chassis },
            { label: 'Netto batteripakke', value: variant?.batteryDetails?.capacity_kWh, unit: 'kWh' },
            { label: 'Egenvekt uten sjåfør', value: variant?.vehicleDimensions?.weight_kg, unit: 'kg' },
            {
              label: 'Pris for testbil',
              value: formattedPrice(vehicleModelReference.price ?? variant.priceNOK),
              unit: 'kr',
            },
            { label: 'Drivhjul', value: variant?.driveTrain.type },
            { label: 'Hestekrefter', value: variant?.engineDetails[0].power_BHP },
          ]}
          flyoutData={{
            ...(variant || {}),
            vehicleVariantSpecification: {
              ...variant?.vehicleVariantSpecification,
              general: {
                ...variant?.vehicleVariantSpecification.general,
                priceNOK: vehicleModelReference.price || variant?.priceNOK,
              },
              electricAndEmissionDetails: {
                ...variant?.vehicleVariantSpecification?.electricAndEmissionDetails,
                rangeWLTP_km: statedRangeKm || variant?.electricVehicleDetails.rangeWLTP_km,
                electricConsumptionWLTP_kWhper100km:
                  statedConsumptionKWhper100km || variant?.electricVehicleDetails.electricConsumptionWLTP_kWhper100km,
              },
            },
          }}
        />
      )}
    </>
  );
};

/**
 * @param {Season} season - "winter" | "summer".
 * @param {string} dateString - any valid date string.
 */
export const formatTestSeason = (season: Season, dateString: string) =>
  `${season === 'summer' ? 'Sommer' : 'Vinter'} ${new Date(dateString).getFullYear()}`;

export const ContentHeader = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space16};
`;

export const Caption = styled(Text)`
  font-style: italic;
  margin: 0;
  width: max-content;
  text-align: left;

  // TextVariant.Small but it works with the "as='figcaption'"
  font-size: 0.875rem;
  line-height: 1.5;

  margin-bottom: ${spacing.space48};
  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
    width: calc(100vw - 48px);
  }
`;

const ElevationChartContainer = styled.figure`
  width: 100%;
  margin: 0;

  @media (max-width: ${breakpoints.s}) {
    margin: 0 -${spacing.space24};
    width: calc(100% + 2 * ${spacing.space24});

    ${StyledLegend} {
      margin-left: ${spacing.space24};
    }
  }
`;

const ScrollAnchorTag = styled.div`
  scroll-margin: 100px;
`;
