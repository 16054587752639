import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { ElprixSeason } from '../../../../types/carModelType';
import { VehicleCategory } from '../../../../types/testType';

type HeaderTextProps = {
  categories: VehicleCategory[];
  title: string;
  ingress: string;
};

export function getElprixSeasonLabel(season: ElprixSeason): string {
  switch (season) {
    case 'summer':
      return 'Sommer';
    case 'winter':
      return 'Vinter';
    default:
      return '';
  }
}

const HeaderText = ({ categories, title, ingress }: HeaderTextProps) => (
  <HeaderContainer>
    <MarginlessText variant={TextVariant.Tag}>{categories.map(({ category }) => category).join(', ')}</MarginlessText>
    <MarginlessText variant={TextVariant.Display}>{title}</MarginlessText>
    <MarginlessText variant={TextVariant.Ingress}>{ingress}</MarginlessText>
  </HeaderContainer>
);

const MarginlessText = styled(Text)`
  margin: 0;
  text-align: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: ${spacing.space16};
  margin: 0 auto;
  max-width: ${breakpoints.m};
`;

export { HeaderText };
