import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, fontStyle, radius, spacing, themeLight } from '@naf/theme';

interface Props {
  /**
   * Tekst på radioknappen.
   */
  label?: string;
  /**
   * Setter radioknappens state til valg / ikke valg.
   */
  checked?: boolean;
  /**
   * Funksjon når radioknappen trykkes.
   */
  onChange: () => void;
  /**
   * Navn for å refere til radioknappen i funksjoner. Vil ikke rendres på siden.
   */
  name?: string;
  /**
   * Radioknappens utseende. Henter farger fra NAF's designguide. `Disabled` vil sette radioknappen til inaktiv.
   */
  variant?: 'standard' | 'error' | 'disabled';
  /**
   * Setter en ramme rundt radioknappen.
   */
  outline?: boolean;
  /**
   * Setter radioknappens bredde relativt til parent container. Brukes sammen med `outline`.
   */
  width?: 'content' | 'half' | 'full';
  /**
   * En liten informasjonstekst plassert under label. Brukes f.eks for å vise feilmeldinger.
   */
  message?: string;

  className?: string;
  isDisabled?: boolean;
  dataTestId?: string;
}

export const RadioButton = ({
  label,
  checked,
  name,
  variant = 'standard',
  width = 'content',
  outline = false,
  message,
  onChange,
  className,
  isDisabled,
  dataTestId,
}: Props) => {
  const handleChange = () => {
    onChange();
  };

  const handleOutlineClick = (isOutlined: boolean) => {
    if (isOutlined) {
      handleChange();
    }
  };

  return (
    <Container
      $outline={outline}
      onClick={() => !isDisabled && handleOutlineClick(outline)}
      $width={width}
      className={className}
      data-testid={dataTestId}
    >
      <Label $variant={isDisabled ? 'disabled' : variant}>
        <input
          name={name}
          type="radio"
          onChange={() => !isDisabled && handleChange()}
          disabled={isDisabled}
          checked={checked}
        />
        <RadioWrap>
          <StyledRadio
            $checked
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleChange();
              }
            }}
          />
        </RadioWrap>
        {label ? <span>{label}</span> : null}
      </Label>
      {message && <Message $variant={variant}>{message}</Message>}
    </Container>
  );
};

export default RadioButton;

const Container = styled.div<{ $outline: boolean; $width: string }>`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  margin: ${spacing.space8} ${spacing.space32} ${spacing.space8} 0;
  width: fit-content;

  ${({ $outline }) =>
    $outline &&
    css`
      padding: ${spacing.space12};
      padding-right: ${spacing.space64};
      border: 1px solid;
      border-radius: ${radius.s};
      border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      &:hover {
        cursor: pointer;
        input ~ div {
          border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        }
      }
    `}

  ${({ $width }) =>
    $width === 'half' &&
    css`
      padding-right: 0;
      width: calc(50% - ${spacing.space12});
    `}

  ${({ $width }) =>
    $width === 'full' &&
    css`
      padding-right: 0;
      width: calc(100% - ${spacing.space12});
    `};

  @media (max-width: ${breakpoints.s}) {
    flex-flow: row wrap;
    align-items: center;
    margin: ${spacing.space8} 0;
    width: calc(100% - 2 * (${spacing.space8}) - ${spacing.space12});
  }
`;

const RadioWrap = styled.div`
  display: inline-flex;
  width: fit-content;
  height: calc(${fontStyle.article.articleText['font-size']} * ${fontStyle.article.articleText['line-height']});
  align-items: center;

  @media (max-width: ${breakpoints.s}) {
    height: calc(${fontStyle.bodyText.bodyText['font-size']} * ${fontStyle.bodyText.bodyText['line-height']});
  }
`;

const StyledRadio = styled.div<{ $checked: boolean }>`
  min-width: ${spacing.space24};
  width: ${spacing.space24};
  height: ${spacing.space24};
  border-radius: 50%;
  box-sizing: border-box;

  &:focus {
    border-radius: 50% !important;
  }
  ${({ $checked }) =>
    $checked &&
    css`
      border-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.interactiveElement.active
          : themeLight.componentColors.interactiveElement.active};
      background: ${({ theme }) =>
        theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
    `}
`;

const Label = styled.label<{ $variant: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  user-select: none;

  input {
    display: none;
  }

  &:hover {
    cursor: pointer;

    ${({ $variant }) =>
      $variant === 'standard' &&
      css`
        div {
          border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        }
      `}

    ${({ $variant }) =>
      $variant === 'disabled' &&
      css`
        cursor: not-allowed;
      `}
  }

  ${StyledRadio} {
    border: 2px solid;

    ${({ $variant }) =>
      $variant === 'standard' &&
      css`
        background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
        border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      `}

    ${({ $variant }) =>
      $variant === 'error' &&
      css`
        background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
        border-color: ${({ theme }) =>
          theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
      `}

    ${({ $variant }) =>
      $variant === 'disabled' &&
      css`
        border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        background: ${({ theme }) =>
          theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText};
      `}
  }

  input:checked ~ div > ${StyledRadio} {
    border: ${spacing.space8} solid;

    ${({ $variant }) =>
      $variant === 'standard' &&
      css`
        border-color: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.interactiveElement.active
            : themeLight.componentColors.interactiveElement.active};
        background: ${({ theme }) =>
          theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
      `}

    ${({ $variant }) =>
      $variant === 'error' &&
      css`
        border-color: ${({ theme }) =>
          theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
        background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.alert.errorBackground
            : themeLight.componentColors.alert.errorBackground};
      `}

    ${({ $variant }) =>
      $variant === 'disabled' &&
      css`
        border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        background: ${({ theme }) =>
          theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText};
      `}
  }

  span {
    ${fontStyle.article.articleText};
    margin-left: ${spacing.space8};

    @media (max-width: ${breakpoints.s}) {
      ${fontStyle.bodyText.bodyText};
    }
  }
`;

const Message = styled.p<{ $variant: string }>`
  ${fontStyle.bodyText.small};
  margin: 0;
  margin-left: calc(${spacing.space24} + ${spacing.space8});

  ${({ $variant }) =>
    $variant === 'standard' &&
    css`
      color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};
    `}

  ${({ $variant }) =>
    $variant === 'error' &&
    css`
      color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
    `}

  ${({ $variant }) =>
    $variant === 'disabled' &&
    css`
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};
    `};

  @media (max-width: ${breakpoints.s}) {
    margin-left: ${spacing.space12};
  }
`;
