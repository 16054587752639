import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { FrontPageType } from '../../../../types/frontPageType';

// Actions
export enum Actions {
  GET_FRONT_PAGE = 'GET_FRONT_PAGE',
  GET_FRONT_PAGE_SUCCESS = 'GET_FRONT_PAGE_SUCCESS',
  GET_FRONT_PAGE_FAIL = 'GET_FRONT_PAGE_FAIL',
  GET_FRONT_PAGE_CANCEL = 'GET_FRONT_PAGE_CANCEL',
}

export interface State {
  data?: FrontPageType;
  meta: { isUpdating: boolean; fetchedAt?: string; isLoggedin?: boolean };
  error?: Error;
}

export const initialState: State = {
  meta: {
    isUpdating: false,
  },
};

export const actions = {
  getFrontPage: createAsyncAction(
    Actions.GET_FRONT_PAGE, // request payload creator
    Actions.GET_FRONT_PAGE_SUCCESS, // success payload creator
    Actions.GET_FRONT_PAGE_FAIL, // failure payload creator
    Actions.GET_FRONT_PAGE_CANCEL, // optional cancel payload creator
  )<[undefined, { token?: string }], FrontPageType, Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getFrontPage.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta = {
        isUpdating: true,
        fetchedAt: draftState.meta.fetchedAt || undefined,
        isLoggedin: !!action.meta.token,
      };
    }),
  )
  .handleAction(actions.getFrontPage.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta = {
        isUpdating: false,
        fetchedAt: new Date().toISOString(),
        isLoggedin: state.meta.isLoggedin,
      };
      draftState.data = action.payload;
    }),
  )
  .handleAction(actions.getFrontPage.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta = { isUpdating: false };
      draftState.error = action.payload;
    }),
  )
  .handleAction(actions.getFrontPage.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.meta = { isUpdating: false };
    }),
  );

export const fetchDocumentEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_FRONT_PAGE),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getFrontPage.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<FrontPageType>({
        url: `${apimBaseUrl}/${apimNafNoApi}/frontpage`,
        headers,
      }).pipe(
        map(({ response }) => actions.getFrontPage.success(response)),
        catchError(() => of(actions.getFrontPage.failure(new Boom.Boom('Could not get document')))),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchDocumentEpic];
