import React from 'react';
import type { SanityBlock } from '@sanity/client';
import { InfoBox } from '@naf/info-box';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import BlockContent from '../../../BlockContent';

interface InfoBoxSerializerProps {
  value: {
    displayOption: 'standard' | 'green';
    highlightedText: string;
    title: string;
    text: SanityBlock[];
  };
}

export const InfoBoxSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: InfoBoxSerializerProps) => (
      <ArticleBlockContentGridCol>
        <InfoBox
          displayColor={value.displayOption}
          highlightedText={value.highlightedText}
          title={value.title}
          content={<BlockContent value={value.text} />}
        />
      </ArticleBlockContentGridCol>
    );
  }

  return ({ value }: InfoBoxSerializerProps) => (
    <InfoBox
      displayColor={value.displayOption}
      highlightedText={value.highlightedText}
      title={value.title}
      content={<BlockContent value={value.text} />}
    />
  );
};
