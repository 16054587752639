/* eslint-disable react/no-danger */
import React from 'react';
import { DEFAULT_IMG_URL, MAIN_URL } from '../../lib/constants';

interface Props {
  name?: string;
  logo?: string;
  url?: string;
  id?: string;
  alternateName?: string;
}

const SchemaOrganization = ({
  id = '',
  name = 'NAF - Norges Automobil-Forbund | NAF',
  logo = DEFAULT_IMG_URL,
  url = MAIN_URL,
  alternateName = 'Norges Automobil-Forbund',
}: Props) => {
  // Hardcode inn contactdata for now, until we get a working footer/contactpage
  const structuredData = `{
  "@context": "http://schema.org",
		    "@type": "Organization",
        "name": "${name}",
        "alternateName": "${alternateName}",
        "logo": "${logo}",
        "url": "${url}",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Postboks 9343 Grønland",
          "addressCountry": "NO",
          "postOfficeBoxNumber": "9343",
          "postalCode": "0135"
        },
        "location": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Skippergata 4",
            "postalCode": "0152"
          }
        },
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "23 21 31 01",
            "contactType": "customer service"
          }
        ]
    }`;

  return (
    <script
      type="application/ld+json"
      key={`organizationJSON-${id}`}
      dangerouslySetInnerHTML={{ __html: structuredData }}
    />
  );
};

export default SchemaOrganization;
