import styled from 'styled-components';
import { TextVariant, Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import React from 'react';

type Shape = 'square' | 'circle' | 'line';
type LegendProps =
  | {
      key: string;
      label: React.ReactNode;
      split?: false;
      color1: string;
      shape: Shape;
    }
  | {
      key: string;
      label: React.ReactNode;
      split: true;
      color1: string;
      color2: string;
      shape: Shape;
    };

export const Legend: React.FC<{ items: LegendProps[] }> = ({ items }) => (
  <StyledLegend>
    {items.map((item) => {
      const { label, color1, key, shape } = item;
      if (item.split) {
        return (
          <LegendItem key={key}>
            <LegendColor shape={shape === 'circle' ? 'leftCircle' : 'square'} width={spacing.space4} color={color1} />
            <LegendColor
              shape={shape === 'circle' ? 'rightCircle' : 'square'}
              width={spacing.space4}
              color={item.color2}
            />
            <StyledText variant={TextVariant.DataLabel}>{label}</StyledText>
          </LegendItem>
        );
      }
      return (
        <LegendItem key={key}>
          <LegendColor shape={shape} width={spacing.space8} color={color1} />
          <StyledText variant={TextVariant.DataLabel}>{label}</StyledText>
        </LegendItem>
      );
    })}
  </StyledLegend>
);

const LegendColor = styled.div<{
  color: string;
  width: string;
  shape: Shape | 'leftCircle' | 'rightCircle';
}>`
  width: ${({ width }) => width};
  height: ${({ shape }) => (shape === 'line' ? '2px' : '8px')};
  background-color: ${({ color }) => color};
  border-radius: ${({ shape }) => {
    switch (shape) {
      case 'leftCircle':
        return '50px 0 0 50px';
      case 'rightCircle':
        return '0 50px 50px 0';
      case 'circle':
        return '50px';
      case 'square':
      default:
        return '0px';
    }
  }};
`;

export const StyledLegend = styled.div`
  display: flex;
  gap: ${spacing.space24};
  margin-bottom: ${spacing.space32};
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.m}) {
    gap: ${spacing.space12};
    margin-bottom: ${spacing.space16};
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin: 0;
  font-weight: 300;
  margin-left: ${spacing.space4} !important;
`;
