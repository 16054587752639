import React from 'react';
import { Button } from '@naf/button';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { ExternalGlobalCTAType } from './index';
import useSelector from '../../../../../../redux/typedHooks';
import { useGTMDataLayer } from '../../../../../../hooks/useGTMDataLayer';
import { isInternalLink } from '../../../../../../lib/isInternalLink';

interface Props {
  data: ExternalGlobalCTAType['data'];
}
export const ExternalUrl = ({ data }: Props) => {
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const history = useHistory();
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const { isInternal, relativeLink } = isInternalLink(baseUrl, data.url);

  const handleClick = () => {
    // Handle tracking
    const urlObject = new URL(data.url);
    dataLayer?.push({
      event: 'cta_click',
      cta_text: data.buttonText,
      cta_location: location.pathname,
      cta_url: data.url,
      cta_hostname: urlObject.hostname,
    });
    // Handle location change
    if (data.blank) {
      window.open(data.url);
      return;
    }
    if (isInternal) {
      history.push(relativeLink);
    } else {
      window.location.href = data.url;
    }
  };

  return (
    <Wrapper>
      <Button onClick={handleClick}>{data.buttonText}</Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
