import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { produce } from 'immer';
import { iif, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, concatMap, delay, map, mergeMap, retryWhen, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import {
  CustomerInformationType,
  FieldValidationResultType,
  OrderType,
  PostPaymentOrderError,
  PostPaymentOrderRequest,
  PostPaymentOrderResponse,
  ProfileForm,
} from '@dtp/membership-service-types';
import { FieldObject, ProfileData, UpdateProfileSuccess } from '../../../../types/profileType';
import { TypedEpic } from '../types';
import { GetHouseholdMemberResponse } from '../../../../types/householdMembers';

// Action Types
export enum Actions {
  GET_HOUSEHOLD_MEMBERS = 'naf/householdMembers/GET_HOUSEHOLD_MEMBERS',
  GET_HOUSEHOLD_MEMBERS_SUCCESS = 'naf/householdMembers/GET_HOUSEHOLD_MEMBERS_SUCCESS',
  GET_HOUSEHOLD_MEMBERS_FAIL = 'naf/householdMembers/GET_HOUSEHOLD_MEMBERS_FAIL',
  GET_HOUSEHOLD_MEMBERS_CANCEL = 'naf/householdMembers/GET_HOUSEHOLD_MEMBERS_CANCEL',
  GET_HOUSEHOLD_MEMBER_DETAILS = 'naf/householdMembers/GET_HOUSEHOLD_MEMBER_DETAILS',
  GET_HOUSEHOLD_MEMBER_DETAILS_SUCCESS = 'naf/householdMembers/GET_HOUSEHOLD_MEMBER_DETAILS_SUCCESS',
  GET_HOUSEHOLD_MEMBER_DETAILS_FAIL = 'naf/householdMembers/GET_HOUSEHOLD_MEMBER_DETAILS_FAIL',
  GET_HOUSEHOLD_MEMBER_DETAILS_CANCEL = 'naf/householdMembers/GET_HOUSEHOLD_MEMBER_DETAILS_CANCEL',
  CREATE_HOUSEHOLD_MEMBER_ORDER = 'naf/householdMembers/CREATE_HOUSEHOLD_MEMBER_ORDER',
  CREATE_HOUSEHOLD_MEMBER_ORDER_SUCCESS = 'naf/householdMembers/CREATE_HOUSEHOLD_MEMBER_ORDER_SUCCESS',
  CREATE_HOUSEHOLD_MEMBER_ORDER_FAIL = 'naf/householdMembers/CREATE_HOUSEHOLD_MEMBER_ORDER_FAIL',
  CREATE_HOUSEHOLD_MEMBER_ORDER_CANCEL = 'naf/householdMembers/CREATE_HOUSEHOLD_MEMBER_ORDER_CANCEL',
  UPDATE_HOUSEHOLD_MEMBER = 'naf/householdMembers/UPDATE_HOUSEHOLD_MEMBER',
  UPDATE_HOUSEHOLD_MEMBER_SUCCESS = 'naf/householdMembers/UPDATE_HOUSEHOLD_MEMBER_SUCCESS',
  UPDATE_HOUSEHOLD_MEMBER_FAIL = 'naf/householdMembers/UPDATE_HOUSEHOLD_MEMBER_FAIL',
  UPDATE_HOUSEHOLD_MEMBER_CANCEL = 'naf/householdMembers/UPDATE_HOUSEHOLD_MEMBER_CANCEL',
}

// State Type
export interface State {
  meta: { isUpdating: boolean; fetchState: string };
  data: {
    mainHouseholdMemberFullName: string;
    isMainHouseholdMember: boolean;
    householdMembers: Record<
      CustomerInformationType['customerId'],
      CustomerInformationType & {
        meta: { isUpdating: boolean };
        data: Partial<ProfileForm>;
        errorState?: Error;
        validation?: Record<string, string | null>;
      }
    >;
    order: {
      salesOrder?: OrderType;
      validation?: PostPaymentOrderError;
      isCreating: boolean;
      orderDetails?: PostPaymentOrderResponse;
      isCreated: boolean;
    };
  };
  errorState?: any;
}

export const initialState: State = {
  meta: { isUpdating: false, fetchState: 'initial' },
  data: {
    householdMembers: {},
    mainHouseholdMemberFullName: '',
    isMainHouseholdMember: false,
    order: {
      salesOrder: {
        customerProductsRequests: [],
        returnUrl: '',
      },
      isCreated: false,
      isCreating: false,
    },
  },
};

// Actions
export const actions = {
  getHouseholdMembers: createAsyncAction(
    Actions.GET_HOUSEHOLD_MEMBERS,
    Actions.GET_HOUSEHOLD_MEMBERS_SUCCESS,
    Actions.GET_HOUSEHOLD_MEMBERS_FAIL,
    Actions.GET_HOUSEHOLD_MEMBERS_CANCEL,
  )<string, GetHouseholdMemberResponse, Error, undefined>(),
  getHouseholdMemberDetails: createAsyncAction(
    Actions.GET_HOUSEHOLD_MEMBER_DETAILS,
    Actions.GET_HOUSEHOLD_MEMBER_DETAILS_SUCCESS,
    Actions.GET_HOUSEHOLD_MEMBER_DETAILS_FAIL,
    Actions.GET_HOUSEHOLD_MEMBER_DETAILS_CANCEL,
  )<
    [CustomerInformationType['customerId'], { token?: string }],
    [ProfileForm, CustomerInformationType['customerId']],
    [Error, CustomerInformationType['customerId']],
    [undefined, CustomerInformationType['customerId']]
  >(),
  createOrder: createAsyncAction(
    Actions.CREATE_HOUSEHOLD_MEMBER_ORDER, // request payload creator
    Actions.CREATE_HOUSEHOLD_MEMBER_ORDER_SUCCESS, // success payload creator
    Actions.CREATE_HOUSEHOLD_MEMBER_ORDER_FAIL, // failure payload creator
    Actions.CREATE_HOUSEHOLD_MEMBER_ORDER_CANCEL, // optional cancel payload creator
  )<[PostPaymentOrderRequest, { token?: string }], PostPaymentOrderResponse, PostPaymentOrderError, undefined>(),
  updateHouseholdMember: createAsyncAction(
    Actions.UPDATE_HOUSEHOLD_MEMBER,
    Actions.UPDATE_HOUSEHOLD_MEMBER_SUCCESS,
    Actions.UPDATE_HOUSEHOLD_MEMBER_FAIL,
    Actions.UPDATE_HOUSEHOLD_MEMBER_CANCEL,
  )<
    [ProfileData, { token?: string; memberId: string }],
    [UpdateProfileSuccess, { memberId: string }],
    [
      { success: boolean; validation: Record<string, string | null>; profile: ProfileData | null },
      { memberId: string },
    ],
    [undefined, { memberId: string }]
  >(),
};

// Reducers
export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getHouseholdMembers.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.meta.isUpdating = true;
      draftState.meta.fetchState = 'loading';
    }),
  )
  .handleAction(actions.getHouseholdMembers.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.mainHouseholdMemberFullName = action.payload.mainHouseholdMemberFullName;
      draftState.data.isMainHouseholdMember = action.payload.isMainHouseholdMember;
      draftState.data.householdMembers = action.payload.householdMembers.reduce(
        (
          acc: Record<
            CustomerInformationType['customerId'],
            CustomerInformationType & { meta: { isUpdating: boolean }; data: Record<string, FieldObject> }
          >,
          cur,
        ) => {
          acc[cur.customerId] = { ...cur, meta: { isUpdating: false }, data: {} };
          return acc;
        },
        {},
      );
      draftState.meta.isUpdating = false;
      draftState.meta.fetchState = 'success';
    }),
  )
  .handleAction(actions.getHouseholdMembers.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta.isUpdating = false;
      draftState.errorState = action.payload;
      draftState.meta.fetchState = 'error';
    }),
  )
  .handleAction(actions.getHouseholdMembers.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.meta.isUpdating = false;
      draftState.meta.fetchState = 'initial';
    }),
  )
  .handleAction(actions.getHouseholdMemberDetails.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.payload].meta.isUpdating = true;
    }),
  )
  .handleAction(actions.getHouseholdMemberDetails.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.meta].data = action.payload;
      draftState.data.householdMembers[action.meta].meta.isUpdating = false;
      draftState.data.householdMembers[action.meta].errorState = undefined;
    }),
  )
  .handleAction(actions.getHouseholdMemberDetails.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.meta].errorState = action.payload;
      draftState.data.householdMembers[action.meta].meta.isUpdating = false;
    }),
  )
  .handleAction(actions.getHouseholdMemberDetails.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.meta].meta.isUpdating = false;
    }),
  )
  .handleAction(actions.updateHouseholdMember.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.meta.memberId].meta.isUpdating = true;
    }),
  )
  .handleAction(actions.updateHouseholdMember.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.meta.memberId].data = action.payload.profile;
    }),
  )
  .handleAction(actions.updateHouseholdMember.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.meta.memberId].meta.isUpdating = false;
      draftState.data.householdMembers[action.meta.memberId].validation = action.payload.validation;
    }),
  )
  .handleAction(actions.updateHouseholdMember.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.householdMembers[action.meta.memberId].meta.isUpdating = false;
    }),
  )
  .handleAction(actions.createOrder.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.data.order.isCreating = true;
      delete draftState.data.order.validation;
    }),
  )
  .handleAction(actions.createOrder.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.order.isCreating = false;
      draftState.data.order.isCreated = true;
      draftState.data.order.orderDetails = action.payload;
    }),
  )
  .handleAction(actions.createOrder.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.data.order.isCreating = false;
    }),
  )
  .handleAction(actions.createOrder.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.order.isCreating = false;
      draftState.data.order.isCreated = false;
      draftState.data.order.validation = action.payload;
      draftState.data.order.validation.validationMap = action.payload.validationResults.reduce(
        (acc: Record<FieldValidationResultType['field'], FieldValidationResultType>, cur) => {
          acc[cur.field] = cur;
          return acc;
        },
        {},
      );
    }),
  );

// Epics
export const getHouseholdMembersEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_HOUSEHOLD_MEMBERS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getHouseholdMembers.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.payload) {
        headers.Authorization = `Bearer ${action.payload}`;
      }
      return ajax<GetHouseholdMemberResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/household-members`,
        headers,
      }).pipe(
        map(({ response }) => actions.getHouseholdMembers.success(response)),
        catchError(() =>
          of(
            actions.getHouseholdMembers.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente husstandsmedlemmene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const getHouseholdMemberDetailsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_HOUSEHOLD_MEMBER_DETAILS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getHouseholdMemberDetails.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<ProfileForm>({
        url: `${apimBaseUrl}/${apimMembershipApi}/household-members/${action.payload}/profile`,
        headers,
      }).pipe(
        map(({ response }) => actions.getHouseholdMemberDetails.success(response, action.payload)),
        catchError(() =>
          of(
            actions.getHouseholdMemberDetails.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente husstandsmedlemmet! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
              action.payload,
            ),
          ),
        ),
      );
    }),
  );
};

const createHouseholdMemberOrderEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = state$.value.application;
  const errorArray = [400, 422];

  return action$.pipe(
    ofType(Actions.CREATE_HOUSEHOLD_MEMBER_ORDER),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.createOrder.request>[]
    >,
    mergeMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<PostPaymentOrderResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/v2/products/payment`,
        headers,
        method: 'POST',
        body: JSON.stringify(action.payload),
        withCredentials: true,
      }).pipe(
        map(({ response }) => actions.createOrder.success(response)),
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((error) => {
              if (!errorArray.includes(error.status)) {
                return of(error);
              }
              return throwError(error);
            }),
            // Use concat map to keep the errors in order and make sure they
            // aren't executed in parallel
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => i > 10,
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                of(e).pipe(delay(500)),
              ),
            ),
          ),
        ),
        catchError((res) => of(actions.createOrder.failure(res.response))),
      );
    }),
  );
};

export const updateHouseholdMemberEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.UPDATE_HOUSEHOLD_MEMBER),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.updateHouseholdMember.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax
        .post<UpdateProfileSuccess>(
          `${apimBaseUrl}/${apimMembershipApi}/household-members/${action.meta.memberId}/profile`,
          action.payload,
          headers,
        )
        .pipe(
          map(({ response }) => actions.updateHouseholdMember.success(response, { memberId: action.meta.memberId })),
          catchError(({ response }) =>
            of(actions.updateHouseholdMember.failure(response, { memberId: action.meta.memberId })),
          ),
        );
    }),
  );
};

export const epics: TypedEpic[] = [
  getHouseholdMembersEpic,
  getHouseholdMemberDetailsEpic,
  createHouseholdMemberOrderEpic,
  updateHouseholdMemberEpic,
];
