import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

export const MembershipActiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.space16};
  padding: ${spacing.space24};
  background: ${nafColor.primary.dew};
  border-radius: ${spacing.space8};

  p,
  h6 {
    margin: 0;
  }
`;
