import { GridCol } from '@naf/grid';
import { CardSize, CardNavigation } from '@naf/cards';
import React from 'react';
import { NavCardLink } from '../../../../components/styled-link/StyledLink';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { InternalLinkType } from '../../../../../../types/internalLinkType';

interface Props {
  internalLink: InternalLinkType;
}

export const InternalLinkCard = ({ internalLink }: Props) => {
  const cardUrl = useDocumentUrlWithNode(internalLink);
  return (
    <GridCol s="12" m="12" l="4" xl="4">
      <NavCardLink to={cardUrl || 'undefined'}>
        <CardNavigation title={internalLink?.name} size={CardSize.Medium} isInternalLink height="100%" />
      </NavCardLink>
    </GridCol>
  );
};
