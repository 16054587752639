import React from 'react';
import styled from 'styled-components';
import { DirectionsWalk } from '@styled-icons/material-outlined/DirectionsWalk';
import { Star } from '@styled-icons/material-outlined/Star';
import { StarOutline } from '@styled-icons/material-outlined/StarOutline';
import { nafColor } from '@nafcore/theme';
import { NafThemeType, breakpoints, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { ButtonLink } from '@naf/button-link';
import { SeatbeltIcon } from '../../icons/Seatbelt';
import { ChildSeatIcon } from '../../icons/ChildSeat';
import { CarAssistantIcon } from '../../icons/CarAssistant';
import { SafetyTestType } from '../../../../../types/carModelType';

interface Props {
  safetyTest: SafetyTestType;
}

export const ScoreIcon = ({ iconKey }: { iconKey: string }): JSX.Element | null => {
  switch (iconKey) {
    case 'Adults':
      return <SeatbeltIcon />;
    case 'Children':
      return <ChildSeatIcon />;
    case 'Pedestrians':
      return <DirectionsWalk size={24} id="pedestrians" />;
    case 'SecuritySystems':
      return <CarAssistantIcon />;
    default:
      return null;
  }
};

export const SafetyTest = ({ safetyTest }: Props) => (
  <Wrapper>
    <Text tag="h2" variant={TextVariant.Header2}>
      {safetyTest.year
        ? `Sikkerhetsrangering i kollisjonstest (${safetyTest.year})`
        : 'Sikkerhetsrangering i kollisjonstest'}
    </Text>
    {safetyTest.totalScore && (
      <StarIconsWrapper>
        <div>
          {[...Array(safetyTest.totalScore).keys()].map((x) => (
            <Star size={32} key={`star${x}`} />
          ))}
          {[...Array(5 - safetyTest.totalScore).keys()].map((x) => (
            <StarOutline size={32} key={`star-outline${x}`} />
          ))}
        </div>
        <Text variant={TextVariant.ArticleTextHeader}>{`${safetyTest.totalScore}/5`}</Text>
      </StarIconsWrapper>
    )}
    {safetyTest.summary && <Text variant={TextVariant.ArticleText}>{safetyTest.summary}</Text>}
    {safetyTest.collisionProtectionScores && safetyTest.collisionProtectionScores.length > 0 && (
      <CollisionScoreWrapper>
        {safetyTest.collisionProtectionScores.map((safetyItem) => (
          <SafetyItemWrapper key={safetyItem.key}>
            {!!safetyItem.value && safetyItem.value > 0 && (
              <>
                {safetyItem.key && (
                  <SafetyScoreIcon>
                    <ScoreIcon iconKey={safetyItem.key} />
                  </SafetyScoreIcon>
                )}
                <SafetyItemScore>
                  <Text variant={TextVariant.BodyTextHeader}>{safetyItem.label}</Text>
                  {safetyItem.key === 'SecuritySystems' ? (
                    <Text variant={TextVariant.BodyText}>{`Ga ${safetyItem.value}% økt beskyttelse`}</Text>
                  ) : (
                    <Text variant={TextVariant.BodyText}>{`${safetyItem.value}% beskyttelse i kollisjon`}</Text>
                  )}
                </SafetyItemScore>
              </>
            )}
          </SafetyItemWrapper>
        ))}
      </CollisionScoreWrapper>
    )}
    <CopyrightText variant={TextVariant.Caption}>
      Testen er levert av{' '}
      <a href="https://www.euroncap.com/en" target="_blank" rel="noreferrer">
        Euro NCAP
      </a>
    </CopyrightText>
    {safetyTest.reportUrl && safetyTest.reportUrl.data && (
      <StyledButtonLink
        href={safetyTest.reportUrl.data.href}
        text="Les hele rapporten"
        icon="launch"
        target={safetyTest.reportUrl.data.blank ? '_blank' : '_self'}
        rel="noreferrer"
      />
    )}
  </Wrapper>
);

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  text-decoration: none !important;
  font-size: 1rem !important;
  span {
    font-size: 1rem;
  }
`;

const SafetyItemScore = styled.div`
  margin-bottom: ${spacing.space24};
  h6 {
    margin: 0px;
  }
  p {
    margin: 0;
    margin-bottom: ${spacing.space4};
  }
`;

const CopyrightText = styled(Text)`
  margin-top: ${spacing.space8};
`;

const SafetyScoreIcon = styled.div`
  background: ${({ theme }: { theme: NafThemeType }) => theme.background.brand};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.space16};
  svg {
    fill: ${({ theme }: { theme: NafThemeType }) => theme.componentColors.extras.accent};
  }
  svg:not(#pedestrians) path {
    fill: ${({ theme }: { theme: NafThemeType }) => theme.componentColors.extras.accent};
  }
`;

const SafetyItemWrapper = styled.div`
  display: flex;
`;

const CollisionScoreWrapper = styled.div`
  padding-top: ${spacing.space8};
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${breakpoints.s}) {
    grid-template-columns: 1fr;
  }
`;

const StarIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  h5 {
    margin: 0 0 0 ${spacing.space8};
  }
  svg {
    color: ${nafColor.primary.park};
    margin-right: 8px;

    @media (max-width: ${breakpoints.m}) {
      width: 24px;
    }
  }
`;
