import { useEffect, useState } from 'react';
import { UrlParamsType } from '../../../types/urlParamsType';
import { getURLParams } from '../lib/getUrlParams';
import useSelector from '../redux/typedHooks';

export const useQueryParams = (): UrlParamsType | undefined => {
  // TODO: This is a hack for triggering an update of params, as window.location is mutable
  // TODO: Remove connected-react-router and use the new hook from react-router useSearchParams
  const urlParamsIndex = useSelector((state) => state.application.urlParamsIndex);
  const [params, setParams] = useState<UrlParamsType | undefined>(undefined);
  useEffect(() => {
    const queryParams = getURLParams();
    setParams(queryParams);
  }, []);
  useEffect(() => {
    const queryParams = getURLParams();
    setParams(queryParams);
  }, [urlParamsIndex]);
  return params;
};
