import { useLocation } from 'react-router';
import { useMemo } from 'react';

import { useDocumentUrl } from './useDocumentUrl';

// Check whether the current location equals the url of the current document
export const useIsDocumentLocationUrlValid = (slug: string) => {
  const location = useLocation();
  const documentUrl = useDocumentUrl(slug);

  return useMemo(() => {
    if (documentUrl) {
      const locationStripped = location.pathname.replace(/\/$/, '');
      return documentUrl.trim() === locationStripped.trim();
    }
    return false;
  }, [documentUrl, location]);
};
