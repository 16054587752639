/* eslint-disable import/no-import-module-exports,import/first */
// This is here to provide the correct static asset base path runtime, since the same bundle is deployed to both test and prod
// eslint-disable-next-line camelcase
__webpack_public_path__ = (window as unknown as Window & { resourceBasePath: string }).resourceBasePath;

import React, { StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

import { App } from './components/App';

import { store } from './redux/store';

import '../assets/fonts/questa/questa.scss';
import '../assets/fonts/bangers/bangers.scss';
import '../assets/fonts/poppins/poppins.scss';

if (module.hot) {
  module.hot.accept(['./redux/store', './redux'], () => async () => {
    const { createReducer } = await import('./redux');
    store.replaceReducer(createReducer());
  });
}

const indexJSX = (
  <StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </StrictMode>
);

loadableReady(() => {
  const container = document.getElementById('root');
  if (container == null) throw new Error('Failed to find the root element');
  hydrateRoot(container, indexJSX);
});
