import React from 'react';
import { Button, ButtonVariant } from '@naf/button';
import { useHistory, useLocation } from 'react-router';
import useSelector from '../../../../../redux/typedHooks';
import { isInternalLink } from '../../../../../lib/isInternalLink';
import { useGTMDataLayer } from '../../../../../hooks/useGTMDataLayer';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { useWindowLocation } from '../../../../../hooks/useWindowLocation';

interface CtaButtonLinkSerializerProps {
  value: { text: string; url: string; variant?: ButtonVariant };
}

const CtaButtonLink = ({ value: { text, url, variant } }: CtaButtonLinkSerializerProps) => {
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const history = useHistory();
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const path = useWindowLocation();

  const handleTracking = () => {
    const urlObject = new URL(path);
    dataLayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_location: location.pathname,
      cta_url: path,
      cta_hostname: urlObject.hostname,
    });
  };

  const { isInternal, relativeLink } = isInternalLink(baseUrl, url);

  if (isInternal) {
    return (
      <Button
        onClick={(e: any) => {
          handleTracking();
          e.preventDefault();
          history.push(relativeLink, { custom: true });
        }}
        href={relativeLink}
        variant={variant}
      >
        {text}
      </Button>
    );
  }
  return (
    <Button
      onClick={(e: any) => {
        handleTracking();
        e.preventDefault();
        window.location.href = url;
      }}
      href={url}
      variant={variant}
    >
      {text}
    </Button>
  );
};

export const CtaButtonLinkSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: CtaButtonLinkSerializerProps) => (
      <ArticleBlockContentGridCol className="CtaButton">
        <CtaButtonLink value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: CtaButtonLinkSerializerProps) => <CtaButtonLink value={value} />;
};
