import React from 'react';
import styled from 'styled-components';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface Props {
  value: {
    _key: string;
    _type: 'anchor';
    type: 'anchor';
    id: string;
  };
}

export const AnchorBlock = ({ value: { id } }: Props) => <Anchor id={id} />;

export const AnchorSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: Props) => (
      <ArticleBlockContentGridCol>
        <AnchorBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: Props) => <AnchorBlock value={value} />;
};

const Anchor = styled.div`
  height: 0;
  width: 0;
`;
