import { Action, ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { MyProfileForm } from '@dtp/membership-service-types';
import { Boom } from '@hapi/boom';
import { TypedEpic } from '../types';
import { ProfileData, ProfileUpdate, Validation } from '../../../../types/profileType';

// Actions
export enum Actions {
  GET_PROFILE = 'naf/GET_PROFILE',
  GET_PROFILE_SUCCESS = 'naf/GET_PROFILE_SUCCESS',
  GET_PROFILE_FAIL = 'naf/GET_PROFILE_FAIL',
  GET_PROFILE_CANCEL = 'naf/GET_PROFILE_CANCEL',
  EDIT_PROFILE = 'naf/EDIT_PROFILE',
  EDIT_PROFILE_SUCCESS = 'naf/EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_FAIL = 'naf/EDIT_PROFILE_FAIL',
  EDIT_PROFILE_CANCEL = 'naf/EDIT_PROFILE_CANCEL',
  EDIT_PROFILE_RESET = 'naf/EDIT_PROFILE_RESET',
  EDIT_EMAIL = 'naf/EDIT_EMAIL',
  EDIT_EMAIL_SUCCESS = 'naf/EDIT_EMAIL_SUCCESS',
  EDIT_EMAIL_FAIL = 'naf/EDIT_EMAIL_FAIL',
  EDIT_EMAIL_CANCEL = 'naf/EDIT_EMAIL_CANCEL',
  EDIT_EMAIL_RESET = 'naf/EDIT_EMAIL_RESET',
}

export interface State {
  profileData: {
    profile: MyProfileForm;
    isLoading: boolean;
    isError: boolean;
  };
  editProfile: {
    isError: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    validation: Validation;
  };
  editEmail: {
    isError: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    validation: Validation;
  };
}

export const initialState: State = {
  profileData: {
    profile: null,
    isError: false,
    isLoading: false,
  },
  editProfile: {
    isError: false,
    isLoading: false,
    isSuccess: false,
    validation: null,
  },
  editEmail: {
    isError: false,
    isLoading: false,
    isSuccess: false,
    validation: null,
  },
};

export const actions = {
  getProfile: createAsyncAction(
    Actions.GET_PROFILE, // request payload creator
    Actions.GET_PROFILE_SUCCESS, // success payload creator
    Actions.GET_PROFILE_FAIL, // failure payload creator
    Actions.GET_PROFILE_CANCEL, // optional cancel payload creator
  )<[undefined, { token: string }], MyProfileForm, Error, undefined>(),
  editProfile: createAsyncAction(
    Actions.EDIT_PROFILE, // request payload creator
    Actions.EDIT_PROFILE_SUCCESS, // success payload creator
    Actions.EDIT_PROFILE_FAIL, // failure payload creator
    Actions.EDIT_PROFILE_CANCEL, // optional cancel payload creator
  )<[Partial<ProfileData> | undefined, { token: string }], ProfileUpdate, Validation, undefined>(),
  resetEditProfile: createAction(Actions.EDIT_PROFILE_RESET, () => ({}))(),
  editEmail: createAsyncAction(
    Actions.EDIT_EMAIL, // request payload creator
    Actions.EDIT_EMAIL_SUCCESS, // success payload creator
    Actions.EDIT_EMAIL_FAIL, // failure payload creator
    Actions.EDIT_EMAIL_CANCEL, // optional cancel payload creator
  )<[{ email: string }, { token: string }], { email: string }, Validation, undefined>(),
  resetEditEmail: createAction(Actions.EDIT_EMAIL_RESET, () => ({}))(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getProfile.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.profileData.isLoading = true;
      draftState.profileData.isError = false;
    }),
  )
  .handleAction(actions.getProfile.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.profileData.isLoading = false;
      draftState.profileData.isError = false;
      draftState.profileData.profile = action.payload;
    }),
  )
  .handleAction(actions.getProfile.failure, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.profileData.isLoading = false;
      draftState.profileData.isError = true;
    }),
  )
  .handleAction(actions.getProfile.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.profileData.isLoading = false;
    }),
  )
  .handleAction(actions.editProfile.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.editProfile.isLoading = true;
      draftState.editProfile.isError = false;
    }),
  )
  .handleAction(actions.editProfile.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.editProfile.isLoading = false;
      draftState.editProfile.isError = false;
      draftState.editProfile.isSuccess = true;
      draftState.profileData.profile = action.payload.profile;
    }),
  )
  .handleAction(actions.editProfile.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.editProfile.isLoading = false;
      draftState.editProfile.isError = true;
      draftState.editProfile.isSuccess = false;
      draftState.editProfile.validation = action.payload;
    }),
  )
  .handleAction(actions.editProfile.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.editProfile.isLoading = false;
    }),
  )
  .handleAction(actions.resetEditProfile, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.editProfile.isLoading = false;
      draftState.editProfile.isError = false;
      draftState.editProfile.isSuccess = false;
      draftState.editProfile.validation = null;
    }),
  )
  .handleAction(actions.editEmail.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.editEmail.isLoading = true;
      draftState.editEmail.isError = false;
    }),
  )
  .handleAction(actions.editEmail.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.editEmail.isLoading = false;
      draftState.editEmail.isError = false;
      draftState.editEmail.isSuccess = true;
      draftState.profileData.profile.email.value = action.payload.email;
    }),
  )
  .handleAction(actions.editEmail.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.editEmail.isLoading = false;
      draftState.editEmail.isError = true;
      draftState.editEmail.isSuccess = false;
      draftState.editEmail.validation = action.payload;
    }),
  )
  .handleAction(actions.editEmail.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.editEmail.isLoading = false;
    }),
  )
  .handleAction(actions.resetEditEmail, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.editEmail.isLoading = false;
      draftState.editEmail.isError = false;
      draftState.editEmail.isSuccess = false;
      draftState.editEmail.validation = null;
    }),
  );

export const getProfileEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_PROFILE),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.getProfile.request>[]>,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
        Authorization: `Bearer ${action.meta.token}`,
      };

      return ajax<MyProfileForm>({
        url: `${apimBaseUrl}/${apimMembershipApi}/profile`,
        headers,
        method: 'GET',
      }).pipe(
        map(({ response }) => actions.getProfile.success(response)),
        catchError(() =>
          of(
            actions.getProfile.failure(
              new Boom(
                'Beklager, vi har motorproblemer... Kunne ikke hente profilen! Kontakt kundesenteret hvis problemet vedvarer!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const editProfileEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;

  return action$.pipe(
    ofType(Actions.EDIT_PROFILE),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.editProfile.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
        Authorization: `Bearer ${action.meta.token}`,
      };

      return ajax<ProfileUpdate>({
        url: `${apimBaseUrl}/${apimMembershipApi}/profile`,
        headers,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(({ response }) => actions.editProfile.success(response)),
        catchError(({ response }) => of(actions.editProfile.failure(response?.validation))),
      );
    }),
  );
};

export const editEmailEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;

  return action$.pipe(
    ofType(Actions.EDIT_EMAIL),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.editEmail.request>[]>,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
        Authorization: `Bearer ${action.meta.token}`,
      };

      return ajax<{ email: string }>({
        url: `${apimBaseUrl}/${apimMembershipApi}/email`,
        headers,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(({ response }) => actions.editEmail.success(response)),
        catchError(({ response }) => of(actions.editEmail.failure(response?.validation))),
      );
    }),
  );
};

export const epics: TypedEpic[] = [getProfileEpic, editProfileEpic, editEmailEpic];
