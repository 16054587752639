import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { PortableTextBlock } from '@portabletext/react';
import {
  articleChargeReferenceType,
  articleRangeReferenceType,
  DocumentMetaType,
  DocumentMetaDisplayType,
  UserAccessibility,
  Category,
} from './articleType';

export type VehicleCategory = {
  category: string;
  id: string;
};

export enum DocumentTypeLabel {
  nafnoArticleCharge = 'nafnoArticleCharge',
  nafnoArticleRange = 'nafnoArticleRange',
  motorVehicleTest = 'motorVehicleTest',
  nafnoArticle = 'nafnoArticle',
  travelSuggestion = 'travelSuggestion',
  articleRangeReferenceComponent = 'articleRangeReferenceComponent',
  articleChargeReferenceComponent = 'articleChargeReferenceComponent',
  nafnoProductArticle = 'nafnoProductArticle',
  nafMembershipBenefit = 'nafMembershipBenefit',
  nafnoSiteStructure = 'nafnoSiteStructure',
  nafCenterProductArticle = 'nafCenterProductArticle',
  nafCenterArticle = 'nafCenterArticle',
  myNafFrontpage = 'myNafFrontpage',
  vehicleDataModel = 'vehicleDataModel',
  nafnoNewsArticle = 'nafnoNewsArticle',
  topicPage = 'topicPage',
  faq = 'faq',
  myNafSubPage = 'myNafSubPage',
  localDepartment = 'localDepartment',
  mcClub = 'mcClub',
  event = 'event',
  nafPracticeTrack = 'nafPracticeTrack',
  testReferenceComponent = 'testReferenceComponent',
  abTestExperiment = 'abTestExperiment',
  productPage = 'productPage',
}

export enum TestTypeLabel {
  nafnoArticleCharge = 'nafnoArticleCharge',
  nafnoArticleRange = 'nafnoArticleRange',
  motorVehicleTest = 'motorVehicleTest',
  nafnoArticle = 'nafnoArticle',
  articleRangeReferenceComponent = 'articleRangeReferenceComponent',
  articleChargeReferenceComponent = 'articleChargeReferenceComponent',
  testReferenceComponent = 'testReferenceComponent',
}

export interface TestType {
  name: string;
  id: string;
  slug: string;
  _type: TestTypeLabel;
  type: TestTypeLabel;
  _rev: string;
  _key: string | null;
  score?: number;
  relatedContent?: [];
  meta: {
    editor?: {
      name: string;
      email: string;
    };
    contentType: string;
    publishAt: string;
    updatedAt: string;
    updateBy: string | null;
  };
  image?: {
    url: string;
    urlSmall: string;
    urlLarge: string;
    urlWide: string;
    urlTall: string;
    caption?: string;
    alternateText?: string;
    path: string;
    type: string;
    source: string;
    publicId: string;
    altText?: string;
  };
  naf?: {
    url: string;
    image: SanityImageObject | null;
    title: string | null;
    intro: string | null;
  };
}

export type Image = {
  url: string;
  urlSmall: string;
  urlLarge: string;
  urlWide: string;
  urlTall: string;
  caption?: string;
  alternateText?: string;
  path: string;
  type: string;
  source: string;
  publicId: string;
  altText?: string;
};

export type RangeTestData = {
  summer_km?: number;
  summerConsumption_kWh?: number;
  winter_km?: number;
  winterConsumption_kWh?: number;
  summerTestDate?: string;
  winterTestDate?: string;
  statedRange_km?: number;
  statedRangeSummer_km?: number;
  statedConsumption_kWhper100km?: number;
  statedConsumptionSummer_kWhper100km?: number;
  image?: Image;
};

export type RangeTestType = {
  type: DocumentTypeLabel.nafnoArticleRange | DocumentTypeLabel.articleRangeReferenceComponent;
  bodyHtml: string;
  seoConfig: {
    title: string | null;
    introduction: string | null;
    isHiddenFromSearch?: boolean;
  } | null;
  image: Image;
  userAccessibility: UserAccessibility;
  slug: string;
  vehicleModelId: number;
  vehicle?: {
    vehicleId: string;
    vehicleName: string;
    year: string;
    modelId: string;
    modelName: string;
    brandName: string;
    slug: string;
  };
  id: string;
  name?: string;
  ingress: string;
  body: PortableTextBlock;
  relatedChargeTest?: articleChargeReferenceType[];
  meta: DocumentMetaType;
  metaDisplay: DocumentMetaDisplayType;
  relatedContent?: [];
} & RangeTestData;

export type ChargeTestData = {
  summerChargeTime_Minutes?: number;
  winterChargeTime_Minutes?: number;
  winterStart_Percent?: number;
  summerStart_Percent?: number;
  winterEnd_Percent?: number;
  summerEnd_Percent?: number;
  summerTestDate?: string;
  winterTestDate?: string;
  summerCharge_5min?: number;
  summerCharge_10min?: number;
  summerCharge_15min?: number;
  summerCharge_20min?: number;
  summerCharge_25min?: number;
  summerChargeField?: string;
  winterCharge_5min?: number;
  winterCharge_10min?: number;
  winterCharge_15min?: number;
  winterCharge_20min?: number;
  winterCharge_25min?: number;
  winterChargeField?: string;
  connectionType?: string;
  yMax?: number;
  statedTimeTo80Percent_Minutes?: number;
  readings?: Record<string, { summer_kW?: number; winter_kW?: number }>;
  image?: Image;
  averageSummerEfficiency?: number;
  averageWinterEfficiency?: number;
  batteryCapacity?: number;
  maxStatedChargeEffect?: number;
};

export type ChargeTestType = {
  type: DocumentTypeLabel.nafnoArticleCharge | DocumentTypeLabel.articleChargeReferenceComponent;
  bodyHtml: string;
  seoConfig: {
    title: string | null;
    introduction: string | null;
    isHiddenFromSearch?: boolean;
  } | null;
  image: Image;
  userAccessibility: UserAccessibility;
  slug: string;
  vehicle?: {
    slug: string;
    vehicleId: string;
    vehicleName: string;
    year: string;
    modelId: string;
    modelName: string;
    brandName: string;
  };
  id: string;
  name?: string;
  ingress: string;
  body: PortableTextBlock;
  relatedRangeTest?: articleRangeReferenceType[];
  meta: DocumentMetaType;
  metaDisplay: DocumentMetaDisplayType;
  relatedContent?: [];
} & ChargeTestData;

export interface MotorTestType {
  id: string;
  externalId: string;
  name: string;
  vehicleModelVariantId: string;
  testDate: string;
  description: string;
  score: number;
  scoreMax: number;
  environment: MotorTestElement;
  price: MotorTestElement;
  runningCosts: MotorTestElement;
  value: MotorTestElement;
  practicality: MotorTestElement;
  security: MotorTestElement;
  design: MotorTestElement;
  equipment: MotorTestElement;
  comfort: MotorTestElement;
  driveability: MotorTestElement;
  naf: {
    url: string;
    image: SanityImageObject | null;
    title: string | null;
    intro: string | null;
  };
  meta: {
    publishAt: string;
    updatedAt: string;
    contentType: string;
    updateBy: string | null;
  };
  _type: TestTypeLabel;
  type: TestTypeLabel;
  _rev: string;
  _key: null;
}

interface MotorTestElement {
  score: number;
  scoreMax: number;
  description: string;
}

export interface MotorTestResponse {
  items: MotorTestType[];
  skip: 0;
  take: 100;
  total: 0;
}

export interface ChargeTestResponse {
  items: ChargeTestType[];
  skip: 0;
  take: 100;
  total: 0;
}

export interface RangeTestResponse {
  items: RangeTestType[];
  skip: 0;
  take: 100;
  total: 0;
}

export type TestReferenceComponent = {
  type: DocumentTypeLabel;
  slug: string;
  category?: Category | null;
  parent?: {
    category?: Category | null;
    slug?: string;
    type: string;
  };
  vehicle?: {
    slug: string;
  };
};
