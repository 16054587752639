import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SiteStructureType } from '../../../types/siteStructureType';
import { actions as siteStructureActions } from '../redux/modules/sitestructure';
import useSelector from '../redux/typedHooks';
import { RootState } from '../redux/types';
import { useAuth0Token } from './useAuth0Token';

function selectCategory(slug: SiteStructureType['slug'], app: string) {
  return (state: RootState) => {
    const category = state.sitestructure.categoryApps[`${slug}/${app}`];
    if (!category || !category.data) return undefined;
    return category;
  };
}

export function useCategoryApp(slug: SiteStructureType['slug'], app: string) {
  const select = selectCategory(slug, app);
  const { isUpdating, isEnriched, isLoggedIn } = useSelector((state) => select(state)?.meta) || {};
  const { simpleToken } = useAuth0Token();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdating && (!isEnriched || (simpleToken && !isLoggedIn))) {
      dispatch(
        siteStructureActions.getCategoryApp.request(
          {
            slug,
            app,
          },
          { token: simpleToken },
        ),
      );
    }
  }, [dispatch, slug, app, isEnriched, isUpdating, simpleToken, isLoggedIn]);

  return useSelector(select);
}
