import React from 'react';
import { Tab, TabList, Tabs } from '@naf/tabs';

type Props = {
  testContexts: { key: string; value: string }[];
  selectedTab: { key: string; value: string };
  testType: 'rangeTest' | 'chargeTest';
  children?: React.ReactNode;
};

export const TestTabs: React.FC<Props> = ({ testContexts, selectedTab, testType, children }) => (
  <Tabs selectedTab={selectedTab.key}>
    {testContexts.length > 1 && (
      <TabList>
        {testContexts.map(({ key, value }) => (
          <Tab item={key} key={`${testType}_${key}`}>
            {value}
          </Tab>
        ))}
      </TabList>
    )}
    {children}
  </Tabs>
);
