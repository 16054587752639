import warn from './utils/warn';

// https://developers.google.com/tag-manager/quickstart

export const Snippets = {
  tags({
    id,
    events,
    dataLayer,
    dataLayerName,
  }: {
    dataLayer: any;
    dataLayerName: keyof Window;
    events: Record<string, any>;
    id: string;
  }) {
    if (!id) warn('GTM Id is required');

    const iframe = `
      <iframe src="https://sgtm.naf.no/ns.html?id=${id}"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(events).slice(1, -1)}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:''; j.id='google-server-tagging';
        j.async=true; j.src='https://sgtm.naf.no/gtm.js?id='+i+dl+'';
        var n=d.querySelector('#tag-manager-mount');
        j.setAttribute('nonce', n&&n.getAttribute('data-nonce'));
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`;

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar,
    };
  },
  dataLayer(dataLayer: any, dataLayerName: keyof Window) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  },
};
