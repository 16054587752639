import styled from 'styled-components';
import { Dialog } from '@naf/dialog';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Text } from '@naf/text';

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1216px;
  margin: auto;
  padding: 0 48px;
  flex-direction: column;

  @media (max-width: ${breakpoints.l}) {
    padding: 0 24px;
  }
`;

const Title = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space8};
  }
`;

const Ingress = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space48};

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space40};
  }
`;

const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
  width: 96px;
  margin: 4px 0 48px;
  color: ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space40};
  }
`;

const StyledDialog = styled(Dialog)`
  position: fixed;
  width: 90vw;
  height: 85vh;
  max-width: 1256px !important;
  max-height: unset;
  transform: unset;
  top: 10vh;
  left: 5vw;
  right: 5vw;
  overflow-y: auto;

  // Fixed close button in Dialog
  > div:first-child > button:first-child {
    position: fixed;
  }

  @media (max-width: ${breakpoints.s}) {
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const Body = styled.div`
  margin-bottom: ${spacing.space40};
  // overflow: hidden;

  & > div:first-child {
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    max-width: 576px;
  }
  p {
    margin: 25px 0;
    max-width: 576px;
    a:hover {
      color: ${nafColor.primary.park};
    }
  }
  h2 {
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 900;
    letter-spacing: 0;
    margin-bottom: 0;
    font-style: normal;
  }
  p + h2,
  ul + h2,
  ol + h2,
  table + h2 {
    margin-top: 54px;
  }
  h2 + p,
  h2 + ul,
  h2 + table,
  h2 + ol {
    margin-top: 29px;
  }
`;

export default { Container, Title, Ingress, HRLine, StyledDialog, Body };
