import React, { ReactNode } from 'react';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import useSelector from '../redux/typedHooks';
import { updateUrlQueryValues } from '../utils/updateUrlQueryValues';

interface Props {
  children: ReactNode | ReactNode[];
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const auth0Domain = useSelector((state) => state.application.authDomain);
  const auth0ClientId = useSelector((state) => state.application.authClientId);
  const auth0RedirectUri = useSelector((state) => state.application.authRedirectUri);
  const auth0Audience = useSelector((state) => state.application.authAudience);

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.returnTo) {
      updateUrlQueryValues.updateRedirect(appState?.returnTo);
    }
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        scope: `openid profile name email https://id.naf.no/external-ids/required/customerId`,
        redirect_uri: auth0RedirectUri,
        audience: auth0Audience,
      }}
      cookieDomain="naf.no"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
