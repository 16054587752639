import React from 'react';
import loadable from '@loadable/component';
import { TravelSuggestion } from '../../../../../../../types/TravelSuggestionType';

const TravelMap = loadable(() => import('../../../../google-maps/TravelMap'));

type Props = {
  value: {
    travelSuggestion: TravelSuggestion;
  };
};

const TravelSuggestionSerializer =
  () =>
  ({ value: { travelSuggestion } }: Props) =>
    <TravelMap travelMode={travelSuggestion?.travelMode} routePoints={travelSuggestion?.routePoints} />;

export default TravelSuggestionSerializer;
