import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@naf/button';
import { Form } from '@naf/radio-button';
import { Input } from '@naf/input';
import { Text, TextVariant } from '@naf/text';
import { formIds, FormType } from '../../../../types/formType';
import { actions as userActions } from '../../redux/modules/user';
import SimpleTextLink from '../simple-textlink/SimpleTextLink';
import ThumbsUp from '../../../assets/images/ThumbsUp.svg?url';
import S from './StyledFeedbackSurvey';

type CustomTexts = {
  positiveRadioLabel?: string;
  negativeRadioLabel?: string;
  positiveTextAreaPlaceholder?: string;
  negativeTextAreaPlaceholder?: string;
};

interface Props {
  contentUrl: string;
  title?: string;
  customText?: CustomTexts;
}

const FeedbackSurvey = ({ contentUrl, title = 'Fant du det du lette etter?', customText }: Props) => {
  const texts = {
    positiveRadioLabel: 'Ja',
    negativeRadioLabel: 'Nei',
    positiveTextAreaPlaceholder: 'Så bra! Er det noe på nettsiden som kunne vært bedre?',
    negativeTextAreaPlaceholder: 'Hva lette du etter?',
    ...customText,
  };

  const dispatch = useDispatch();
  const [radioBtnValue, setRadioBtnValue] = useState('');
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isAnswerSent, setIsAnswerSent] = useState(false);

  const POSITIVE_VALUE = 'yes';
  const NEGATIVE_VALUE = 'no';
  const contentIsUseful = radioBtnValue === POSITIVE_VALUE;

  const dispatchSetUserFeedback = async () => {
    const numberValue = contentIsUseful ? 1 : -1;
    // GTM tracking
    dispatch(
      userActions.setUserFeedback({
        contentUrl,
        contentUseful: contentIsUseful,
        contentComment: textAreaValue,
        contentValue: numberValue,
      }),
    );

    // * After the form values are sendt to Google Analytics, we need to post it to contenthub api
    const formPayload: FormType = {
      formId: formIds.FEEDBACK_FORM,
      fieldValues: [
        { fieldName: 'fromUrl', value: contentUrl },
        { fieldName: 'yesOrNo', value: contentIsUseful ? POSITIVE_VALUE : NEGATIVE_VALUE },
        { fieldName: 'feedback', value: textAreaValue },
      ],
    };
    dispatch(userActions.validateForm.request(formPayload));
  };

  const onClickSendAnswer = () => {
    dispatchSetUserFeedback();
    setIsAnswerSent(true);
  };

  return (
    <S.Wrapper s="12" m="12" l="12" xl="12">
      <S.HRLine />
      {!isAnswerSent ? (
        <>
          <S.Title>{title}</S.Title>
          <Form>
            <S.StyledRadioButton
              label={texts.positiveRadioLabel}
              name="FeedbackRadio"
              value={POSITIVE_VALUE}
              checked={radioBtnValue === POSITIVE_VALUE}
              onChange={() => setRadioBtnValue(POSITIVE_VALUE)}
            />
            <S.StyledRadioButton
              label={texts.negativeRadioLabel}
              name="FeedbackRadio"
              value={NEGATIVE_VALUE}
              checked={radioBtnValue === NEGATIVE_VALUE}
              onChange={() => setRadioBtnValue(NEGATIVE_VALUE)}
            />
          </Form>

          {radioBtnValue && (
            <>
              <S.ResponseText variant={TextVariant.ArticleTextHeader}>
                {contentIsUseful ? texts.positiveTextAreaPlaceholder : texts.negativeTextAreaPlaceholder}
              </S.ResponseText>
              <S.StyledTextArea>
                <Input
                  as="textarea"
                  placeholder="Skriv din tilbakemelding her."
                  maxCharacters={500}
                  width={450}
                  value={textAreaValue}
                  onChange={(text: string) => {
                    if (typeof text === 'string') {
                      setTextAreaValue(text);
                    }
                  }}
                />
              </S.StyledTextArea>
              <S.TextAreaInfo>
                <S.StyledIcon size={24} />
                <Text variant={TextVariant.Small}>
                  Ikke skriv personlig informasjon.
                  <br />
                  Her svarer vi ikke, men trenger du hjelp kan du{' '}
                  <SimpleTextLink href="https://www.naf.no/kontakt-oss/">kontakte oss</SimpleTextLink>.
                </Text>
              </S.TextAreaInfo>
              <S.ButtonWrapper>
                <Button variant="outline" onClick={() => onClickSendAnswer()}>
                  Send svar
                </Button>
              </S.ButtonWrapper>
            </>
          )}
        </>
      ) : (
        <S.ResponseMsg>
          <S.Img src={ThumbsUp} alt="Tommel opp" />
          <Text variant={TextVariant.ArticleTextHeader}>
            Takk for din tilbakemelding! Dine innspill hjelper oss å lage bedre nettsider.
          </Text>
          <Text>
            Dette er ikke kundeservice og vi vil derfor ikke svare deg. Hvis du ønsker å snakke med oss kan du ta
            kontakt med <SimpleTextLink href="https://www.naf.no/kontakt-oss/">kundeservice</SimpleTextLink>.
          </Text>
        </S.ResponseMsg>
      )}
    </S.Wrapper>
  );
};

export default FeedbackSurvey;
