import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Point } from 'highcharts';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { VehicleTestData } from '../../../../../types/showcaseBlockType';
import { VehicleCategory } from '../../../../../types/testType';
import { formattedPrice } from '../../../lib/formattedPrice';
import { HighChartComponent } from '../../bilguide/chart/HighChartComponent';
import { Filter } from './Filter';
import { ElPrixContextData } from '../../../../../types/ElPrixContextData';

type Props = {
  data: VehicleTestData[];
  className?: any;
  categories?: VehicleCategory[];
  tests: ElPrixContextData['tests'];
  includeFilters?: boolean;
  initialCategories?: VehicleCategory[];
};

const Plot: React.FC<Props> = ({ data, className, categories, includeFilters, tests, initialCategories }) => {
  const [filteredVehicles, setFilteredVehicles] = useState(data);

  const options = useMemo(
    (): Highcharts.Options => ({
      chart: {
        type: 'scatter',
        zooming: {
          type: 'xy',
        },
      },
      plotOptions: {
        scatter: {
          color: nafColor.primary.park,
          marker: {
            radius: 12,
          },
        },
      },
      accessibility: {
        enabled: true,
        keyboardNavigation: {
          enabled: true,
          seriesNavigation: {
            mode: 'normal',
          },
        },
      },
      yAxis: {
        max: Math.max(...data.map((d) => d.price || 0)),
        min: 0,
        gridLineDashStyle: 'Dash',
        crosshair: {
          color: nafColor.primary.park,
          dashStyle: 'Dash',
        },
        labels: {
          style: {
            fontSize: '0.75rem',
          },
          formatter: ({ value, isLast }) => {
            if (isLast) return 'Kr';
            return value !== 0 ? `${formattedPrice((value as number) / 1000)} k` : '0';
          },
        },
        lineWidth: 1,
        lineColor: nafColor.neutral.neutral3,
        tickInterval: 250000,
        title: undefined,
      },
      xAxis: {
        min: Math.min(...data.map(({ testData }) => (testData.rangeTest.measuredRangeKm || Infinity) - 50), 250),
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        lineWidth: 1,
        lineColor: nafColor.neutral.neutral3,
        max: Math.max(...data.map(({ testData }) => testData.rangeTest.measuredRangeKm || 0), 550) + 50, // Add +50 so large bubbles fit on the right: ;
        title: {
          text: undefined,
        },
        tickInterval: 50,
        labels: {
          style: {
            fontSize: '0.75rem',
          },
          formatter: ({ value, isLast }) => (isLast ? 'KM' : `${value}`),
        },
        crosshair: {
          color: nafColor.primary.park,
          dashStyle: 'Dash',
        },
      },
      title: undefined,
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        useHTML: true,
        shadow: false,
        borderWidth: 0,
        formatter: function format() {
          // eslint-disable-next-line react/no-this-in-sfc
          const { name, x, y } = this.point as Point;
          return `<table><tr><th>${name}</th></tr>
          ${y ? `<tr><td>${formattedPrice(y)} kr</td></tr>` : ''}
          ${x ? `<tr><td>${x} km</td></tr>` : ''}
          ${x && y ? `<tr><td>${formattedPrice(Math.round(y / x))} kr.pr km</td></tr>` : ''}
          </table>
          `;
        },
        followPointer: true,
        padding: 0,
      },
      series: [
        {
          type: 'scatter',
          data: data.map((d) => ({
            name: d.name,
            // Setting x to -100 removes it from the chart, while maintaining relative size of all the bubbles
            x: filteredVehicles.includes(d) ? d.testData.rangeTest.measuredRangeKm || 0 : -100,
            y: d.price,
          })),
        },
      ],
    }),
    [data, filteredVehicles],
  );

  return (
    <>
      {includeFilters && categories && (
        <Filter
          tests={tests}
          data={data}
          onlyChips
          categories={categories}
          initialChips={initialCategories}
          setData={setFilteredVehicles}
        />
      )}
      <HighChartComponent containerProps={{ className }} options={options} />
    </>
  );
};

export const ScatterPlot = styled(Plot)`
  .highcharts-point {
    fill: rgba(255, 255, 255, 0.5) !important;
    stroke-width: 1px;
    stroke: ${nafColor.primary.park} !important;
  }

  .highcharts-point-hover {
    fill: ${nafColor.primary.park} !important;
  }

  .highcharts-tooltip > span {
    background: white !important;
    border: 1px solid ${nafColor.neutral.neutral2};
    border-radius: 2px;
    padding: ${spacing.space8};
  }

  .highcharts-tooltip > span > table > tbody > tr > th {
    text-transform: uppercase;
    margin-bottom: ${spacing.space4};
  }

  .highcharts-axis-labels {
    font-family: questa-sans;
    font-weight: 700;
  }

  .highcharts-plot-line-label {
    font-family: questa-sans;
    fill: ${nafColor.neutral.neutral4};
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 0.875rem !;
    letter-spacing: 0.03rem !;
  }
`;
