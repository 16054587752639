import React from 'react';
import ConstitutionDayDesktop from '../../../../../../assets/decorations/constitutionDay/ConstitutionDayDesktop.svg?url';
import ConstitutionDayMobile from '../../../../../../assets/decorations/constitutionDay/ConstitutionDayMobile.svg?url';
import * as S from './Styles';

export const ConstitutionDay = ({ isScrolled }: { isScrolled?: boolean }) => (
  <>
    <S.DesktopWrapper $isScrolled={isScrolled}>
      <img src={ConstitutionDayDesktop} alt="" />
    </S.DesktopWrapper>
    <S.MobileWrapper>
      <img src={ConstitutionDayMobile} alt="" />
    </S.MobileWrapper>
  </>
);
