// General
// ? Mangler Topp hastighet fra API

const NEW_PRICE = 'Nypris';
const ELECTRIC = 'elektrisk';
const HYBRID = 'hybrid';
const FUEL_CATEGORIES = 'fuelCategories';
const MIN_PRICE = 'minprice';
const MAX_PRICE = 'maxprice';
const CARGO_SPACE_VOLUME = 'cargoSpaceVolume_l';
const PRICE_GROUP = 'priceGroup';
const MAXWLTP = 'maxWltp_km';
const MINWLTP = 'minWltp_km';

// Data from OFV

const GENERAL = 'general';

const PRICE = 'priceNOK';
const CHASSIS = 'chassis';
const ENGINE_TYPE = 'engineType';
const ENERGY_SOURCE = 'energySource';
const GEARBOX = 'gearbox';
const NUMBER_OF_GEARS = 'numberofGears';
const TOTAL_TORQUE = 'totalTorque_Nm';
const DRIVE_TRAIN = 'driveTrain';
const BREAK_HORSE_POWER = 'bhp';
const ZERO_TO_HUNDRED = 'zeroToOnehundred_s';
const EURONCAP = 'euroNCAPScore';
const NUMBER_OF_COLLISIONS_BAG = 'numberofCollisionBag';
const WARRANTY = 'warranty';
const WARRANTY_YEARS = 'warranty_Years';
const WARRANTY_KM = 'warranty_km';
const SERVICE_INTERVAL = 'serviceInterval';

// Electric cars
const ELECTRIC_EMISSION_DETAILS = 'electricAndEmissionDetails';

const RANGE_WLTP = 'rangeWLTP_km';
const RANGE_NEDC = 'rangeNEDC_km';
const RANGE_NAF = 'rangeNAF_km';
const BATTERY = 'battery';
const BATTERY_CAPACITY = 'batteryCapacity_kWh';
const BATTERY_WARANTY = 'batteryWarranty';
const BATTERY_WARANTY_YEARS = 'batteryWarranty_Years';
const BATTERY_WARANTY_KM = 'batteryWarranty_km';
const PRICE_PER_KM_RANGE = 'priceperkm';
const FAST_CHARGER = 'fastCharger';
const CONNECTION_TYPE = 'connectionType';
const CHARGING_CABLE = 'chargingCable';
const ON_BOARD_CHARGER = 'onBoardCharger_kW';
const BATTERY_VOLTAGE_V = 'batteryVoltage_V';
const CHARGE_VOLTAGE_V = 'chargeVoltage_V';
const EXTERNAL_CHARGING = 'externalCharging';
const ELECTRIC_CONSUMPTION_WLTP = 'electricConsumptionWLTP_lperkm';
const BATTERY_RENTAL_PRICE = 'batteryRentalPrice_1000kmperyear';
const ELECTRIC_CONSUMPTION_WLTP_PER100KM = 'electricConsumptionWLTP_kWhper100km';
const ELECTRIC_CONSUMPTION_NEDC_PER100KM = 'electricConsumptionNEDC_kWhper100km';
const CHARGING_TIME_NORMAL = 'chargingTimeNormal_h';
const CHARGING_TIME_FAST = 'chargingTimeFast_h';
const CHARGING_TIME_NAF = 'chargingTimeNAF_h';
const CAPACITY_KWH = 'capacity_kWh';
const BATTERY_WEIGHT = 'batteryWeight_kg';
const GUARANTEED_RESIDUAL_CAPACITY = 'guaranteedResidualCapacity_percent';
const MAX_POWER_CHARGE = 'maxPowerCharge_kW';
const CO2_EMISSION_WLTP = 'cO2EmissionWLTP_gperkm';
const AVERAGE_CONSUMPTION_WLTP = 'averageConsumptionWLTP_lper100km';
const NOX_EMISSION_WLTP = 'nOxEmissionWLTP_mgperkm';
const NOX_EMISSION_NORMAL = 'nOxEmissionNormal_mgperkm';

// Size and weights
const SIZE_AND_WEIGHT = 'sizeandWeight';

const NUMBER_OF_DOORS = 'numberofDoors';
const NUMBER_OF_SEATS = 'numberofSeats';
const NORMAL_CARGOSPACE_VOLUME = 'normalCargospaceVolume_l';
const MAX_CARGOSPACE_VOLUME = 'maxCargospaceVolume_l';
const TRAILER_HITCH = 'trailerHitch';
const TRAILER_PRICE = 'trailerPrice';
const MAX_TOW_WEIGHT = 'maxTowWeight_kg';
const PAYLOAD = 'payload_kg';
const WEIGHT = 'weight_kg';
const ALLOWED_TOTAL_WEIGHT = 'allowedTotalWeight_kg';
const ROOF_PAYLOAD = 'roofPayload_kg';
const GROUND_CLEARANCE = 'groundClearance_mm';
const LENGTH = 'length_mm';
const WIDTH = 'width_mm';
const HEIGHT = 'height_mm';
const AXEL_DISTANCE = 'axelDistance_mm';
const WHEEL_DIM_FRONT = 'wheelDimensionFront';
const WHEEL_DIM_REAR = 'wheelDimensionRear';
const RIM_DIM_FRONT = 'rimDimensionFront';
const RIM_DIM_REAR = 'rimDimensionRear';

// Equipments
const EQUIPMENT = 'equipment';

const EQUIPMENT_COMMENT = 'equipmentComment';
const NAVIGATION_SYSTEM = 'navigationSystem';
const CRUISE_HITCH = 'cruiseControl';
const TIRE_PRESSURE_MONITOR = 'tirePressureMonitor';
const REAR_CAMERA = 'rearCamera';

const carTerms = {
  CHASSIS,
  NEW_PRICE,
  ELECTRIC,
  HYBRID,
  FUEL_CATEGORIES,
  MIN_PRICE,
  MAX_PRICE,
  DRIVE_TRAIN,
  CARGO_SPACE_VOLUME,
  GENERAL,
  ELECTRIC_EMISSION_DETAILS,
  PRICE,
  ENGINE_TYPE,
  ENERGY_SOURCE,
  GEARBOX,
  NUMBER_OF_GEARS,
  TOTAL_TORQUE,
  BREAK_HORSE_POWER,
  ZERO_TO_HUNDRED,
  EURONCAP,
  NUMBER_OF_COLLISIONS_BAG,
  WARRANTY,
  WARRANTY_YEARS,
  WARRANTY_KM,
  SERVICE_INTERVAL,
  AVERAGE_CONSUMPTION_WLTP,
  BATTERY,
  BATTERY_CAPACITY,
  BATTERY_WARANTY,
  BATTERY_WARANTY_YEARS,
  BATTERY_WARANTY_KM,
  CO2_EMISSION_WLTP,
  CHARGING_TIME_FAST,
  CHARGING_TIME_NAF,
  CHARGING_TIME_NORMAL,
  CONNECTION_TYPE,
  ELECTRIC_CONSUMPTION_WLTP,
  FAST_CHARGER,
  MAX_POWER_CHARGE,
  NOX_EMISSION_WLTP,
  NOX_EMISSION_NORMAL,
  ON_BOARD_CHARGER,
  PRICE_PER_KM_RANGE,
  RANGE_NAF,
  RANGE_NEDC,
  RANGE_WLTP,
  SIZE_AND_WEIGHT,
  ALLOWED_TOTAL_WEIGHT,
  AXEL_DISTANCE,
  GROUND_CLEARANCE,
  HEIGHT,
  LENGTH,
  MAX_CARGOSPACE_VOLUME,
  MAX_TOW_WEIGHT,
  NORMAL_CARGOSPACE_VOLUME,
  NUMBER_OF_DOORS,
  NUMBER_OF_SEATS,
  PAYLOAD,
  ROOF_PAYLOAD,
  TRAILER_HITCH,
  TRAILER_PRICE,
  WEIGHT,
  WIDTH,
  EQUIPMENT,
  EQUIPMENT_COMMENT,
  NAVIGATION_SYSTEM,
  CRUISE_HITCH,
  REAR_CAMERA,
  TIRE_PRESSURE_MONITOR,
  PRICE_GROUP,
  MAXWLTP,
  MINWLTP,
  WHEEL_DIM_FRONT,
  WHEEL_DIM_REAR,
  RIM_DIM_FRONT,
  RIM_DIM_REAR,
  BATTERY_VOLTAGE_V,
  CHARGE_VOLTAGE_V,
  BATTERY_RENTAL_PRICE,
  BATTERY_WEIGHT,
  CAPACITY_KWH,
  GUARANTEED_RESIDUAL_CAPACITY,
  EXTERNAL_CHARGING,
  ELECTRIC_CONSUMPTION_WLTP_PER100KM,
  ELECTRIC_CONSUMPTION_NEDC_PER100KM,
  CHARGING_CABLE,
};

export default carTerms;
