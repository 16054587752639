import React, { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import styled from 'styled-components';
import { SanityBlock } from '@dtp/membership-service-types/dist/sanity/sanity';
import BlockContent from '../../../BlockContent';

export const Receipt: FC<
  Partial<StepWizardChildProps> & {
    receiptPageBody: SanityBlock[];
    centerAlignReceipt?: boolean;
  }
> = ({ receiptPageBody, centerAlignReceipt }) => {
  if (!receiptPageBody) return null;
  if (centerAlignReceipt) {
    return (
      <CenterAligned>
        <BlockContent value={receiptPageBody} />
      </CenterAligned>
    );
  }
  return <BlockContent value={receiptPageBody} />;
};

const CenterAligned = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & p {
    text-align: center;
  }
  & figure {
    width: 160px;
    height: 160px;
  }
`;
