import React from 'react';

export const Rain = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.95 21.9C13.7 22.0333 13.446 22.054 13.188 21.962C12.9293 21.8707 12.7333 21.7 12.6 21.45L11.1 18.45C10.9667 18.2 10.946 17.946 11.038 17.688C11.1293 17.4293 11.3 17.2333 11.55 17.1C11.8 16.9667 12.054 16.946 12.312 17.038C12.5707 17.1293 12.7667 17.3 12.9 17.55L14.4 20.55C14.5333 20.8 14.5543 21.054 14.463 21.312C14.371 21.5707 14.2 21.7667 13.95 21.9ZM19.95 21.9C19.7 22.0333 19.446 22.054 19.188 21.962C18.9293 21.8707 18.7333 21.7 18.6 21.45L17.1 18.45C16.9667 18.2 16.946 17.946 17.038 17.688C17.1293 17.4293 17.3 17.2333 17.55 17.1C17.8 16.9667 18.054 16.946 18.312 17.038C18.5707 17.1293 18.7667 17.3 18.9 17.55L20.4 20.55C20.5333 20.8 20.5543 21.054 20.463 21.312C20.371 21.5707 20.2 21.7667 19.95 21.9ZM7.95 21.9C7.7 22.0333 7.446 22.054 7.188 21.962C6.92933 21.8707 6.73333 21.7 6.6 21.45L5.1 18.45C4.96667 18.2 4.946 17.946 5.038 17.688C5.12933 17.4293 5.3 17.2333 5.55 17.1C5.8 16.9667 6.054 16.946 6.312 17.038C6.57067 17.1293 6.76667 17.3 6.9 17.55L8.4 20.55C8.53333 20.8 8.55433 21.054 8.463 21.312C8.371 21.5707 8.2 21.7667 7.95 21.9ZM7.5 16C5.98333 16 4.68767 15.4623 3.613 14.387C2.53767 13.3123 2 12.0167 2 10.5C2 9.11667 2.45833 7.90833 3.375 6.875C4.29167 5.84167 5.425 5.23333 6.775 5.05C7.30833 4.1 8.03767 3.354 8.963 2.812C9.88767 2.27067 10.9 2 12 2C13.5 2 14.804 2.47933 15.912 3.438C17.0207 4.396 17.6917 5.59167 17.925 7.025C19.075 7.125 20.0417 7.6 20.825 8.45C21.6083 9.3 22 10.3167 22 11.5C22 12.75 21.5627 13.8127 20.688 14.688C19.8127 15.5627 18.75 16 17.5 16H7.5ZM7.5 14H17.5C18.2 14 18.7917 13.7583 19.275 13.275C19.7583 12.7917 20 12.2 20 11.5C20 10.8 19.7583 10.2083 19.275 9.725C18.7917 9.24167 18.2 9 17.5 9H16V8C16 6.9 15.6083 5.95833 14.825 5.175C14.0417 4.39167 13.1 4 12 4C11.2 4 10.4707 4.21667 9.812 4.65C9.154 5.08333 8.65833 5.66667 8.325 6.4L8.075 7H7.45C6.5 7.03333 5.68767 7.38733 5.013 8.062C4.33767 8.73733 4 9.55 4 10.5C4 11.4667 4.34167 12.2917 5.025 12.975C5.70833 13.6583 6.53333 14 7.5 14Z"
      fill="#1A1A1A"
    />
  </svg>
);
