import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useScript } from '../hooks/useScript';

interface Props {
  returnUrl: string;
  variant?: 'primary' | 'dark' | 'light';
  isStretched?: boolean;
  isBranded?: boolean;
  isRounded?: boolean;
}
export const VippsLoginButton = ({
  returnUrl,
  variant = 'primary',
  isRounded = false,
  isStretched = false,
  isBranded = true,
}: Props) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  useScript('https://checkout.vipps.no/checkout-button/v1/vipps-checkout-button.js', 'vipps-button');
  const [isLoading, setIsLoading] = useState(false);
  return !isAuthenticated ? (
    <vipps-mobilepay-button
      type="button"
      brand="vipps"
      variant={variant}
      rounded={isRounded.toString()}
      language="no"
      verb="register"
      stretched={isStretched.toString()}
      branded={isBranded.toString()}
      loading={isLoading.toString()}
      onClick={() => {
        setIsLoading(true);
        loginWithRedirect({
          authorizationParams: { connection: 'VippsLogin' },
          appState: { returnTo: returnUrl },
        });
      }}
    />
  ) : null;
};
