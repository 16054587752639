import { GridCol } from '@naf/grid';
import { CardSize, CardNavigation } from '@naf/cards';
import React from 'react';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { ExternalLinkType } from '../../../../../../types/externalLinkType';
import { isInternalLink } from '../../../../lib/isInternalLink';
import useSelector from '../../../../redux/typedHooks';
import { NavCardLink } from '../../../../components/styled-link/StyledLink';

interface Props {
  externalLink: ExternalLinkType;
}

export const ExternalLinkCard = ({ externalLink }: Props) => {
  const cardUrl = externalLink.href;
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const { isInternal, relativeLink } = isInternalLink(baseUrl, cardUrl);

  if (isInternal) {
    return (
      <GridCol s="12" m="12" l="4" xl="4">
        <NavCardLink to={relativeLink || 'undefined'}>
          <CardNavigation title={externalLink?.title} size={CardSize.Medium} isInternalLink height="100%" />
        </NavCardLink>
      </GridCol>
    );
  }

  return (
    <GridCol s="12" m="12" l="4" xl="4">
      <StyledA href={cardUrl}>
        <CardNavigation title={externalLink?.title} size={CardSize.Medium} isInternalLink height="100%" />
      </StyledA>
    </GridCol>
  );
};

const StyledA = styled.a`
  text-decoration: none;
  display: flex;
  &:hover {
    color: ${nafColor.signature.black};
  }

  &:focus {
    .card {
      outline: 2px solid ${nafColor.signature.black} !important;
    }
  }
`;
