import { CloudinaryImageType } from './blockTypes';
import { ArticleType } from './articleType';
import { DocumentTypeLabel } from './testType';

export type Routepoint = {
  title: string;
  latitude: number;
  longitude: number;
  about: string | null;
};

export enum TravelMode {
  CAR = 'car',
  MC = 'mc',
  BIKE = 'bike',
  WALK = 'walk',
}
export interface TravelSuggestion extends ArticleType {
  type: DocumentTypeLabel.travelSuggestion;
  routePoints: Routepoint[];
  name: string;
  travelMode: TravelMode;
  ingress: string;
}

export type TravelSuggestionLink = {
  type: string;
  data: {
    slug: string;
    type: string;
    parent: {
      type: string;
      slug: string;
    };
  };
};

export type TravelSuggestionImageLink = {
  title: string;
  image: CloudinaryImageType;
  slug: string;
  parent: { slug: string };
};

export type TravelSuggestionSnapshot = {
  slug: string;
  parent: { slug: string };
  id: string;
  name: string;
  travelMode: string;
  regions: string[];
  image: CloudinaryImageType;
  type: DocumentTypeLabel.travelSuggestion;
};

export type Region = {
  key: string;
  label: string;
};
