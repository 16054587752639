import { Boom } from '@hapi/boom';
import { produce } from 'immer';
import { ofType } from 'redux-observable';
import { Observable, OperatorFunction, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { TypedEpic } from '../types';
import { LocalDepartments } from '../../../../types/nafLocations';
import { LocationSlugs } from '../../hooks/useGetNafLocations';

export enum Actions {
  GET_NAF_LOCATIONS = 'naf/centers/GET_NAF_LOCATIONS',
  GET_NAF_LOCATIONS_SUCCESS = 'naf/centers/GET_NAF_LOCATIONS_SUCCESS',
  GET_NAF_LOCATIONS_FAIL = 'naf/centers/GET_NAF_LOCATIONS_FAIL',
  GET_NAF_LOCATIONS_CANCEL = 'naf/centers/GET_NAF_LOCATIONS_CANCEL',
}

type FetchState = 'initial' | 'isUpdating' | 'success' | 'error' | 'cancelled';

export interface State {
  locations: Record<
    LocationSlugs,
    {
      data?: LocalDepartments[];
      totalCount?: number | null;
      fetchState: FetchState;
      error?: Error;
      previousTake?: number;
    }
  >;
}

export const initialState: State = {
  locations: {
    [LocationSlugs.lokalavdeling]: {
      fetchState: 'initial',
    },
    [LocationSlugs.mc]: {
      fetchState: 'initial',
    },
    [LocationSlugs.nafCenters]: {
      fetchState: 'initial',
    },
    [LocationSlugs.practiceTracks]: {
      fetchState: 'initial',
    },
  },
};

export const actions = {
  getNafLocations: createAsyncAction(
    Actions.GET_NAF_LOCATIONS, // request payload creator
    Actions.GET_NAF_LOCATIONS_SUCCESS, // success payload creator
    Actions.GET_NAF_LOCATIONS_FAIL, // failure payload creator
  )<
    { search: LocationSlugs; take?: number; counties?: string[] },
    { items: LocalDepartments[]; total: number; name: LocationSlugs; take: number },
    { error: Error; name: LocationSlugs }
  >(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getNafLocations.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.locations[action.payload.search] = {
        ...draftState.locations[action.payload.search],
        fetchState: 'isUpdating',
      };
    }),
  )
  .handleAction(actions.getNafLocations.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.locations[action.payload.name] = {
        data: action.payload.items,
        totalCount: action.payload.total,
        fetchState: 'success',
        error: undefined,
        previousTake: action.payload.take,
      };
    }),
  )
  .handleAction(actions.getNafLocations.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.locations[action.payload.name] = {
        fetchState: 'error',
        error: action.payload.error,
      };
    }),
  );

const fetchNafLocationsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_NAF_LOCATIONS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getNafLocations.request>[]
    >,
    switchMap(([action]) => {
      const { search, ...params } = action.payload;
      const qp = Object.keys(params)
        .filter((key) => params[key])
        .map((key) => `${key}=${params[key]}`);
      const url = `${apimBaseUrl}/${apimNafNoApi}/naf-locations/${search}${qp.length ? `?${qp.join('&')}` : ''}`;
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      return ajax<{ items: LocalDepartments[]; total: number }>({
        url,
        headers,
      }).pipe(
        map(({ response }) => {
          if (Array.isArray(response.items)) {
            return actions.getNafLocations.success({
              ...response,
              name: action.payload.search,
              take: action.payload.take,
            });
          }
          return actions.getNafLocations.failure({
            error: new Boom(
              'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
            ),
            name: action.payload.search,
          });
        }),
        catchError(() =>
          of(
            actions.getNafLocations.failure({
              error: new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
              name: action.payload.search,
            }),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchNafLocationsEpic];
