import React from 'react';
import styled, { css } from 'styled-components';

import { YoutubeSettingsType } from '../../../types/livestreamBlockType';

export type YoutubeVideo = {
  id: string;
  settings: YoutubeSettingsType | null;
};

export type YoutubeEmbedProps = {
  embed: YoutubeVideo;
  isFeatureHeader?: boolean;
};

export type YoutubeEmbedType = {
  embedType: 'youtubeEmbed';
  youtubeEmbed: YoutubeVideo;
};

export function constructYoutubeParameters(settings: YoutubeSettingsType | null): string {
  if (settings === null || Object.values(settings).every((value) => value === null)) {
    return '';
  }

  const parameters = [
    settings.autoplay ? 'autoplay=1' : null,
    settings.mute || settings.autoplay ? 'mute=1' : null, // Failsafe in case autoplay is set and mute is not (should not be possible, but better safe than sorry)
    settings.rel ? 'rel=1' : 'rel=0',
    settings.controls ? null : 'controls=0',
    settings.modestBranding ? 'modestbranding=1' : null,
    settings.color ? `color=${settings.color}` : null,
  ];

  return `?${parameters.filter((param) => param !== null).join('&')}`;
}

export const YoutubeEmbed = ({ embed, isFeatureHeader }: YoutubeEmbedProps) => (
  <EmbedIframe
    src={`https://www.youtube.com/embed/${embed.id}${constructYoutubeParameters({
      ...embed.settings,
      ...(isFeatureHeader ? { controls: false } : {}),
    })}`}
    title="YouTube video player"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
    $isFeatureHeader={isFeatureHeader}
  />
);

const EmbedIframe = styled.iframe<{ $isFeatureHeader: boolean }>`
  width: 100%;
  aspect-ratio: 16 / 9;
  display: block;
  border: 0;
  ${({ $isFeatureHeader }) =>
    $isFeatureHeader &&
    css`
      align-self: center;
      justify-self: center;
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1000;

      iframe {
        /* optional */
        width: 100%;
        height: 100%;
      }
    `};
`;
