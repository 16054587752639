export enum ProductID {
  MembershipWithRoadsideAssistance = '2020-1121',
  Membership = '1121',
  Young = '1131',
  ChargingTag = '8004',
  Idp1Year = '8002',
  Idp3Year = '8003',
  Idp1and3Year = '8002-8003',
  RoadSideAssistance = '2020',
  MC = '1601',
  TrialMembershipWithRoadsideAssistance = '6020',
}
