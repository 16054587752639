import { AbTestExperimentType, AbTestExperimentVariantType } from '../../../../types/abTestExperiment';

export const calculateABTestExperimentBins = (variants: AbTestExperimentType['variants']) => {
  const temp = variants.reduce(
    (acc, cur, currentIndex) => {
      acc.binMap[currentIndex] = {
        ...cur,
        lowerLimit: acc.accumulatedWeights,
        upperLimit: acc.accumulatedWeights + cur.weight,
      };
      acc.accumulatedWeights += cur.weight;
      return acc;
    },
    {
      accumulatedWeights: 0,
      binMap: {} as Record<number, AbTestExperimentVariantType & { lowerLimit: number; upperLimit: number }>,
    },
  );
  return temp.binMap;
};
