import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { ButtonLink } from '@naf/button-link';

export const MessageWrapper = styled.div`
  background-color: ${nafColor.information.warningLight || '#FFE5CC'};
  padding: ${spacing.space24} ${spacing.space48};

  h5 {
    margin: 0 0 ${spacing.space8} 0;
  }

  p {
    margin: 0 0 ${spacing.space8} 0;
  }

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space32} ${spacing.space24};
  }

  @media (max-width: ${breakpoints.m}) {
    padding: ${spacing.space16} ${spacing.space24};
  }
`;

export const InnerWrapper = styled.div`
  max-width: 1096px;
  margin: 0 auto;
  position: relative;
  padding: 0 ${spacing.space24} 0 0;
`;

const CloseButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  color: ${nafColor.signature.black};
`;

const DesktopBodyWrapper = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.s}) {
    display: none;
  }
`;

const MobileBodyWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.s}) {
    display: block;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  display: none;

  @media (max-width: ${breakpoints.s}) {
    display: block;
  }
`;

export default {
  MessageWrapper,
  InnerWrapper,
  CloseButton,
  DesktopBodyWrapper,
  MobileBodyWrapper,
  StyledButtonLink,
};
