import React from 'react';

export const CarAssistantIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4148 0.67985C16.5067 1.12869 17.4989 1.78656 18.3346 2.6159L16.9465 3.99336C16.2931 3.34491 15.5173 2.83053 14.6636 2.4796C13.8098 2.12866 12.8948 1.94803 11.9707 1.94803C11.0465 1.94803 10.1315 2.12866 9.27772 2.4796C8.42395 2.83053 7.6482 3.34491 6.99476 3.99336L5.60669 2.6159C6.44242 1.78656 7.43457 1.12869 8.5265 0.67985C9.61843 0.231013 10.7888 0 11.9707 0C13.1525 0 14.3229 0.231013 15.4148 0.67985ZM14.2668 3.43031C14.9947 3.72954 15.6562 4.16812 16.2133 4.72101L14.7884 6.13506C14.4184 5.76786 13.9791 5.47658 13.4956 5.27786C13.0122 5.07913 12.494 4.97684 11.9707 4.97684C11.4474 4.97684 10.9292 5.07913 10.4458 5.27786C9.96229 5.47658 9.52301 5.76786 9.15298 6.13506L7.72804 4.72101C8.2852 4.16812 8.94663 3.72954 9.67458 3.43031C10.4025 3.13109 11.1828 2.97708 11.9707 2.97708C12.7586 2.97708 13.5388 3.13109 14.2668 3.43031ZM17.5 8.12225C18.16 8.12225 18.72 8.53904 18.92 9.12453L21 15.0688V23.0076C21 23.5534 20.55 24 20 24H19C18.45 24 18 23.5534 18 23.0076V22.0153H6V23.0076C6 23.5534 5.55 24 5 24H4C3.45 24 3 23.5534 3 23.0076V15.0688L5.08 9.12453C5.29 8.53904 5.84 8.12225 6.5 8.12225H17.5ZM17.14 10.107H6.85L5.77 13.1932H18.22L17.14 10.107ZM5 20.0306H19V15.0688H5V20.0306ZM9 17.5497C9 18.3718 8.32843 19.0382 7.5 19.0382C6.67157 19.0382 6 18.3718 6 17.5497C6 16.7276 6.67157 16.0612 7.5 16.0612C8.32843 16.0612 9 16.7276 9 17.5497ZM16.5 19.0382C17.3284 19.0382 18 18.3718 18 17.5497C18 16.7276 17.3284 16.0612 16.5 16.0612C15.6716 16.0612 15 16.7276 15 17.5497C15 18.3718 15.6716 19.0382 16.5 19.0382Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default CarAssistantIcon;
