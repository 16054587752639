import React from 'react';
import useSelector from '../../redux/typedHooks';
import * as S from './Styles';

export interface SearchBarProps {
  isMenuOpen: boolean;
  onSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  searchInputRef: React.RefObject<HTMLInputElement>;
  isScrolled?: boolean;
}

export const SearchBar = ({
  isMenuOpen,
  onSearch,
  onSearchInputChange,
  searchInputRef,
  isScrolled,
}: SearchBarProps) => {
  const searchInputText = useSelector((state) => state.internalSiteSearch.query);
  return (
    <S.SearchBarWrapper $isMenuOpen={isMenuOpen}>
      <S.StyledForm action="#">
        <S.InputWrapper $isScrolled={isScrolled}>
          <S.SearchIcon size={24} />
          <S.StyledInput
            id="searchInput"
            name="searchQuery"
            $isScrolled={isScrolled}
            onChange={onSearchInputChange}
            onKeyDown={onSearch}
            type="text"
            enterKeyHint="search"
            placeholder="Søk"
            value={searchInputText}
            aria-label="Søk på naf.no"
            ref={searchInputRef}
            role="search"
          />
        </S.InputWrapper>
      </S.StyledForm>
    </S.SearchBarWrapper>
  );
};
