import styled, { css } from 'styled-components';
import { Input } from '@naf/input';
import { Checkbox } from '@naf/checkbox';
import { breakpoints, spacing } from '@naf/theme';

const StyledForm = styled.form`
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`;

const FieldWrapper = styled.div<{ $isVisible: boolean }>`
  margin-top: ${spacing.space12};

  @media (max-width: ${breakpoints.s}) {
    > div {
      width: 100%;
    }
  }

  ${({ $isVisible }) =>
    $isVisible === false &&
    css`
      display: none;
    `}
`;

const StyledInput = styled(Input)<{ width?: 'Wide' | 'Small' }>`
  width: ${(props) => (props.width === 'Wide' ? '100%' : '256px')};

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 16px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 12px;
  }
`;

const GroupFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  @media (min-width: ${breakpoints.s}) {
    flex-direction: row;
    > div {
      width: calc(50% - ${spacing.space16});
      label + div {
        width: 100%;
      }
    }
  }
`;

const TextFieldWrapper = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space12};
`;

export default {
  StyledForm,
  FieldWrapper,
  StyledInput,
  StyledCheckbox,
  ToggleWrapper,
  GroupFieldWrapper,
  TextFieldWrapper,
  RadioWrapper,
};
