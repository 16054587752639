import imageUrlBuilder from '@sanity/image-url';

import { Cloudinary } from '@cloudinary/url-gen';
import { fill, limitFill, limitFit } from '@cloudinary/url-gen/actions/resize';
import { autoGravity, focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { face } from '@cloudinary/url-gen/qualifiers/focusOn';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { ApplicationState } from '../redux/modules/application';

// Get a pre-configured url-builder from your sanity client
const motorBuilder = (applicationState: ApplicationState) =>
  imageUrlBuilder({
    projectId: applicationState.sanityProjectId,
    dataset: applicationState.sanityMotorDataset,
  });

const nafBuilder = (applicationState: ApplicationState) =>
  imageUrlBuilder({
    projectId: applicationState.sanityProjectId,
    dataset: applicationState.sanityNafNoDataset,
  });

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:

export interface MotorListItemImage {
  type: 'nafimage' | null;
  caption: string | null;
  asset: { reference: string | null; type: 'reference' | null };
}

export const imageUrlForMotor = (
  image: MotorListItemImage | null,
  applicationState: ApplicationState,
): ImageUrlBuilder | undefined =>
  (!!image && image.asset && image.asset.reference && motorBuilder(applicationState).image(image.asset.reference)) ||
  undefined;

export const imageUrlForNaf = (image: SanityImageObject, applicationState: ApplicationState): ImageUrlBuilder =>
  image && image.asset && nafBuilder(applicationState).image(image.asset._ref);

export const cloudinaryImage = (
  publicId: string,
  application: ApplicationState,
  width?: number | null,
  height?: number,
  focusOnFace?: boolean,
  aspectRatio?: number,
): string => {
  const cl = new Cloudinary({
    cloud: {
      cloudName: application.imageCloudName,
    },
    url: {
      secure: true,
      analytics: false,
    },
  });
  const image = cl.image(publicId);
  image.format('auto').delivery(dpr('3.0'));
  if (width && aspectRatio) {
    image.resize(fill().width(width).aspectRatio(aspectRatio).gravity(autoGravity()));
    return image.toURL();
  }
  if (width && height && focusOnFace) {
    image.resize(fill().width(width).height(height).gravity(focusOn(face())));
    return image.toURL();
  }
  if (width && height) {
    image.resize(limitFill().width(width).height(height));
    return image.toURL();
  }
  if (width || width === 0) {
    image.resize(limitFit().width(width));
    return image.toURL();
  }
  if (height) {
    image.resize(limitFit().height(height));
    return image.toURL();
  }
  return image.toURL();
};

export const cloudinaryAttachment = (publicId: string, application: ApplicationState, filename?: string): string => {
  const cl = new Cloudinary({
    cloud: {
      cloudName: application.imageCloudName,
    },
    url: {
      secure: true,
    },
  });
  const attachment = cl.image(publicId);
  const flag = filename
    ? `attachment:${filename.replace('æ', 'ae').replace('ø', 'oe').replace('å', 'aa')}`
    : `attachment`;
  attachment.addFlag(flag);
  return attachment.toURL();
};
