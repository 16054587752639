import { Select } from '@naf/input';
import { Label } from '@naf/label';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import React, { useState } from 'react';
import styled from 'styled-components';
import { VehicleTestData } from '../../../../../types/showcaseBlockType';
import { ChargeCurve } from '../../bilguide/chart/ChargeCurve';
import { Season } from '../../../../../types/ElPrixContextData';

type Props = {
  data: VehicleTestData[];
  season: Season;
};

export const plotLineStyle: Highcharts.YAxisPlotLinesOptions = {
  label: {
    align: 'left',
  },
  width: 2,
  color: nafColor.neutral.neutral3,
  dashStyle: 'Solid',
  zIndex: 1,
};

export const ChargeCurveWrapper: React.FC<Props> = ({ data, season }) => {
  const [idx, setIdx] = useState<number>(0);
  const selected = data[idx];

  return (
    <div>
      <Label text="Testbil" />
      <StyledSelect
        placeholder="Velg bil..."
        selected={idx === -1 ? { value: '', label: '' } : { value: selected.vehicleId, label: selected.name }}
        options={data.map(({ name, vehicleId }) => ({ value: vehicleId, label: name }))}
        handleSelect={(e: { value: string; label: string }) => setIdx(data.findIndex((d) => d.vehicleId === e.value))}
      />
      {idx !== -1 && selected.testData?.chargeTest && (
        <ChargeCurve
          asBlock
          summerData={season === 'summer' ? selected.testData.chargeTest : undefined}
          winterData={season === 'winter' ? selected.testData.chargeTest : undefined}
          plotLines={[
            {
              label: {
                align: 'right',
                text: `GJ.SNITT: ${Math.round(selected.testData.chargeTest.averageChargeEfficiency)} kW`,
                x: -5,
              },
              zIndex: 2,
              width: 2,
              value: selected.testData.chargeTest.averageChargeEfficiency,
              dashStyle: 'Dash',
              color: season === 'winter' ? nafColor.information.information : nafColor.primary.park,
            },
            {
              ...plotLineStyle,
              label: {
                align: 'left',
                text: 'Hurtiglading'.toUpperCase(),
              },
              value: 50,
            },
            {
              ...plotLineStyle,
              label: {
                align: 'left',
                text: 'Lynlading'.toUpperCase(),
              },
              value: 150,
            },
            {
              ...plotLineStyle,
              label: {
                align: 'left',
                text: 'Normallading'.toUpperCase(),
              },
              className: 'normal-charging',
              value: 0,
            },
          ]}
        />
      )}
    </div>
  );
};

const StyledSelect = styled(Select)`
  margin-bottom: ${spacing.space24};
`;
