import React, { useContext } from 'react';
import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { DowngradeContext } from '../../../../../pages/downgrade/context/DowngradeContext';

interface TextWithEmailProps {
  value: {
    text: string;
  };
}

export const TextWithEmail = ({ value: { text } }: TextWithEmailProps) => {
  const { email } = useContext(DowngradeContext);

  return (
    <Text variant={TextVariant.ArticleText}>
      {text}{' '}
      <BoldText tag="span" variant={TextVariant.ArticleText}>
        {email}
      </BoldText>
    </Text>
  );
};

export const TextWithEmailSerializer =
  () =>
  ({ value }: TextWithEmailProps) =>
    <TextWithEmail value={value} />;

export const BoldText = styled(Text)`
  font-weight: 700;
`;
