import React from 'react';
import { CardSize } from '@naf/cards';
import { AdvancedImage } from '@cloudinary/react';
import { MainEntryType } from '../../../../types/siteStructureType';
import useSelector from '../../redux/typedHooks';
import { isInternalLink } from '../../lib/isInternalLink';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { NavCardLink } from '../styled-link/StyledLink';
import { ShortCutCol, StyledCardNavigation } from './Styles';
import { useCloudinary } from '../../hooks/useCloudinary';

export const MainEntryCard = ({ entry, col }: { entry: MainEntryType; col: string }) => {
  const application = useSelector((state) => state.application);

  // Image handling
  const cld = useCloudinary();
  const itemImage = entry.illustration?.publicId && cld.image(entry.illustration?.publicId); // .format('svg');

  // Link handling
  let isInternal = false;
  let internalItemUrl: string | undefined;
  let isBlank = false;

  // Handle the plain internal link case
  internalItemUrl = useDocumentUrlWithNode(entry.internalLink);
  if (entry.internalLink?.slug) {
    isInternal = true;

    // Check whether the internal link should open a new tab
    if (entry.internalLink?.blank) {
      isBlank = entry.internalLink.blank;
    }
  }

  // If not plain internal -> check whether the external link points to an internal address
  if (!isInternal && entry.externalLink?.href) {
    const { isInternal: isInternalExternalLink, relativeLink } = isInternalLink(
      application.baseUrl,
      entry.externalLink.href,
    );
    isInternal = isInternalExternalLink;
    if (isInternal) {
      internalItemUrl = relativeLink || '';
    }

    // Check whether the external link should open a new tab regardless of whether it is of external or internal type
    if (entry.externalLink.blank) {
      isBlank = entry.externalLink.blank;
    }
  }

  if (isInternal && internalItemUrl) {
    return (
      <ShortCutCol s="12" m="6" l={6} xl={col} key={internalItemUrl}>
        <NavCardLink to={internalItemUrl} target={isBlank ? '_blank' : '_self'}>
          <StyledCardNavigation
            title={entry.title || entry.internalLink?.name || ''}
            size={parseInt(col, 10) <= 3 ? CardSize.Medium : CardSize.Large}
            illustration={itemImage && <AdvancedImage cldImg={itemImage} alt={entry.illustration?.altText || ''} />}
            height="100%"
            colorVariant={entry.colorVariant}
            isInternalLink
          >
            {entry.description}
          </StyledCardNavigation>
        </NavCardLink>
      </ShortCutCol>
    );
  }

  if (entry.externalLink?.href) {
    return (
      <ShortCutCol s="12" m="6" l="6" xl={col} key={entry.externalLink.href}>
        <StyledCardNavigation
          href={entry.externalLink?.href}
          target={isBlank ? '_blank' : '_self'}
          title={entry.title}
          size={parseInt(col, 10) <= 3 ? CardSize.Medium : CardSize.Large}
          illustration={itemImage && <AdvancedImage cldImg={itemImage} alt={entry.illustration?.altText || ''} />}
          height="100%"
          colorVariant={entry.colorVariant}
        >
          {entry.description}
        </StyledCardNavigation>
      </ShortCutCol>
    );
  }

  return (
    <ShortCutCol s="12" m="6" l={6} xl={col} key={entry.title}>
      <StyledCardNavigation
        title={entry.title}
        isNotClickable
        size={parseInt(col, 10) <= 3 ? CardSize.Medium : CardSize.Large}
        illustration={itemImage && <AdvancedImage cldImg={itemImage} alt={entry.illustration?.altText || ''} />}
        height="100%"
        colorVariant={entry.colorVariant}
      >
        {entry.description}
      </StyledCardNavigation>
    </ShortCutCol>
  );
};
