import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { TextList, TextListItem } from '@naf/text-list';
import useSelector from '../../../../../redux/typedHooks';
import { ProductID } from '../../../../../lib/ProductID';

export const MembershipOverview = () => {
  const { membership, relatedCustomers, services } = useSelector(
    (state) => state.myMembership.customerInformation.data,
  );

  const hasMc = !!services.find((service) => service.product.id === ProductID.MC);
  const hasRoadSideAssistance = services.find((service) => service.product.id === ProductID.RoadSideAssistance);
  const showOverview = relatedCustomers.length > 0 || hasMc;

  if (!showOverview) {
    return null;
  }

  return (
    <div>
      <Text variant={TextVariant.ArticleTextLargeHeader}>Oppsigelsen gjelder:</Text>
      <Text variant={TextVariant.ArticleText}>{membership.product.name}</Text>
      {relatedCustomers.length > 0 && (
        <>
          <Text variant={TextVariant.ArticleText}>{`Husstandsmedlem${
            hasRoadSideAssistance ? ' med veihjelp' : ''
          }`}</Text>
          <TextList variant="bullet">
            {relatedCustomers.map((relatedCustomer) => (
              <TextListItem key={relatedCustomer.customerId}>{relatedCustomer.fullName}</TextListItem>
            ))}
          </TextList>
        </>
      )}
      {hasMc && (
        <Text variant={TextVariant.ArticleText}>{`MC-medlemskap${hasRoadSideAssistance ? ' med veihjelp' : ''}`}</Text>
      )}
    </div>
  );
};

export const MembershipOverviewSerializer = () => () => <MembershipOverview />;
