import { Text, TextVariant } from '@naf/text';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Money } from '@styled-icons/material/Money';
import { PrivacyTip } from '@styled-icons/material/PrivacyTip';
import { ChevronRight } from '@styled-icons/material/ChevronRight';
import { AccountBox } from '@styled-icons/material/AccountBox';
import { Analytics } from '@styled-icons/material/Analytics';
import { BookmarkBorder } from '@styled-icons/material/BookmarkBorder';
import { Build } from '@styled-icons/material/Build';
import { CardMembership } from '@styled-icons/material/CardMembership';
import { FavoriteBorder } from '@styled-icons/material/FavoriteBorder';
import { Feedback } from '@styled-icons/material/Feedback';
import { Fingerprint } from '@styled-icons/material/Fingerprint';
import { Gavel } from '@styled-icons/material/Gavel';
import { LiveHelp } from '@styled-icons/material/LiveHelp';
import { MarkunreadMailbox } from '@styled-icons/material/MarkunreadMailbox';
import { Receipt } from '@styled-icons/material/Receipt';
import { StarBorderPurple500 } from '@styled-icons/material/StarBorderPurple500';
import { ThumbUp } from '@styled-icons/material/ThumbUp';
import { breakpoints } from '@naf/theme';

type Props = {
  faq: {
    title: string;
    slug: {
      current: string;
    };
    icon: string;
  };
};

const getIcon = (title: string) => {
  switch (title) {
    case 'MoneyIcon':
      return <Money height={32} width={32} />;
    case 'PrivacyTipIcon':
      return <PrivacyTip height={32} width={32} />;
    case 'BuildIcon':
      return <Build height={32} width={32} />;
    case 'CardMembershipIcon':
      return <CardMembership height={32} width={32} />;
    case 'MarkunreadMailboxIcon':
      return <MarkunreadMailbox height={32} width={32} />;
    case 'StarBorderPurple500Icon':
      return <StarBorderPurple500 height={32} width={32} />;
    case 'GavelIcon':
      return <Gavel height={32} width={32} />;
    case 'FeedbackIcon':
      return <Feedback height={32} width={32} />;
    case 'LiveHelpIcon':
      return <LiveHelp height={32} width={32} />;
    case 'FavoriteBorderIcon':
      return <FavoriteBorder height={32} width={32} />;
    case 'FingerprintIcon':
      return <Fingerprint height={32} width={32} />;
    case 'ThumbUpIcon':
      return <ThumbUp height={32} width={32} />;
    case 'ReceiptIcon':
      return <Receipt height={32} width={32} />;
    case 'AccountBoxIcon':
      return <AccountBox height={32} width={32} />;
    case 'BookmarkBorderIcon':
      return <BookmarkBorder height={32} width={32} />;
    case 'AnalyticsIcon':
      return <Analytics height={32} width={32} />;
    default:
      return null;
  }
};

const FAQLink = ({ faq }: Props) => (
  <LinkWrapper to={`/kundeservice/${faq.slug?.current || ''}`}>
    {getIcon(faq.icon)}
    <Text variant={TextVariant.Header3}>{faq.title}</Text>
    <ChevronRight height={24} width={24} />
  </LinkWrapper>
);

const LinkWrapper = styled(Link)`
  grid-column: auto / span 6;
  display: flex;
  align-items: center;
  max-width: 448px;
  text-decoration: none;
  border-bottom: 1px solid #e8e8e8;

  & > h3 {
    margin-left: 28px;
    margin-right: auto;
  }

  @media (max-width: ${breakpoints.m}) {
    max-width: 100%;
    grid-column: auto / span 12;
  }
`;

export default FAQLink;
