import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

export const useAnchors = () => {
  const history = useHistory();
  const routerLocation = useLocation();
  const [mutationObserver, setMutationObserver] = useState<MutationObserver | null>(null);
  const [isNewRoute, setIsNewRoute] = useState(true);
  const [storedTimer, setStoredTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const unListen = history.listen((_, action) => {
      if (action === 'PUSH') {
        setMutationObserver(null);
        setIsNewRoute(true);
      }
    });
    return () => {
      unListen();
    };
  }, [history]);

  const elementId = useMemo(() => decodeURI(routerLocation.hash.slice(1)), [routerLocation]);

  useEffect(() => {
    if (elementId && isNewRoute && !mutationObserver) {
      const newMutationObserver = new MutationObserver(() => {
        const element = document.getElementById(elementId);
        if (element && isNewRoute) {
          element.scrollIntoView({ behavior: 'auto' });
          return true;
        }
        return false;
      });
      newMutationObserver?.observe(document, {
        attributes: false,
        childList: true,
        subtree: true,
      });
      setMutationObserver(newMutationObserver);
      const timer = setTimeout(() => {
        newMutationObserver.disconnect();
      }, 450);
      setStoredTimer(timer);
      setIsNewRoute(false);
      return () => {
        mutationObserver?.disconnect();
        setMutationObserver(null);
        if (storedTimer) {
          clearTimeout(storedTimer);
          setStoredTimer(null);
        }
      };
    }

    return () => {};
  }, [elementId, isNewRoute, mutationObserver, storedTimer]);
};
