import styled, { css, keyframes } from 'styled-components';
import { breakpoints } from '@naf/theme';

const resizeAnimation = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
`;

export const ChristmasWrapper = styled.div<{ $isScrolled?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 80px;
`;

export const ScaledContent = styled.div<{ $isScrolled?: boolean }>`
  position: relative;
  transform-origin: top left;
  transition: transform 0.3s ease-in-out;
  display: flex;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      @media (min-width: ${breakpoints.l}) {
        transform: scale(0.5);
        animation: ${resizeAnimation} 0.3s ease-in-out;
      }
    `};

  @media (max-width: ${breakpoints.l}) {
    transform: scale(0.8);
  }
`;

export const DesktopWrapper = styled.div`
  position: relative;
  display: flex;
  left: -50px;
  top: -10px;
  transition: left 0.3s ease-in-out;

  @media (max-width: ${breakpoints.l}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.l}) {
    position: relative;
    display: flex;
    left: -35px;
    top: -7px;
  }
`;

export const LightsWrapper = styled.div`
  position: relative;
`;

export const SnowContainer = styled.div`
  display: flex;
`;

export const SmallSnowWrapper = styled.div`
  position: relative;
  left: -20px;

  @media (max-width: ${breakpoints.l}) {
    left: -35px;
    height: 50px;
  }
`;

export const LargeSnowWrapper = styled.div`
  position: relative;

  @media (max-width: ${breakpoints.l}) {
    display: none;
  }
`;
