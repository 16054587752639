import styled, { css } from 'styled-components';
import { breakpoints } from '@naf/theme';

export const Logo = styled.img<{ $isScrolled?: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  width: 44px;
  transition: all 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      height: 32px;
      width: 32px;
    `}

  @media (max-width: ${breakpoints.m}) {
    height: 40px;
    width: 40px;
  }
`;
