import React from 'react';
import styled from 'styled-components';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface Props {
  value: {
    src: string;
    title: string;
  };
}

export const PodcastBlock = ({ value, value: { src, title } }: Props) => {
  if (src) {
    return <StyledIframe width="100%" title={title} src={src} />;
  }
  return <pre>{JSON.stringify(value, null, 2)}</pre>;
};

export const PodcastSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: Props) => (
      <ArticleBlockContentGridCol>
        <PodcastBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: Props) => <PodcastBlock value={value} />;
};

const StyledIframe = styled.iframe`
  border: none;
`;
