import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { ArticleResponse, ArticleType, Category } from '../../../../types/articleType';
import { SiteStructureType } from '../../../../types/siteStructureType';

// Actions
export enum Actions {
  GET_ARTICLE_LIST = 'GET_ARTICLE_LIST',
  GET_ARTICLE_LIST_SUCCESS = 'GET_ARTICLE_LIST_SUCCESS',
  GET_ARTICLE_LIST_FAIL = 'GET_ARTICLE_LIST_FAIL',
  GET_ARTICLE_LIST_CANCEL = 'GET_ARTICLE_LIST_CANCEL',
  GET_MORE_ARTICLES = 'GET_MORE_ARTICLES',
  GET_MORE_ARTICLES_SUCCESS = 'GET_MORE_ARTICLES_SUCCESS',
  GET_MORE_ARTICLES_FAIL = 'GET_MORE_ARTICLES_FAIL',
  GET_MORE_ARTICLES_CANCEL = 'GET_MORE_ARTICLES_CANCEL',
}

const allArticlesKey = 'all-articles';

export interface State {
  data: Record<
    SiteStructureType['slug'] | 'all-articles',
    {
      pages: Record<number, { isLoading: boolean; data?: Record<ArticleType['slug'], ArticleType> }>;
      total: number;
      totalFetchedArticles: number;
      isLoadingMore: boolean;
      numberOfLoadedPages: number;
      categories?: Category[];
      isUpdating: boolean;
      errorState?: Error;
    }
  >;
}

export const initialState: State = {
  data: {},
};

export const actions = {
  getArticleList: createAsyncAction(
    Actions.GET_ARTICLE_LIST, // request payload creator
    Actions.GET_ARTICLE_LIST_SUCCESS, // success payload creator
    Actions.GET_ARTICLE_LIST_FAIL, // failure payload creator
    Actions.GET_ARTICLE_LIST_CANCEL, // optional cancel payload creator
  )<
    { category: SiteStructureType | null; simpleToken?: string | null } | null,
    [ArticleResponse, { category?: SiteStructureType }],
    [Error, { category?: SiteStructureType }],
    SiteStructureType
  >(),
  getMoreArticles: createAsyncAction(
    Actions.GET_MORE_ARTICLES, // request payload creator
    Actions.GET_MORE_ARTICLES_SUCCESS, // success payload creator
    Actions.GET_MORE_ARTICLES_FAIL, // failure payload creator
    Actions.GET_MORE_ARTICLES_CANCEL, // optional cancel payload creator
  )<
    { category: SiteStructureType | null; simpleToken?: string | null } | null,
    [ArticleResponse, { category?: SiteStructureType }],
    [Error, { category?: SiteStructureType }],
    SiteStructureType
  >(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getArticleList.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.payload?.category?.slug || allArticlesKey;
      draftState.data[slug] = {
        pages: { 1: { data: null, isLoading: true } },
        isLoadingMore: true,
        numberOfLoadedPages: 0,
        totalFetchedArticles: 0,
        total: 0,
        isUpdating: false,
      };
    }),
  )
  .handleAction(actions.getArticleList.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.meta.category?.slug || allArticlesKey;
      draftState.data[slug].isUpdating = false;
      draftState.data[slug].total = action.payload.total;
      draftState.data[slug].totalFetchedArticles += action.payload.items.length;
      draftState.data[slug].categories = action.payload.categories;
      draftState.data[slug].isLoadingMore = false;
      draftState.data[slug].numberOfLoadedPages = 1;
      draftState.data[slug].pages = {
        1: {
          data: action.payload.items.reduce((acc: Record<ArticleType['slug'], ArticleType>, cur) => {
            acc[cur.slug] = { ...cur, stateMeta: { isEnriched: false, fetchedAt: new Date().toISOString() } };
            return acc;
          }, {}),
          isLoading: false,
        },
      };
    }),
  )
  .handleAction(actions.getArticleList.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.meta.category?.slug || allArticlesKey;
      draftState.data[slug].isUpdating = false;
      delete draftState.data[slug].pages[1];
      draftState.data[slug].errorState = action.payload;
    }),
  )
  .handleAction(actions.getArticleList.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.payload?.slug || allArticlesKey;
      delete draftState.data[slug].pages[1];
      draftState.data[slug].isUpdating = false;
    }),
  )
  .handleAction(actions.getMoreArticles.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.payload?.category?.slug || allArticlesKey;
      draftState.data[slug].isLoadingMore = true;
      draftState.data[slug].pages[state.data[slug].numberOfLoadedPages + 1] = {
        data: null,
        isLoading: true,
      };
    }),
  )
  .handleAction(actions.getMoreArticles.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.meta.category?.slug || allArticlesKey;
      draftState.data[slug].isLoadingMore = false;
      draftState.data[slug].totalFetchedArticles += action.payload.items.length;
      draftState.data[slug].pages[state.data[slug].numberOfLoadedPages + 1] = {
        isLoading: false,
        data: action.payload.items.reduce((acc: Record<ArticleType['slug'], ArticleType>, cur) => {
          acc[cur.slug] = { ...cur, stateMeta: { isEnriched: false, fetchedAt: new Date().toISOString() } };
          return acc;
        }, {}),
      };
      draftState.data[slug].numberOfLoadedPages += 1;
    }),
  )
  .handleAction(actions.getMoreArticles.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.meta.category?.slug || allArticlesKey;
      draftState.data[slug].isLoadingMore = false;
      delete draftState.data[slug][state.data[slug].numberOfLoadedPages + 1];
      draftState.data[slug].errorState = action.payload;
    }),
  )
  .handleAction(actions.getMoreArticles.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      const slug = action.payload?.slug || allArticlesKey;
      draftState.data[slug].isLoadingMore = false;
      delete draftState.data[slug].pages[draftState.data[slug].numberOfLoadedPages + 1];
    }),
  );

const fetchArticleListEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_ARTICLE_LIST),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getArticleList.request>[]
    >,
    switchMap(([action]: { payload: { category?: SiteStructureType | null; simpleToken?: string } }[]) => {
      const query = action.payload?.category
        ? `categories=${action.payload.category?.slug}&orderBy=published&take=12`
        : `orderBy=published&take=12`;

      return ajax<ArticleResponse>({
        url: `${apimBaseUrl}/${apimNafNoApi}/articles?${query}&includeCategories=false`,
        headers: {
          'Ocp-Apim-Subscription-Key': apimContentHub,
          ...(action.payload.simpleToken ? { Authorization: `Bearer ${action.payload.simpleToken}` } : {}),
        },
      }).pipe(
        map(({ response }) => actions.getArticleList.success(response, { category: action.payload?.category })),
        catchError(() =>
          of(
            actions.getArticleList.failure(new Boom.Boom('Could not get articles'), {
              category: action.payload.category,
            }),
          ),
        ),
      );
    }),
  );
};

const fetchMoreArticlesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MORE_ARTICLES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMoreArticles.request>[]
    >,
    switchMap(([action]: { payload: { category?: SiteStructureType | null; simpleToken?: string } }[]) => {
      let query;
      if (action.payload?.category) {
        query =
          action.payload.category.isGlobalTag && action.payload.category.parentSlug
            ? `categories=${action.payload.category.parentSlug}&tags=${
                action.payload.category.slug
              }&orderBy=published&skip=${
                state$.value.articles.data[action.payload.category.slug].totalFetchedArticles
              }&take=12`
            : `categories=${action.payload.category.slug}&orderBy=published&skip=${
                state$.value.articles.data[action.payload.category.slug].totalFetchedArticles
              }&take=12`;
      } else {
        query = `orderBy=published&take=12&skip=${state$.value.articles.data[allArticlesKey].totalFetchedArticles}`;
      }

      return ajax<ArticleResponse>({
        url: `${apimBaseUrl}/${apimNafNoApi}/articles?${query}&includeCategories=false`,
        headers: {
          'Ocp-Apim-Subscription-Key': apimContentHub,
          ...(action.payload.simpleToken ? { Authorization: `Bearer ${action.payload.simpleToken}` } : {}),
        },
      }).pipe(
        map(({ response }) => actions.getMoreArticles.success(response, { category: action.payload.category })),
        catchError(() =>
          of(
            actions.getMoreArticles.failure(new Boom.Boom('Could not get more articles'), {
              category: action.payload.category,
            }),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchMoreArticlesEpic, fetchArticleListEpic];
