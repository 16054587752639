import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';

export const useAuth0Token = (): { simpleToken: string | undefined; richToken: string | undefined } => {
  const [simpleToken, setSimpleToken] = useState<string | undefined>(undefined);
  const [richToken, setRichToken] = useState<string | undefined>(undefined);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const generateAccessToken = useCallback(async () => {
    try {
      const newToken = await getAccessTokenSilently();
      setSimpleToken(newToken);
      const claims = await getIdTokenClaims();
      // eslint-disable-next-line no-underscore-dangle
      setRichToken(claims?.__raw);
    } catch (e) {
      // console.log(e);
    }
  }, [getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    generateAccessToken().then();
  }, [generateAccessToken]);

  return { simpleToken, richToken };
};
