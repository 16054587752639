import React from 'react';
import { MAIN_URL, DEFAULT_IMG_URL } from '../../lib/constants';

interface Props {
  title?: string;
  description?: string;
  datePublished?: any;
  dateModified?: any;
  author?: string;
  url?: string;
  articleBody?: string;
  logo?: string;
  imgUrl?: string;
  id?: string;
  isAccessibleForFree?: boolean;
}

const SchemaArticle = ({
  title = 'NAF - Norges Automobil-Forbund | NAF',
  description = '',
  datePublished = '',
  dateModified = '',
  author = 'NAF',
  articleBody = '',
  logo = DEFAULT_IMG_URL,
  url = '',
  imgUrl = DEFAULT_IMG_URL,
  id = '',
  isAccessibleForFree = true,
}: Props) => {
  const structuredData = `{
        "@context": "http://schema.org",
		    "@type": "Article",
		    "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://google.com/article"
        },
        "headline": "${title?.replace(/"/g, '')}",
        "image": "${imgUrl}",
        ${datePublished ? `"datePublished": "${datePublished}",` : ''}
        ${dateModified ? `"dateModified": "${dateModified}",` : ''}
        ${
          author
            ? `"author": {
            "@type": "${author ? 'Person' : 'Organization'}",
            "name": "${author}"
        },`
            : ''
        }
        ${articleBody ? `"articleBody": "${articleBody?.replace(/"/g, '')}",` : ''}
        ${
          !isAccessibleForFree
            ? `
            "isAccessibleForFree": false,
            "hasPart": {
              "@type": "WebPageElement",
              "isAccessibleForFree": false,
              "cssSelector" : ".paywall"
            },`
            : ''
        }
        "publisher": {
            "@type": "Organization",
            "name": "NAF",
            "logo": {
                "@type": "ImageObject",
                "url": "${logo}"
            }
        },
        "description": "${description?.replace(/"/g, '')}",
        "url": "${MAIN_URL}${url}"
    }`;

  return (
    // eslint-disable-next-line react/no-danger
    <script type="application/ld+json" key={`articleJSON-${id}`} dangerouslySetInnerHTML={{ __html: structuredData }} />
  );
};

export default SchemaArticle;
