import React, { useContext } from 'react';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import { DowngradeContext } from '../../../../../pages/downgrade/context/DowngradeContext';

interface InfoBoxSerializerProps {
  value: {
    text: string;
  };
}

export const WarningUserInteraction = ({ value }: InfoBoxSerializerProps) => {
  const { unpaidInvoice } = useContext(DowngradeContext);

  if (!unpaidInvoice) return null;

  return <UserInteraction variant={UserInteractionVariant.Warning}>{value.text}</UserInteraction>;
};

export const WarningUserInteractionSerializer =
  () =>
  ({ value }: InfoBoxSerializerProps) =>
    <WarningUserInteraction value={value} />;
