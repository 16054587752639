import { Button } from '@naf/button';
import { Link } from 'react-router-dom';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: ${spacing.space8};
  justify-content: center;
  align-items: center;
`;

export const StyledLink = styled(Link)<{ $hideButton?: boolean }>`
  display: ${({ $hideButton }) => ($hideButton ? 'none' : 'block')};
`;

export const StyledButton = styled(Button)<{ $isScrolled?: boolean; $spruceVariant?: boolean }>`
  transition: height 0.3s ease-in-out;
  border-radius: ${spacing.space4};
  height: 44px;
  color: ${nafColor.signature.black} !important;
  padding: ${spacing.space12};

  @media (max-width: ${breakpoints.l}) {
    height: 38px;
  }

  @media (max-width: ${breakpoints.s}) {
    min-width: 160px;
    width: 100%;
  }

  span {
    transition: height, font 0.3s ease-in-out;
    color: inherit;
    height: 44px;
    padding: 0 -${spacing.space12};
  }

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      @media (min-width: ${breakpoints.l}) {
        height: 30px;
      }
    `}

  ${({ $spruceVariant }) =>
    $spruceVariant &&
    css`
      color: ${nafColor.signature.white} !important;
      transition: height 0.3s ease-in-out;

      &:hover {
        span {
          color: ${nafColor.primary.spruce} !important;
        }
      }
    `}
`;
