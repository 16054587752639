import styled, { css } from 'styled-components';
import { breakpoints } from '@naf/theme';

export const ElPrixWrapper = styled.div`
  display: flex;
  height: 100%;
  width: calc(100vw - 32px);
  max-width: 1400px;

  @media (max-width: ${breakpoints.m}) {
    height: 50%;
  }
`;

export const DesktopWrapper = styled.div<{ $isScrolled?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 8px;
  transition: margin 0.3s ease-in-out;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.l}) {
    ${({ $isScrolled }) =>
      $isScrolled &&
      css`
        margin: -4px;
      `}
  }

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: flex;
    width: 100%;
    height: 50%;
    margin-top: 5px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    display: flex;
  }
`;
