import React, { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { DialogArticle } from '../../../DialogArticle/DialogArticle';

interface InternalLinkInDialogProps {
  href: string;
  trackingFunction: (e: MouseEvent) => void;
  children: ReactNode[] | ReactNode;
}
export const InternalLinkInDialog = ({ href, trackingFunction, children }: InternalLinkInDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    trackingFunction(e);
    setOpen(true);
  };

  const slug = href.split('/').pop();

  return (
    <DialogArticle slug={slug} isOpen={open} setIsOpen={setOpen}>
      <Link to={slug} onClick={onClick}>
        {children}
      </Link>
    </DialogArticle>
  );
};
