import React from 'react';
import { SocialProof } from '@naf/quote';
import { Link } from 'react-router-dom';
import useSelector from '../../../../../redux/typedHooks';
import { cloudinaryImage } from '../../../../../utils/imageUrl';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { SocialProofType } from '../../../../../../../types/blockTypes';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';
import { isInternalLink } from '../../../../../lib/isInternalLink';

interface SocialProofSerializerProps {
  value: SocialProofType;
}
export const SocialProofBlock = ({
  value: { title, rating, totalRatings, tooltip, text, author, authorImage, internalLink, externalLink, linkText },
}: SocialProofSerializerProps) => {
  const application = useSelector((state) => state.application);
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const refUrl = useDocumentUrlWithNode(internalLink) || '';
  const renderLink = () => {
    if (internalLink && isInternalLink(baseUrl, internalLink.slug).isInternal) {
      return <Link to={refUrl}>{linkText}</Link>;
    }
    return (
      <a href={externalLink?.href} target={externalLink?.blank ? '_blank' : '_self'} rel="noopener noreferrer">
        {linkText}
      </a>
    );
  };
  return (
    <SocialProof
      title={title}
      rating={rating}
      totalNumberOfRatings={totalRatings}
      tooltip={tooltip}
      caption={author}
      img={
        authorImage
          ? {
              alt: authorImage?.altText || 'forfatterbilde',
              src: cloudinaryImage(authorImage?.publicId, application, 50),
            }
          : undefined
      }
      linkComponent={renderLink()}
    >
      {text}
    </SocialProof>
  );
};
export default SocialProofBlock;
export const SocialProofSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: SocialProofSerializerProps) => (
      <ArticleBlockContentGridCol className="socialProof">
        <SocialProofBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: SocialProofSerializerProps) => <SocialProofBlock value={value} />;
};
