import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { CloudinaryImageType, CloudinaryVideoType } from '../../../../../../../types/blockTypes';
import { DynamicVideoPlayer } from '../../../../video-player/DynamicVideoPlayer';
import { ImageCaption } from '../../../../ImageCaption';

interface Props {
  value: { video: CloudinaryVideoType; videoThumbnail?: CloudinaryImageType };
}

export const VideoBlock = ({ value: { video } }: Props) => (
  <VideoWrapper>
    <DynamicVideoPlayer video={video} />
    {video?.caption && <ImageCaption caption={video.caption} />}
  </VideoWrapper>
);

export const ArticleVideoBlock = ({ value: { video, videoThumbnail } }: Props) => (
  <ArticleWrapper className="videoBlock">
    <ArticleVideoGridCol>
      <DynamicVideoPlayer video={video} image={videoThumbnail} />
    </ArticleVideoGridCol>
    {video?.caption && (
      <ArticleVideoCaptionGridCol $captionMode="text-right">
        <ImageCaption caption={video.caption} />
      </ArticleVideoCaptionGridCol>
    )}
  </ArticleWrapper>
);

export const VideoSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: Props) => <ArticleVideoBlock value={value} />;
  }
  return ({ value }: Props) => <VideoBlock value={value} />;
};

const ArticleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column dense;
  grid-column: 1 / -1;
  grid-gap: 0 32px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  margin-bottom: ${spacing.space32};
  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space24} 0;
  }
`;

const VideoWrapper = styled.div`
  margin: 64px 0 59px 0;
`;

const ArticleVideoCaptionGridCol = styled.figcaption<{ $captionMode?: 'text-right' | 'text-left' }>`
  grid-column: ${({ $captionMode }) => ($captionMode === 'text-right' ? '11 / 13' : '1 / 3')};
  align-self: end;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    grid-row: 2;
  }
`;

const ArticleVideoGridCol = styled.div`
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    grid-row: 1;
  }
`;
