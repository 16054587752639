import React, { useMemo, useState, useCallback } from 'react';
import { nafColor } from '@nafcore/theme';
import { TravelSuggestion } from '../../../../types/TravelSuggestionType';
import * as S from './styles';
import { RouteBox } from './RouteBox';
import { mapTravelModeToGoogleTravelMode, getGoogleMapsLink, getPOIIcon } from './utils';
import {
  DirectionsRenderer,
  DirectionsService,
  MapWrapper,
  Marker,
} from '../../loadable-elements/LoadableGoogleMapsAPI';

const MAP_OPTIONS = {
  mapId: '241506239fc98522',
  streetViewControl: false,
  mapTypeControl: false,
  disableDefaultUI: true,
};

const DEFAULT_ZOOM = 5;

type TravelMapProps = {
  routePoints: TravelSuggestion['routePoints'];
  travelMode: TravelSuggestion['travelMode'];
};

const TravelMap = ({ routePoints, travelMode }: TravelMapProps) => {
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
  const [markers, setMarkers] = useState<any[]>([]);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      if (map) {
        const mappedMarkers = routePoints?.map((point) => ({
          ...point,
          latlng: new google.maps.LatLng(point.latitude, point.longitude),
        }));

        setMarkers(mappedMarkers);

        if (mapInstance) {
          return; // Only run once
        }
        setMapInstance(map);
        const bounds = new google.maps.LatLngBounds();
        mappedMarkers?.forEach((marker) => {
          bounds.extend(marker.latlng);
        });
        map.fitBounds(bounds);
        const initialZoom = map.getZoom();
        map.setZoom(initialZoom - 1);
      }
    },
    [routePoints, mapInstance],
  );

  const directionsOptions = useMemo(() => {
    if (markers && markers.length > 0) {
      return {
        origin: markers[0].latlng,
        destination: markers[markers.length - 1].latlng,
        waypoints: markers.slice(1, -1).map(({ latlng }) => ({ location: latlng, stopover: false })),
        provideRouteAlternatives: false,
        travelMode: mapTravelModeToGoogleTravelMode({
          travelMode,
          isMapLoaded: !!mapInstance,
        }) as google.maps.TravelMode,
        unitSystem: google.maps.UnitSystem.METRIC,
      };
    }
    return null;
  }, [markers, travelMode, mapInstance]);

  const directionsRendererOptions: google.maps.DirectionsRendererOptions = {
    preserveViewport: true,
    directions,
    polylineOptions: {
      strokeColor: nafColor.primary.spruce,
    },
    suppressMarkers: true,
  };

  return (
    <S.MapContainer>
      <MapWrapper onLoad={onLoad} zoom={DEFAULT_ZOOM} mapOptions={MAP_OPTIONS}>
        {mapInstance && (
          <>
            {directions &&
              markers.map((marker, index) => (
                <Marker
                  key={`${marker.longitude}-${marker.latitude}`}
                  position={{
                    lat: marker.latitude,
                    lng: marker.longitude,
                  }}
                  icon={{
                    url: getPOIIcon(index, markers.length - 1),
                    scale: 8,
                  }}
                  options={{
                    zIndex: index === 0 || index === markers.length - 1 ? 10 : 1,
                  }}
                />
              ))}
            {!directions && directionsOptions && (
              <DirectionsService
                options={directionsOptions as google.maps.DirectionsRequest}
                callback={(result) => {
                  setDirections(result);
                }}
              />
            )}
            {directions && <DirectionsRenderer options={directionsRendererOptions} />}
          </>
        )}
      </MapWrapper>
      {mapInstance && (
        <RouteBox
          routePoints={routePoints}
          googleMapsLink={getGoogleMapsLink({ routePoints, travelMode, isMapLoaded: !!mapInstance })}
        />
      )}
    </S.MapContainer>
  );
};

export default TravelMap;
