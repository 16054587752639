import React from 'react';
import styled from 'styled-components';
import { Accordion } from '@naf/accordion';
import { spacing } from '@naf/theme';
import { PortableTextBlock } from '@portabletext/react';
import BlockContent from '../../../BlockContent';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface AccordionSerializerProps {
  value: { text: PortableTextBlock[]; title: string };
}

export const AccordionBlock = ({ value: { text, title } }: AccordionSerializerProps) => (
  <Accordion label={title} className="accordion">
    <AccordionContent>
      <BlockContent value={text} />
    </AccordionContent>
  </Accordion>
);

export const AccordionSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: AccordionSerializerProps) => (
      <ArticleBlockContentGridCol className="blockAccordion">
        <AccordionBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: AccordionSerializerProps) => <AccordionBlock value={value} />;
};

const AccordionContent = styled.div`
  p {
    margin: 0;
  }
  p:not(:last-child) {
    margin-bottom: ${spacing.space16};
  }
`;
