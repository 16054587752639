import styled from 'styled-components';

export const BlockContentContainer = styled.div`
  flex-direction: column;

  > p:first-child {
    margin-bottom: 8px;
    margin-top: 0;
  }

  > p:last-child {
    margin-top: 24px;
    margin-bottom: 0;
  }
`;
