import React from 'react';
import { Text, TextVariant } from '@naf/text';
import useSelector from '../../../../../../redux/typedHooks';
import { formatDate } from '../../../../../../utils/Dates';
import * as S from './styles';

export const MembershipValidUntilBlock = () => {
  const { membership } = useSelector((state) => state.myMembership.customerInformation.data);
  return (
    <S.MembershipActiveContainer>
      <Text variant={TextVariant.BodyTextHeader}>Medlemskapet er aktivt til</Text>
      <Text variant={TextVariant.ArticleTextLarge}>{formatDate(membership.validUntil)}</Text>
    </S.MembershipActiveContainer>
  );
};

export const MembershipValidUntilGreenBadgeSerializer = () => () => <MembershipValidUntilBlock />;
