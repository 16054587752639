import { FormField } from '@dtp/membership-service-types/dist/form/Field';
import { FieldComponentTypes, FieldValueTypes, SelectOptions } from '@dtp/membership-service-types';

export const calculatorForm: FormField[] = [
  {
    id: 'price',
    label: 'Kjøretøypris (Kr)',
    component: FieldComponentTypes.TextInput,
    type: FieldValueTypes.Number,
    initialValue: '250000',
    validation: {
      required: { value: true, message: 'Prisen er nødvendig for å kalkulere lånet.' },
      min: { value: 0, message: 'Prisen må være positiv' },
      max: { value: 9999999, message: 'Prisen er for høy' },
    },
  },
  {
    id: 'downPayment',
    label: 'Egenkapital (Kr)',
    component: FieldComponentTypes.TextInput,
    type: FieldValueTypes.Number,
    initialValue: '100000',
    validation: {
      required: { value: true, message: 'Mengden egenkapital er nødvendig for å kalkulere lånet.' },
      min: { value: 0, message: 'Egenkapitalen må være positiv' },
    },
  },
  {
    id: 'repaymentYears',
    label: 'Nedbetalingstid',
    component: FieldComponentTypes.Select,
    type: FieldValueTypes.Number,
    initialValue: '5',
    options: {
      selectableValues: {
        '1': {
          value: '1',
          label: '1 år',
        },
        '2': {
          value: '2',
          label: '2 år',
        },
        '3': {
          value: '3',
          label: '3 år',
        },
        '4': {
          value: '4',
          label: '4 år',
        },
        '5': {
          value: '5',
          label: '5 år',
        },
        '6': {
          value: '6',
          label: '6 år',
        },
        '7': {
          value: '7',
          label: '7 år',
        },
        '8': {
          value: '8',
          label: '8 år',
        },
        '9': {
          value: '9',
          label: '9 år',
        },
        '10': {
          value: '10',
          label: '10 år',
        },
      },
      width: '140px',
    } as unknown as SelectOptions,
    validation: {
      required: { value: true, message: 'Nedbetalingstid er nødvendig for å kalkulere lånet.' },
      min: { value: 1, message: 'Nedbetalingstiden må være minst ett år.' },
      max: { value: 10, message: 'Nedbetalingstiden kan være maks 10 år.' },
    },
  },
];
