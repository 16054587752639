import React from 'react';
import { Results } from '../../../../SectionBlocks/Testshowcase/Results';
import { TestResultBlock } from '../../../../../../../types/testResultBlock';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface TestResultSerializerProps {
  value: TestResultBlock;
}

const Block: React.FC<TestResultSerializerProps> = ({ value }) => <Results block={value} />;

export const TestResultSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: TestResultSerializerProps) => (
      <ArticleBlockContentGridCol>
        <Block value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: TestResultSerializerProps) => <Block value={value} />;
};
