import React from 'react';
import { ArticleBlockContentGridCol } from '../ArticleBlockContentGridCol';
import { DataWrapperBlock } from './DataWrapperBlock';

interface DataWrapperSerializerProps {
  value: { ariaLabel: string; chartId: string; title: string; _type: string; _key: string };
}

export const DataWrapperSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: DataWrapperSerializerProps) => (
      <ArticleBlockContentGridCol>
        <DataWrapperBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: DataWrapperSerializerProps) => <DataWrapperBlock value={value} />;
};
