import type { SanityBlock } from '@sanity/client';

export interface AlternativeType {
  alternativeText: string;
  isCorrect?: boolean;
  _key: string;
}

export interface QuestionType {
  questionText: string;
  alternatives: AlternativeType[];
  explanation: SanityBlock[];
  image?: {
    public_id: string;
    source_altText?: string;
  };
  _key: string;
}

export enum QuestionStatus {
  WRONG = 'wrong',
  CORRECT = 'correct',
  UNANSWERED = 'unanswered',
}

export type QuestionsWithStatus = QuestionType & { isAnsweredCorrectly: QuestionStatus };
