import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Text, TextVariant } from '@naf/text';
import useSelector from '../../../redux/typedHooks';
import { NavigationBarItem } from '../../../../../types/globalSettingsType';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { isInternalLink } from '../../../lib/isInternalLink';
import { useHandleNavigationClick } from '../../../utils/Tracking/Navigation/NavigationTracking';
import * as S from './Styles';

interface NavbarLinkProps {
  item: NavigationBarItem;
  isScrolled?: boolean;
}

export const NavbarLink: React.FC<NavbarLinkProps> = ({ item, isScrolled }) => {
  const location = useLocation();
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const refUrl = useDocumentUrlWithNode(item.link.data) || '';
  const navbarItemLocation = 'navbarItem';
  const buttonVariant = isScrolled ? TextVariant.ButtonSmall : TextVariant.Button;
  const handleOnClick = useHandleNavigationClick({
    event: 'navbarMenuClick',
    menuPage: location.pathname,
    menuSectionName: navbarItemLocation,
    menuItemName: item.header,
    menuItemUrl: refUrl,
  });

  if (item.link.data.type === 'internalLink' || isInternalLink(baseUrl, item.link.data.slug).isInternal) {
    return (
      <S.NavbarLinkItem $isScrolled={isScrolled}>
        <Link to={refUrl} onClick={handleOnClick}>
          <Text tag="span" variant={buttonVariant}>
            {item.header}
          </Text>
        </Link>
      </S.NavbarLinkItem>
    );
  }

  return (
    <S.NavbarLinkItem $isScrolled={isScrolled}>
      <a
        href={item.link.data.href}
        target={item.link.data.blank ? '_blank' : '_self'}
        rel="noopener noreferrer"
        onClick={handleOnClick}
      >
        <Text tag="span" variant={buttonVariant}>
          {item.header}
        </Text>
      </a>
    </S.NavbarLinkItem>
  );
};
