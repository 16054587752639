import { breakpoints, spacing } from '@naf/theme';
import React from 'react';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
};

export const BlockContentWrapper: React.FC<Props> = ({ children }) => (
  <BlockContentContainer>{children}</BlockContentContainer>
);

const BlockContentContainer = styled.div`
  p {
    margin: 0 !important;
    margin-bottom: ${spacing.space20} !important;
  }

  & img + img {
    margin-top: 0;
  }

  & ul,
  & ol {
    margin-top: ${spacing.space16};
    margin-bottom: ${spacing.space24};
  }

  margin-bottom: ${spacing.space48};
  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
  }
`;
