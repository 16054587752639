import React from 'react';

const LinkedInIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.52519 6.63159H0.419922V20H4.52519V6.63159Z" fill="white" />
    <path
      d="M2.42105 4.84211C3.78947 4.84211 4.8421 3.68421 4.8421 2.42105C4.8421 1.15789 3.78947 0 2.42105 0C1.05263 0 0 1.15789 0 2.42105C0 3.68421 1.05263 4.84211 2.42105 4.84211Z"
      fill="white"
    />
    <path
      d="M11.1562 12.9481C11.1562 11.0533 11.9984 9.89543 13.6826 9.89543C15.1562 9.89543 15.8931 10.9481 15.8931 12.9481C15.8931 14.8428 15.8931 20.0007 15.8931 20.0007H19.9984C19.9984 20.0007 19.9984 15.1586 19.9984 11.4744C19.9984 7.89543 17.9984 6.10596 15.1563 6.10596C12.3141 6.10596 11.1562 8.31648 11.1562 8.31648V6.52701H7.15625V19.8954H11.1562C11.1562 20.0007 11.1562 15.0533 11.1562 12.9481Z"
      fill="white"
    />
  </svg>
);

export default LinkedInIcon;
