import React from 'react';
import BlockImage from './BlockImage';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import { ArticleTextStandardImage } from '../../../../ArticleImage/ArticleTextStandardImage';

interface ImageSerializerProps {
  value: { image: CloudinaryImageType; captionMode?: 'text-right' | 'text-left' };
}

const Image = ({ value: { image } }: ImageSerializerProps) => <BlockImage image={image} />;

export const ImageSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value: { image, captionMode } }: ImageSerializerProps) => (
      <ArticleTextStandardImage image={image} captionMode={captionMode} />
    );
  }
  return ({ value }: ImageSerializerProps) => <Image value={value} />;
};
