import React from 'react';
import { MEMBERSHIP, NAFCENTER } from '../../../lib/constants';
import {
  useHandleBecomeMemberClick,
  useHandleBookAppointmentClick,
} from '../../../utils/Tracking/Navigation/NavigationTracking';
import * as S from './Styles';

interface NavigationButtonsProps {
  isLoggedIn?: boolean;
  isScrolled?: boolean;
}

export const NavigationButtons: React.FC<NavigationButtonsProps> = ({ isLoggedIn, isScrolled }) => {
  const handleBecomeMemberClick = useHandleBecomeMemberClick('navbarMenuClick');
  const handleBookAppointmentClick = useHandleBookAppointmentClick('navbarMenuClick');

  return (
    <S.Wrapper>
      {isLoggedIn ? (
        <S.StyledLink to={`${NAFCENTER}`} onClick={handleBookAppointmentClick} className="booking-button">
          <S.StyledButton
            $isScrolled={isScrolled}
            size="small"
            aria-label="Bestill time"
            type="button"
            variant="spruce"
            $spruceVariant
          >
            Bestill time
          </S.StyledButton>
        </S.StyledLink>
      ) : (
        <>
          <S.StyledLink to={`${MEMBERSHIP}`} onClick={handleBecomeMemberClick}>
            <S.StyledButton
              $isScrolled={isScrolled}
              size="small"
              aria-label="Bli medlem"
              type="button"
              variant="signature"
            >
              Bli medlem
            </S.StyledButton>
          </S.StyledLink>
          <S.StyledLink to={`${NAFCENTER}`} onClick={handleBookAppointmentClick}>
            <S.StyledButton
              $isScrolled={isScrolled}
              size="small"
              aria-label="Bestill time"
              type="button"
              variant="spruce"
              $spruceVariant
            >
              Bestill time
            </S.StyledButton>
          </S.StyledLink>
        </>
      )}
    </S.Wrapper>
  );
};
