import React from 'react';
import { InternalSearchMetaDataType } from '../../../../types/InternalSearchMetaDataType';

export enum InternalSearchCardType {
  Simple = 'Simple',
  Article = 'Article',
  CategoryApp = 'CategoryApp',
  Product = 'Product',
  RouteSuggestion = 'RouteSuggestion',
  Car = 'Car',
  BecomeAMember = 'BecomeAMember',
  Benefit = 'Benefit',
  LocalDepartment = 'LocalDepartment',
  LocalBenefit = 'LocalBenefit',
  LocalArticle = 'LocalArticle',
  Event = 'Event',
  PracticeTrack = 'PracticeTrack',
  NAFCenter = 'NAFCenter',
  Placeholder = 'Placeholder',
}

export enum ElasticFieldNames {
  nafPageType = 'naf_page_type',
  nafPageTitle = 'naf_page_title',
  nafPageDescription = 'naf_page_description',
  nafImagePublicId = 'naf_image_publicid',
  productTitle = 'product_title',
  author = 'author',
  tag = 'tag',
  brandName = 'brand_name',
  carModelImage = 'car_model_image',
  partnerLogoUrl = 'partner_logo_url',
  partnerLogoPublicId = 'partner_logo_public_id',
  partnerName = 'partner_name',
  nafImageUrl = 'naf_image_url',
  organizerTitle = 'organizer_title',
  streetAddress = 'street_address',
  postalCode = 'postal_code',
  contactPhone = 'contact_phone',
  contactEMail = 'contact_email',
}

export const InternalSearchMetaData = ({
  internalSearchMetaData,
  title,
  description,
}: {
  internalSearchMetaData: InternalSearchMetaDataType;
  title: string;
  description: string;
}) => {
  // This component returns an array instead of a fragment, due to lack of support of fragments in React Helmet
  // https://github.com/nfl/react-helmet/issues/342
  const baseFields = [
    <meta
      className="elastic"
      key={ElasticFieldNames.nafPageType}
      name={ElasticFieldNames.nafPageType}
      content={internalSearchMetaData.cardType}
    />,
    title ? (
      <meta
        className="elastic"
        key={ElasticFieldNames.nafPageTitle}
        name={ElasticFieldNames.nafPageTitle}
        content={title}
      />
    ) : null,
    description ? (
      <meta
        className="elastic"
        key={ElasticFieldNames.nafPageDescription}
        name={ElasticFieldNames.nafPageDescription}
        content={description}
      />
    ) : null,
    internalSearchMetaData?.imagePublicId ? (
      <meta
        className="elastic"
        key={ElasticFieldNames.nafImagePublicId}
        name={ElasticFieldNames.nafImagePublicId}
        content={internalSearchMetaData.imagePublicId}
      />
    ) : null,
  ].filter(Boolean);

  if (internalSearchMetaData.cardType === InternalSearchCardType.Product) {
    const { product } = internalSearchMetaData;
    return [
      ...baseFields,
      product?.title ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.productTitle}
          name={ElasticFieldNames.productTitle}
          content={product.title}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.Article) {
    const { author, tags } = internalSearchMetaData;
    return [
      ...baseFields,
      author ? (
        <meta className="elastic" key={ElasticFieldNames.author} name={ElasticFieldNames.author} content={author} />
      ) : null,
      tags
        ? tags.map((tag) => (
            <meta className="elastic" key={ElasticFieldNames.tag} name={ElasticFieldNames.tag} content={tag} />
          ))
        : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.Car) {
    const { brandName, carModelImage } = internalSearchMetaData;
    return [
      ...baseFields,
      brandName ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.brandName}
          name={ElasticFieldNames.brandName}
          content={brandName}
        />
      ) : null,
      carModelImage ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.carModelImage}
          name={ElasticFieldNames.carModelImage}
          content={carModelImage}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.Benefit) {
    const { partnerLogoPublicId, partnerName } = internalSearchMetaData;
    return [
      ...baseFields,
      partnerLogoPublicId ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.partnerLogoPublicId}
          name={ElasticFieldNames.partnerLogoPublicId}
          content={partnerLogoPublicId}
        />
      ) : null,
      partnerName ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.partnerName}
          name={ElasticFieldNames.partnerName}
          content={partnerName}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.LocalDepartment) {
    const { imageUrl } = internalSearchMetaData;
    return [
      ...baseFields,
      imageUrl ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.nafImageUrl}
          name={ElasticFieldNames.nafImageUrl}
          content={imageUrl}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.LocalBenefit) {
    const { imageUrl, partnerName, partnerLogoUrl } = internalSearchMetaData;
    return [
      ...baseFields,
      imageUrl ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.nafImageUrl}
          name={ElasticFieldNames.nafImageUrl}
          content={imageUrl}
        />
      ) : null,
      partnerName ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.partnerName}
          name={ElasticFieldNames.partnerName}
          content={imageUrl}
        />
      ) : null,
      partnerLogoUrl ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.partnerLogoUrl}
          name={ElasticFieldNames.partnerLogoUrl}
          content={imageUrl}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.LocalArticle) {
    const { author, tags, imageUrl } = internalSearchMetaData;
    return [
      ...baseFields,
      author ? (
        <meta className="elastic" key={ElasticFieldNames.author} name={ElasticFieldNames.author} content={author} />
      ) : null,
      tags
        ? tags.map((tag) => (
            <meta className="elastic" key={ElasticFieldNames.tag} name={ElasticFieldNames.tag} content={tag} />
          ))
        : null,
      imageUrl ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.nafImageUrl}
          name={ElasticFieldNames.nafImageUrl}
          content={imageUrl}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.Event) {
    const { imageUrl, organizerTitle } = internalSearchMetaData;
    return [
      ...baseFields,
      organizerTitle ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.organizerTitle}
          name={ElasticFieldNames.organizerTitle}
          content={organizerTitle}
        />
      ) : null,
      imageUrl ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.nafImageUrl}
          name={ElasticFieldNames.nafImageUrl}
          content={imageUrl}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.PracticeTrack) {
    const { streetAddress, postalCode, contactPhone, contactEMail } = internalSearchMetaData;
    return [
      ...baseFields,
      streetAddress ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.streetAddress}
          name={ElasticFieldNames.streetAddress}
          content={streetAddress}
        />
      ) : null,
      postalCode ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.postalCode}
          name={ElasticFieldNames.postalCode}
          content={postalCode}
        />
      ) : null,
      contactPhone ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.contactPhone}
          name={ElasticFieldNames.contactPhone}
          content={contactPhone}
        />
      ) : null,
      contactEMail ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.contactEMail}
          name={ElasticFieldNames.contactEMail}
          content={contactEMail}
        />
      ) : null,
    ].filter(Boolean);
  }
  if (internalSearchMetaData.cardType === InternalSearchCardType.NAFCenter) {
    const { streetAddress, postalCode, imagePublicId } = internalSearchMetaData;
    return [
      ...baseFields,
      streetAddress ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.streetAddress}
          name={ElasticFieldNames.streetAddress}
          content={streetAddress}
        />
      ) : null,
      postalCode ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.postalCode}
          name={ElasticFieldNames.postalCode}
          content={postalCode}
        />
      ) : null,
      postalCode ? (
        <meta
          className="elastic"
          key={ElasticFieldNames.nafImagePublicId}
          name={ElasticFieldNames.nafImagePublicId}
          content={imagePublicId}
        />
      ) : null,
    ].filter(Boolean);
  }
  return baseFields;
};

export const resultFields = Object.values(ElasticFieldNames).reduce(
  (acc, cur) => {
    acc[cur] = {
      raw: {},
      snippet: {},
    };
    return acc;
  },
  {
    url: { raw: {} },
    title: { raw: {} },
    body_content: { raw: {}, snippet: {} },
    meta_description: { raw: {} },
    id: { raw: {} },
  },
);
