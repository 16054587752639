import React from 'react';
import { Button, ButtonVariant } from '@naf/button';
import { useHistory, useLocation } from 'react-router';
import { useGTMDataLayer } from '../../../../../hooks/useGTMDataLayer';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { useWindowLocation } from '../../../../../hooks/useWindowLocation';
import { RelatedReferenceComponentType } from '../../../../../../../types/articleType';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';

interface CtaButtonInternalLinkSerializerProps {
  value: {
    text: string;
    anchorId?: string;
    referenceComponent: RelatedReferenceComponentType;
    variant?: ButtonVariant;
  };
}

const CtaButtonInternalLink = ({
  value: { text, anchorId, referenceComponent, variant },
}: CtaButtonInternalLinkSerializerProps) => {
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const path = useWindowLocation();
  const history = useHistory();

  const handleTracking = () => {
    const urlObject = new URL(path);
    dataLayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_location: location.pathname,
      cta_url: path,
      cta_hostname: urlObject.hostname,
    });
  };

  const referenceUrl = useDocumentUrlWithNode(referenceComponent) || '';

  let linkUrl = referenceUrl;
  if (anchorId) {
    linkUrl = `${referenceUrl}#${anchorId}`;
  }

  return (
    <Button
      onClick={(e: any) => {
        e.preventDefault();
        handleTracking();
        history.push(linkUrl);
      }}
      variant={variant}
    >
      {text}
    </Button>
  );
};

export const CtaButtonInternalLinkSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: CtaButtonInternalLinkSerializerProps) => (
      <ArticleBlockContentGridCol className="CtaButton">
        <CtaButtonInternalLink value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: CtaButtonInternalLinkSerializerProps) => <CtaButtonInternalLink value={value} />;
};
