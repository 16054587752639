import { Text } from '@naf/text';
import styled from 'styled-components';

export const TextWithNewLine = styled(Text)`
  white-space: break-spaces;
`;

export const BoldText = styled(Text)`
  font-weight: 700;
`;
