import { Boom } from '@hapi/boom';
import { produce } from 'immer';
import { ofType } from 'redux-observable';
import { Observable, OperatorFunction, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { CenterProducts } from '../../../../types/centerArticleType';
import { TypedEpic } from '../types';
import { CenterProductArticleType } from '../../../../types/centerProductArticleType';

export enum Actions {
  GET_CENTER_PRODUCTS_WITH_ID = 'naf/centers/GET_CENTER_PRODUCTS_WITH_ID',
  GET_CENTER_PRODUCTS_WITH_ID_SUCCESS = 'naf/centers/GET_CENTER_PRODUCTS_WITH_ID_SUCCESS',
  GET_CENTER_PRODUCTS_WITH_ID_FAIL = 'naf/centers/GET_CENTER_PRODUCTS_WITH_ID_FAIL',
  GET_CENTER_PRODUCTS_WITH_ID_CANCEL = 'naf/centers/GET_CENTER_PRODUCTS_WITH_ID_CANCEL',
}

export interface State {
  products: CenterProducts[] | null;
  isUpdating: boolean;
  errorState?: Error;
}

export const initialState: State = {
  products: null,
  isUpdating: false,
};

export const actions = {
  getCenterProducts: createAsyncAction(
    Actions.GET_CENTER_PRODUCTS_WITH_ID, // request payload creator
    Actions.GET_CENTER_PRODUCTS_WITH_ID_SUCCESS, // success payload creator
    Actions.GET_CENTER_PRODUCTS_WITH_ID_FAIL, // failure payload creator
    Actions.GET_CENTER_PRODUCTS_WITH_ID_CANCEL, // optional cancel payload creator
  )<[string, { id?: number; token?: string }], CenterProducts[], Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getCenterProducts.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getCenterProducts.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.products = action.payload;
    }),
  )
  .handleAction(actions.getCenterProducts.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getCenterProducts.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

const fetchCenterProductsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CENTER_PRODUCTS_WITH_ID),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getCenterProducts.request>[]
    >,
    switchMap(([action]: { payload: string; meta?: { id?: number; token?: string } }[]) => {
      let url = `${apimBaseUrl}/${apimNafNoApi}/commonarticles/${action.payload}`;
      if (action.meta && action.meta.id) {
        url += `?centerId=${action.meta.id}`;
      }
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta && action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<CenterProductArticleType>({
        url,
        headers,
      }).pipe(
        map(({ response }) => actions.getCenterProducts.success(response.products)),
        catchError(() =>
          of(
            actions.getCenterProducts.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchCenterProductsEpic];
