import React, { useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';

import { Alternatives } from './Alternatives';
import BlockContent from '../../../../BlockContent';
import { QuestionStatus, QuestionsWithStatus, QuestionType } from './types';
import { useCloudinary } from '../../../../../../hooks/useCloudinary';

interface Props {
  question: QuestionsWithStatus;
  questionNumber: number;
  numberOfQuestions: number;
  setStatus: (id: QuestionType['_key'], status: QuestionStatus) => void;
}

export const Question = ({ question, questionNumber, numberOfQuestions, setStatus }: Props) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const cld = useCloudinary();

  const [questionIimage] = useState(
    cld.image(question.image?.public_id).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto') ||
      undefined,
  );

  const setQuestionStatus = (status: QuestionStatus) => {
    setStatus(question._key, status);
  };

  return (
    <div key={question._key}>
      <ImageWrapper>
        <AdvancedImage
          style={{ maxWidth: '100%' }}
          alt={question.image?.source_altText}
          cldImg={questionIimage}
          plugins={[
            lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
            responsive({ steps: 200 }),
            placeholder({ mode: 'blur' }),
          ]}
        />
      </ImageWrapper>
      <StyledNumbering variant={TextVariant.Header3}>
        {questionNumber} av {numberOfQuestions}
      </StyledNumbering>
      <QuestionText>{question.questionText}</QuestionText>
      {question.alternatives && (
        <Alternatives
          status={question.isAnsweredCorrectly}
          setQuestionStatus={setQuestionStatus}
          alternatives={question.alternatives}
          setSelectedValue={setSelectedValue}
          selectedValue={selectedValue}
        />
      )}
      {(question.isAnsweredCorrectly === QuestionStatus.CORRECT ||
        question.isAnsweredCorrectly === QuestionStatus.WRONG) && (
        <StyledUserInteraction
          variant={
            question.isAnsweredCorrectly === QuestionStatus.CORRECT
              ? UserInteractionVariant.Success
              : UserInteractionVariant.Error
          }
          title={question.isAnsweredCorrectly === QuestionStatus.CORRECT ? 'Det er riktig' : 'Det er feil'}
        >
          <BlockContent value={question.explanation} />
        </StyledUserInteraction>
      )}
    </div>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  object-fit: contain;
  margin-bottom: 40px;
`;

const StyledUserInteraction = styled(UserInteraction)`
  margin-bottom: 80px;
  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`;

const StyledNumbering = styled(Text)`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
`;

const QuestionText = styled.p`
  margin-top: 8px !important;
  margin-bottom: 24px !important;
`;
