import { Action, ActionType, createAsyncAction, createReducer, createAction } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import { TypedEpic } from '../types';
import { MyVehiclesResponse, VehicleObject, VehicleAlertsResponse } from '../../../../types/myVehiclesType';

// Actions
export enum Actions {
  GET_MY_VEHICLES = 'naf/myVehicles/GET_MY_VEHICLES',
  GET_MY_VEHICLES_SUCCESS = 'naf/myVehicles/GET_MY_VEHICLES_SUCCESS',
  GET_MY_VEHICLES_FAIL = 'naf/myVehicles/GET_MY_VEHICLES_FAIL',
  GET_MY_VEHICLES_CANCEL = 'naf/myVehicles/GET_MY_VEHICLES_CANCEL',
  GET_VEHICLE = 'naf/myVehicles/GET_VEHICLE',
  GET_VEHICLE_SUCCESS = 'naf/myVehicles/GET_VEHICLE_SUCCESS',
  GET_VEHICLE_FAIL = 'naf/myVehicles/GET_VEHICLE_FAIL',
  GET_VEHICLE_CANCEL = 'naf/myVehicles/GET_VEHICLE_CANCEL',
  UPDATE_VEHICLE_ALERTS = 'naf/myVehicles/UPDATE_VEHICLE_ALERTS',
  UPDATE_VEHICLE_ALERTS_SUCCESS = 'naf/myVehicles/UPDATE_VEHICLE_ALERTS_SUCCESS',
  UPDATE_VEHICLE_ALERTS_FAIL = 'naf/myVehicles/UPDATE_VEHICLE_ALERTS_FAIL',
  UPDATE_VEHICLE_ALERTS_CANCEL = 'naf/myVehicles/UPDATE_VEHICLE_ALERTS_CANCEL',
  REMOVE_VEHICLE_FROM_LIST = 'naf/myVehicles/REMOVE_VEHICLE_FROM_LIST',
  ADD_VEHICLE_TO_LIST = 'naf/myVehicles/ADD_VEHICLE_TO_LIST',
}

export interface State {
  vehicleList: {
    data?: Record<VehicleObject['licensePlateNumber'], VehicleObject>;
    isUpdating: boolean;
    errorState?: any;
  };
  vehicle: {
    data?: VehicleObject;
    isUpdating: boolean;
    errorState?: any;
  };
  alerts: {
    data?: VehicleAlertsResponse;
    isUpdating: boolean;
    errorState?: any;
  };
}

export const initialState: State = {
  vehicleList: {
    data: undefined,
    isUpdating: false,
  },
  vehicle: {
    data: undefined,
    isUpdating: false,
  },
  alerts: {
    data: undefined,
    isUpdating: false,
  },
};

export const actions = {
  getMyVehicles: createAsyncAction(
    Actions.GET_MY_VEHICLES, // request payload creator
    Actions.GET_MY_VEHICLES_SUCCESS, // success payload creator
    Actions.GET_MY_VEHICLES_FAIL, // failure payload creator
    Actions.GET_MY_VEHICLES_CANCEL, // optional cancel payload creator
  )<string, MyVehiclesResponse, Error, undefined>(),
  getVehicle: createAsyncAction(
    Actions.GET_VEHICLE,
    Actions.GET_VEHICLE_SUCCESS,
    Actions.GET_VEHICLE_FAIL,
    Actions.GET_VEHICLE_CANCEL,
  )<[string, { token?: string }], VehicleObject, Error, undefined>(),
  updateVehicleAlerts: createAsyncAction(
    Actions.UPDATE_VEHICLE_ALERTS,
    Actions.UPDATE_VEHICLE_ALERTS_SUCCESS,
    Actions.UPDATE_VEHICLE_ALERTS_FAIL,
    Actions.UPDATE_VEHICLE_ALERTS_CANCEL,
  )<
    [Record<string, string | number | boolean>[], { licensePlateNumber?: string; token?: string }],
    VehicleAlertsResponse,
    Error,
    undefined
  >(),
  removeVehicleFromList: createAction(Actions.REMOVE_VEHICLE_FROM_LIST, (licensePlateNumber: string) => ({
    licensePlateNumber,
  }))(),
  addVehicleToList: createAction(Actions.ADD_VEHICLE_TO_LIST, (vehicleObject: VehicleObject) => ({
    vehicleObject,
  }))(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getMyVehicles.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.vehicleList.isUpdating = true;
    }),
  )
  .handleAction(actions.getMyVehicles.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.vehicleList.isUpdating = false;
      draftState.vehicleList.errorState = false;
      draftState.vehicleList.data = action.payload.vehicles.reduce(
        (acc, cur) => {
          acc[cur.licensePlateNumber] = cur;
          return acc;
        },
        { ...draftState.vehicleList.data },
      );
    }),
  )
  .handleAction(actions.getMyVehicles.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.vehicleList.isUpdating = false;
      draftState.vehicleList.errorState = action.payload;
    }),
  )
  .handleAction(actions.getMyVehicles.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.vehicleList.isUpdating = false;
    }),
  )
  .handleAction(actions.getVehicle.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.vehicle.isUpdating = true;
    }),
  )
  .handleAction(actions.getVehicle.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.vehicle.isUpdating = false;
      draftState.vehicle.errorState = false;
      draftState.vehicle.data = action.payload;
    }),
  )
  .handleAction(actions.getVehicle.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.vehicle.isUpdating = false;
      draftState.vehicle.errorState = action.payload;
    }),
  )
  .handleAction(actions.getVehicle.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.vehicle.isUpdating = false;
    }),
  )
  .handleAction(actions.updateVehicleAlerts.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.alerts.isUpdating = true;
    }),
  )
  .handleAction(actions.updateVehicleAlerts.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.alerts.isUpdating = false;
      draftState.alerts.errorState = false;
      draftState.alerts.data = action.payload;
    }),
  )
  .handleAction(actions.updateVehicleAlerts.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.alerts.isUpdating = false;
      draftState.alerts.errorState = action.payload;
    }),
  )
  .handleAction(actions.updateVehicleAlerts.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.alerts.isUpdating = false;
    }),
  )
  .handleAction(actions.removeVehicleFromList, (state, action) => {
    const newData = { ...state.vehicleList.data };
    delete newData[action.payload.licensePlateNumber];

    return {
      ...state,
      vehicleList: {
        ...state.vehicleList,
        data: newData,
      },
    };
  })
  .handleAction(actions.addVehicleToList, (state, action) => {
    const newData = {
      ...state.vehicleList.data,
      [action.payload.vehicleObject.licensePlateNumber]: action.payload.vehicleObject,
    };

    return {
      ...state,
      vehicleList: {
        ...state.vehicleList,
        data: newData,
      },
    };
  });

export const getMyVehiclesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MY_VEHICLES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMyVehicles.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.payload) {
        headers.Authorization = `Bearer ${action.payload}`;
      }
      return ajax<MyVehiclesResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/vehicles`,
        headers,
      }).pipe(
        map(({ response }) => actions.getMyVehicles.success(response)),
        catchError(() =>
          of(
            actions.getMyVehicles.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente kjøretøyene dine! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const getVehicleEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_VEHICLE),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.getVehicle.request>[]>,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<VehicleObject>({
        url: `${apimBaseUrl}/${apimMembershipApi}/vehicles/${action.payload}`,
        headers,
      }).pipe(
        map(({ response }) => actions.getVehicle.success(response)),
        catchError(() =>
          of(
            actions.getVehicle.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente kjøretøyet ditt! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const updateVehicleAlertsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.UPDATE_VEHICLE_ALERTS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.updateVehicleAlerts.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax
        .post<VehicleAlertsResponse>(
          `${apimBaseUrl}/${apimMembershipApi}/upsertvehiclealerts/${action.meta.licensePlateNumber}`,
          { channelType: action.payload },
          headers,
        )
        .pipe(
          map(({ response }) => actions.updateVehicleAlerts.success(response)),
          catchError(() =>
            of(
              actions.updateVehicleAlerts.failure(
                new Boom(
                  'Oops, vi har problemer med motoren... Kunne ikke oppdatere varslingstjenesten! Ta kontakt med kundesenteret hvis problemet fortsetter!',
                ),
              ),
            ),
          ),
        );
    }),
  );
};

export const epics: TypedEpic[] = [getMyVehiclesEpic, getVehicleEpic, updateVehicleAlertsEpic];
