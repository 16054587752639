import React from 'react';

import { NafThemeType, breakpoints, spacing, themeLight } from '@naf/theme';
import styled, { css } from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { Forecasts } from './Forecast';
import { PointOfInterest } from './RouteCharts';

type Props = {
  date: string | null;
  places: PointOfInterest[];
  inArticle?: boolean;
};

const WeatherForecasts = ({ date, places, inArticle }: Props) => (
  <WeatherForecastsContainer inArticle={inArticle}>
    <StyledTitleContainer>
      {inArticle ? null : <ChartTitle variant={TextVariant.SubHeader}>Værvarsel for testdagen</ChartTitle>}
      <ForecastText variant={TextVariant.BodyText}>
        {date
          ? `${new Date(date).toLocaleDateString('no-NB', {
              weekday: 'long',
              day: '2-digit',
              month: 'long',
            })} - Mellom 07:00 og 23:00`
          : 'Mellom 07:00 og 23:00'}
      </ForecastText>
    </StyledTitleContainer>
    <Forecasts points={places} />
    <SourceText variant={TextVariant.Caption}>
      Værdata hentet fra{' '}
      <a href="https://yr.no" target="_blank" rel="noreferrer">
        yr.no
      </a>
    </SourceText>
  </WeatherForecastsContainer>
);

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  box-sizing: border-box;
  width: 100%;
  margin-bottom: ${spacing.space24};

  p {
    color: ${({ theme }: { theme?: NafThemeType }) => theme?.typography.subtleText || themeLight.typography.subtleText};
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space24};
    margin-bottom: ${spacing.space16};
  }
`;

const ChartTitle = styled(Text)`
  margin: 0;
`;

const ForecastText = styled(Text)`
  ::first-letter {
    text-transform: capitalize;
  }
`;

const SourceText = styled(Text)`
  color: ${({ theme }: { theme?: NafThemeType }) => theme?.typography.subtleText || themeLight.typography.subtleText};
  margin-top: ${spacing.space16};
  margin-bottom: 0;

  > a,
  > a:visited {
    color: ${({ theme }: { theme?: NafThemeType }) => theme?.typography.subtleText || themeLight.typography.subtleText};
    text-decoration: inherit solid underline;
  }
`;

const WeatherForecastsContainer = styled.div<{ inArticle?: boolean }>`
  ${({ inArticle }) =>
    inArticle
      ? css`
          grid-column: 3 / -3;

          @media (max-width: ${breakpoints.m}) {
            grid-column: 1 / -1;
          }
        `
      : css`
          margin-top: ${spacing.space40};
        `}
`;

export { WeatherForecasts };
