import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import { HighChartComponent } from '../../../bilguide/chart/HighChartComponent';
import { PointOfInterest } from './RouteCharts';
import { Season } from '../../../../../../types/ElPrixContextData';
import { Legend } from '../../Testshowcase/Legend';
import { formatTestSeason } from '../../../../pages/Elbilguiden/RangeTests';

type RangeData = {
  stated: number;
  measured: number;
};
interface Props {
  altitudes: number[];
  pointsOfInterest: PointOfInterest[];
  className?: any;
  rangeData?: RangeData;
  season?: Season;
  testDate?: string;
  display?: 'route' | 'range';
}

const createRouteChart = (
  altitudes: number[],
  pointsOfInterest: PointOfInterest[],
  season,
): Highcharts.SeriesOptionsType[] => [
  {
    name: 'Moh',
    data: altitudes,
    type: 'areaspline',
    lineColor: season === 'winter' ? nafColor.information.information : nafColor.information.successDark,
    color: season === 'winter' ? nafColor.information.informationLight : nafColor.primary.dew,
    zIndex: 0,
    marker: {
      symbol: 'circle',
      radius: 1,
      fillColor: nafColor.information.successDark,
      lineColor: nafColor.information.successDark,
    },
    accessibility: {
      keyboardNavigation: {
        enabled: false,
      },
    },
  },
  {
    name: 'Steder langs ruten',
    data: pointsOfInterest
      .filter((d) => d.includeOnChart)
      .map((d) => ({ name: d.title, x: d.kmFromStart, y: d.altitude })),
    marker: {
      radius: 6,
      symbol: 'circle',
      fillColor: nafColor.signature.white,
      lineColor: season === 'winter' ? nafColor.information.information : nafColor.information.successDark,
      lineWidth: 2,
    },
    type: 'scatter',
    lineWidth: 0,
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    zIndex: 7,
  },
];

const createRangeChart = ({
  altitudes,
  rangeData,
  repeatPoint,
  season,
  testDate,
  pointsOfInterest,
}: {
  altitudes: number[];
  rangeData: RangeData;
  repeatPoint: number;
  season: Season;
  testDate: string;
  pointsOfInterest: PointOfInterest[];
}): Highcharts.SeriesOptionsType[] => {
  const { stated, measured } = rangeData;
  const statedAsInt = Math.round(stated);
  const measuredAsInt = Math.round(measured);
  const route = [...altitudes];

  const lowest = Math.min(statedAsInt, measuredAsInt);
  const largest = Math.max(statedAsInt, measuredAsInt);

  const initialRouteLength = altitudes.length;
  const loopLength = initialRouteLength - repeatPoint;

  const measuredIsLower = measured < stated;
  const loopMultiplier = Math.ceil(Math.max(0, (Math.max(stated, measured) - initialRouteLength) / loopLength));
  const loopSlice = altitudes.slice(repeatPoint);

  const seasonColor = season === 'winter' ? nafColor.information.information : nafColor.information.successDark;

  const getPoiColor = (x: number) => {
    if (x < lowest) {
      return { lineColor: seasonColor, className: season };
    }
    if (x >= lowest && x <= largest) {
      return measuredIsLower
        ? { lineColor: nafColor.information.errorDark, className: 'shorter' }
        : { lineColor: nafColor.information.successDark, className: 'longer' };
    }
    return { lineColor: nafColor.neutral.neutral3, className: 'not-reached' };
  };

  for (let i = 0; i < loopMultiplier; i += 1) {
    route.push(...loopSlice);
  }

  const areaTypeConfig = {
    accessibility: {
      keyboardNavigation: {
        enabled: false,
      },
    },
    zIndex: 0,
  };

  return [
    {
      ...areaTypeConfig,
      type: 'area',
      name: 'Distance to either wltp or measured',
      data: route.map((y, x) => (x <= lowest ? y : null)),
      lineColor: seasonColor,
      color: nafColor.neutral.neutral1,
      fillOpacity: 1,
    },
    {
      ...areaTypeConfig,
      type: 'area',
      name: 'Positive or negative difference',
      lineColor: measuredIsLower ? nafColor.information.errorDark : nafColor.information.successDark,
      color: measuredIsLower ? 'rgba(255, 204, 204, 1)' : 'rgba(198, 241, 211, 1)',
      fillOpacity: 1,
      data: route.map((y, x) => (x >= lowest && x <= largest ? y : null)),
    },
    {
      ...areaTypeConfig,
      type: 'area',
      name: 'Rest of the route',
      lineColor: nafColor.neutral.neutral3,
      fillOpacity: 1,
      color: nafColor.neutral.neutral1,
      data: route.map((y, x) => (x >= largest ? y : null)),
    },
    {
      type: 'scatter',
      data: [{ name: formatTestSeason(season, testDate), x: measuredAsInt, y: route[measuredAsInt] }],
      marker: {
        radius: 6,
        symbol: 'diamond',
        fillColor: measuredIsLower ? nafColor.information.errorDark : nafColor.information.successDark,
      },
      zIndex: 7,
    },
    {
      type: 'scatter',
      data: [{ name: 'WLTP', x: statedAsInt, y: route[statedAsInt] }],
      marker: {
        radius: 1,
        symbol: 'square',
        fillColor: nafColor.signature.black,
        lineColor: nafColor.signature.black,
        lineWidth: 6,
      },
      zIndex: 7,
    },
    {
      name: 'Steder langs ruten',
      data: pointsOfInterest
        .filter((d) => d.includeOnChart)
        .map((d) => {
          const { className, lineColor } = getPoiColor(d.kmFromStart);
          return {
            name: d.title,
            x: d.kmFromStart,
            y: d.altitude,
            className,
            marker: {
              radius: 6,
              symbol: 'circle',
              fillColor: nafColor.signature.white,
              lineColor,
              lineWidth: 2,
            },
          };
        }),

      type: 'scatter',
      lineWidth: 0,
      states: {
        hover: {
          lineWidthPlus: 0,
        },
      },
      zIndex: 7,
    },
  ];
};

const Plot: React.FC<Props> = ({
  altitudes,
  pointsOfInterest,
  rangeData,
  className,
  display = 'route',
  season,
  testDate,
}) => {
  const repeatRoute = pointsOfInterest.find((poi) => poi.title === 'repeat');

  const options = useMemo(
    (): Highcharts.Options => ({
      plotOptions: {
        area: {
          pointStart: 0,
        },
        series: {
          stickyTracking: false,
          states: {
            inactive: { opacity: 1 },
            hover: { enabled: false },
          },
        },
      },
      chart: {
        zooming: {
          type: 'x',
        },
      },
      tooltip: {
        snap: 10,
        useHTML: true,
        shadow: false,
        shared: true,
        formatter: function f() {
          const { x, y, key } = this;

          const label = (
            <div>
              {key ? <b>{key}</b> : null}
              <div>
                <p>{x === 0 ? 'Start' : `${x} km`}</p>
                <p>{y} moh.</p>
              </div>
            </div>
          );

          return renderToString(label);
        },
        borderWidth: 0,
        padding: 0,
      },
      title: undefined,
      xAxis: {
        plotLines:
          display === 'range' && rangeData
            ? [
                {
                  color: season === 'winter' ? nafColor.information.information : nafColor.information.successDark,
                  width: 1,
                  value: rangeData.measured,
                  dashStyle: 'Dash',
                  zIndex: 2,
                },
                {
                  color: season === 'winter' ? nafColor.information.information : nafColor.information.successDark,
                  width: 1,
                  value: rangeData.stated,
                  dashStyle: 'Dash',
                  zIndex: 2,
                },
              ]
            : undefined,
        plotBands: repeatRoute
          ? [
              {
                from: repeatRoute.kmFromStart,
                to: altitudes.length - 1,
                label: {
                  style: { color: nafColor.neutral.neutral4 },
                  text: 'SLØYFEN GJENTAS',
                },
                color: nafColor.signature.white,
                borderColor: nafColor.neutral.neutral3,
                borderWidth: 1,
              },
            ]
          : [],
        tickInterval: 50,
        labels: {
          formatter: ({ value }) => `${value}km`,
        },
        title: {
          text: undefined,
        },
        accessibility: {
          rangeDescription: 'Range: 0 to 486km',
        },
        crosshair: {
          dashStyle: 'Dash',
          color: season === 'winter' ? nafColor.information.information : nafColor.information.successDark,
          zIndex: 6,
        },
      },
      yAxis: {
        startOnTick: true,
        endOnTick: false,
        maxPadding: 0.35,
        lineWidth: 1,
        gridLineDashStyle: 'Dash',
        title: {
          text: undefined,
        },
        labels: {
          format: '{value} m',
        },
        accessibility: {
          description: 'Elevation',
          rangeDescription: 'Range: 0 to 1,053 meters',
        },
      },

      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series: [
        ...(display === 'range'
          ? createRangeChart({
              altitudes,
              rangeData,
              repeatPoint: repeatRoute?.kmFromStart ?? Math.min(altitudes.length, 350),
              season,
              testDate,
              pointsOfInterest,
            })
          : createRouteChart(altitudes, pointsOfInterest, season)),
      ],
    }),
    [altitudes, pointsOfInterest, rangeData, display, repeatRoute, season, testDate],
  );

  return (
    <>
      {display === 'range' && rangeData && (
        <Legend
          items={[
            {
              label: (
                <>
                  WLTP: <b>{rangeData.stated} km</b>
                </>
              ),
              key: 'stated',
              color1: nafColor.signature.black,
              shape: 'square',
            },
            {
              label: (
                <>
                  Målt: <b>{rangeData.measured} km</b>
                </>
              ),
              key: 'measured',
              color1: season === 'winter' ? nafColor.information.information : nafColor.information.successDark,
              shape: 'line',
            },
            {
              key: 'diff',
              label: (
                <>
                  Avvik: <b>{Math.round(Math.abs(rangeData.measured - rangeData.stated))} km</b>
                </>
              ),
              color1: rangeData.measured > rangeData.stated ? nafColor.primary.moss : nafColor.information.errorDark,
              shape: 'line',
            },
          ]}
        />
      )}
      <HighChartComponent containerProps={{ className }} options={options} />
    </>
  );
};

export const ElevationChart = styled(Plot)<{ season?: Season }>`
  .highcharts-point:hover {
    fill: ${({ season }) =>
      season === 'winter' ? nafColor.information.information : nafColor.information.successDark} !important;
  }

  .highcharts-tooltip > span {
    background: white !important;
  }

  .highcharts-tooltip > span > div > b {
    font-weight: bold;
    font-family: questa-sans;
    text-transform: uppercase;
    margin: 0;
    font-size: 1rem;
  }

  .highcharts-tooltip > span > div {
    padding: ${spacing.space4} ${spacing.space8};
  }

  .highcharts-tooltip > span > div > div {
    margin-top: 4px;
  }

  .highcharts-tooltip > span > div > div > p {
    font-family: questa-sans;
    font-size: 1rem;
    margin: 0;
  }

  .summer:hover {
    fill: ${nafColor.information.successDark} !important;
  }
  .winter:hover {
    fill: ${nafColor.information.information} !important;
  }
  .shorter:hover {
    fill: ${nafColor.information.errorDark} !important;
  }
  .longer:hover {
    fill: ${nafColor.information.successDark} !important;
  }

  .not-reached:hover {
    fill: ${nafColor.neutral.neutral3} !important;
  }
`;
