import React from 'react';
import { ArticleBlockContentGridCol } from '../types/components/ArticleBlockContentGridCol';
import { FormBlock } from './FormBlock';
import { FormBlockProps } from './FormBlock.types';

export const FormSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: FormBlockProps) => (
      <ArticleBlockContentGridCol>
        <FormBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: FormBlockProps) => <FormBlock value={value} />;
};
