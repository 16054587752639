import React from 'react';
import styled from 'styled-components';
import { Button } from '@naf/button';
import useSelector from '../../../../../redux/typedHooks';
import { cloudinaryAttachment } from '../../../../../utils/imageUrl';
import DownloadArrowIcon from '../../../../icons/DownloadArrow';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface CtaFileLinkSerializerProps {
  value: {
    text: string;
    filename?: string;
    file: {
      publicId: string;
      srcSet?: {
        file?: string;
      };
      version?: number;
    };
  };
}

export const CtaFileLinkBlock = ({ value: { text, file, filename } }: CtaFileLinkSerializerProps) => {
  const application = useSelector((state) => state.application);
  const downloadUrl = file && file.publicId && cloudinaryAttachment(file.publicId, application, filename);
  return (
    (downloadUrl && (
      <StyledButton href={downloadUrl} variant="outline" download>
        {text} <DownloadArrowIcon />
      </StyledButton>
    )) ||
    null
  );
};

export const CtaFileLinkSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: CtaFileLinkSerializerProps) => (
      <ArticleBlockContentGridCol className="ctaFileLink">
        <CtaFileLinkBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: CtaFileLinkSerializerProps) => <CtaFileLinkBlock value={value} />;
};

const StyledButton = styled(Button)`
  svg {
    display: inline;
    margin-left: 20px;
  }
`;
