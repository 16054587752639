import { useLocation } from 'react-router';
import { useSiteStructureUrl } from './useSiteStructureUrl';

// Check whether the current location url equals the url of the site structure node
export const useIsSiteStructureLocationUrlValid = (slug?: string) => {
  const location = useLocation();
  const siteStructureUrl = useSiteStructureUrl(slug);
  let isLocationUrlValid = false;
  if (siteStructureUrl) {
    const locationStripped = location.pathname.replace(/\/$/, '');
    isLocationUrlValid = siteStructureUrl.trim() === locationStripped.trim();
  }
  return isLocationUrlValid;
};
