import { useState, useCallback } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';
import { OilChangeCalculations } from '../../../types/oilChangeCalculationTypes';
import { WindshieldWiperChangeCalculationType } from '../../../types/windshieldChangeCalculationTypes';
import { useAuth0Token } from './useAuth0Token';

export enum ProductType {
  OILCHANGE = 'oilchange',
  WINDSHIELD = 'windshield',
}
interface Props {
  productType: ProductType;
  licensePlateNumber?: string;
  centerId?: string;
}

interface ReturnType {
  calculate: () => void;
  calculation?: OilChangeCalculations | WindshieldWiperChangeCalculationType;
  isLoading: boolean;
  error: boolean;
}

export const useProductChangeCalculator = ({ productType, licensePlateNumber, centerId }: Props): ReturnType => {
  const [calculation, setCalculation] = useState<
    OilChangeCalculations | WindshieldWiperChangeCalculationType | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = useSelector((state) => state.application);
  const { simpleToken } = useAuth0Token();

  const calculate = useCallback(async () => {
    if (licensePlateNumber && !isLoading) {
      setIsLoading(true);
      try {
        const headers: {
          'Ocp-Apim-Subscription-Key': string;
          Authorization?: string;
        } = {
          'Ocp-Apim-Subscription-Key': apimContentHub,
        };
        let url = `${apimBaseUrl}/${apimNafNoApi}/naf-centers/${productType}/${licensePlateNumber.trim()}`;
        if (centerId) {
          url += `?centerId=${centerId}`;
        }
        if (simpleToken) {
          headers.Authorization = `Bearer ${simpleToken}`;
        }
        const res = await axios.get<OilChangeCalculations | WindshieldWiperChangeCalculationType>(url, {
          headers,
        });
        setIsLoading(false);
        setCalculation(res.data);
        setError(false);
      } catch (e) {
        setIsLoading(false);
        setError(true);
      }
    }
  }, [apimBaseUrl, apimContentHub, apimNafNoApi, centerId, isLoading, licensePlateNumber, productType, simpleToken]);

  return { calculate, calculation, isLoading, error };
};
