import React from 'react';
import { Question } from './Question';
import { QuestionStatus, QuestionsWithStatus, QuestionType } from './types';

interface Props {
  questions?: QuestionsWithStatus[];
  setStatus: (id: QuestionType['_key'], status: QuestionStatus) => void;
}

export const Questions = ({ questions, setStatus }: Props) =>
  (questions && (
    <>
      {questions.map((question, index) => (
        <Question
          key={question._key}
          question={question}
          questionNumber={index + 1}
          numberOfQuestions={questions.length}
          setStatus={setStatus}
        />
      ))}
    </>
  )) ||
  null;
