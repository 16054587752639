import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { Content } from '../../../layout/Layout';
import { BlockContainer } from '../BlockContainer';
import { ScrollableTextBlocksWithBackgroundImagesType } from '../../../../../../types/ScrollableTextBlocksWithBackgroundImagesType';
import { ParallaxItem } from './ParallaxItem';
import { ParallaxItemImage } from './ParallaxItemImage';

interface ScrollableProps {
  block: ScrollableTextBlocksWithBackgroundImagesType;
}

export const ScrollableTextBlocksWithBackgroundImages = ({ block }: ScrollableProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const [lowerThreshold, setLowerThreshold] = useState(0.05);
  const [upperThreshold, setUpperThreshold] = useState(0.5);
  const [topMargin, setTopMargin] = useState(300);
  const [bottomMargin, setBottomMargin] = useState(150);

  /*
   * Listen to scroll direction in order to handle different thresholds for directions.
   * When scrolling up, threshold needs to be lower than when scrolling down.
   */
  useEffect(() => {
    if (typeof window === 'undefined') return () => {};
    if (window.innerHeight > 1200) {
      setLowerThreshold(0.25);
      setUpperThreshold(0.9);
    } else if (window.innerHeight > 900) {
      setLowerThreshold(0.15);
      setUpperThreshold(0.85);
    } else {
      setLowerThreshold(0.05);
      setUpperThreshold(0.5);
    }

    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      setScrollDirection(window.scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', updateScrollDirection, { passive: true });

    return () => window.removeEventListener('scroll', updateScrollDirection);
  }, []);

  useEffect(() => {
    const currentStepRefs = stepRefs.current;

    const stepObserverOptions: IntersectionObserverInit = {
      root: null,
      threshold: [0, lowerThreshold, upperThreshold],
      rootMargin: '0px',
    };

    const stepObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const indexAttr = entry.target.getAttribute('data-index');
        const index = indexAttr ? parseInt(indexAttr, 10) : 0;
        if (entry.isIntersecting) {
          if (scrollDirection === 'up' && entry.intersectionRatio > lowerThreshold && index < currentIndex) {
            setCurrentIndex(index);
          } else if (scrollDirection === 'down' && entry.intersectionRatio > upperThreshold) {
            setCurrentIndex(index);
          }
        }
      });
    }, stepObserverOptions);

    stepRefs.current.forEach((ref) => {
      if (ref) stepObserver.observe(ref);
    });

    return () => {
      currentStepRefs.forEach((ref) => {
        if (ref) stepObserver.unobserve(ref);
      });
    };
  }, [block.textAndImageBlocks, currentIndex, scrollDirection, lowerThreshold, upperThreshold]);

  const assignRef = (index: number) => (el: HTMLDivElement | null) => {
    stepRefs.current[index] = el;
  };

  return (
    <StyledBlockContainer className="ScrollableTextBlocksWithBackgroundImages">
      {block?.textAndImageBlocks?.length > 0 ? (
        <StyledContent>
          <ImageContainer $topMargin={topMargin} $bottomMargin={bottomMargin}>
            {block.textAndImageBlocks.map((item, index) => (
              <ParallaxItemImage
                item={item}
                showImage={currentIndex === index}
                setTopMargin={index === 0 ? setTopMargin : undefined}
                setBottomMargin={index === block.textAndImageBlocks.length - 1 ? setBottomMargin : undefined}
              />
            ))}
          </ImageContainer>
          {block.textAndImageBlocks.map((item, index) =>
            item.textBlock ? <ParallaxItem item={item} index={index} ref={assignRef(index)} /> : null,
          )}
        </StyledContent>
      ) : undefined}
    </StyledBlockContainer>
  );
};

const ImageContainer = styled.div<{ $topMargin?: number; $bottomMargin?: number }>`
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-top: -${({ $topMargin }) => $topMargin || 0}px;
  margin-bottom: -${({ $bottomMargin }) => $bottomMargin || 0}px;
`;

const StyledBlockContainer = styled(BlockContainer)`
  margin-top: 0;
  margin-bottom: ${spacing.space56};

  display: flex;
  p + & {
    margin-top: ${spacing.space48};
  }
`;

const StyledContent = styled(Content)`
  padding-left: 0;
  padding-right: 0;
  position: relative;
  width: 100%;
`;
