import React from 'react';
import { ImageCollage } from '@naf/image-collage';
import styled from 'styled-components';
import useSelector from '../../../../../redux/typedHooks';
import { cloudinaryImage } from '../../../../../utils/imageUrl';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import { isNonNullable } from '../../../../../lib/isNonNullable';

interface Props {
  images?: CloudinaryImageType[];
}
const BlockImageCollage = ({ images }: Props) => {
  const application = useSelector((state) => state.application);

  const mappedImages = images
    ?.map((image: CloudinaryImageType, index: number) => {
      const imageHeight = index === 0 ? 530 : 260;
      const cloudinaryImageSrc = image?.publicId && cloudinaryImage(image.publicId, application, null, imageHeight);

      if (cloudinaryImageSrc) {
        return {
          src: cloudinaryImageSrc,
          description: image.caption ? image.caption : '',
          altText: '',
        };
      }
      return null;
    })
    .filter(isNonNullable);

  return (
    <ImageCollageWrapper className="BlockImageCollage">
      <ImageCollage images={mappedImages} />
    </ImageCollageWrapper>
  );
};

const ImageCollageWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 58px;
  p {
    max-width: 100%;
  }
`;

export default BlockImageCollage;
