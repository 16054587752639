import React from 'react';

export const PartiallyCloudyNight = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1 23H10.5L11 22.688C11.3333 22.4793 11.696 22.246 12.088 21.988C12.4793 21.7293 12.8417 21.496 13.175 21.288L13.675 20.975C15.025 20.875 16.271 20.4667 17.413 19.75C18.5543 19.0333 19.4583 18.075 20.125 16.875C18.6917 16.7417 17.3333 16.3793 16.05 15.788C14.7667 15.196 13.6167 14.3917 12.6 13.375C11.5833 12.3583 10.775 11.2083 10.175 9.925C9.575 8.64167 9.21667 7.28333 9.1 5.85C7.81667 6.56667 6.81267 7.554 6.088 8.812C5.36267 10.0707 5 11.4333 5 12.9V13.2L4.7 13.337C4.5 13.429 4.27933 13.525 4.038 13.625C3.796 13.725 3.575 13.8207 3.375 13.912L3.075 14.05C3.04167 13.8667 3.021 13.675 3.013 13.475C3.00433 13.275 3 13.0833 3 12.9C3 10.4667 3.775 8.32067 5.325 6.462C6.875 4.604 8.85 3.45 11.25 3C10.95 4.65 11.0417 6.26233 11.525 7.837C12.0083 9.41233 12.8417 10.7917 14.025 11.975C15.2083 13.1583 16.5877 13.9917 18.163 14.475C19.7377 14.9583 21.35 15.05 23 14.75C22.5667 17.15 21.4167 19.125 19.55 20.675C17.6833 22.225 15.5333 23 13.1 23ZM6 21H10.5C10.9167 21 11.2707 20.854 11.562 20.562C11.854 20.2707 12 19.9167 12 19.5C12 19.0833 11.8583 18.7293 11.575 18.438C11.2917 18.146 10.95 18 10.55 18H9.25L8.75 16.8C8.51667 16.25 8.15 15.8127 7.65 15.488C7.15 15.1627 6.6 15 6 15C5.16667 15 4.45833 15.2877 3.875 15.863C3.29167 16.4377 3 17.15 3 18C3 18.8333 3.29167 19.5417 3.875 20.125C4.45833 20.7083 5.16667 21 6 21ZM6 23C4.61667 23 3.43767 22.5127 2.463 21.538C1.48767 20.5627 1 19.3833 1 18C1 16.6167 1.48767 15.4373 2.463 14.462C3.43767 13.4873 4.61667 13 6 13C7 13 7.91267 13.2707 8.738 13.812C9.56267 14.354 10.175 15.0833 10.575 16C11.525 16.0333 12.3333 16.3873 13 17.062C13.6667 17.7373 14 18.55 14 19.5C14 20.4667 13.6583 21.2917 12.975 21.975C12.2917 22.6583 11.4667 23 10.5 23H6Z"
      fill="#1A1A1A"
    />
  </svg>
);
