import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints, fontStyle, spacing, themeLight } from '@naf/theme';
import { Search } from '@styled-icons/material/Search';

export const SearchBarWrapper = styled.div<{ $isMenuOpen?: boolean }>`
  display: none;

  ${({ $isMenuOpen }) =>
    $isMenuOpen &&
    css`
      display: flex;
    `};

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space24} ${spacing.space16} ${spacing.space16} ${spacing.space16};
    justify-content: center;
  }

  @media (max-width: ${breakpoints.m}) {
    padding: ${spacing.space24} ${spacing.space12} ${spacing.space16} ${spacing.space12};
  }
`;

export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;

  @media (max-width: ${breakpoints.l}) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
  }
`;

export const SearchIcon = styled(Search)`
  width: ${spacing.space24};
  height: ${spacing.space24};
  color: ${nafColor.neutral.neutral4};
`;

export const InputWrapper = styled.div<{ $isScrolled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${nafColor.neutral.neutral4};
  border-radius: 40px;
  padding: 0 ${spacing.space16};
  box-sizing: border-box;
  height: 40px;
  background-color: ${nafColor.signature.white};
  transition: height 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      height: 35px;
    `}
  &:focus-within {
    border: 2px solid ${themeLight.componentColors.inputElement.borderActive};
    padding: 0 calc(${spacing.space16} - 1px);
  }
`;

export const StyledInput = styled.input<{ $isScrolled: boolean }>`
  filter: grayscale(100%) brightness(110%); /* Fix for autofill in Chrome. */
  border: 0;
  background: none;
  appearance: none;
  padding: ${spacing.space8};
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  outline: 0;
  ${fontStyle.article.articleText}

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      height: 30px;
    `}

  &:focus {
    outline: none !important;
  }
`;
