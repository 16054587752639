import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface IframeSerializerProps {
  value: { src: string; height?: number | string; width?: number | string };
}

const IframeBlock = ({ value: { src, height, width } }: IframeSerializerProps) => (
  <iframe
    title={`iframe: ${src}`}
    src={src}
    height={height}
    width={width || '100%'}
    style={{ border: 'none' }}
    allow="fullscreen"
  />
);

export const IframeSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: IframeSerializerProps) => (
      <StyledArticleBlockContentGridCol>
        <IframeBlock value={value} />
      </StyledArticleBlockContentGridCol>
    );
  }
  return ({ value }: IframeSerializerProps) => <IframeBlock value={value} />;
};

const StyledArticleBlockContentGridCol = styled(ArticleBlockContentGridCol)`
  margin: ${spacing.space32} 0;
  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space24} 0;
  }
`;
