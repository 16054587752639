import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { ExternalMainEntryCard, InternalMainEntryCard } from './MainEntry';
import { InternalMainEntry } from '../../../../../../types/InternalMainEntryType';
import { ExternalMainEntry } from '../../../../../../types/ExternalMainEntryType';

interface MainEntriesBlockProps {
  node: MainEntriesProps;
}

export interface MainEntriesProps {
  key: string;
  mainEntriesList: (InternalMainEntry | ExternalMainEntry)[];
  type: 'mainEntries';
}

export const MainEntries = ({ node: { mainEntriesList } }: MainEntriesBlockProps) => (
  <Wrapper>
    {mainEntriesList &&
      mainEntriesList?.map((e) => {
        switch (e.type) {
          case 'externalLink':
            return <ExternalMainEntryCard externalMainEntry={e} key={e.key} />;
          case 'internalLink':
            return <InternalMainEntryCard internalMainEntry={e} key={e.key} />;
          default:
            return null;
        }
      })}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
  @media (max-width: ${breakpoints.m}) {
    flex-wrap: wrap;
    > * {
      width: fit-content;
    }
  }
`;
