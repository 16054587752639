import { useSiteStructure } from './useSiteStructure';

// Get the url of the site structure node
export const useSiteStructureUrl = (slug?: string) => {
  const siteStructure = useSiteStructure(slug);
  const parentSlug = siteStructure && siteStructure?.data?.parent;
  const parentSiteStructure = useSiteStructure(parentSlug);

  let fullCategoryUrl;

  if (siteStructure?.data || parentSiteStructure?.data) {
    fullCategoryUrl = parentSiteStructure?.data ? `${parentSiteStructure?.data?.url}` : `${siteStructure?.data?.url}`;
  }
  return fullCategoryUrl;
};
