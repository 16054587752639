import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Text, TextVariant } from '@naf/text';
import { Label, Select } from '@naf/input';
import { spacing } from '@naf/theme';
import { Close } from '@styled-icons/material/Close';
import useSelector from '../../../../../redux/typedHooks';
import { actions as idpCountriesAction } from '../../../../../redux/modules/idpCountries';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

export const IdpCountriesListBlock = () => {
  const dispatch = useDispatch();
  const [didFetchCountries, setDidFetchCountries] = useState<boolean>(false);
  const countries = useSelector((state) => state.idpCountries.data);
  const options = countries && countries.map((country) => ({ label: country.countryName, value: country.countryName }));
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const selectCountry = (countryList: typeof countries, value: string) =>
    countryList.find((country) => country.countryName === value);

  useEffect(() => {
    if (!didFetchCountries) {
      dispatch(idpCountriesAction.getIdpCountries.request(undefined, undefined));
      setDidFetchCountries(true);
    }
  }, [didFetchCountries, dispatch]);

  const handleRemoveCountry = (country: string) => {
    setSelectedCountries(selectedCountries.filter((countryName) => countryName !== country));
  };

  if (options) {
    return (
      <Wrapper>
        <Label>Legg til land</Label>
        <Select
          options={options}
          placeholder="Velg land"
          handleSelect={(option: Record<string, string>) => setSelectedCountries([...selectedCountries, option.label])}
          maxLines={6}
        />
        {countries && selectedCountries && selectedCountries.length > 0 && (
          <AdditionalInfo>
            {selectedCountries.map(
              (country) =>
                selectCountry(countries, country) && (
                  <CountryWrapper key={country}>
                    <TextWrapper>
                      <Text variant={TextVariant.ArticleTextHeader}>{country}</Text>
                      <Text>{selectCountry(countries, country)?.additionalInfo}</Text>
                    </TextWrapper>
                    <CloseButton onClick={() => handleRemoveCountry(country)}>
                      <Close size={18} />
                    </CloseButton>
                  </CountryWrapper>
                ),
            )}
          </AdditionalInfo>
        )}
      </Wrapper>
    );
  }
  return null;
};

export const IdpCountriesListSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <IdpCountriesListBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <IdpCountriesListBlock />;
};

const Wrapper = styled.div`
  margin-top: ${spacing.space16};
  margin-bottom: ${spacing.space80};

  p {
    margin: 0 0 ${spacing.space8} !important;
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.space24};
`;

const CountryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.space24};
  padding-bottom: ${spacing.space24};
  border-bottom: 1px solid #e0e0e0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacing.space40};

  h5,
  p {
    margin: 0;
  }
`;

const CloseButton = styled.button`
  /* reset style */
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  touch-action: manipulation;
  appearance: none !important;

  /* style */
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-top: -7px;
  padding: ${spacing.space12};
  height: fit-content;
`;
