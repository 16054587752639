import styled, { css, keyframes } from 'styled-components';
import { IconButton } from '@naf/icon-button';
import { spacing } from '@naf/theme';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Wrapper = styled.div<{ $isVisible?: boolean; $isChatVisible?: boolean }>`
  z-index: 80;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: ${({ $isChatVisible }) => ($isChatVisible ? '90px' : spacing.space20)};
  right: ${spacing.space8};
  animation: ${({ $isVisible }) =>
    $isVisible
      ? css`
          ${fadeIn} 0.5s
        `
      : css`
          ${fadeOut} 0.5s
        `};
  animation-fill-mode: forwards;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  height: 40px;
  width: 40px;

  svg {
    transform: rotate(-90deg) !important;
  }
`;
