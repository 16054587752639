import { useMemo } from 'react';

export const useDateWithPadding = (unixStringDate?: string) =>
  useMemo(() => createDateWithPadding(unixStringDate), [unixStringDate]);

export const createDateWithPadding = (unixStringDate?: string) => {
  if (unixStringDate) {
    const date = new Date(unixStringDate);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}`;
  }
  return null;
};
