const routeConfig = [
  {
    path: '/',
    breadcrumb: null,
  },
  {
    path: '/bilguiden/bilmodell',
    breadcrumb: null,
  },
  {
    path: '/bilverksted-og-tester',
    breadcrumb: 'Naf Senter',
  },
  {
    path: '/bilverksted-og-tester/naf-senter',
    breadcrumb: null,
  },
  { path: '/her-finner-du-naf', breadcrumb: null },
  { path: '/her-finner-du-naf/:slug1/:slug2/artikkel', breadcrumb: null },
  { path: '/her-finner-du-naf/:slug1/:slug2/fordeler', breadcrumb: null },
  { path: '/kundeservice', breadcrumb: 'Hjelp og kontakt' },
];

export default routeConfig;
