import React from 'react';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { Link } from 'react-router-dom';
import { ButtonLink } from '@naf/button-link';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';

interface Props {
  relatedLinks: RelatedLink[] | undefined;
}

type RelatedLink = {
  referenceComponent: {
    type: string;
    slug: string;
    id: string;
    category?: {
      type: string;
      slug: string;
    };
    parent?: any;
  };
  type: string;
  href?: string;
  title: string;
  blank: boolean;
};

const RelatedLinkView = ({ relatedLink }: { relatedLink: RelatedLink }) => {
  const itemUrl = useDocumentUrlWithNode(relatedLink.referenceComponent) || '';
  if (relatedLink.type === 'link' && relatedLink.href) {
    return relatedLink.blank ? (
      <RelatedListItem key={`${relatedLink.href}-${relatedLink.type}`}>
        <ButtonLink href={relatedLink.href} text={relatedLink.title} icon="launch" target="_blank" rel="noreferrer" />
      </RelatedListItem>
    ) : (
      <RelatedListItem key={`${relatedLink.href}-${relatedLink.type}`}>
        <ButtonLink href={relatedLink.href} text={relatedLink.title} />
      </RelatedListItem>
    );
  }
  if (relatedLink.type === 'internalLink') {
    if (!relatedLink.referenceComponent || !relatedLink.referenceComponent?.type) {
      return null;
    }
    return (
      <RelatedListItem key={relatedLink.referenceComponent.slug}>
        <ButtonLink as={Link} to={itemUrl} text={relatedLink.title} />
      </RelatedListItem>
    );
  }
  return null;
};

const RelatedLinksList = ({ relatedLinks }: Props) =>
  (relatedLinks && (
    <RelatedList>
      {relatedLinks.map((relatedLink) => (
        <RelatedLinkView key={relatedLink?.referenceComponent?.id || relatedLink.href} relatedLink={relatedLink} />
      ))}
    </RelatedList>
  )) ||
  null;

export default RelatedLinksList;

const RelatedList = styled.ul`
  padding: 0;
  margin: 0;
`;

const RelatedListItem = styled.li`
  padding-bottom: ${spacing.space20};
  list-style-type: none;
`;
