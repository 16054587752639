import React from 'react';
import { PortraitsWithTextType } from '../../../../../../../types/portraitsWithTextType';
import { PortraitsWithTextBlock } from '../../../../SectionBlocks/blocks/PortraitsWithTextBlock';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface PortraitsWithTextProps {
  value: PortraitsWithTextType;
}

export const PortraitsWithTextSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: PortraitsWithTextProps) => (
      <ArticleBlockContentGridCol className="portraitsWithText">
        <PortraitsWithTextBlock block={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: PortraitsWithTextProps) => <PortraitsWithTextBlock block={value} />;
};
