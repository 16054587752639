import styled, { css } from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const StyledArticleBlockContentGridCol = styled.div<{ $orientation: 'left' | 'right'; $vehicleCount: number }>`
  ${({ $vehicleCount, $orientation }) => {
    if ($vehicleCount === 1 && $orientation === 'left') {
      return css`
        grid-column: 1 / -3;
      `;
    }
    if ($vehicleCount === 1 && $orientation === 'right') {
      return css`
        grid-column: 3 / -1;
      `;
    }
    if ($vehicleCount >= 3) {
      return css`
        grid-column: 1 / -1;
      `;
    }
    return css`
      grid-column: 3 / -3;
    `;
  }};

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export const Container = styled.div<{ $orientation?: 'left' | 'right'; $vehicleCount?: number }>`
  display: flex;
  gap: ${spacing.space16};
  scroll-behavior: smooth;
  justify-content: center;

  > a,
  p {
    height: auto;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 352px;
  }

  > a {
    max-width: 352px;
  }

  div > h2 {
    margin-bottom: ${spacing.space16};
  }

  ${({ $orientation, $vehicleCount }) =>
    $orientation &&
    $vehicleCount === 1 &&
    css`
      flex-flow: ${$orientation === 'left' ? 'row-reverse wrap-reverse' : 'row wrap'};
    `};

  ${({ $vehicleCount }) =>
    $vehicleCount === 3 &&
    css`
      overflow-x: scroll;
      justify-content: start;
    `}

  ${({ $vehicleCount }) =>
    $vehicleCount > 3 &&
    css`
      overflow-x: hidden;
      justify-content: start;
    `};

  @media (max-width: ${breakpoints.l}) {
    ${({ $vehicleCount }) =>
      $vehicleCount > 3 &&
      css`
        overflow-x: scroll;
      `};
  }

  @media (max-width: ${breakpoints.m}) {
    width: calc(100vw - 48px);
    gap: ${spacing.space16};
    justify-content: start;
    overflow-x: scroll;

    > a,
    p {
      min-width: calc(100vw - 48px);
    }

    ${({ $vehicleCount }) =>
      $vehicleCount === 1
        ? css`
            flex-wrap: wrap;
          `
        : css`
            width: calc(100vw - 24px);
            margin-left: -${spacing.space24};
            padding: 0 24px;

            > a:last-child {
              padding-right: ${spacing.space24};
            }
          `};
  }
`;

export const Header = styled.h3`
  font-size: 1.375rem;
`;

export const ScrollWrapper = styled.div``;
