import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CameraAlt } from '@styled-icons/material-outlined/CameraAlt';
import { ImageCarousel } from '@naf/image-carousel';
import { breakpoints } from '@naf/theme';
import { CarModelType } from '../../../../../types/carModelType';
import S from '../../../styles/bilguide/StyledBilGuide';
import { toCloudinaryPath } from '../cloudinary/cloudinary';

interface Props {
  vehicleModelPictures: CarModelType['vehicleModelPictures'];
}

export enum CarImageTypes {
  SMALL_FRONT = 'smallFront',
  SMALL_BACK = 'smallBack',
  SMALL_INTERIOR = 'smallInterior',
  THUMB_FRONT = 'thumbFront',
  THUMB_BACK = 'thumbBack',
  THUMB_INTERIOR = 'thumbInterior',
  LARGE_BACK = 'largeBack',
  LARGE_FRONT = 'largeFront',
  LARGE_INTERIOR = 'largeInterior',
}

export const LARGE_IMAGES = [CarImageTypes.LARGE_BACK, CarImageTypes.LARGE_FRONT, CarImageTypes.LARGE_INTERIOR];

const CarImageModal: React.FC<Props> = ({ vehicleModelPictures }: Props) => {
  const mainImg =
    vehicleModelPictures?.length > 0 ? vehicleModelPictures.find((pic: any) => pic.type === 'largeFront') : null;
  const height = mainImg?.size?.split('x').pop() || '472';
  const modalDesktopImages = useMemo(
    () =>
      vehicleModelPictures.reduce((acc: any[], cur: CarModelType['vehicleModelPictures'][0]) => {
        if (LARGE_IMAGES.includes(cur.type as any) && cur.path) {
          return [
            ...acc,
            {
              src: toCloudinaryPath(cur.path, 'Large') || '',
              description: cur.source,
              altText: cur.alternateText,
            },
          ];
        }
        return acc;
      }, []),
    [vehicleModelPictures],
  );

  if (modalDesktopImages?.length === 0)
    return (
      <S.ImagePlaceholder>
        <CameraAlt color="inherit" size={120} />
        <S.PlaceholderTextElement>Bilde kommer snart!</S.PlaceholderTextElement>
      </S.ImagePlaceholder>
    );

  return (
    <Wrapper height={parseInt(height, 10)}>
      <ImageCarousel images={modalDesktopImages} height={parseInt(height, 10)} />
    </Wrapper>
  );
};

export default CarImageModal;

const Wrapper = styled.figure<{ height: number }>`
  height: 100%;
  margin: 0 0 0 -33px;
  padding: 0;

  @media (max-width: ${breakpoints.s}) {
    margin: 0 0 25px 0;
  }

  & img {
    clip-path: inset(0 0 10px 0); // Current solution to crop the credit of image from OFV
    @media (max-width: ${breakpoints.l}) {
      clip-path: inset(0 0 15px 0); // Current solution to crop the credit of image from OFV
      height: 100%;
    }
  }

  & > div {
    margin: 0 !important;
    height: ${(props) => props.height}px;

    @media (max-width: ${breakpoints.l}) {
      height: 100%;
    }
  }

  & .modal {
    & img {
      clip-path: inset(0 0 30px 0); // Current solution to crop the credit of image from OFV
      height: fit-content;
    }
  }
`;
