import { TextList, TextListItem } from '@naf/text-list';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ArticleBlockContentGridCol } from '../types/components/ArticleBlockContentGridCol';

interface ListProps {
  type?: 'number' | 'bullet' | 'check' | undefined;
  children?: ReactNode[] | ReactNode;
}

export const list = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ type, children }: ListProps) => (
      <ArticleBlockContentGridCol className="list">
        <StyledList variant={type}>
          {Array.isArray(children)
            ? children.map((c: any) => <TextListItem key={c.key}>{c.props.children}</TextListItem>)
            : null}
        </StyledList>
      </ArticleBlockContentGridCol>
    );
  }
  return ({ type, children }: ListProps) => (
    <StyledList variant={type}>
      {Array.isArray(children)
        ? children.map((c: any) => <TextListItem key={c.key}>{c.props.children}</TextListItem>)
        : null}
    </StyledList>
  );
};

const StyledList = styled(TextList)`
  max-width: 576px;
`;
