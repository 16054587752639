/* eslint-disable camelcase */
import { RadioOptions } from '@dtp/membership-service-types/dist/form/input-options/radio';
import { DynamicForm } from './FormBlock.types';

export const getEventPayload = (formDataValue: any, dynamicForm: DynamicForm) => {
  const { fieldComponents, title, formTrackingId } = dynamicForm || {};

  const categoryInitialValueId = formDataValue.fields.find((obj) => obj.id === 'case')?.value;
  const caseComponent = fieldComponents?.find((c) => c.id === 'case');
  const categories = caseComponent?.options as unknown as RadioOptions;
  const categoryName = categories?.find((c) => c.value === categoryInitialValueId)?.label;

  const eventPayload: Record<string, any> = {
    event: 'innsendt_skjema',
    skjema_type: `${formTrackingId || title}`,
    skjema_kategori: categoryName,
  };

  if (fieldComponents) {
    fieldComponents
      .filter((field) => field.isTrackable)
      .map((field: any) => {
        const valueObject = formDataValue.fields.find((obj) => obj.id === field.id);
        eventPayload[field.trackingId] = valueObject?.value;
        return null;
      });
  }

  return eventPayload;
};
