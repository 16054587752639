import { Redirect, Route, Switch, useLocation } from 'react-router';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from '@naf/theme-provider';
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';
import { ThemeContextValue } from '../lib/ThemeContext';
import { useTheme } from '../hooks/useTheme';

// Styles
import { GlobalStyle } from './GlobalStyle';

// Routers

// Components
import { AppWrapper } from './AppWrapper';
import Footer from './footer/Footer';
import { CustomCookieBanner } from './Cookies/CustomCookieBanner';
import SystemMessage from './SystemMessage';
import { ScrollToTop } from './ScrollToTop';
import { ThemeVariants } from '../../../types/themes';
import NPS from '../pages/NPS/NPS';

import 'react-toastify/dist/ReactToastify.css';

import { MEMBERPAGES_URL } from '../lib/constants';
import { ContentSwitch } from '../routers/ContentSwitch';
import ErrorBoundary from './error-component/ErrorBoundary';
import Navbar from './navbar/Navbar';
import { NotFound } from '../loadable-elements/NotFound';

// Pages

export const acquireEmailPath = '/registrer-epost';
const EmailAcquisition = loadable(() => import('../pages/EmailAcquisition'), {
  resolveComponent: (components) => components.EmailAcquisition,
});

export const customizeCookiesPath = '/administrer-cookies';
const CustomizeCookies = loadable(() => import('../pages/cookies/customize'), {
  resolveComponent: (components) => components.CustomizeCookies,
});

export const cookieDeclarationPath = '/cookies';
const CookieDeclaration = loadable(() => import('../pages/cookies/declaration'), {
  resolveComponent: (components) => components.CookieDeclaration,
});

export const idpReceiptPath = '/kvittering-internasjonalt-forerkort/:orderId';
const IdpReceipt = loadable(() => import('../pages/internationalDrivingPermit/components/receipt'), {
  resolveComponent: (components) => components.IdpReceipt,
});

export const invoiceReceiptPath = '/betalings-kvittering/:paymentId';
const InvoicePaymentReceipt = loadable(() => import('../pages/MyMembership/InvoicePaymentReceipt'), {
  resolveComponent: (components) => components.InvoicePaymentReceipt,
});

export const orderReceiptPath = '/ordre-kvittering/:paymentId';
const OrderReceipt = loadable(() => import('../pages/MyMembership/OrderReceipt'), {
  resolveComponent: (components) => components.OrderReceipt,
});

export const welcomePath = '/velkommen-som-medlem/:orderId';
const Welcome = loadable(() => import('../pages/Welcome'), {
  resolveComponent: (components) => components.Welcome,
});

const idpArticlePath = `/internasjonalt-forerkort`;
const IdpArticle = loadable(() => import('../pages/internationalDrivingPermit/article'), {
  resolveComponent: (components) => components.IdpArticle,
});

const Chat = loadable(() => import('./Chat'), { resolveComponent: (components) => components.Chat });

export const becomeAMemberPath = '/bli-medlem';
const BecomeAMember = loadable(() => import('../pages/BecomeAMember'), {
  resolveComponent: (components) => components.BecomeAMember,
});

export const buyIdpPath = '/bestill-internasjonalt-forerkort';
const BuyInternationalDrivingPermit = loadable(() => import('../pages/internationalDrivingPermit'), {
  resolveComponent: (components) => components.BuyInternationalDrivingPermit,
});

export const frontPagePath = '/';
const FrontPage = loadable(() => import('../pages/FrontPage'), {
  resolveComponent: (components) => components.FrontPage,
});

const MedlemskapsRouter = loadable(() => import('../routers/MedlemskapsRouter'), {
  resolveComponent: (components) => components.MedlemskapsRouter,
});

const BilGuideRouter = loadable(() => import('../routers/BilGuideRouter'), {
  resolveComponent: (components) => components.BilGuideRouter,
});

const ArticleTestPage = loadable(() => import('../pages/ArticleTestPage'));

const LoggingIn = loadable(() => import('../pages/LoggingIn'), {
  resolveComponent: (components) => components.LoggingIn,
});
const LoggedOut = loadable(() => import('../pages/LoggedOut'), {
  resolveComponent: (components) => components.LoggedOut,
});

const InternalServerError = loadable(() => import('../pages/errors/InternalServerError'), {
  resolveComponent: (components) => components.InternalServerError,
});

export const myProfileRouterPath = `/${MEMBERPAGES_URL}`;
const MyProfileRouter = loadable(() => import('../routers/MyProfileRouter'), {
  resolveComponent: (components) => components.MyProfileRouter,
});

export const qrLandingPagePath = '/qr/:qrId';
const QrLandingPage = loadable(() => import('../pages/QrLandingPage/QrLandingPage'), {
  resolveComponent: (components) => components.QrLandingPage,
});

export const ThemedApp = ({ initialTheme }: { initialTheme?: ThemeVariants | null }) => {
  const location = useLocation();
  const theme = useContext(ThemeContextValue);
  useTheme(initialTheme);

  return (
    <>
      <ErrorBoundary>
        <SystemMessage />
        <Navbar />
      </ErrorBoundary>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <CustomCookieBanner />
        <Chat />
        <HeaderAndContentWrapper>
          <AppWrapper id="appWrapper">
            <ContentWrapper>
              <ErrorBoundary>
                <ScrollToTop>
                  <Switch location={location}>
                    <Route exact path={frontPagePath} component={FrontPage} />
                    <Redirect
                      from="/:url*(/+)"
                      to={`${location.pathname.slice(0, -1)}${location.search ? location.search : ''}`}
                    />
                    <Route exact path="/nps" component={NPS} />
                    <Route exact path="/testartikkel" component={ArticleTestPage} />
                    <Route path={myProfileRouterPath} component={MyProfileRouter} />
                    <Route exact path="/nafid/login" component={LoggingIn} />
                    <Route exact path="/nafid/logout" component={LoggedOut} />
                    <Route path="/bilguiden" component={BilGuideRouter} />
                    <Route exact path={invoiceReceiptPath} component={InvoicePaymentReceipt} />
                    <Route exact path={orderReceiptPath} component={OrderReceipt} />
                    <Route exact path={becomeAMemberPath} component={BecomeAMember} />
                    <Route exact path={welcomePath} component={Welcome} />
                    <Route exact path={buyIdpPath} component={BuyInternationalDrivingPermit} />
                    <Route exact path={idpReceiptPath} component={IdpReceipt} />
                    <Route exact path={cookieDeclarationPath} component={CookieDeclaration} />
                    <Route exact path={customizeCookiesPath} component={CustomizeCookies} />
                    <Route exact path={idpArticlePath} component={IdpArticle} />
                    <Route exact path={acquireEmailPath} component={EmailAcquisition} />
                    <Route exact path="/medlemskap" component={MedlemskapsRouter} />
                    <Route exact path={qrLandingPagePath} component={QrLandingPage} />
                    <Route exact path="/500" component={InternalServerError} />
                    <Route path="/*/:slug" component={ContentSwitch} />
                    <Route exact path="/:slug" component={ContentSwitch} />
                    <Route component={NotFound} />
                  </Switch>
                </ScrollToTop>
              </ErrorBoundary>
            </ContentWrapper>
            <Footer />
          </AppWrapper>
        </HeaderAndContentWrapper>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
};

const HeaderAndContentWrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  min-height: calc(100vh - 480px);
`;
