import React from 'react';
import type { SanityBlock } from '@sanity/client';
import styled from 'styled-components';
import { spacing, themeLight } from '@naf/theme';
import { NumberInCircle } from '@naf/number';
import { Text, TextVariant } from '@naf/text';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import BlockImage from './BlockImage';
import BlockContent from '../../../BlockContent';

interface Props {
  value: { numberedList?: { image: CloudinaryImageType; body: SanityBlock[]; title: string }[]; title: string };
}

export const NumberedList = ({ value: { numberedList } }: Props) => (
  <Wrapper className="numberedList">
    {numberedList?.map(({ image, body, title }, index) => (
      <ListItem key={title}>
        <StyledNumber size="small" count={index + 1} />
        <HeaderText variant={TextVariant.ArticleTextLargeHeader}>{title}</HeaderText>
        <ContentWrapper>
          <BlockImage image={image} />
          <BlockContent value={body} />
        </ContentWrapper>
      </ListItem>
    ))}
  </Wrapper>
);

function NumberedListSerializer(context: 'article' | 'stripped' = 'stripped') {
  if (context === 'article') {
    return ({ value }: Props) => (
      <ArticleBlockContentGridCol>
        <NumberedList value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: Props) => <NumberedList value={value} />;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space16};
`;

const ListItem = styled.div`
  display: grid;
  grid-template-columns: ${spacing.space24} auto;
  gap: ${spacing.space16};

  & p,
  figure,
  h4 {
    margin: 0;
  }

  padding-top: ${spacing.space16};
  padding-bottom: ${spacing.space16};
  border-top: 1px solid ${({ theme }) => theme.border.subtle || themeLight.border.subtle};
`;

const HeaderText = styled(Text)`
  grid-row: 1;
  grid-column: 2;
`;

const ContentWrapper = styled.div`
  grid-column: 2/ -1;
`;

const StyledNumber = styled(NumberInCircle)`
  grid-row: 1;
  grid-column: 1;
  align-self: center;
`;

export default NumberedListSerializer;
