import React from 'react';
import BlockImage from '../BlockImage';
import { DoubleImageSerializerProps } from './DoubleImageSerializerProps';
import { DoubleImageBlock } from './DoubleImageBlock';

export const DoubleImageSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: DoubleImageSerializerProps) => <DoubleImageBlock value={value} />;
  }
  // TODO: Hvis denne skal tas i bruk for annen type brødtekst enn ny artikkelmal må denne nok forbedres noe
  return ({ value: { imageLeft, imageRight } }: DoubleImageSerializerProps) => (
    <>
      <BlockImage image={imageLeft} />
      <BlockImage image={imageRight} />
    </>
  );
};
