import React, { useState } from 'react';
import { ArrowForward, ArrowBack } from '@styled-icons/material';
import * as S from './Styles';

const ScrollControl: React.FC<{
  scrollItems: string[];
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}> = ({ scrollItems, scrollContainerRef }) => {
  const [indexInCenter, setCenteredIndex] = useState<number>(0);
  const cardWidth = 352;
  const gap = 12;
  const cardsPerPage = 3;
  const scrollAmount = cardWidth + gap + cardsPerPage;

  const scrollLeft = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const nextIndex = Math.min(indexInCenter + cardsPerPage, scrollItems.length - cardsPerPage);
      const newScrollPosition = nextIndex * scrollAmount;
      scrollContainer.scrollLeft = newScrollPosition;
      setCenteredIndex(nextIndex);
    }
  };

  const scrollRight = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const previousIndex = Math.max(0, indexInCenter - cardsPerPage);
      const newScrollPosition = previousIndex * scrollAmount;
      scrollContainer.scrollLeft = newScrollPosition;
      setCenteredIndex(previousIndex);
    }
  };

  return (
    <S.ScrollContainer>
      <S.ScrollButton
        disabled={indexInCenter === 0}
        $direction="back"
        variant="secondary"
        onClick={scrollRight}
        type="button"
        aria-label="Forrige"
      >
        <ArrowBack width={20} />
      </S.ScrollButton>
      {scrollItems.map((key, i) => (
        <S.Dot key={`${key}_dot`} $inView={i >= indexInCenter && i < indexInCenter + cardsPerPage} />
      ))}
      <S.ScrollButton
        disabled={indexInCenter + cardsPerPage >= scrollItems.length}
        $direction="forward"
        variant="secondary"
        type="button"
        onClick={scrollLeft}
        aria-label="Neste"
      >
        <ArrowForward width={20} />
      </S.ScrollButton>
    </S.ScrollContainer>
  );
};

export default ScrollControl;
