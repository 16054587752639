import styled from 'styled-components';
import { Info } from '@styled-icons/material-outlined/Info';
import { nafColor } from '@nafcore/theme';
import { RadioButton } from '@naf/radio-button';
import { GridCol } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { Text } from '@naf/text';
import { HR_COLOR } from '../../styles/colors';

const Wrapper = styled(GridCol)`
  display: flex;
  flex-direction: column;
`;

const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${HR_COLOR};
  width: 96px;
  margin: 0 0 ${spacing.space32};
  color: ${HR_COLOR};

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: ${spacing.space48};
  }
`;

const Title = styled.strong`
  font-weight: 500;
`;

const Img = styled.img`
  height: ${spacing.space160};
  width: ${spacing.space160};
  margin-bottom: ${spacing.space24};
`;

const ResponseMsg = styled.div`
  display: flex;
  flex-direction: column;

  p,
  h5 {
    margin: 0;
  }

  h5 {
    margin-bottom: ${spacing.space8};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${spacing.space24};

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space12};
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: ${spacing.space24};
  margin-top: ${spacing.space12};

  @media (max-width: ${breakpoints.s}) {
    width: fit-content;
    padding: 0;
    margin-top: ${spacing.space16};
  }
`;

const TextAreaInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: -${spacing.space4};

  svg {
    margin-right: ${spacing.space8};
  }

  p {
    margin: 0;
  }
`;

const StyledTextArea = styled.div`
  margin: ${spacing.space8} 0 0;

  > div {
    max-width: 100%;
  }
`;

const ResponseText = styled(Text)`
  margin: ${spacing.space24} 0 0;
`;

const StyledIcon = styled(Info)`
  color: ${nafColor.information.information};
`;

export default {
  Wrapper,
  HRLine,
  Title,
  ButtonWrapper,
  Img,
  ResponseMsg,
  StyledRadioButton,
  StyledTextArea,
  TextAreaInfo,
  ResponseText,
  StyledIcon,
};
