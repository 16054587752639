/* eslint-disable import/no-import-module-exports */
// import { routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import { Action, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { History } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { rootEpic, rootReducer, RootState } from './index';
import * as services from '../services';
import { routerHistory } from '../lib/routerHistory';
import { applicationOfflineState } from './dispatchers/applicationOfflineState';

// Exports
/**
 * Create redux store with initial state
 *
 * @param {{}} preloadedState
 * @returns {{ store: Store, history: History }}
 */
export const createStore = (preloadedState = {}): { store: Store; history: History } => {
  const epicMiddleware = createEpicMiddleware<Action, Action, RootState, typeof services>({
    dependencies: services,
  });

  // Create middlewares
  const middleware = [
    ...[
      // connected-react-router
      // routerMiddleware(routerHistory),

      // redux-observables
      epicMiddleware,

      /*
       * Development only middlewares
       */

      // Logging
      process.env.NODE_ENV !== 'production' &&
        createLogger({
          collapsed: (_, action, logEntry) =>
            (!logEntry || !logEntry.error) && !action.error && !(action.payload instanceof Error),
        }),
    ].filter(Boolean),
  ];

  // Create store
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  });

  // Enable hot reloading
  if (module.hot) {
    module.hot.accept('./index', () => {
      store.replaceReducer(rootReducer);
    });
  }

  // Run epics
  epicMiddleware.run(rootEpic);

  // Subscribe dispatchers if we are in browser mode
  if (typeof window !== 'undefined') {
    applicationOfflineState(store);
  }

  return { store, history: routerHistory };
};
