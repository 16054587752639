import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { enableScroll } from './useScrollBlock';
import { actions as userActions } from '../redux/modules/user';

const useCloseMenu = () => {
  const dispatch = useDispatch();

  const closeMenu = useCallback(() => {
    enableScroll();

    dispatch(userActions.closeMenu());
  }, [dispatch]);

  return closeMenu;
};

export default useCloseMenu;
