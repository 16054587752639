import { stylesheet } from '@naf/global-style';
import { createGlobalStyle } from 'styled-components';

import { ChatStyles } from './ChatStyles';

export const GlobalStyle = createGlobalStyle<{ isMouseClick?: boolean }>`
  ${stylesheet}
  ${ChatStyles}
  body {
    overflow-x: clip;
  }

  /* Hide native cookie banner */
  #cookiebanner {
    display: none !important;
  }

  html {
    transition: color 0.2s linear, background-color 0.2s linear;
    overflow-x: clip;
  }
`;
