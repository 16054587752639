import { Action, ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { produce } from 'immer';
import { Observable, of, OperatorFunction } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import Boom from '@hapi/boom';
import { InternalSearchResultType, SearchData } from '../../hooks/GlobalSearchTypes';
import { TypedEpic } from '../types';
import { resultFields } from '../../components/layout/InternalSearchMetaData';

// Actions
export enum Actions {
  UPDATE_QUERY = 'internalSiteSearch/UPDATE_QUERY',
  RESET = 'internalSiteSearch/RESET',
  GET_SEARCH_RESULTS_REQUEST = 'internalSiteSearch/GET_SEARCH_RESULTS_REQUEST',
  GET_SEARCH_RESULTS_SUCCESS = 'internalSiteSearch/GET_SEARCH_RESULTS_SUCCESS',
  GET_SEARCH_RESULTS_CANCEL = 'internalSiteSearch/GET_SEARCH_RESULTS_CANCEL',
  GET_SEARCH_RESULTS_ERROR = 'internalSiteSearch/GET_SEARCH_RESULTS_ERROR',
  GET_MORE_SEARCH_RESULTS_REQUEST = 'internalSiteSearch/GET_MORE_SEARCH_RESULTS_REQUEST',
  GET_MORE_SEARCH_RESULTS_SUCCESS = 'internalSiteSearch/GET_MORE_SEARCH_RESULTS_SUCCESS',
  GET_MORE_SEARCH_RESULTS_CANCEL = 'internalSiteSearch/GET_MORE_SEARCH_RESULTS_CANCEL',
  GET_MORE_SEARCH_RESULTS_ERROR = 'internalSiteSearch/GET_MORE_SEARCH_RESULTS_ERROR',
}

export interface State {
  query: string;
  isLoading: boolean;
  pages: Record<number, { data: InternalSearchResultType[]; meta: { isLoading: boolean } }>;
  displayCount: number;
  totalResults: number;
  error?: Error;
  currentPage: number;
}

export const initialState: State = {
  query: '',
  pages: {},
  isLoading: false,
  displayCount: undefined,
  totalResults: undefined,
  currentPage: undefined,
};

export const actions = {
  updateQuery: createAction(Actions.UPDATE_QUERY, (query: string) => query)(),
  reset: createAction(Actions.RESET)(),
  getSearchResults: createAsyncAction(
    Actions.GET_SEARCH_RESULTS_REQUEST, // request payload creator
    Actions.GET_SEARCH_RESULTS_SUCCESS, // success payload creator
    Actions.GET_SEARCH_RESULTS_CANCEL, // failure payload creator
    Actions.GET_SEARCH_RESULTS_ERROR, // optional cancel payload creator
  )<[undefined, undefined], SearchData, Error, undefined>(),
  getMoreSearchResults: createAsyncAction(
    Actions.GET_MORE_SEARCH_RESULTS_REQUEST, // request payload creator
    Actions.GET_MORE_SEARCH_RESULTS_SUCCESS, // success payload creator
    Actions.GET_MORE_SEARCH_RESULTS_CANCEL, // failure payload creator
    Actions.GET_MORE_SEARCH_RESULTS_ERROR, // optional cancel payload creator
  )<[undefined, undefined], SearchData, Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.updateQuery, (state: State = initialState, action) =>
    produce(state, (draftState) => {
      draftState.query = action.payload;
    }),
  )
  .handleAction(actions.reset, (state: State = initialState) =>
    produce(state, (draftState) => {
      draftState.query = '';
      draftState.pages = {};
      draftState.isLoading = false;
      draftState.displayCount = undefined;
      draftState.totalResults = undefined;
      draftState.currentPage = undefined;
    }),
  )
  .handleAction(actions.getSearchResults.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.pages = { 1: { data: undefined, meta: { isLoading: true } } };
      draftState.displayCount = undefined;
      draftState.totalResults = undefined;
      draftState.currentPage = undefined;
      draftState.isLoading = true;
    }),
  )
  .handleAction(actions.getSearchResults.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.pages = { 1: { data: action.payload.results, meta: { isLoading: false } } };
      draftState.isLoading = false;
      draftState.displayCount = action.payload.results.length;
      draftState.totalResults = action.payload.meta.page.total_results;
      draftState.currentPage = action.payload.meta.page.current;
    }),
  )
  .handleAction(actions.getSearchResults.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      delete draftState.pages[1];
      draftState.isLoading = false;
    }),
  )
  .handleAction(actions.getSearchResults.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      delete draftState.pages[1];
      draftState.isLoading = false;
      draftState.error = action.payload;
    }),
  )
  .handleAction(actions.getMoreSearchResults.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.pages[draftState.currentPage + 1] = { data: undefined, meta: { isLoading: true } };
      draftState.isLoading = true;
    }),
  )
  .handleAction(actions.getMoreSearchResults.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.pages[draftState.currentPage + 1] = { data: action.payload.results, meta: { isLoading: false } };
      draftState.isLoading = false;
      draftState.displayCount += action.payload.results.length;
      draftState.totalResults = action.payload.meta.page.total_results;
      draftState.currentPage = action.payload.meta.page.current;
    }),
  )
  .handleAction(actions.getMoreSearchResults.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      delete draftState.pages[draftState.currentPage + 1];
      draftState.isLoading = false;
    }),
  )
  .handleAction(actions.getMoreSearchResults.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      delete draftState.pages[draftState.currentPage + 1];
      draftState.isLoading = false;
      draftState.error = action.payload;
    }),
  );

export const fetchSearchResultsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, elasticSearchAPI, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_SEARCH_RESULTS_REQUEST),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getSearchResults.request>[]
    >,
    switchMap(() => {
      const toDate = new Date();
      const fromDate = new Date();
      fromDate.setHours(toDate.getHours() - 2);

      return ajax
        .post<SearchData>(
          `${apimBaseUrl}/${elasticSearchAPI}/search`,
          {
            query: state$.value.internalSiteSearch.query,
            filters: {
              last_crawled_at: {
                from: fromDate.toISOString(),
                to: toDate.toISOString(),
              },
            },
            page: {
              current: 1,
              size: 20,
            },
            result_fields: resultFields,
          },
          { 'Ocp-Apim-Subscription-Key': apimContentHub },
        )
        .pipe(
          map(({ response }) => actions.getSearchResults.success(response)),
          catchError(() => of(actions.getSearchResults.failure(new Boom.Boom('Could not get search results')))),
        );
    }),
  );
};

export const fetchMoreSearchResultsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, elasticSearchAPI, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MORE_SEARCH_RESULTS_REQUEST),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMoreSearchResults.request>[]
    >,
    switchMap(() =>
      ajax
        .post<SearchData>(
          `${apimBaseUrl}/${elasticSearchAPI}/search`,
          {
            query: state$.value.internalSiteSearch.query,
            page: {
              current: state$.value.internalSiteSearch.currentPage + 1,
              size: 20,
            },
            result_fields: resultFields,
          },
          { 'Ocp-Apim-Subscription-Key': apimContentHub },
        )
        .pipe(
          map(({ response }) => actions.getMoreSearchResults.success(response)),
          catchError(() =>
            of(actions.getMoreSearchResults.failure(new Boom.Boom('Could not get more search results'))),
          ),
        ),
    ),
  );
};

export const epics: TypedEpic[] = [fetchSearchResultsEpic, fetchMoreSearchResultsEpic];
