import { useEffect, useMemo, useState } from 'react';
import { PointOfInterest } from '../components/SectionBlocks/blocks/Charts/RouteCharts';
import { RichGeopoint } from '../../../types/routeBlockType';

const metersFromStart = (stopIndex: number, lastInterpolation: number, steps: google.maps.DirectionsStep[]) => {
  const metersUntilLastStep = steps.slice(0, stopIndex).reduce((acc, cur) => acc + (cur.distance?.value || 0), 0);
  const lastStep = steps[stopIndex];
  const metersOnLastStep = (lastStep.distance?.value || 0) * lastInterpolation;
  return Math.round((metersUntilLastStep + metersOnLastStep) / 1000);
};

type Input = {
  routepoints?: RichGeopoint[];
  elevation?: number[];
};

export const useElPrixRouteData = ({ routepoints, elevation }: Input) => {
  const [pointsOfInterest, setPointsOfInterest] = useState<PointOfInterest[]>([]);
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();

  const directionService = useMemo(() => new google.maps.DirectionsService(), []);

  useEffect(() => {
    if (!routepoints || !elevation) {
      return;
    }

    const waypoints = [...routepoints];
    const start = waypoints.shift();
    const end = waypoints.pop();
    if (start && end) {
      const request: google.maps.DirectionsRequest = {
        origin: start.latlng,
        destination: end.latlng,
        waypoints: waypoints.map(({ latlng }) => ({ location: latlng, stopover: false })),
        provideRouteAlternatives: false,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      };

      directionService.route(request, (result) => {
        if (result && elevation && routepoints) {
          setDirections(result);
          const route = result.routes[0];
          const routeLength = elevation.length;

          const leg = route.legs[0] as any; // via_waypoint is not on provided on DirectionLeg type
          const viaWaypoints = leg.via_waypoint as { step_index: number; step_interpolation: number }[];

          setPointsOfInterest([
            { ...start, kmFromStart: 0, altitude: elevation[0] },
            // eslint-disable-next-line camelcase
            ...viaWaypoints.map(({ step_index, step_interpolation }, i) => {
              const kmFromStart = metersFromStart(step_index, step_interpolation, leg.steps);
              return {
                ...waypoints[i],
                name: waypoints[i].title,
                kmFromStart,
                altitude: elevation[kmFromStart],
              };
            }),
            {
              ...end,
              kmFromStart: routeLength - 1,
              altitude: elevation[routeLength - 1],
            },
          ]);
        }
      });
    }
  }, [routepoints, directionService, elevation]);

  return { directions, pointsOfInterest };
};
