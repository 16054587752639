import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing } from '@naf/theme';

export const NavbarLinkItem = styled.div<{ $isScrolled: boolean }>`
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  margin: 0 ${spacing.space16} 0 0 !important;
  line-height: 2.75rem;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      line-height: 1.75rem;
    `}

  span {
    transition: all 0.3s ease-in-out;
    color: ${nafColor.signature.black};
  }

  a {
    padding: 0 ${spacing.space8};
    border-radius: ${spacing.space4};
  }

  &:hover {
    a {
      background-color: ${nafColor.signature.yellow40};
      text-decoration: none;
    }
  }
`;
