import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { CloudinaryImageType } from '../../../../types/blockTypes';
import { ImageCaption } from '../ImageCaption';
import ResponsiveImage from '../image/ResponsiveImage';

export const ArticleTextWideImage = ({
  image,
  mobileImage,
}: {
  image?: CloudinaryImageType;
  mobileImage?: CloudinaryImageType;
}) => {
  const { publicId, caption, altText, photographer, copyright } = image || {};
  const { publicId: mobileImagePublicId, caption: mobileImageCaption, altText: mobileImageAltText } = mobileImage || {};
  const shouldShowCaption = caption || copyright || photographer;
  const shouldShowMobileCaption = mobileImageCaption || copyright || photographer;

  return (
    <Wrapper className="articleTextWideImage">
      <ArticleImageGridCol>
        {publicId ? (
          <ImageWrapper isMobileView={false} hasMobileImage={!!mobileImage}>
            <ResponsiveImage imageId={publicId} altText={altText || caption} />
          </ImageWrapper>
        ) : null}
        {mobileImagePublicId ? (
          <ImageWrapper isMobileView hasMobileImage={!!mobileImage}>
            <ResponsiveImage imageId={mobileImagePublicId} altText={mobileImageAltText || mobileImageCaption} />
          </ImageWrapper>
        ) : null}
      </ArticleImageGridCol>
      <ArticleImageCaptionGridCol>
        <ImageCaptionWrapper>
          {shouldShowCaption ? (
            <MobileToggle isMobileView={false} hasMobileImage={!!mobileImage}>
              <ImageCaption caption={caption} captionId={publicId} photographer={photographer} copyright={copyright} />
            </MobileToggle>
          ) : null}
          {shouldShowMobileCaption ? (
            <MobileToggle isMobileView hasMobileImage={!!mobileImage}>
              <ImageCaption
                caption={mobileImageCaption}
                captionId={mobileImagePublicId}
                photographer={photographer}
                copyright={copyright}
              />
            </MobileToggle>
          ) : null}
        </ImageCaptionWrapper>
      </ArticleImageCaptionGridCol>
    </Wrapper>
  );
};

const Wrapper = styled.figure`
  display: grid;
  grid-column: 1 / -1;
  grid-gap: 0 32px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  margin: ${spacing.space32} 0;
  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space24} 0;
  }
`;

const ArticleImageGridCol = styled.div`
  grid-column: 1 / -1;
  @media (max-width: ${breakpoints.m}) {
    grid-row: 1;
    width: calc(100% + 48px);
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};
  }
`;

const ArticleImageCaptionGridCol = styled.figcaption`
  grid-column: 1 / 3;
  align-self: end;
  position: relative;
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    grid-row: 2;
  }
`;

const ImageWrapper = styled.div<{ hasMobileImage: boolean; isMobileView: boolean }>`
  margin: 0;
  line-height: 0;
  width: 100%;

  img {
    width: 100%;
  }

  display: ${({ isMobileView, hasMobileImage }) => {
    let isVisible = false;
    if (hasMobileImage && isMobileView) {
      isVisible = false;
    }
    if (hasMobileImage && !isMobileView) {
      isVisible = true;
    }
    if (!hasMobileImage && isMobileView) {
      isVisible = false;
    }
    if (!hasMobileImage && !isMobileView) {
      isVisible = true;
    }

    return isVisible ? `inherit` : `none`;
  }};

  @media (max-width: ${breakpoints.m}) {
    display: ${({ isMobileView, hasMobileImage }) => {
      let isVisible = false;
      if (hasMobileImage && isMobileView) {
        isVisible = true;
      }
      if (hasMobileImage && !isMobileView) {
        isVisible = false;
      }
      if (!hasMobileImage && isMobileView) {
        isVisible = false;
      }
      if (!hasMobileImage && !isMobileView) {
        isVisible = true;
      }
      return isVisible ? `inherit` : `none`;
    }};
    img {
      max-width: 100%;
      width: auto;
      max-height: 100vh;
    }
  }
`;

const MobileToggle = styled.div<{ hasMobileImage: boolean; isMobileView: boolean }>`
  display: ${({ isMobileView, hasMobileImage }) => {
    let isVisible = false;
    if (hasMobileImage && isMobileView) {
      isVisible = false;
    }
    if (hasMobileImage && !isMobileView) {
      isVisible = true;
    }
    if (!hasMobileImage && isMobileView) {
      isVisible = false;
    }
    if (!hasMobileImage && !isMobileView) {
      isVisible = true;
    }

    return isVisible ? `inherit` : `none`;
  }};

  @media (max-width: ${breakpoints.m}) {
    display: ${({ isMobileView, hasMobileImage }) => {
      let isVisible = false;
      if (hasMobileImage && isMobileView) {
        isVisible = true;
      }
      if (hasMobileImage && !isMobileView) {
        isVisible = false;
      }
      if (!hasMobileImage && isMobileView) {
        isVisible = false;
      }
      if (!hasMobileImage && !isMobileView) {
        isVisible = true;
      }

      return isVisible ? `inherit` : `none`;
    }};
    img {
      max-width: 100%;
      width: auto;
    }
  }
`;

const ImageCaptionWrapper = styled.div`
  @media (min-width: ${breakpoints.m}) {
    position: absolute;
    top: ${spacing.space64};
  }
`;
