import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { FaqType } from '../../../../types/faqType';

// Actions
export enum Actions {
  GET_FAQ = 'GET_FAQ',
  GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS',
  GET_FAQ_FAIL = 'GET_FAQ_FAIL',
  GET_FAQ_CANCEL = 'GET_FAQ_CANCEL',
}

export interface State {
  data?: FaqType[];
  meta: { isUpdating: boolean; fetchedAt?: string };
  error?: Error;
}

export const initialState: State = {
  meta: {
    isUpdating: false,
  },
};

export const actions = {
  getFaq: createAsyncAction(
    Actions.GET_FAQ, // request payload creator
    Actions.GET_FAQ_SUCCESS, // success payload creator
    Actions.GET_FAQ_FAIL, // failure payload creator
    Actions.GET_FAQ_CANCEL, // optional cancel payload creator
  )<undefined, FaqType[], Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getFaq.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.meta = {
        isUpdating: true,
        fetchedAt: draftState.meta.fetchedAt || undefined,
      };
    }),
  )
  .handleAction(actions.getFaq.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta = {
        isUpdating: false,
        fetchedAt: new Date().toISOString(),
      };
      draftState.data = action.payload;
    }),
  )
  .handleAction(actions.getFaq.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta = { isUpdating: false };
      draftState.error = action.payload;
    }),
  )
  .handleAction(actions.getFaq.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.meta = { isUpdating: false };
    }),
  );

export const fetchDocumentEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_FAQ),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.getFaq.request>[]>,
    switchMap(() => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      return ajax<FaqType[]>({
        url: `${apimBaseUrl}/${apimNafNoApi}/faq`,
        headers,
      }).pipe(
        map(({ response }) => actions.getFaq.success(response)),
        catchError(() => of(actions.getFaq.failure(new Boom.Boom('Could not get document')))),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchDocumentEpic];
