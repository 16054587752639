import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { ChargeTestType, RangeTestType } from '../../../../types/testType';
import { ArticleType, ProductArticleType } from '../../../../types/articleType';
import { BenefitsType } from '../../../../types/benefitsType';
import { CenterArticleType } from '../../../../types/centerArticleType';
import { CenterProductArticleType } from '../../../../types/centerProductArticleType';
import { LocalDepartmentType } from '../../../../types/localDepartmentType';
import { EventType } from '../../../../types/EventType';
import { PracticeTrackType } from '../../../../types/PracticeTrackType';
import { TopicPageAppPropertiesType } from '../../../../types/CategoryAppType';
import { FaqArticleType } from '../../../../types/faqArticleType';
import { DynamicFormArticleType } from '../../../../types/DynamicFormArticleType';

// Actions
export enum Actions {
  GET_DOCUMENT = 'GET_DOCUMENT',
  GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_FAIL = 'GET_DOCUMENT_FAIL',
  GET_DOCUMENT_CANCEL = 'GET_DOCUMENT_CANCEL',
}

export type DocumentData =
  | ArticleType
  | ProductArticleType
  | RangeTestType
  | ChargeTestType
  | BenefitsType
  | CenterArticleType
  | CenterProductArticleType
  | LocalDepartmentType
  | EventType
  | PracticeTrackType
  | TopicPageAppPropertiesType
  | FaqArticleType
  | DynamicFormArticleType;

export type MappedData = {
  data?: DocumentData;
  meta: { isUpdating: boolean; fetchedAt?: string; isLoggedin?: boolean };
  error?: Error & { status?: number };
};

export interface State {
  mappedData: Record<string, MappedData>;
}

export const initialState: State = {
  mappedData: {},
};

export const actions = {
  getDocument: createAsyncAction(
    Actions.GET_DOCUMENT, // request payload creator
    Actions.GET_DOCUMENT_SUCCESS, // success payload creator
    Actions.GET_DOCUMENT_FAIL, // failure payload creator
    Actions.GET_DOCUMENT_CANCEL, // optional cancel payload creator
  )<
    [string, { token?: string }],
    [DocumentData, { id: string }],
    [Error, { id: string }],
    [undefined, { id: string }]
  >(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getDocument.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.payload] = {
        data: draftState.mappedData[action.payload]?.data || undefined,
        meta: {
          isUpdating: true,
          fetchedAt: draftState.mappedData[action.payload]?.meta.fetchedAt || undefined,
          isLoggedin: !!action.meta.token,
        },
      };
    }),
  )
  .handleAction(actions.getDocument.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.meta.id] = {
        meta: {
          isUpdating: false,
          fetchedAt: new Date().toISOString(),
          isLoggedin: state.mappedData[action.meta.id].meta.isLoggedin,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data: action.payload,
      };
    }),
  )
  .handleAction(actions.getDocument.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.meta.id] = { meta: { isUpdating: false }, error: action.payload };
    }),
  )
  .handleAction(actions.getDocument.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.meta.id] = { meta: { isUpdating: false }, error: action.payload };
    }),
  );

export const fetchDocumentEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_DOCUMENT),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getDocument.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
        Location?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      if (document) {
        headers.Location = document.location.href;
      }
      return ajax<DocumentData>({
        url: `${apimBaseUrl}/${apimNafNoApi}/commonarticles/${action.payload}`,
        headers,
      }).pipe(
        map(({ response }) => actions.getDocument.success(response, { id: action.payload })),
        catchError((error) => of(actions.getDocument.failure(error, { id: action.payload }))),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchDocumentEpic];
