import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { GlobalSettingsType } from '../../../../types/globalSettingsType';

// Actions
export enum Actions {
  GET_GLOBALSETTINGS = 'naf/globalSettings/GET_GLOBALSETTINGS',
  GET_GLOBALSETTINGS_SUCCESS = 'naf/globalSettings/GET_GLOBALSETTINGS_SUCCESS',
  GET_GLOBALSETTINGS_FAIL = 'naf/globalSettings/GET_GLOBALSETTINGS_FAIL',
  GET_GLOBALSETTINGS_CANCEL = 'naf/globalSettings/GET_GLOBALSETTINGS_CANCEL',
}

export interface State {
  isUpdating: boolean;
  settings: GlobalSettingsType;
  errorState?: Error;
}

export const initialState: State = {
  settings: {
    title: '',
    megaMenu: {},
    profileMenu: [],
  },
  isUpdating: false,
};

export const actions = {
  getGlobalSettings: createAsyncAction(
    Actions.GET_GLOBALSETTINGS, // request payload creator
    Actions.GET_GLOBALSETTINGS_SUCCESS, // success payload creator
    Actions.GET_GLOBALSETTINGS_FAIL, // failure payload creator
    Actions.GET_GLOBALSETTINGS_CANCEL, // optional cancel payload creator
  )<string, GlobalSettingsType, [Error, { id: string }]>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getGlobalSettings.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getGlobalSettings.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.settings = action.payload;
    }),
  )
  .handleAction(actions.getGlobalSettings.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getGlobalSettings.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

export const fetchGlobalSettingsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_GLOBALSETTINGS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getGlobalSettings.request>[]
    >,
    mergeMap(([action]: { payload: string }[]) =>
      ajax<GlobalSettingsType>({
        url: `${apimBaseUrl}/${apimNafNoApi}/global-settings`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getGlobalSettings.success(response)),
        catchError(() =>
          of(actions.getGlobalSettings.failure(new Boom.Boom('Could not get globalsettings'), { id: action.payload })),
        ),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [fetchGlobalSettingsEpic];
