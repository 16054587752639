type CalculationState = {
  stated?: number;
  measured?: number;
  increaseIsPositive: boolean;
  scheme: 'percentage' | 'difference';
};

export const calculateChange = ({ stated, measured, increaseIsPositive, scheme }: CalculationState) => {
  if (!stated || !measured) return null;

  let result: number;

  if (scheme === 'difference') {
    result = Math.round(measured - stated);
  } else {
    const percent = ((stated - measured) / stated) * 100;
    result = -Math.round((percent + Number.EPSILON) * 10) / 10;
  }

  const color = (increaseIsPositive && result >= 0) || (!increaseIsPositive && result <= 0) ? 'green' : 'red';
  return { diff: result, color, unit: scheme === 'difference' ? 'min' : '%' };
};
