import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { Grid, GridCol } from '@naf/grid';
import { nafColor } from '@nafcore/theme';
import { Form } from '../../../../../Form';

const Header = styled(Text)`
  position: absolute;
  margin-top: 21px;
  margin-left: ${spacing.space24};
`;

const Wrapper = styled(Grid)`
  border: 1px solid ${nafColor.neutral.neutral3};
  border-radius: 2px;
`;

const FormGridCol = styled(GridCol)`
  padding: 96px 0 ${spacing.space32} ${spacing.space24};
  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space80} ${spacing.space24} ${spacing.space20} ${spacing.space24};
  }
`;

const StyledForm = styled(Form)`
  margin: 0;
`;

const SummaryGridCol = styled(GridCol)`
  padding: 56px ${spacing.space32} 56px ${spacing.space32};
  background-color: ${nafColor.signature.yellow10};
  @media (max-width: ${breakpoints.s}) {
    padding: 0 ${spacing.space24} ${spacing.space24} ${spacing.space24};
  }
`;

const Summary = styled.div``;

const MonthlyPriceLabel = styled(Text)`
  margin-bottom: ${spacing.space8};
`;

const MonthlyPrice = styled(Text)`
  margin: 0;
  padding-bottom: ${spacing.space12};
`;

const SummaryDetails = styled.div`
  border-top: 1px solid ${nafColor.neutral.neutral3};
  margin-bottom: ${spacing.space24};
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${nafColor.neutral.neutral3};
  padding: ${spacing.space8} 0;
`;

export default {
  Header,
  Wrapper,
  FormGridCol,
  StyledForm,
  SummaryGridCol,
  Summary,
  MonthlyPriceLabel,
  MonthlyPrice,
  SummaryDetails,
  SummaryItem,
};
