import { useMemo } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import useSelector from '../redux/typedHooks';

export const useCloudinary = () => {
  const imageCloudName = useSelector((state) => state.application?.imageCloudName);

  return useMemo(() => {
    if (imageCloudName) {
      return new Cloudinary({
        cloud: {
          cloudName: imageCloudName,
        },
        url: {
          secure: true,
          analytics: false,
        },
      });
    }
    return null;
  }, [imageCloudName]);
};
