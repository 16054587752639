import React, { useEffect, useState } from 'react';
import * as S from './Styles';

export const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (!isVisible && window.scrollY > 1000) {
        setIsVisible(true);
        checkIfChatIsVisible();
      } else if (isVisible && window.scrollY <= 1000) {
        setIsVisible(false);
        checkIfChatIsVisible();
      }
    };

    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      return () => {};
    }
    const timer = setTimeout(() => {
      setShouldRender(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);

  const onClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const checkIfChatIsVisible = () => {
    const chat = document.querySelector('[data-puzzel-chat]');
    if (chat) {
      setIsChatVisible(true);
    }
  };

  useEffect(() => {
    checkIfChatIsVisible();
  }, []);

  if (!shouldRender) {
    return null;
  }

  return (
    <S.Wrapper $isVisible={isVisible} $isChatVisible={isChatVisible}>
      <S.StyledIconButton ariaLabel="Tilbake til toppen" onClick={onClick} icon="arrow-right" variant="primary" />
    </S.Wrapper>
  );
};
