import React from 'react';
import { ButtonLink } from '@naf/button-link';
import { isInternalLink } from '../../../../../lib/isInternalLink';
import useSelector from '../../../../../redux/typedHooks';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface ExternalLinkSerializerProps {
  value: { text: string; blank?: boolean; link: string };
}

export const ExternalLink = ({ value: { text, blank, link } }: ExternalLinkSerializerProps) => {
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const { isInternal, relativeLink } = isInternalLink(baseUrl, link);
  if (isInternal) {
    return blank ? (
      <ButtonLink href={relativeLink} text={text} target="_blank" rel="noreferrer" />
    ) : (
      <ButtonLink href={relativeLink} text={text} />
    );
  }

  return blank ? (
    <ButtonLink href={link} text={text} icon="launch" target="_blank" rel="noreferrer" />
  ) : (
    <ButtonLink href={link} text={text} icon="launch" />
  );
};

export const ExternalLinkSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: ExternalLinkSerializerProps) => (
      <ArticleBlockContentGridCol className="externalLink">
        <ExternalLink value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: ExternalLinkSerializerProps) => <ExternalLink value={value} />;
};
