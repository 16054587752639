import styled from 'styled-components';
import { breakpoints } from '@naf/theme';

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: block;

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;
