import React, { forwardRef, Ref } from 'react';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing, breakpoints } from '@naf/theme';
import BlockContent from '../../../block-content/BlockContent';

import { TextAndBackgroundImageBlock } from '../../../../../../types/ScrollableTextBlocksWithBackgroundImagesType';

export const ParallaxItem = forwardRef(
  ({ item, index }: { item: TextAndBackgroundImageBlock; index: number }, ref: Ref<HTMLDivElement>) => (
    <Section data-index={index} className="section" id={item.title} ref={ref}>
      <ParallaxText className="sectionText" data-bg={item?.colorScheme} data-image-id={item._key}>
        {item.textBlock && <BlockContent value={item.textBlock} />}
      </ParallaxText>
    </Section>
  ),
);

const Section = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-end;
  width: calc(100vw - 96px);
  max-width: 1120px;
  min-height: 1120px;
  height: 100%;
  position: relative;
  z-index: 20;
  margin-top: 200px;

  @media (max-width: ${breakpoints.l}) {
    width: calc(100vw - 48px);
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
  }
`;

const ParallaxText = styled.div`
  background: ${nafColor?.primary?.dew || '#E9F3E6'};
  display: inline-block;
  max-width: 576px;
  margin: ${spacing.space64};
  padding: ${spacing.space32};
  border-radius: 4px;
  color: ${nafColor?.signature?.black || '#1A1A1A'};
  p,
  h2,
  h3 {
    color: ${nafColor?.signature?.black || '#1A1A1A'};
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${nafColor?.signature?.black || '#1A1A1A'};

    &:hover {
      color: ${nafColor?.primary?.park || '#267C4F'};
    }
  }

  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space48} ${spacing.space16};
  }

  @media (max-width: ${breakpoints.s}) {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;
