import React, { useContext } from 'react';
import { Text, TextVariant } from '@naf/text';
import { TextList, TextListItem } from '@naf/text-list';
import styled from 'styled-components';
import * as S from './styles';
import { DowngradeContext } from '../../../../../../pages/downgrade/context/DowngradeContext';
import { formatDate } from '../../../../../../utils/Dates';

interface GreenBoxWithMembersListAndEndDateProps {
  value: {
    boldText: string;
    withMembersList: boolean;
    text: string;
  };
}

export const GreenBoxWithMembersListAndEndDate = ({
  value: { boldText, withMembersList, text },
}: GreenBoxWithMembersListAndEndDateProps) => {
  const { endDate, members } = useContext(DowngradeContext);
  return (
    <S.MembershipActiveContainer>
      <Text variant={TextVariant.BodyTextHeader}>{boldText}</Text>
      {withMembersList && (
        <TextList>
          {members.map((member) => (
            <TextListItem key={member.customerId}>
              {member.fullName}
              {!member.isMainMember ? ' (hustandlemmer)' : ''}
            </TextListItem>
          ))}
        </TextList>
      )}
      <Text variant={TextVariant.ArticleText}>
        {text} <BoldText tag="span">{formatDate(endDate)}</BoldText>
      </Text>
    </S.MembershipActiveContainer>
  );
};

export const GreenBoxWithMembersListAndEndDateSerializer =
  () =>
  ({ value }: GreenBoxWithMembersListAndEndDateProps) =>
    <GreenBoxWithMembersListAndEndDate value={value} />;

const BoldText = styled(Text)`
  font-weight: 700;
`;
