import { ActionType, createAction, createReducer } from 'typesafe-actions';

/**
 * ActionTypes
 */
export enum ActionTypes {
  APPLICATION_SET_OFFLINE = 'varan/application/SET_OFFLINE',
  APPLICATION_SET_ONLINE = 'varan/application/SET_ONLINE',
  APPLICATION_SET_IS_FIRST_RENDERING = '@@router/SET_IS_FIRST_RENDERING',
  APPLICATION_INCREMENT_URL_PARAMS_INDEX = 'varan/application/INCREMENT_URL_PARAMS_INDEX',
}

/**
 * State
 */
export interface ApplicationState {
  apimBaseUrl: string;
  apimVehicleDataApi: string;
  apimNafNoApi: string;
  apimMotorApi: string;
  apimFormApi: string;
  apimMembershipApi: string;
  apimNafCenterApi: string;
  apimMessageHubApi: string;
  apimConsentsApi: string;
  apimCustomerLogicApi: string;
  apimTravelApi: string;
  apimContentHub: string;
  gtmMeasurementId: string;
  signalR: string;
  baseUrl: string;
  allowIndexing: boolean;
  appInsightsInstrumentationKey: string;
  gtmId: string;
  sanityProjectId: string;
  sanityMotorDataset: string;
  sanityNafNoDataset: string;
  imageCloudName: string;
  env: string;
  initialServerRenderedRoute: string;
  isFirstRendering: boolean;
  toggledFeatures: {
    myNAF: boolean;
    becomeAMember: boolean;
    myProfile: boolean;
    myMembership: boolean;
    myMessages: boolean;
    myVehicles: boolean;
    myDocuments: boolean;
    myTravels: boolean;
    benefits: boolean;
    buyIdp: boolean;
    search: boolean;
    nafCenters: boolean;
    downgrade: boolean;
  };
  urlParamsIndex: number;
  authDomain: string;
  authClientId: string;
  authRedirectUri: string;
  authAudience: string;
  connectMembership: string;
  rplUrl: string;
  orderPvcUrl: string;
  kjopeKontraktUrl: string;
  npsSurveyId: string;
  elasticSearchAPI: string;
  puzzelConfigurationId: string;
  puzzelCustomerKey: string;
  googleMapsApiKey: string;
  isEditor: boolean;
  cmsEnv: string;
  isInhouse: boolean;
  xForwarded: string | string[];
  requestIp: string;
  espialScriptUrl: string;
  espialEndpointUrl: string;
}
export const initialState: Readonly<ApplicationState> = {
  apimBaseUrl: '',
  apimContentHub: '',
  gtmMeasurementId: '',
  appInsightsInstrumentationKey: '',
  allowIndexing: false,
  signalR: '',
  baseUrl: '',
  apimFormApi: '',
  apimMotorApi: '',
  apimNafNoApi: '',
  apimVehicleDataApi: '',
  apimMembershipApi: '',
  apimNafCenterApi: '',
  apimMessageHubApi: '',
  apimConsentsApi: '',
  apimCustomerLogicApi: '',
  apimTravelApi: '',
  initialServerRenderedRoute: '',
  isFirstRendering: false,
  sanityNafNoDataset: '',
  sanityMotorDataset: '',
  sanityProjectId: '',
  imageCloudName: '',
  gtmId: '',
  env: 'dev',
  urlParamsIndex: 0,
  toggledFeatures: {
    myNAF: false,
    becomeAMember: false,
    myProfile: false,
    myMembership: false,
    myMessages: false,
    myVehicles: false,
    myDocuments: false,
    myTravels: false,
    benefits: false,
    buyIdp: false,
    search: false,
    nafCenters: false,
    downgrade: false,
  },
  authDomain: '',
  authClientId: '',
  authRedirectUri: '',
  authAudience: '',
  connectMembership: '',
  rplUrl: '',
  orderPvcUrl: '',
  kjopeKontraktUrl: '',
  npsSurveyId: '',
  elasticSearchAPI: '',
  puzzelConfigurationId: '',
  puzzelCustomerKey: '',
  googleMapsApiKey: '',
  isEditor: false,
  cmsEnv: '',
  isInhouse: false,
  xForwarded: '',
  requestIp: '',
  espialScriptUrl: '',
  espialEndpointUrl: '',
};

/**
 * ActionCreators
 */
export const actionCreators = {
  setOffline: createAction(ActionTypes.APPLICATION_SET_OFFLINE)(),
  setOnline: createAction(ActionTypes.APPLICATION_SET_ONLINE)(),
  incrementUrlParamsIndex: createAction(ActionTypes.APPLICATION_INCREMENT_URL_PARAMS_INDEX)(),
  setIsFirstRendering: createAction(
    ActionTypes.APPLICATION_SET_IS_FIRST_RENDERING,
    (isFirstRendering) => isFirstRendering,
  )(),
};

/**
 * Reducers
 */
export const reducers = createReducer<ApplicationState, ActionType<typeof actionCreators>>(initialState)
  .handleAction(actionCreators.setOffline, (state) => ({ ...state, isOffline: true }))
  .handleAction(actionCreators.setOnline, (state) => ({ ...state, isOffline: false }))
  .handleAction(actionCreators.incrementUrlParamsIndex, (state) => ({
    ...state,
    urlParamsIndex: state.urlParamsIndex + 1,
  }))
  .handleAction(actionCreators.setIsFirstRendering, (state, action) => ({
    ...state,
    isFirstRendering: action.payload.isFirstRendering,
  }));

/**
 * Epics
 */
export const epics = [];
