import styled from 'styled-components';
import React from 'react';
import { FlyoutModal } from '@naf/flyout-modal';
import CarVariantData from './CarVariantData';

interface Props {
  data: any;
  onHandleClose: () => void;
}

const CarVariantFlyout = ({ data, onHandleClose }: Props) => (
  <Wrapper>
    <FlyoutModal isOpen title={data.name} handleClose={onHandleClose} maxWidth={600} headerZIndex={3}>
      <CarVariantData data={data} />
    </FlyoutModal>
  </Wrapper>
);

const Wrapper = styled.div`
  strong {
    text-transform: capitalize;
  }
`;

export default CarVariantFlyout;
