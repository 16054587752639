import React from 'react';
import { FormBlock } from '../../Form/FormBlock';
import { useReferencedComponent } from '../../../../../hooks/useReferencedComponent';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { FeaturedVehicleResultBlock } from './FeaturedVehicleResultSerializer/FeaturedVehicleResultSerializer';
import { Results } from '../../../../SectionBlocks/Testshowcase/Results';

export interface ReferencedComponentProps {
  type: 'referencedComponent';
  documentId: string;
}

interface ReferencedComponentSerializerProps {
  value: ReferencedComponentProps;
}

export const ReferencedComponentBlock = ({ value: { documentId } }: ReferencedComponentSerializerProps) => {
  const data = useReferencedComponent(documentId);
  if (data?.type === 'dynamicForm') {
    return <FormBlock value={{ _key: '', _type: 'dynamicForm', dynamicForm: data }} />;
  }
  if (data?.type === 'bodyFeaturedVehicleTestResult') {
    return <FeaturedVehicleResultBlock value={data} />;
  }
  if (data?.type === 'testResultBlockGlobal') {
    return <Results block={data} />;
  }

  return null;
};

export const ReferencedComponentSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  const ArticleBlockContent = ({ value }: ReferencedComponentSerializerProps) => {
    const data = useReferencedComponent(value.documentId);

    if (data?.type === 'bodyFeaturedVehicleTestResult') {
      return <ReferencedComponentBlock value={value} />;
    }

    return (
      <ArticleBlockContentGridCol>
        <ReferencedComponentBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  };

  if (context === 'article') {
    return ArticleBlockContent;
  }

  return ({ value }: ReferencedComponentSerializerProps) => <ReferencedComponentBlock value={value} />;
};
