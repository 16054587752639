import React from 'react';
import { CampaignBannerVariant } from '@naf/banner';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

import { CampaignBanner } from '../../../../SectionBlocks/blocks/Campaign';
import { CampaignBlockType } from '../../../../../../../types/campaignBlockType';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface CampaignBlockSerializerProps {
  value: CampaignBlockType;
}

export const CampaignBlock = ({ value }: CampaignBlockSerializerProps) => (
  <CampaignBanner campaign={{ ...value, variant: value.variant || CampaignBannerVariant.Medium }} />
);

export const CampaignBlockSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: CampaignBlockSerializerProps) => (
      <StyledArticleBlockContentGridCol className="campaignBlock">
        <CampaignBlock value={value} />
      </StyledArticleBlockContentGridCol>
    );
  }
  return ({ value }: CampaignBlockSerializerProps) => <CampaignBlock value={value} />;
};

const StyledArticleBlockContentGridCol = styled(ArticleBlockContentGridCol)`
  margin: ${spacing.space32} 0;
  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space24} 0;
  }
`;
