import { breakpoints, spacing } from '@naf/theme';
import styled, { css } from 'styled-components';

export const BlockContainer = styled.div<{ $isFrontPage?: boolean; $hasBreadCrumbs?: boolean }>`
  margin: ${spacing.space32} 0;

  ${({ $isFrontPage, $hasBreadCrumbs }) =>
    ($isFrontPage || !$hasBreadCrumbs) &&
    css`
      margin-top: ${spacing.space120};
      margin-bottom: ${spacing.space32};
    `}

  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space48} 0;

    ${({ $isFrontPage }) =>
      $isFrontPage &&
      css`
        margin-top: ${spacing.space80};
        margin-bottom: ${spacing.space32};
      `}
  }
`;
