import { Text, TextVariant } from '@naf/text';
import { Table, TableBody, TableCell, TableRow } from '@naf/table';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Accordion } from '@naf/accordion';
import { breakpoints, spacing } from '@naf/theme';
import { ButtonLink } from '@naf/button-link';
import { nafColor } from '@nafcore/theme';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { useWindowResize } from '../../../../../hooks/useWindowResize';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

export interface TableRowType {
  _key: string;
  _type: string;
  cells: string[];
}

interface TableSerializerProps {
  value: {
    title: string;
    caption: string;
    table: { rows: TableRowType[] };
    orientation: 'top' | 'left' | 'crossTable';
    shouldCollapse: boolean;
    displayStyle: 'standard' | 'nafCenter';
  };
}

const CellContent = (cell: string) => {
  if (cell === 'check') {
    return <StyledCheckCircle size={24} />;
  }
  return cell;
};

const NafCenterTableView = ({ value: { table, shouldCollapse, caption } }: TableSerializerProps) => {
  const [bodyWidth, setBodyWidth] = useState<number>();
  const [shouldRowsCollapse, setShouldRowsCollapse] = useState<boolean>(shouldCollapse);

  const setWidth = () => {
    if (document) {
      const element = document.querySelector('main');
      if (element) {
        setBodyWidth(element.offsetWidth);
      }
    }
  };

  useEffect(() => {
    setWidth();
  }, []);

  useWindowResize(setWidth);

  return (
    <TableWrapper width={bodyWidth}>
      <h2>{caption}</h2>
      <Table>
        {table.rows.map((row: any, index: number) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {index <= 10 && (
              <Tr index={index}>
                {row.cells.map((cell: string) => (index === 0 ? <Th>{cell}</Th> : <Td>{CellContent(cell) || ''}</Td>))}
              </Tr>
            )}
          </>
        ))}
      </Table>
      <ShowMoreWrapper $isHidden={shouldRowsCollapse}>
        <StyledTable>
          {table.rows.map((row: any, index: number) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {index > 10 && (
                <Tr>
                  {row.cells.map((cell: string) => (
                    <Td>{CellContent(cell) || ''}</Td>
                  ))}
                </Tr>
              )}
            </>
          ))}
        </StyledTable>
      </ShowMoreWrapper>
      {shouldRowsCollapse && table.rows.length > 10 && (
        <StyledButtonLink text="Se alle testpunker +" onClick={() => setShouldRowsCollapse(false)} />
      )}
    </TableWrapper>
  );
};

const TableBlock = ({
  value,
  value: { title, table, orientation, shouldCollapse, caption, displayStyle },
}: TableSerializerProps) => {
  if (displayStyle === 'nafCenter') {
    return <NafCenterTableView value={value} />;
  }
  return (
    <>
      <Text variant={TextVariant.Header2}>{title}</Text>
      <StyledFigure>
        <ExpandedTable shouldCollapse={shouldCollapse} variant="vertical">
          <TableBody>
            {table &&
              table.rows &&
              table.rows.map((row, rowIndex) => (
                <TableRow key={row._key}>
                  {row.cells.map((cell, index) => {
                    let role: 'td' | 'th' = 'td';
                    let align: 'left' | 'right' | 'center' = 'left';
                    if (orientation === 'top') {
                      if (rowIndex === 0) {
                        role = 'th';
                      }
                      if (index > 0) {
                        align = 'right';
                      }
                    }

                    if (orientation === 'left') {
                      if (index === 0) {
                        role = 'th';
                      }
                      if (index > 0) {
                        align = 'right';
                      }
                    }
                    if (orientation === 'crossTable') {
                      if (index === 0 || rowIndex === 0) {
                        role = 'th';
                      }
                      if (index > 0) {
                        align = 'right';
                      }
                    }
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableCell key={`${row._key}-${index}`} align={align} as={role}>
                        {cell}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </ExpandedTable>
        {orientation === 'left' &&
          table &&
          table.rows &&
          table.rows.map((row) => (
            <CollapsedTable
              key={`left-table-collapsed-key-${row._key}`}
              shouldCollapse={shouldCollapse}
              label={row.cells[0]}
            >
              {row.cells.slice(1).map((cell, cellIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`left-table-collapsed-key-${row._key}-${cellIndex}`}>{cell}</div>
              ))}
            </CollapsedTable>
          ))}
        {orientation === 'crossTable' &&
          table &&
          table.rows &&
          table.rows.slice(1).map((row) => (
            <CollapsedTable
              key={`cross-table-collapsed-${row._key}`}
              shouldCollapse={shouldCollapse}
              label={row.cells[0]}
            >
              {row.cells.slice(1).map((cell, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Table key={`cross-table-collapsed-${row._key}-${index}`}>
                  <TableRow>
                    <TableCell as="th" align="left">
                      {table.rows[0].cells.slice(1)[index]}
                    </TableCell>
                    <TableCell align="right">{cell}</TableCell>
                  </TableRow>
                </Table>
              ))}
            </CollapsedTable>
          ))}
        {orientation === 'top' &&
          table &&
          table.rows &&
          table.rows[0].cells.map((columnHeader, columnIndex) => (
            <CollapsedTable
              key={`top-table-collapsed-${columnHeader}`}
              shouldCollapse={shouldCollapse}
              label={columnHeader}
            >
              {table.rows.slice(1).map((row) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`top-table-collapsed-${columnIndex}-${row._key}`}>{row.cells[columnIndex]}</div>
              ))}
            </CollapsedTable>
          ))}
        {caption && <Caption>{caption}</Caption>}
      </StyledFigure>
    </>
  );
};

export const TableSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: TableSerializerProps) => (
      <ArticleBlockContentGridCol>
        <TableBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: TableSerializerProps) => <TableBlock value={value} />;
};

const StyledFigure = styled.figure`
  margin: 0;
  padding: 0;
`;

const Caption = styled.figcaption`
  font-size: 0.875rem;
  font-style: italic;
  max-width: 100%;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const ExpandedTable = styled(Table)<{ shouldCollapse: boolean }>`
  display: block;
  ${(props) =>
    props.shouldCollapse &&
    css`
      @media (max-width: 768px) {
        display: none;
      }
    `}
`;

const CollapsedTable = styled(Accordion)<{ shouldCollapse: boolean }>`
  display: none;
  ${(props) =>
    props.shouldCollapse &&
    css`
      @media (max-width: 768px) {
        display: block;
      }
    `}
`;

const TableWrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => (props.width ? `calc(${props.width}px - 96px)` : '100vw')};
  margin-bottom: ${spacing.space64};

  h2 ~ div > div {
    overflow: hidden;
  }

  @media (max-width: ${breakpoints.m}) {
    width: ${(props) => (props.width ? `calc(${props.width}px - 48px)` : '100vw')};
  }
  @media (max-width: ${breakpoints.s}) {
    width: ${(props) => (props.width ? `calc(${props.width}px)` : '100vw')};
    margin-bottom: ${spacing.space40};
  }
`;

const StyledTable = styled.table`
  width: 100%;
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 340px;
`;

const ShowMoreWrapper = styled.div<{ $isHidden?: boolean }>`
  max-height: 10000px;
  transition: max-height 1s ease-in;

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      max-height: 0;
      overflow: hidden;
    `}
`;

const Tr = styled.tr<{ index?: number }>`
  display: table-row;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => (props.index === 0 ? nafColor.neutral.neutral3 : 'rgba(0, 0, 0, 0.12)')};

  th:nth-child(2),
  td:nth-child(2) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    @media (max-width: ${breakpoints.s}) {
      border-right: 0;
    }
  }
`;

const StyledTableCell = css`
  display: table-cell;
  padding: ${spacing.space16} 0 ${spacing.space16} ${spacing.space80};
  text-align: left;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.m}) {
    padding-left: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space12} 0;
  }

  &:first-child {
    padding-left: 0;
  }
`;

const Th = styled.th`
  ${StyledTableCell};
`;

const Td = styled.td`
  ${StyledTableCell};
`;

const StyledCheckCircle = styled(CheckCircle)`
  color: ${nafColor.primary.park};
`;

const StyledButtonLink = styled(ButtonLink)`
  margin-top: ${spacing.space32};
`;
