import { Action, ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { produce } from 'immer';
import { FormType, FormValidationResult } from '../../../../types/formType';
import { TypedEpic } from '../types';

interface UserFeedback {
  contentUrl: string;
  contentUseful: boolean;
  contentComment: string;
  contentValue: number;
}

interface MonthlyOrYearlyFeedback {
  monthlyOrYearly: string;
}

// Actions
export enum Actions {
  SET_FEEDBACK = 'SET_FEEDBACK',
  TOGGLE_MENU = 'TOGGLE_MENU',
  CLOSE_MENU = 'CLOSE_MENU',
  OPEN_MENU = 'OPEN_MENU',
  SHOW_SYSTEM_MESSAGE = 'SHOW_SYSTEM_MESSAGE',
  SHOW_OLD_NAV = 'SHOW_OLD_NAV',
  VALIDATE_FORM = 'VALIDATE_FORM',
  VALIDATE_FORM_SUCCESS = 'VALIDATE_FORM_SUCCESS',
  VALIDATE_FORM_FAIL = 'VALIDATE_FORM_FAIL',
  VALIDATE_FORM_CANCEL = 'VALIDATE_FORM_CANCEL',
  POST_FORM = 'POST_FORM',
  POST_FORM_SUCCESS = 'POST_FORM_SUCCESS',
  POST_FORM_FAIL = 'POST_FORM_FAIL',
  POST_FORM_CANCEL = 'POST_FORM_CANCEL',
  TOGGLE_SEARCH_OVERLAY = 'TOGGLE_SEARCH_OVERLAY',
  TOGGLE_SEARCH_ACTIVE = 'TOGGLE_SEARCH_ACTIVE',
}

export interface State {
  type: string;
  feedbacks: UserFeedback[];
  preferredPaymentMethodFeedback: MonthlyOrYearlyFeedback;
  isMenuOpen: boolean;
  showSystemMessage: boolean;
  isSearchOverlayOpen: boolean;
  showOldNav: boolean;
  isValidatingForm: boolean;
  isPostingForm: boolean;
  isSearchActive: boolean;
}

export const initialState: State = {
  type: 'guest',
  feedbacks: [],
  preferredPaymentMethodFeedback: { monthlyOrYearly: '' },
  isMenuOpen: false,
  showSystemMessage: false,
  isSearchOverlayOpen: false,
  showOldNav: true,
  isValidatingForm: false,
  isPostingForm: false,
  isSearchActive: false,
};

export const actions = {
  validateForm: createAsyncAction(
    [Actions.VALIDATE_FORM, (formPayload: FormType) => formPayload], // request payload creator
    [Actions.VALIDATE_FORM_SUCCESS, (res: FormValidationResult) => res], // success payload creator
    [Actions.VALIDATE_FORM_FAIL, (err: Error) => err], // failure payload creator
    Actions.VALIDATE_FORM_CANCEL, // optional cancel payload creator
  )(),
  postForm: createAsyncAction(
    [Actions.POST_FORM, (formPayload: FormType) => formPayload], // request payload creator
    Actions.POST_FORM_SUCCESS, // success payload creator
    [Actions.POST_FORM_FAIL, (err: Error) => err], // failure payload creator
    Actions.POST_FORM_CANCEL, // optional cancel payload creator
  )(),
  setUserFeedback: createAction(Actions.SET_FEEDBACK, (feedback: UserFeedback) => feedback)(),
  setPreferredPaymentMethodFeedback: createAction(
    Actions.SET_FEEDBACK,
    (feedback: MonthlyOrYearlyFeedback) => feedback,
  )(),
  openMenu: createAction(Actions.OPEN_MENU)(),
  closeMenu: createAction(Actions.CLOSE_MENU)(),
  toggleMenu: createAction(Actions.TOGGLE_MENU)(),
  showSystemMessage: createAction(Actions.SHOW_SYSTEM_MESSAGE, (showSystemMessage: boolean) => showSystemMessage)(),
  showOldNav: createAction(Actions.SHOW_OLD_NAV)(),
  toggleSearchOverlay: createAction(Actions.TOGGLE_SEARCH_OVERLAY, (openOverlay?: boolean) => openOverlay)(),
  toggleSearchActive: createAction(Actions.TOGGLE_SEARCH_ACTIVE, (searchActive?: boolean) => searchActive)(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.setUserFeedback, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.feedbacks = [...draftState.feedbacks, action.payload];
    }),
  )
  .handleAction(actions.setPreferredPaymentMethodFeedback, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.preferredPaymentMethodFeedback = action.payload;
    }),
  )
  .handleAction(actions.toggleMenu, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isMenuOpen = !state.isMenuOpen;
      if (state.isMenuOpen) {
        draftState.isSearchActive = false;
        draftState.isSearchOverlayOpen = false;
      }
    }),
  )
  .handleAction(actions.openMenu, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isMenuOpen = true;
    }),
  )
  .handleAction(actions.closeMenu, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isMenuOpen = false;
      draftState.isSearchActive = false;
      draftState.isSearchOverlayOpen = false;
    }),
  )
  .handleAction(actions.showSystemMessage, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.showSystemMessage = action.payload;
    }),
  )
  .handleAction(actions.showOldNav, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.showOldNav = true;
    }),
  )
  .handleAction(actions.validateForm.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isValidatingForm = false;
    }),
  )
  .handleAction(actions.validateForm.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isValidatingForm = true;
    }),
  )
  .handleAction(actions.validateForm.failure, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isValidatingForm = false;
    }),
  )
  .handleAction(actions.postForm.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isPostingForm = false;
    }),
  )
  .handleAction(actions.postForm.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isPostingForm = true;
    }),
  )
  .handleAction(actions.postForm.failure, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isPostingForm = false;
    }),
  )
  .handleAction(actions.postForm.success, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isPostingForm = false;
    }),
  )
  .handleAction(actions.toggleSearchOverlay, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isSearchOverlayOpen = action.payload;
    }),
  )
  .handleAction(actions.toggleSearchActive, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isSearchActive = action.payload;
    }),
  );

const validateFormEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimFormApi, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.VALIDATE_FORM),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.validateForm.request>[]
    >,
    mergeMap(([action]: { payload: FormType }[]) =>
      ajax<{ result: { isValid: boolean } }>({
        url: `${apimBaseUrl}/${apimFormApi}/forms/validate`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
        method: 'POST',
        body: JSON.stringify(action.payload),
      })
        .pipe(
          filter(
            ({
              response: {
                result: { isValid },
              },
            }) => isValid,
          ),
        )
        .pipe(
          map(
            () => actions.postForm.request(action.payload),
            catchError((err) => {
              actions.validateForm.failure(err);
              return of(err);
            }),
          ),
        ),
    ),
  );
};

const postFormEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimFormApi, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.POST_FORM),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.postForm.request>[]>,
    mergeMap(([action]: { payload: FormType }[]) =>
      ajax({
        url: `${apimBaseUrl}/${apimFormApi}/forms/submit`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          () => actions.postForm.success(),
          catchError((err) => {
            actions.postForm.failure(err);
            return of(err);
          }),
        ),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [validateFormEpic, postFormEpic];
