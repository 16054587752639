import React, { useState } from 'react';
import { GridCol } from '@naf/grid';
import { FeatureHeader } from '@naf/section-header';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { DynamicVideoPlayer } from '../video-player/DynamicVideoPlayer';
import ResponsiveImage from '../image/ResponsiveImage';
import { FeatureArticleHeaderProps } from './Types';
import * as S from './Styles';
import utils from '../../utils';
import BreadCrumb from '../breadcrumb/BreadCrumb';
import routeConfig from '../../config/routeConfig';
import { YoutubeEmbed } from '../YoutubeEmbed';

export const FeatureArticleHeader = ({
  name,
  image,
  ingress,
  runningHead,
  video,
  youtubeVideo,
  overlay = true,
  videoPlayerOptions,
}: FeatureArticleHeaderProps) => {
  const [hasOverlay, setHasOverlay] = useState(false);

  const handleVideoReady = () => {
    setHasOverlay(true);
  };

  const caption = utils.getPhotographerAndCopyrightCaption({
    photographer: image?.photographer,
    copyright: image?.copyright,
  });

  const generatedCrumbs = useBreadcrumbs(routeConfig);

  let mediaElement = <ResponsiveImage imageId={image?.publicId} altText={image?.altText || image?.caption} />;

  if (video) {
    mediaElement = (
      <DynamicVideoPlayer
        video={video}
        videoPlayerOptions={videoPlayerOptions}
        aria-labelledby={video.publicId}
        image={image || undefined}
        onVideoPlay={handleVideoReady}
      />
    );
  }

  if (youtubeVideo) {
    mediaElement = <YoutubeEmbed embed={youtubeVideo} isFeatureHeader />;
  }

  return (
    <S.FeatureHeaderWrapper>
      {generatedCrumbs && generatedCrumbs.length > 1 ? (
        <S.BreadcrumbWrapper>
          <GridCol>
            <BreadCrumb />
          </GridCol>
        </S.BreadcrumbWrapper>
      ) : null}
      <FeatureHeader
        title={name}
        subText={ingress}
        overlay={video ? hasOverlay : overlay}
        runningHead={runningHead}
        caption={caption}
        imageOrVideo={mediaElement}
        clickThroughHeader={!!youtubeVideo}
      />
    </S.FeatureHeaderWrapper>
  );
};
