import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Launch } from '@styled-icons/material/Launch';
import { MotorTestProps } from '../../components/bilguide/carTest/CarTestCard';
import MotorIcon from '../../components/icons/Motor';

type Props = {
  motorTest?: MotorTestProps;
};

export const MotorTest: React.FC<Props> = ({ motorTest }) => {
  if (!motorTest) return null;

  const { score, url } = motorTest;
  return (
    <Container id="motorTest">
      <Score>
        <MotorIcon size={28} />
        <em>
          <Text variant={TextVariant.Header3} tag="p">
            {score}
          </Text>
        </em>
        /100 av Motor
      </Score>

      <A target="_blank" href={url} rel="noreferrer">
        Les testen
        <Launch style={{ marginLeft: 4 }} height={16} width={16} />
      </A>
    </Container>
  );
};

const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.border.subtle};
  border-radius: 2px;
  display: flex;
  padding: 0 ${spacing.space24};
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing.space48};
  scroll-margin-top: ${spacing.space120};

  @media (max-width: ${breakpoints.l}) {
    padding: 0 ${spacing.space16};
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space40};
  }
`;

const Score = styled.div`
  display: flex;
  align-items: center;
  > em {
    margin-left: ${spacing.space8};
  }
`;

const A = styled.a`
  text-decoration: underline;
`;
