import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const Wrapper = styled.div`
  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space24};

    div:first-child {
      width: 100%;
    }
  }
`;
