import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Alternative } from './Alternative';
import { AlternativeType, QuestionStatus } from './types';

interface Props {
  alternatives: AlternativeType[];
  selectedValue: string | null;
  setSelectedValue: Dispatch<SetStateAction<string | null>>;
  setQuestionStatus: (status: QuestionStatus) => void;
  status: QuestionStatus;
}

export const Alternatives = ({ alternatives, status, selectedValue, setSelectedValue, setQuestionStatus }: Props) => (
  <StyledForm>
    {alternatives.map(
      (alternative) =>
        alternative && (
          <Alternative
            status={status}
            key={alternative._key}
            _key={alternative._key}
            isSelected={selectedValue === alternative._key}
            alternative={alternative}
            text={alternative.alternativeText}
            setSelectedValue={setSelectedValue}
            setQuestionStatus={setQuestionStatus}
            isDisabled={typeof selectedValue === 'string'}
          />
        ),
    )}
  </StyledForm>
);

const StyledForm = styled.form`
  margin-bottom: 32px;
`;
