import { nafColor } from '@nafcore/theme';

export const ChatStyles = `
  [class*="-user"] {
    [class*="messageContent-"] {
      background-color: ${nafColor.secondary.glacier}!important;
    }
  }
  [data-puzzel-chat] {
    z-index: 95;
    h1 {
      font-family: questa-sans;
    }
    .circle-0-0-30 {
      background-color: ${nafColor.primary.park};
    }
  }
  [class*="message-"] {
    font-size: 0.75rem!important;
  }
  [class*="-meta-text"] {
    font-size: 0.75rem!important;
    margin-top: -1rem!important;
  }
  [class*="-meta-time"] {
    font-size: 0.75rem!important;
    span {
      font-size: 0.75rem!important;
      vertical-align: sub;
    }
  }
  .mobile {
    input[type='text'],
    input[type='number'],
    input[type='email'],
    textarea {
      font-size: 1rem;
    }
  }
`;
