import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import { Label } from '@naf/label';
import { Link } from 'react-router-dom';

export const StyledText = styled(Text)`
  margin: 0;
`;

export const StyledLabel = styled(Label)`
  position: absolute;
  top: -${spacing.space24};
  left: 0;
  white-space: nowrap;
`;

export const StyledImg = styled.img<{ $size: 'small' | 'large' }>`
  margin-right: ${spacing.space16};
  width: ${({ $size }) => ($size === 'small' ? '90px' : '160px')};
  height: ${({ $size }) => ($size === 'small' ? '70px' : '120px')};

  @media (max-width: ${breakpoints.m}) {
    width: ${({ $size }) => ($size === 'small' ? '218px' : '160px')};
    height: ${({ $size }) => ($size === 'small' ? '116px' : '120px')};
  }
`;

export const ImgAndLabel = styled.div`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled(Link)<{ $isActive: boolean; ref: any }>`
  display: flex;
  margin: ${spacing.space16} 0;
  padding: ${spacing.space16};
  min-width: 340px;
  ${({ $isActive }) => $isActive && `background-color: ${themeLight.background.brand}`};
  text-decoration: none;
  scroll-snap-align: start;
  scroll-snap-type: none;
  scroll-margin: 0 30px;

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    min-width: 240px;
    scroll-margin: 0 16px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Ingress = styled(Text)`
  margin-top: ${spacing.space8};

  @media (max-width: ${breakpoints.s}) {
    display: none;
  }
`;
