export interface LoanOffer {
  loanAmount: number;
  downPayment: number;
  repaymentYears: number;
  monthlyPayments: number;
  paymentInsurancePrice: number;
  estimatedPaymentInsurancePrice: number;
  effectiveInterestRate: number;
  nominalInterestRate: number;
  totalMonthlyPayments: number;
}

export interface LoanRequest {
  price: string;
  downPayment: string;
  repaymentYears: string;
}

export enum LoanType {
  CAR = 'CAR',
  ELECTRIC_CAR = 'ELECTRIC_CAR',
  MC = 'MC',
  CARAVAN = 'CARAVAN',
}

export enum NordeaLoanType {
  CAR = 'CAR_NAF',
  ELECTRIC_CAR = 'ENVFRIENDLYCAR',
  MC = 'BIKE_NAF',
  CARAVAN = 'CARAVAN_NAF',
}

export enum NordeaLoanTypeRedirect {
  CAR = 'car',
  ELECTRIC_CAR = 'miljobil',
  MC = 'mc',
  CARAVAN = 'caravan',
}

export enum LoanCalculatorHeadings {
  CAR = 'Billånskalkulator',
  ELECTRIC_CAR = 'Grønt billånskalkulator',
  MC = 'MC-lånskalkulator',
  CARAVAN = 'Caravanlånskalkulator',
}

export type FormSchema = Partial<LoanRequest> & { errors?: Record<string, any>; mode: LoanType };
