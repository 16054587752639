import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import loadable from '@loadable/component';
import { PointOfInterest } from '../../../../SectionBlocks/blocks/Charts/RouteCharts';
import { ElevationChart } from '../../../../SectionBlocks/blocks/Charts/ElevationChart';
import { useElPrixRouteData } from '../../../../../hooks/useElPrixRouteData';

const GoogleMapsWrapper = loadable(() => import('../../../../SectionBlocks/blocks/Charts/GoogleMapsWrapper'), {
  resolveComponent: (components) => components.GoogleMapsWrapper,
});

type Props = {
  value: {
    _key: string;
    _type: 'altitudeChart';
  } & (CustomRouteAltitudeData | TestRouteAltitudeData);
};

type TestRouteAltitudeData = {
  useRouteFromTest: true;
  elPrixTestReference: { routepoints: PointOfInterest[]; altitudeProfile: number[] };
};
type CustomRouteAltitudeData = { useRouteFromTest: false; routepoints: PointOfInterest[]; altitudeProfile: number[] };

const AltitudeChartSerializer =
  () =>
  ({ value }: Props) =>
    (
      <GoogleMapsWrapper>
        <RenderAltitudeChart value={value} />
      </GoogleMapsWrapper>
    );

const RenderAltitudeChart = ({ value }: Props) => {
  const { routepoints, altitudeProfile } = value.useRouteFromTest === true ? value.elPrixTestReference : value;
  const { pointsOfInterest } = useElPrixRouteData({ routepoints, elevation: altitudeProfile });

  return (
    <ChartContainer>
      <ElevationChart altitudes={altitudeProfile} pointsOfInterest={pointsOfInterest} />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export { AltitudeChartSerializer };
