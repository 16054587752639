import React, { ReactNode } from 'react';

interface Props {
  value?: {
    eMailAddress: string;
  };
  children: ReactNode;
}

export const MailToLinkSerializer = ({ value: { eMailAddress }, children }: Props) => (
  <a href={`mailto:${eMailAddress}`}>{children}</a>
);
