import React from 'react';
import styled from 'styled-components';
import { NafThemeType, breakpoints, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { ProsAndCons as ProsAndConsType } from '../../../../types/carModelType';

type ProOrCon = 'pro' | 'con';
export const ProsAndCons: React.FC<ProsAndConsType> = ({ pros, cons }) => {
  if (!(pros?.length > 0 && cons?.length > 0)) return null;

  return (
    <>
      <Container>
        {['pro', 'con'].map((proOrCon: ProOrCon) => {
          const title = proOrCon === 'pro' ? 'Fordeler' : 'Ulemper';
          const data = proOrCon === 'pro' ? pros : cons;
          return (
            <ProConContainer key={proOrCon}>
              <Title variant={TextVariant.Header3}>{title}</Title>
              <Ul type={proOrCon}>
                {data.map((item) => (
                  <li key={item}>
                    <Text>{item}</Text>
                  </li>
                ))}
              </Ul>
            </ProConContainer>
          );
        })}
      </Container>
      <Disclaimer variant={TextVariant.Small}>
        NB: Dette er basert på våre rådgivere sine faglige og personlige preferanser.
      </Disclaimer>
    </>
  );
};

const Container = styled.div`
  border-top: 1px solid ${({ theme }: { theme: NafThemeType }) => theme.border.subtle};
  border-bottom: 1px solid ${({ theme }: { theme: NafThemeType }) => theme.border.subtle};

  display: flex;
  width: 100%;

  > div {
    flex-grow: 1;
  }

  > div:first-child {
    border-right: 1px solid ${({ theme }: { theme: NafThemeType }) => theme.border.subtle};
  }

  > div:last-child {
    margin-left: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;

    > div:first-child {
      border-right: 0;
      border-bottom: 1px solid ${({ theme }: { theme: NafThemeType }) => theme.border.subtle};
    }

    > div:last-child {
      margin-left: 0;
    }
  }
`;

const Title = styled(Text)`
  margin: ${spacing.space24} 0 ${spacing.space8} 0;
  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space16} 0 ${spacing.space8} 0;
  }
`;

const Ul = styled.ul<{ type: ProOrCon }>`
  margin: 0;
  margin-bottom: ${spacing.space24};
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: ${spacing.space16};

  > li::marker {
    color: ${({ type, theme }: { type: ProOrCon; theme: NafThemeType }) =>
      type === 'pro' ? theme.componentColors.alert.success : theme.componentColors.alert.error};
  }
  > li > p {
    margin: 0;
    margin-bottom: ${spacing.space8};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space16};
  }
`;

const ProConContainer = styled.div`
  flex-shrink: 0;
  flex-basis: 50%;
`;

const Disclaimer = styled(Text)`
  font-style: italic;
`;
