import React, { useEffect, useState } from 'react';
import { breakpoints } from '@naf/theme';
import styled from 'styled-components';
import { ArrowUpward } from '@styled-icons/material/ArrowUpward';
import { animated, config, useSpring } from '@react-spring/web';

type Props = {
  show: boolean;
  onClick: () => void;
};

export const BackToTopButton: React.FC<Props> = ({ show, onClick }) => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [userIsScrollingUp, setUserIsScrollingUp] = useState<boolean>(false);

  const [props] = useSpring(
    () => ({
      config: config.gentle,
      from: { opacity: 0, y: 20 },
      to: {
        opacity: userIsScrollingUp ? 1 : 0,
        y: userIsScrollingUp ? 0 : 20,
      },
    }),
    [userIsScrollingUp],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const listen = () => {
        if (window.scrollY > lastScrollY) {
          setUserIsScrollingUp(false);
        } else if (window.scrollY < 250) {
          setUserIsScrollingUp(false);
        } else {
          setUserIsScrollingUp(true);
        }
        setLastScrollY(window.scrollY);
      };
      window.addEventListener('scroll', listen);
      return () => window.removeEventListener('scroll', listen);
    }
    return undefined;
  }, [lastScrollY]);

  return (
    show && (
      <Button style={props} onClick={onClick}>
        <ArrowUpward height={24} width={24} />
      </Button>
    )
  );
};

const Button = styled(animated.button)`
  display: none;
  position: fixed;
  background: ${({ theme }) => theme.background.brand};
  bottom: 20px;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 0;

  @media (max-width: ${breakpoints.l}) {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      min-width: 24px;
      min-height: 24px;
      fill: ${({ theme }) => theme.typography.brandText};
    }
  }
`;
