import React from 'react';
import { Grid } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { ThreeColumnBlockType } from '../../../../../types/ThreeColumnBlockType';
import { MainEntryType } from '../../../../../types/siteStructureType';
import { MainEntryCard } from '../../entry-cards/MainEntryCard';

export const ThreeColumnBlock = ({ block }: { block: ThreeColumnBlockType }) => {
  const { title, mainEntries } = block;
  return (
    <div className="threeColumnBlock">
      {title && <Text variant={TextVariant.Header2}>{title}</Text>}
      {mainEntries && <MainEntries mainEntries={mainEntries} />}
    </div>
  );
};

export const MainEntries = ({ mainEntries }: { mainEntries: MainEntryType[] }) => {
  const mainEntriesCol = mainEntries && mainEntries.length < 4 ? '4' : '3';
  return (
    <Grid>
      {mainEntries.map((entry) => (
        <MainEntryCard key={`${entry.title}-${entry.internalLink?.slug}`} entry={entry} col={mainEntriesCol} />
      ))}
    </Grid>
  );
};
