/* eslint-disable camelcase */
import { useState, useEffect, useMemo, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import useSelector from '../redux/typedHooks';
import { NpsData } from '../../../types/npsTypes';

interface SurveyProps {
  surveyId: string;
  token?: string;
  isDismissed?: boolean;
}

interface SurveyResponse {
  survey: {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    expiresAt: string;
    frequencyLimit: number;
  };
  eligibility: {
    elegible: boolean;
    reason: string;
  };
}

interface SurveyReturn {
  data: SurveyResponse | undefined;
  isLoading: boolean;
  error: boolean;
}

export const useNpsSurvey = ({ surveyId, token, isDismissed }: SurveyProps): SurveyReturn => {
  const [data, setData] = useState<SurveyResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = useSelector((state) => state.application);

  const fetchSurvey = useMemo(() => {
    if (apimBaseUrl && apimContentHub && apimMembershipApi && surveyId && token) {
      return async () => {
        try {
          const res = await axios.get<SurveyResponse>(`${apimBaseUrl}/${apimMembershipApi}/surveys/${surveyId}`, {
            headers: {
              'Ocp-Apim-Subscription-Key': apimContentHub,
              Authorization: `Bearer ${token}`,
            },
          });
          setIsLoading(false);
          setError(false);
          setData(res.data);
        } catch (e) {
          setIsLoading(false);
          setError(true);
        }
      };
    }
    return null;
  }, [apimBaseUrl, apimContentHub, apimMembershipApi, surveyId, token]);

  useEffect(() => {
    if (fetchSurvey && !isDismissed) {
      fetchSurvey().then();
      setIsLoading(true);
    } else if (isDismissed) {
      setIsLoading(false);
      setError(false);
      setData(undefined);
    }
  }, [fetchSurvey, isDismissed]);

  return { data, isLoading, error };
};

interface RespondProps {
  token?: string;
}

interface RespondReturn {
  postResponse: ({ score, action, utm_source, utm_medium, utm_campaign, utm_content, surveyId }: NpsData) => void;
  postResponseWithCustomerId: ({
    score,
    action,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    surveyId,
    customerId,
  }: NpsData & Required<Pick<NpsData, 'customerId'>>) => Promise<{ id: string } | null>;
  patchResponse: ({ responseId, action }: { responseId: string; action?: string }) => void;
  isSubmitted: boolean;
  isLoading: boolean;
  isPatched: boolean;
  error: null | AxiosError;
}

export const useNpsResponse = ({ token }: RespondProps): RespondReturn => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | AxiosError>(null);
  const [isPatched, setIsPatched] = useState(false);
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = useSelector((state) => state.application);

  const postResponse = useCallback(
    async ({ score, action, utm_source, utm_medium, utm_campaign, utm_content, surveyId }: NpsData) => {
      setIsLoading(true);
      const data = {
        score,
        action,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
      };
      try {
        let headers: Record<string, string> = {
          'Ocp-Apim-Subscription-Key': apimContentHub,
        };
        if (token) {
          headers = { ...headers, Authorization: `Bearer ${token}` };
        }
        const response = await axios.post<{ id: string }>(
          `${apimBaseUrl}/${apimMembershipApi}/surveys/${surveyId}`,
          data,
          {
            headers,
          },
        );
        setIsSubmitted(true);
        setError(null);
        setIsLoading(false);
        return response.data;
      } catch (e) {
        setIsSubmitted(false);
        setError(e as AxiosError);
        setIsLoading(false);
        return null;
      }
    },
    [apimBaseUrl, apimContentHub, apimMembershipApi, token],
  );

  const postResponseWithCustomerId = useCallback(
    async ({
      score,
      action,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      surveyId,
      customerId,
    }: NpsData & Required<Pick<NpsData, 'customerId'>>) => {
      setIsLoading(true);
      const data = {
        score,
        action,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
      };
      try {
        const response = await axios.post<{ id: string }>(
          `${apimBaseUrl}/${apimMembershipApi}/surveys/${surveyId}?customerId=${customerId}`,
          data,
          {
            headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
          },
        );
        setIsSubmitted(true);
        setError(null);
        setIsLoading(false);
        return response.data;
      } catch (e) {
        setIsSubmitted(false);
        setError(e as AxiosError);
        setIsLoading(false);
        return null;
      }
    },
    [apimBaseUrl, apimContentHub, apimMembershipApi],
  );

  const patchResponse = useCallback(
    async ({ action, responseId }: { responseId: string; action?: string }) => {
      setIsLoading(true);
      const data = {
        action,
      };
      try {
        let headers: Record<string, string> = {
          'Ocp-Apim-Subscription-Key': apimContentHub,
        };
        if (token) {
          headers = { ...headers, Authorization: `Bearer ${token}` };
        }
        await axios.patch<{ id: string }>(`${apimBaseUrl}/${apimMembershipApi}/surveys/${responseId}`, data, {
          headers,
        });
        setIsPatched(true);
        setError(null);
        setIsLoading(false);
      } catch (e) {
        setIsPatched(false);
        setError(e as AxiosError);
        setIsLoading(false);
      }
    },
    [apimBaseUrl, apimContentHub, apimMembershipApi, token],
  );

  return {
    postResponse,
    postResponseWithCustomerId,
    patchResponse,
    isSubmitted,
    isLoading,
    error,
    isPatched,
  };
};
