import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { DocumentMetaDisplayType, DocumentMetaType } from '../../../../types/articleType';
import { createNorwegianDateString } from '../../utils/createNorwegianDateString';
import * as S from './Styles';
import { DocumentTypeLabel } from '../../../../types/testType';

interface MetaDataProps {
  meta?: DocumentMetaType;
  metaDisplay?: DocumentMetaDisplayType;
  documentType?: DocumentTypeLabel;
  displayAsText?: boolean;
}

export const MetaData = ({ meta, metaDisplay, documentType, displayAsText = false }: MetaDataProps) => {
  const shouldDisplayPublicationDate = meta && meta?.publishAt && !metaDisplay?.hidePublicationDate;
  const shouldDisplayUpdateDate = meta && meta?.updatedAt && !metaDisplay?.hideUpdateDate;
  const shouldDisplayEditor =
    meta && meta?.editor && !metaDisplay?.hideEditor && documentType !== DocumentTypeLabel.nafnoNewsArticle;

  const publicationDate = createNorwegianDateString(new Date(meta?.publishAt));
  const updateDate = createNorwegianDateString(new Date(meta?.updatedAt));

  return (
    <S.MetaDataWrapper>
      {shouldDisplayPublicationDate &&
        (displayAsText ? (
          <Text variant={TextVariant.Small}>{`Publisert: ${publicationDate}`}</Text>
        ) : (
          <div>{publicationDate}</div>
        ))}
      {shouldDisplayUpdateDate && (
        <Text variant={TextVariant.Small}>
          <b>{`Sist oppdatert: ${updateDate}`}</b>
        </Text>
      )}
      {shouldDisplayEditor && (
        <div>
          {meta.editor.name}, <S.BylineRole>{meta.editor.position}</S.BylineRole>
        </div>
      )}
    </S.MetaDataWrapper>
  );
};
