import { Routepoint, TravelMode } from '../../../../types/TravelSuggestionType';
import PoiIcon from '../../../assets/images/POI.svg?url';
import PoiAIcon from '../../../assets/images/POI-A.svg?url';
import PoiBIcon from '../../../assets/images/POI-B.svg?url';

export const mapTravelModeToGoogleTravelMode = ({
  isMapLoaded,
  travelMode,
}: {
  isMapLoaded: boolean;
  travelMode: string;
}) => {
  if (!isMapLoaded) {
    // eslint-disable-next-line no-console
    console.warn('Google Maps is not loaded!');
    return 'DRIVING';
  }
  switch (travelMode) {
    case TravelMode.CAR:
    case TravelMode.MC:
      return google.maps.TravelMode.DRIVING;
    case TravelMode.BIKE:
      return google.maps.TravelMode.BICYCLING;
    case TravelMode.WALK:
      return google.maps.TravelMode.WALKING;
    default:
      return google.maps.TravelMode.DRIVING;
  }
};
export const getGoogleMapsLink = ({
  isMapLoaded,
  routePoints,
  travelMode,
}: {
  isMapLoaded: boolean;
  routePoints: Routepoint[];
  travelMode: TravelMode;
}): string => {
  const baseUrl = 'https://www.google.com/maps/dir/?api=1';
  if (!isMapLoaded) {
    // eslint-disable-next-line no-console
    console.warn('Google Maps is not loaded!');
    return baseUrl;
  }
  if (routePoints.length < 2) {
    // eslint-disable-next-line no-console
    console.warn('Minimum 2 routePoints are required');
    return baseUrl;
  }

  const origin = routePoints[0];
  const originParam = `&origin=${origin.latitude},${origin.longitude}`;
  const destination = routePoints[routePoints.length - 1];
  const destinationParam = `&destination=${destination.latitude},${destination.longitude}`;

  const waypointsParam = routePoints
    .slice(1, -1)
    .reduce(
      (waypointsString, routepoint, currentIndex, waypointsArr) =>
        `${waypointsString}${routepoint.latitude},${routepoint.longitude}${
          currentIndex !== waypointsArr.length - 1 ? '%7C' : ''
        }`,
      '&waypoints=',
    );

  const travelModeParam = `&travelmode=${mapTravelModeToGoogleTravelMode({ travelMode, isMapLoaded: true })}`;

  return `${baseUrl}${originParam}${destinationParam}${waypointsParam}${travelModeParam}`;
};

export const getPOIIcon = (markerIndex: number, totalMarkersNumber: number) => {
  if (markerIndex === 0) {
    return PoiAIcon;
  }
  if (markerIndex === totalMarkersNumber) {
    return PoiBIcon;
  }

  return PoiIcon;
};
