import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Star } from '@styled-icons/material-outlined/Star';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { Chips } from '@naf/chips';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { useLocation } from 'react-router';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import useSelector from '../redux/typedHooks';
import { useWindowLocation } from '../hooks/useWindowLocation';
import BlockContent from '../components/block-content/BlockContent';
import { DocumentTypeLabel } from '../../../types/testType';
import RelatedArticleList from '../components/related-articles/RelatedArticleList';
import RelatedLinksList from '../components/related-links/RelatedLinksList';
import FeedbackSurvey from '../components/feedbacksurvey/FeedbackSurvey';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import { LayoutWithMainContent } from '../components/layout/Layout';
import SchemaArticle from '../components/structured-data/SchemaArticle';
import { LoaderContent } from './LoaderPage';
import { ArticleType } from '../../../types/articleType';
import { findTopCategorySlug } from '../utils/createCategoryUrl';
import TableOfContents from '../components/TableOfContents/TableOfContents';
import { ArticleHeader } from '../components/ArticleHeader/ArticleHeader';
import ArticleSeriesHeader from '../components/articleSeries/ArticleSeriesHeader';
import ArticleSeriesFooter from '../components/articleSeries/ArticleSeriesFooter';
import { cloudinaryImage } from '../utils/imageUrl';
import TravelMap from '../components/google-maps/TravelMap';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';
import { MetaData } from '../components/metaData/MetaData';
import S from '../styles/articles/StyledArticle';

interface Props {
  article: ArticleType;
}

export const path = ':mainCategorySlug/:subCategorySlug/:articleSlug';

const Article = ({ article }: Props) => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Artikkel');
  const windowPath = useWindowLocation();
  const location = useLocation();

  const {
    name,
    runningHead,
    ingress,
    body,
    id,
    image,
    relatedContent,
    video,
    meta,
    seoConfig,
    tags,
    articleScore,
    relatedLinks,
    parent,
    category,
    slug,
    metaDisplay,
    type,
    userAccessibility,
    generateTableOfContents,
    displayFeatureHeader,
    articleSeries,
    openGraphImage,
    routePoints,
    travelMode,
    youtubeVideo,
  } = article;

  const application = useSelector((state) => state.application);
  const ogImage = openGraphImage || image;
  const cldOpenGraphImage = ogImage && cloudinaryImage(ogImage.publicId, application);

  const { mappedCategories } = useSelector((state) => state.sitestructure);

  const mappedCategory = category?.slug ? mappedCategories[category?.slug]?.data : null;

  // If article has no category, check for parent article
  const mappedParentCategory =
    !mappedCategory && parent && parent.category?.slug ? mappedCategories[parent.category.slug].data : null;

  const isUpdatingArticle = useSelector((state) => state.documents.mappedData[slug]?.meta.isUpdating);
  const history = useHistory();

  const articleUrl = location && location.pathname ? location.pathname : `${slug}`;

  const seoDescription = seoConfig?.introduction ? seoConfig.introduction : ingress;
  const seoTitle = seoConfig?.title ? seoConfig.title : name;
  const pageCategory = mappedCategory
    ? `${mappedCategory?.prettyUrl}/${name}`
    : `${mappedParentCategory?.prettyUrl}/${parent?.slug}/${name}`;

  const articleRef = useRef<HTMLDivElement | null>(null);

  return (
    <LayoutWithMainContent
      title={name}
      description={ingress}
      url={windowPath}
      imgUrl={cldOpenGraphImage}
      gtmArgs={{
        page_type: 'ArticlePage',
        pageCategory,
        contentId: id,
        author: meta?.editor?.name,
        headline: name,
        publishDate: meta?.publishAt,
        daysSincePublish: differenceInBusinessDays(new Date(), new Date(meta?.publishAt)),
        isPaywall: userAccessibility.viewType !== 'Free',
        viewType: userAccessibility.viewType,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoDescription={seoDescription}
      seoTitle={seoTitle}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Article,
        imagePublicId: image?.publicId,
        author: meta?.editor?.name,
        tags,
      }}
      backToTopButton
    >
      <Wrapper>
        <SchemaArticle
          title={name}
          description={ingress}
          imgUrl={cldOpenGraphImage}
          datePublished={meta?.publishAt}
          dateModified={meta?.updatedAt}
          url={articleUrl}
          id={slug}
          isAccessibleForFree={userAccessibility.viewType === 'Free'}
          author={meta?.editor?.name}
        />
        {!mappedCategory?.isHiddenCategory && !displayFeatureHeader && (
          <BreadCrumbsGrid>
            <BreadCrumbGridCol>
              <BreadCrumb />
            </BreadCrumbGridCol>
          </BreadCrumbsGrid>
        )}
        {type === DocumentTypeLabel.nafnoNewsArticle ? (
          <ArticleHeader
            name={name}
            image={image || undefined}
            ingress={ingress}
            runningHead={runningHead}
            displayFeatureHeader={displayFeatureHeader || false}
            video={video || undefined}
            youtubeVideo={youtubeVideo}
            userAccessibility={userAccessibility}
            meta={meta}
            metaDisplay={metaDisplay}
          />
        ) : (
          <ArticleHeader
            name={name}
            image={image || undefined}
            ingress={ingress}
            runningHead={runningHead}
            displayFeatureHeader={displayFeatureHeader || false}
            video={video || undefined}
            youtubeVideo={youtubeVideo}
            userAccessibility={userAccessibility}
          />
        )}
        <>
          {articleSeries && (
            <StyledArticleContentGrid>
              <ArticleSeriesHeader articleSeries={articleSeries} />
            </StyledArticleContentGrid>
          )}
          {routePoints && <TravelMap routePoints={routePoints} travelMode={travelMode} />}
          {generateTableOfContents && body && (
            <StyledArticleContentGrid>
              <ContentCol>
                <TableOfContents tagType="h2" parentElement={articleRef} />
              </ContentCol>
            </StyledArticleContentGrid>
          )}
          {relatedLinks?.links?.length > 0 && (
            <StyledArticleContentGrid>
              <RelatedGridCol>
                <Text tag="h3" variant={TextVariant.Header3}>
                  {relatedLinks.header ? relatedLinks.header : 'Les også'}
                </Text>
                <RelatedLinksList relatedLinks={relatedLinks.links} />
              </RelatedGridCol>
            </StyledArticleContentGrid>
          )}
          {!!articleScore?.selectedScore && (
            <StyledArticleContentGrid>
              <ContentCol>
                <S.ScoreWrapper>
                  <Text tag="h3" variant={TextVariant.Header3}>
                    Testresultat
                  </Text>
                  <S.ScoreIconWrapper>
                    <Star size={24} />
                    <span>
                      <b>{articleScore.selectedScore} poeng</b>{' '}
                      {!!articleScore.maxScore && `av ${articleScore.maxScore}`}
                    </span>
                  </S.ScoreIconWrapper>
                </S.ScoreWrapper>
              </ContentCol>
            </StyledArticleContentGrid>
          )}
          {isUpdatingArticle && !body ? (
            <StyledArticleContentGrid>
              <ContentCol>
                <LoaderContent />
              </ContentCol>
            </StyledArticleContentGrid>
          ) : (
            (body && (
              <ArticleBlockWrapper ref={articleRef}>
                <BlockContent
                  context="article"
                  value={body}
                  isAccessibleForUser={userAccessibility.isAccessibleForUser}
                />
              </ArticleBlockWrapper>
            )) ||
            null
          )}
          {articleSeries && (
            <StyledArticleContentGrid>
              <ArticleSeriesFooter articleSeries={articleSeries} />
            </StyledArticleContentGrid>
          )}
          <StyledArticleContentGrid>
            <BottomContentCol>
              {userAccessibility.isAccessibleForUser ? (
                <MetaData meta={meta} metaDisplay={metaDisplay} documentType={type} displayAsText />
              ) : null}
              {tags && (
                <S.TagsWrapper>
                  {tags.map((tag: any) => {
                    const chipCategory = mappedCategories[tag.slug]?.data;
                    let chipUrl = chipCategory?.url || articleUrl;
                    if (chipCategory?.isGlobalTag && category) {
                      const topCategorySlug = findTopCategorySlug(category);
                      if (topCategorySlug) chipUrl = `/${topCategorySlug}/${chipCategory.slug}`;
                    }
                    return (
                      <Chips
                        className="tags-chip"
                        key={`chip-${tag.name}`}
                        text={tag.name}
                        onClick={() => {
                          history.push(chipUrl);
                        }}
                      />
                    );
                  })}
                </S.TagsWrapper>
              )}
            </BottomContentCol>
            {relatedContent?.length > 0 && (
              <RelatedGridCol>
                <S.ReadMoreHRLine />
                <Text tag="h2" variant={TextVariant.Header3} id="relatedContent">
                  Les også
                </Text>
                <RelatedArticleList relatedContent={relatedContent} />
              </RelatedGridCol>
            )}
            {!mappedCategory?.isHiddenCategory && !metaDisplay?.hideForm && (
              <RelatedGridCol>
                <FeedbackSurvey contentUrl={windowPath} />
              </RelatedGridCol>
            )}
          </StyledArticleContentGrid>
        </>
      </Wrapper>
    </LayoutWithMainContent>
  );
};

// Need this empty wrapper for SchemaArticle script
const Wrapper = styled.div``;

export const StyledArticleContentGrid = styled(Grid)`
  margin-bottom: ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const BreadCrumbsGrid = styled(Grid)`
  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const BreadCrumbGridCol = styled(GridCol)`
  grid-column: 3 / -3;
  margin-bottom: ${spacing.space48};

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    margin-bottom: ${spacing.space32};
  }
`;

const ArticleBlockWrapper = styled.div`
  // Styling for spacing of elements specific to article. Perhaps to be moved to serializers, if possible
  h2,
  h3,
  p {
    margin: 0;
  }

  .article_h2,
  .article_h3 {
    margin-bottom: -${spacing.space16};
  }

  // Headers that directly precede numbered lists require an actual margin
  .article_h2:has(+ * > .numberedList),
  .article_h3:has(+ * > .numberedList) {
    margin-bottom: ${spacing.space16};
  }

  // for table of contents with navbar
  h2 {
    scroll-margin-top: ${spacing.space80};
  }

  /* Specific checks to determine spacing if elements are in certain order
    article_normal here is mostly used for normal text, aka paragraph tags
    This is to create extra spacing when other elements follow blocks of text/paragraph. 
    This should apply extra margin to top of elements following a paragraph, except other paragraphs
  */
  .article_normal
    + :not(
      .article_normal,
      .list,
      .article_h3,
      .article_h2,
      .CtaButton,
      .videoBlock,
      .blockAccordion,
      .numberedList,
      .internalLink
    ) {
    margin-top: ${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: ${spacing.space24};
    }
  }

  /* Selects .blockAccordion that is a direct sibling of .article_h2 or .article_h3 and is not the first child */
  .article_h2 + .blockAccordion,
  .article_h3 + .blockAccordion {
    margin-top: 0; /* Reset margin */
  }

  /* Apply margin to all other instances of .blockAccordion */
  .blockAccordion {
    margin-top: -${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: -${spacing.space24};
    }
  }

  .numberedList {
    margin-top: -${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: -${spacing.space24};
    }
  }

  .list + :not(.article_normal, .list, .article_h3) {
    margin-top: ${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: ${spacing.space24};
    }
  }
`;

const ContentCol = styled.div`
  // Firefox overflow bugfix
  max-width: 100%;
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

const BottomContentCol = styled.div`
  // Firefox overflow bugfix
  max-width: 100%;
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

const RelatedGridCol = styled.div`
  grid-column: 3 / -3;

  h3 {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export default Article;
