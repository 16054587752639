import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonLink } from '@naf/button-link';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, radius, spacing } from '@naf/theme';
import { AdvancedImage, placeholder, responsive } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { LatestArticlesBlockType } from '../../../../../../types/LatestArticlesBlockType';
import { LatestArticleItem } from './LatestArticleItem';
import { InternalLinkType } from '../../../../../../types/internalLinkType';
import { ExternalTarget, LinkType } from '../../../../../../types/LinkType';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { RelatedArticleType } from '../../../../../../types/articleType';

const LatestArticleListWrapper = styled.div`
  grid-area: articles;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${spacing.space24};
`;

const ImageWrapper = styled.div`
  grid-area: image;

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const ImageCanvas = styled.div`
  width: 100%;
  padding-top: ${(9 / 16) * 100}%;
  background: lightgrey;
  position: relative;

  * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${radius.s};

    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  img {
    object-fit: cover;
  }

  #active {
    opacity: 1;
  }
`;

const StyledText = styled(Text)`
  margin: 0;
  grid-area: title;
  place-self: center start;
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 5em max-content;
  grid-gap 0 ${spacing.space24};
  grid-template-areas:
    'title cta'
    'articles image';

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: auto;
    grid-template-rows: 5em max-content 4em;
    grid-template-areas:
      'title'
      'articles'
      'cta';
  }
`;

export const LinkField = styled(
  ({ title, link, className }: { title?: string; link: LinkType; className?: string }) => {
    switch (link.targetType) {
      case 'internalReference':
        if (link.internalReference) {
          return (
            <InternalReferenceButtonLink
              title={title || link.title}
              internalReference={link.internalReference}
              className={className}
            />
          );
        }
        return null;
      case 'externalTarget':
        if (link.title && link.externalTarget) {
          return (
            <ExternalTargetButtonLink
              title={title || link.title}
              externalTarget={link.externalTarget}
              className={className}
            />
          );
        }
        return null;
      default:
        return null;
    }
  },
)``;

const ExternalTargetButtonLink = ({
  title,
  externalTarget,
  className,
}: {
  title: string;
  externalTarget: ExternalTarget;
  className?: string;
}) => {
  const { href, blank } = externalTarget;
  return (
    <ButtonLink
      text={title || 'Se mer'}
      href={href}
      target={blank ? '_blank' : undefined}
      rel={blank ? 'noopener noreferrer' : undefined}
      className={className}
    />
  );
};

const InternalReferenceButtonLink = ({
  title,
  internalReference,
  className,
}: {
  title?: string;
  internalReference: InternalLinkType;
  className?: string;
}) => {
  const target = useDocumentUrlWithNode(internalReference);
  if (!target) return null;
  return <ButtonLink as={Link} text={title || 'Se mer'} to={target} className={className} />;
};

const LatestArticleButtonLink = styled(LinkField)`
  grid-area: cta;
  place-self: center end;

  @media (max-width: ${breakpoints.m}) {
    place-self: end start;
  }
`;

export const LatestArticlesBlock = ({ block }: { block: LatestArticlesBlockType }) => {
  const { articleCategory, cta, title } = block;
  const [currentArticle, setCurrentArticle] = useState(articleCategory.articles[0]);
  const images = useMemo(
    () =>
      articleCategory.articles.map(
        (article) => (
          <ArticleImage article={article} isCurrent={currentArticle.slug === article.slug} key={article.slug} />
        ),
        [articleCategory, currentArticle],
      ),
    [articleCategory, currentArticle],
  );

  return (
    <StyledContent>
      <StyledText tag="h2" variant={TextVariant.Header2}>
        {title}
      </StyledText>
      <LatestArticleButtonLink link={cta.link} />
      <LatestArticleListWrapper>
        {articleCategory.articles.map((article) => (
          <LatestArticleItem article={article} setCurrentArticle={setCurrentArticle} key={article.slug} />
        ))}
      </LatestArticleListWrapper>
      <ImageWrapper>
        <ImageCanvas>{images}</ImageCanvas>
      </ImageWrapper>
    </StyledContent>
  );
};

const ArticleImage = ({ article, isCurrent }: { article: RelatedArticleType; isCurrent: boolean }) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const cldImg = article.image?.publicId
    ? cld.image(article.image.publicId).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto')
    : undefined;

  return cldImg ? (
    <AdvancedImage
      id={isCurrent ? 'active' : undefined}
      style={{ maxWidth: '100%' }}
      alt={article.image?.altText}
      cldImg={cldImg}
      plugins={[responsive({ steps: 200 }), placeholder({ mode: 'blur' })]}
    />
  ) : null;
};
