import React from 'react';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';

interface InfoBoxSerializerProps {
  value: {
    text: string;
  };
}

export const InformationUserInteraction = ({ value: { text } }: InfoBoxSerializerProps) => (
  <UserInteraction variant={UserInteractionVariant.Info}>{text}</UserInteraction>
);

export const InformationUserInteractionSerializer =
  () =>
  ({ value }: InfoBoxSerializerProps) =>
    <InformationUserInteraction value={value} />;
