import React from 'react';
import { ButtonLink } from '@naf/button-link';
import { useHistory, useLocation } from 'react-router';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';
import { RelatedReferenceComponentType } from '../../../../../../../types/articleType';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface InternalLinkSerializerProps {
  value: {
    text: string;
    referenceComponent: RelatedReferenceComponentType;
    anchorId?: string;
  };
}

export const InternalLinkBlock = ({ value: { text, anchorId, referenceComponent } }: InternalLinkSerializerProps) => {
  const location = useLocation();

  const referenceUrl = useDocumentUrlWithNode(referenceComponent) || '';

  let linkUrl = referenceUrl;
  if (anchorId) {
    linkUrl = `${referenceUrl}#${anchorId}`;
  }

  const history = useHistory();
  return (
    <ButtonLink
      icon={location.pathname === referenceUrl && anchorId ? 'arrow-down' : undefined}
      onClick={() => {
        history.push(linkUrl);
      }}
      text={text}
    />
  );
};

export const InternalLinkSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: InternalLinkSerializerProps) => (
      <ArticleBlockContentGridCol className="internalLink">
        <InternalLinkBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: InternalLinkSerializerProps) => <InternalLinkBlock value={value} />;
};
