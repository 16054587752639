import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import RadioButton from '../../../../../RadioButton';
import { AlternativeType, QuestionStatus } from './types';

interface Props {
  alternative: AlternativeType;
  isSelected: boolean;
  _key: string;
  text: string;
  setSelectedValue: Dispatch<SetStateAction<string | null>>;
  setQuestionStatus: (status: QuestionStatus) => void;
  isDisabled?: boolean;
  status: QuestionStatus;
}

export const Alternative = ({
  status,
  alternative,
  setQuestionStatus,
  isSelected,
  _key,
  text,
  setSelectedValue,
  isDisabled,
}: Props) => {
  useEffect(() => {
    if (isSelected) {
      if (alternative.isCorrect === true) {
        setQuestionStatus(QuestionStatus.CORRECT);
      } else {
        setQuestionStatus(QuestionStatus.WRONG);
      }
    }
  }, [isSelected, alternative.isCorrect, setQuestionStatus]);

  return (
    <StyledRadioButton
      variant={isDisabled && !isSelected ? 'disabled' : 'standard'}
      isDisabled={isDisabled}
      checked={isSelected}
      isCorrect={alternative.isCorrect}
      outline
      key={_key}
      label={text}
      name="fullRadio"
      width="full"
      status={status}
      onChange={() => {
        setSelectedValue(_key);
      }}
    />
  );
};

const StyledRadioButton = styled(RadioButton)<{ status: QuestionStatus; isCorrect?: boolean }>`
  width: calc(100% - 15px);
  padding: 25px 0 25px 13px;
  margin-top: 0;
  margin-bottom: 12px;
  ${({ status, isCorrect }) => status !== QuestionStatus.UNANSWERED && isCorrect && 'border: 1px solid #267C4F;'}
  ${({ status, checked }) => status === QuestionStatus.WRONG && checked && 'border: 1px solid #C00015;'}
  :last-child {
    margin-bottom: 0;
  }
`;
