import { useState } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';
import { VehicleInspectionType } from '../../../types/vehicleInspectionType';

interface Props {
  token?: string;
  licensePlateNumber: string;
}

interface ReturnType {
  vehicleInspection?: VehicleInspectionType;
  lookup: ({ token, licensePlateNumber }: Props) => void;
  isLoading: boolean;
  error: boolean;
}

export const useVehicleInspection = (): ReturnType => {
  const [vehicleInspection, setVehicleInspection] = useState<VehicleInspectionType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = useSelector((state) => state.application);

  const vehicleInspectionSearch = async ({ token, licensePlateNumber }: Props) => {
    if (licensePlateNumber && !isLoading) {
      setIsLoading(true);
      try {
        const headers: {
          'Ocp-Apim-Subscription-Key': string;
          Authorization?: string;
        } = {
          'Ocp-Apim-Subscription-Key': apimContentHub,
        };
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
        const res = await axios.get<VehicleInspectionType>(
          `${apimBaseUrl}/${apimNafNoApi}/vehicle-inspection/${licensePlateNumber}`,
          {
            headers,
          },
        );
        setIsLoading(false);
        setVehicleInspection(res.data);
        setError(false);
      } catch (e) {
        setIsLoading(false);
        setError(true);
      }
    }
    return () => {};
  };

  const lookup = ({ token, licensePlateNumber }: Props) => {
    vehicleInspectionSearch({ token, licensePlateNumber });
  };

  return { vehicleInspection, lookup, isLoading, error };
};
