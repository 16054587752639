import React from 'react';
import styled from 'styled-components';
import { NafThemeType, breakpoints, spacing, themeLight } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { CarMeta } from '../../../../types/carModelType';
import { Season } from '../../../../types/ElPrixContextData';
import { MotorText } from '../../components/icons/MotorText';

type Props = Pick<CarMeta, 'rangeTests' | 'chargeTests'> & {
  testedByMotor: boolean;
  onNafTestClick: (testDate: string, season: Season) => void;
  onMotorTestClick: () => void;
};

export const TestChips = ({ rangeTests, chargeTests, testedByMotor, onNafTestClick, onMotorTestClick }: Props) => {
  const performedTests: { [key: string]: Season } = [...rangeTests, ...chargeTests].reduce((tests, current) => {
    if (!Object.keys(tests).includes(current.context.testDate)) {
      return { ...tests, [current.context.testDate]: current.context.season };
    }
    return { ...tests };
  }, {});

  const sortedTests = Object.entries(performedTests).sort(
    ([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime(),
  );

  return (
    <Container>
      <Chips>
        {sortedTests.map(([dateString, season]) => (
          <Chip
            tag="p"
            key={dateString}
            variant={TextVariant.TagSmall}
            $colorVariant={season}
            onClick={() => onNafTestClick(dateString, season)}
          >
            El Prix {season === 'summer' ? 'sommer' : 'vinter'} {dateString.split('-')[0]}
          </Chip>
        ))}
        {testedByMotor && (
          <Chip tag="p" variant={TextVariant.TagSmall} $colorVariant="motor" onClick={onMotorTestClick}>
            Testet av <MotorText />
          </Chip>
        )}
      </Chips>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  text-align: center;
  gap: ${spacing.space8};
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.space40};
  cursor: pointer;

  @media (max-width: ${breakpoints.l}) {
    margin-top: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
  }
`;

const Chips = styled.div`
  display: flex;
  gap: ${spacing.space8};
  flex-wrap: wrap;
  justify-content: center;
`;

const Chip = styled(Text)<{ $colorVariant: Season | 'motor' }>`
  background-color: ${({ theme, $colorVariant }) => {
    const activeTheme = (theme?.background ? theme : themeLight) as NafThemeType;
    switch ($colorVariant) {
      case 'motor':
        return activeTheme.border.subtle;
      case 'winter':
        return activeTheme.componentColors.alert.infoLight;
      case 'summer':
      default:
        return activeTheme.background.brand;
    }
  }};
  border-radius: 2px;
  padding: 3px 4px;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2px;
`;
