import { InternalLinkMarks } from './InternalLinkMarks';
import { LinkMarks } from './LinkMarks';
import { MailToLinkSerializer } from './MailToLinkSerializer';
import { PhoneNumberLinkSerializer } from './PhoneNumberLinkSerializer';
import { WordReferenceMarks } from './WordReferenceMarks';

export const marks = {
  internalLink: InternalLinkMarks,
  link: LinkMarks,
  mailToLink: MailToLinkSerializer,
  phoneNumberLink: PhoneNumberLinkSerializer,
  wordDocumentReference: WordReferenceMarks,
};
