import React from 'react';
import { Quote } from '@naf/quote';
import useSelector from '../../../../../redux/typedHooks';
import { cloudinaryImage } from '../../../../../utils/imageUrl';
import { QuoteType } from '../../../../../../../types/blockTypes';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface QuoteSerializerProps {
  value: QuoteType;
}

export const QuoteBlock = ({ value: { text, author, authorImage, link } }: QuoteSerializerProps) => {
  const application = useSelector((state) => state.application);
  return authorImage ? (
    <Quote
      caption={author}
      img={{
        alt: authorImage.source_altText ? authorImage.source_altText : 'forfatterbilde',
        src: cloudinaryImage(authorImage.publicId, application, 50),
      }}
      href={link}
    >
      {text}
    </Quote>
  ) : (
    <Quote caption={author} href={link}>
      {text}
    </Quote>
  );
};

export const QuoteSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: QuoteSerializerProps) => (
      <ArticleBlockContentGridCol className="quote">
        <QuoteBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: QuoteSerializerProps) => <QuoteBlock value={value} />;
};
