import React from 'react';
import { TextVariant } from '@naf/text';
import { Logo } from '../LogoDecorations/Logo';
import * as S from './Styles';

const LogoArea = ({ isScrolled, onNavClick }: { isScrolled?: boolean; onNavClick: () => void }) => (
  <S.LogoArea>
    <Logo isScrolled={isScrolled} onNavClick={onNavClick} />
    <S.RoadAssistance href="tel:+47-08505">
      <S.StyledTopText $isScrolled={isScrolled} tag="span" variant={TextVariant.Small}>
        Ring oss
      </S.StyledTopText>
      <S.StyledBottomText $isScrolled={isScrolled} tag="span" variant={TextVariant.Small}>
        08 505
      </S.StyledBottomText>
    </S.RoadAssistance>
    <S.SeparatorLine $isScrolled={isScrolled} />
  </S.LogoArea>
);

export default LogoArea;
