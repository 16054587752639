import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { nafColor } from '@nafcore/theme';
import { radius, themeLight, spacing } from '@naf/theme';

export const StyledLink = styled(Link)<{ $hasCardGrid?: boolean }>`
  text-decoration: none;
  height: fit-content;

  &:hover {
    color: inherit;
  }

  ${({ $hasCardGrid }) =>
    $hasCardGrid &&
    css`
      &:focus {
        #cardGrid {
          outline: 2px solid ${nafColor.signature.black} !important;
          border-radius: ${radius.s};
        }
      }
    `}
`;

export const NavCardLink = styled(Link)<{ $hasCardGrid?: boolean }>`
  text-decoration: none;
  height: fit-content;
  display: flex;
  height: 100%;

  &:focus {
    outline: none !important; // Navigation card inside will handle focus state
    .card {
      outline: 3px solid;
      outline-color: ${({ theme }) => (theme.border ? theme.border.subtle : themeLight.border.subtle)};

      div > div:last-child {
        background-color: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.cta.secondaryHover
            : themeLight.componentColors.cta.secondaryHover};
        margin-left: ${spacing.space16};
      }
    }
  }

  &:hover {
    color: inherit;
  }
`;
