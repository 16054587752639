import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MappedData, actions as documentActions } from '../redux/modules/documents';
import useSelector from '../redux/typedHooks';

const initialState: MappedData = { meta: { fetchedAt: undefined, isUpdating: true, isLoggedin: undefined } };
// Get a document with the given slug
export const useDocument = (slug: string, token?: string) => {
  const { isAuthenticated } = useAuth0();
  const { meta, data, error } = useSelector((state) => state.documents.mappedData[slug]) || {};
  const { fetchedAt, isLoggedin } = meta || {};

  const dispatch = useDispatch();

  useEffect(() => {
    const now = new Date().getTime();
    const age = (fetchedAt && now - new Date(fetchedAt).getTime()) || 0;
    if (slug && (!isAuthenticated || typeof token === 'string') && !error) {
      if (!fetchedAt || age > 1800000 || (!isLoggedin && typeof token === 'string')) {
        // either fetch if meta is undefined (first time),
        // or if the document is older than 30 minutes (1800000 ms),
        // or if the user is logged in and the document was first fetched server side (without user data)
        dispatch(documentActions.getDocument.request(slug, { token }));
      }
    }
  }, [slug, token, dispatch, isAuthenticated, fetchedAt, isLoggedin, error]);

  // default to loading state on initial render
  return meta ? { meta, data } : initialState;
};
