import React from 'react';
import { TestCollection } from '../../../../testcollection/TestCollection';
import { RelatedArticleType } from '../../../../../../../types/articleType';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface Props {
  value: { testscores: RelatedArticleType[] };
}
export const TestCollectionBlock = ({ value: { testscores } }: Props) => <TestCollection testCollection={testscores} />;

export const TestCollectionSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: Props) => (
      <ArticleBlockContentGridCol>
        <TestCollectionBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: Props) => <TestCollectionBlock value={value} />;
};
