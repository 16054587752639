import React from 'react';
import { quality } from '@cloudinary/url-gen/actions/delivery';
import { Text, TextVariant } from '@naf/text';
import { useCloudinary } from '../../hooks/useCloudinary';
import { ImageCaption } from '../ImageCaption';
import { DynamicVideoPlayer } from '../video-player/DynamicVideoPlayer';
import ResponsiveImage from '../image/ResponsiveImage';
import { FeatureArticleHeader } from './FeatureArticleHeader';
import { ArticleHeaderProps } from './Types';
import * as S from './Styles';
import { MetaData } from '../metaData/MetaData';
import { YoutubeEmbed } from '../YoutubeEmbed';

export const ArticleHeader = ({
  name,
  image,
  ingress,
  runningHead,
  displayFeatureHeader,
  video,
  youtubeVideo,
  userAccessibility,
  meta,
  metaDisplay,
}: ArticleHeaderProps) => {
  const cld = useCloudinary();
  const videoObject = video?.publicId && cld.video(video.publicId);
  const videoUrl = videoObject ? videoObject.delivery(quality('auto')).toURL() : video?.srcSet?.mp4;

  if (displayFeatureHeader) {
    return (
      <FeatureArticleHeader
        name={name}
        ingress={ingress}
        runningHead={runningHead}
        image={image}
        video={video}
        videoPlayerOptions={{ autoplay: true, loop: true, muted: true, controls: false }}
        youtubeVideo={youtubeVideo}
      />
    );
  }

  return (
    <S.HeaderWrapperContentGrid>
      <S.HeaderContentGridCol>
        {runningHead && (
          <Text tag="p" variant={TextVariant.Tag}>
            {runningHead}
          </Text>
        )}
        {name && (
          <S.HeadingText tag="h1" variant={TextVariant.Header1}>
            {name}
          </S.HeadingText>
        )}
        {ingress && (
          <>
            <S.Ingress tag="p" variant={TextVariant.Ingress}>
              {ingress}
            </S.Ingress>
            <S.HRLine />
            <MetaData meta={meta} metaDisplay={metaDisplay} />
          </>
        )}
      </S.HeaderContentGridCol>
      {image?.publicId && !video && !youtubeVideo && (
        <S.MainImageGridCol>
          <S.ImageFigure aria-labelledby={image.publicId} $hasCaption={!!image.caption || !!image.photographer}>
            <ResponsiveImage imageId={image.publicId} altText={image.altText || image.caption} />
          </S.ImageFigure>
        </S.MainImageGridCol>
      )}
      {image && (image.caption || image.photographer || image.copyright) && !video && !youtubeVideo && (
        <S.ContentCol>
          <S.Caption>
            <ImageCaption
              captionId={image.publicId}
              photographer={image.photographer}
              copyright={image.copyright}
              caption={image.caption}
              addNewLineAfterCaption
            />
          </S.Caption>
        </S.ContentCol>
      )}
      {video && videoUrl && (
        <S.MainImageGridCol>
          <S.VideoWrapper hasCaption={!!video.caption}>
            {/* TODO: add captions */}
            <DynamicVideoPlayer
              video={video}
              isAccessibleForUser={userAccessibility && userAccessibility.isAccessibleForUser}
              videoPlayerOptions={{}}
              aria-labelledby={video.publicId}
              image={image || undefined}
            />
          </S.VideoWrapper>
        </S.MainImageGridCol>
      )}
      {youtubeVideo && (
        <S.MainImageGridCol>
          <S.VideoWrapper hasCaption={false}>
            <YoutubeEmbed embed={youtubeVideo} />
          </S.VideoWrapper>
        </S.MainImageGridCol>
      )}
      {video && video.caption && (
        <S.ContentCol>
          <S.Caption>
            <ImageCaption caption={video.caption || undefined} captionId={video.publicId} />
          </S.Caption>
        </S.ContentCol>
      )}
    </S.HeaderWrapperContentGrid>
  );
};
