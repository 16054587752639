import { spacing } from '@naf/theme';
import styled from 'styled-components';

export const BylineRole = styled.span`
  font-style: italic;
`;

export const MetaDataWrapper = styled.div`
  padding-bottom: ${spacing.space12};
`;
