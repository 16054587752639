import { Text, TextVariant } from '@naf/text';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { IconButton } from '@naf/icon-button';
import { breakpoints, spacing } from '@naf/theme';
import { ArticleSeries } from '../../../../types/articleType';
import ArticleSeriesItem from './ArticleSeriesItem/ArticleSeriesItem';

type Props = {
  articleSeries: ArticleSeries;
};

// The debounce function receives our function as a parameter
const debounce = (fn) => {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;
  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }
    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params);
    });
  };
};

const ArticleSeriesHeader = ({ articleSeries: { title, items } }: Props) => {
  const scrollRef = useRef(null);
  const activeRef = useRef(null);
  const [scrollPercent, setScroll] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current;
      // Reads out the scroll position and stores it in the data attribute
      // so we can use it in our stylesheets
      const storeScroll = () => {
        setScroll(scrollContainer.scrollLeft / (scrollContainer.scrollWidth - scrollContainer.clientWidth));
      };

      // Listen for new scroll events, here we debounce our `storeScroll` function
      scrollContainer.addEventListener('scroll', debounce(storeScroll), { passive: true });
      scrollContainer.addEventListener('scrollend', () => setIsScrolling(false));

      // Update scroll position for first time
      storeScroll();
      return () => {
        scrollContainer.removeEventListener('scroll', debounce(storeScroll));
        scrollContainer.removeEventListener('scrollend', () => setIsScrolling(false));
      };
    }
    return () => {};
  }, [scrollPercent, scrollRef]);

  useEffect(() => {
    // Always scroll the active article into view and to the left
    if (activeRef.current !== null && scrollRef.current) {
      if (scrollRef.current.clientWidth < activeRef.current.offsetLeft + activeRef.current.clientWidth) {
        scrollRef.current.scrollLeft = activeRef.current.offsetLeft;
      }
    }
  }, [activeRef, scrollRef, location.pathname]);

  return (
    <>
      <Header variant={TextVariant.Header3}>{title}</Header>
      <Wrapper $scrollPercent={scrollPercent}>
        <StyledArrowLeft
          icon="arrow-left"
          variant="secondary"
          ariaLabel="pil venstre"
          onClick={() => {
            // if element is currently not scrolling (to stop multiple clicks)
            if (!isScrolling) {
              // if element is at the start, no scrolling will happen
              if (scrollRef.current.scrollLeft !== 0) {
                setIsScrolling(true);
              }
              scrollRef?.current?.scrollBy({ left: -340, behavior: 'smooth' });
            }
          }}
        />
        <ScrollContainer ref={scrollRef}>
          <ArticleCarousel>
            {items.map((article) => (
              <ArticleSeriesItem key={article.id} article={article} ref={article.isActive ? activeRef : null} />
            ))}
          </ArticleCarousel>
        </ScrollContainer>
        <StyledArrowRight
          icon="arrow-right"
          variant="secondary"
          ariaLabel="pil høyre"
          onClick={() => {
            if (!isScrolling) {
              if (scrollRef.current.scrollLeft !== scrollRef.current.scrollWidth - scrollRef.current.clientWidth) {
                setIsScrolling(true);
              }
              scrollRef?.current?.scrollBy({ left: 340, behavior: 'smooth' });
            }
          }}
        />
      </Wrapper>
    </>
  );
};

const Header = styled(Text)`
  margin: 0;
  grid-column: 3 / -3;
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

const Wrapper = styled.div<{ $scrollPercent: number }>`
  grid-column: 2 / -2;
  margin-bottom: ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
  position: relative;
  display: flex;
  align-items: center;

  :before {
    @media (max-width: ${breakpoints.m}) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 100%;
      background: linear-gradient(90deg, #ffffff 2.84%, rgba(255, 255, 255, 0) 100%);
      opacity: clamp(0, calc(${(props) => props.$scrollPercent} * 5), 1);
      z-index: 2;
    }
  }

  :after {
    @media (max-width: ${breakpoints.m}) {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 100%;
      background: linear-gradient(270deg, #ffffff 2.84%, rgba(255, 255, 255, 0) 100%);
      opacity: clamp(0, calc((1 - ${(props) => props.$scrollPercent}) * 5), 1);
      z-index: 2;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: -${spacing.space32};
    margin-right: -${spacing.space32};
  }
`;

const ScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding-left: ${spacing.space16};
  padding-right: ${spacing.space16};

  @media (min-width: ${breakpoints.m}) {
    margin-left: ${spacing.space48};
    margin-right: ${spacing.space48};
  }

  // Remove scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ArticleCarousel = styled.div`
  display: flex;
  width: fit-content;
  scroll-snap-type: x proximity;
  scroll-margin: 0 64px;
`;

const StyledArrowLeft = styled(IconButton)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
  position: absolute;
  left: 0;
  z-index: 3;
`;

const StyledArrowRight = styled(IconButton)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
  position: absolute;
  right: 0;
  z-index: 3;
`;

export default ArticleSeriesHeader;
