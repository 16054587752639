import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';
import { useAuth0Token } from './useAuth0Token';

export const useReferencedComponent = (documentId: string) => {
  const [component, setComponent] = useState<any>(null);
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = useSelector((state) => state.application);
  const { simpleToken } = useAuth0Token();

  const fetchReferencedComponent = useCallback(async () => {
    const headers: { 'Ocp-Apim-Subscription-Key': string; Authorization?: string } = {
      'Ocp-Apim-Subscription-Key': apimContentHub,
    };
    if (simpleToken) {
      headers.Authorization = `Bearer ${simpleToken}`;
    }
    try {
      const res = await axios.get(`${apimBaseUrl}/${apimNafNoApi}/component/${documentId}`, {
        headers,
      });

      setComponent(res.data);
    } catch (e) {
      // TODO: handle error?
    }
  }, [documentId, simpleToken, apimBaseUrl, apimContentHub, apimNafNoApi]);

  useEffect(() => {
    fetchReferencedComponent().then();
  }, [fetchReferencedComponent]);

  return component;
};
