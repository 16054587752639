import React, { useMemo } from 'react';
import { CardList } from '@naf/cards';
import { AcUnit } from '@styled-icons/material-outlined/AcUnit';
import { WbSunny } from '@styled-icons/material-outlined/WbSunny';
import RoadIcon from '../icons/Road';
import useSelector from '../../redux/typedHooks';
import { cloudinaryImage } from '../../utils/imageUrl';
import { getSeason, isFuture } from '../../utils/Dates';
import { articleChargeReferenceType, articleRangeReferenceType } from '../../../../types/articleType';
import S from './StyledRelatedCars';
import FallbackImage from '../../../assets/images/fallback_image.png';
import { StyledLink } from '../styled-link/StyledLink';
import { ChargeTestType, RangeTestType } from '../../../../types/testType';

const RelatedRangeTestCard = ({
  test: { image, name, slug, statedRange_km, summer_km, winter_km, summerTestDate, winterTestDate, vehicle, ingress },
}: {
  test: articleRangeReferenceType;
}) => {
  const seasonWinter = useMemo(() => winterTestDate && getSeason(winterTestDate), [winterTestDate]);
  const seasonSummer = useMemo(() => summerTestDate && getSeason(summerTestDate), [summerTestDate]);

  const isFutureWinterTest = useMemo(() => winterTestDate && isFuture(winterTestDate), [winterTestDate]);
  const isFutureSummerTest = useMemo(() => summerTestDate && isFuture(summerTestDate), [summerTestDate]);

  const application = useSelector((state) => state.application);

  const mainImage = image ? image.publicId && cloudinaryImage(image.publicId, application, 200) : FallbackImage;

  const testUrl = vehicle && vehicle.slug ? `/bilguiden/bilmodell/${vehicle.slug}/${slug}` : `/bilguiden/${slug}`;

  return (
    <StyledLink to={testUrl}>
      <CardList
        title={name}
        img={{
          src: mainImage,
          alt: image?.altText,
        }}
        isInternalLink
      >
        <S.ElementInfo>
          {ingress && <S.ElementSubInfo>{ingress}</S.ElementSubInfo>}
          <S.IconWrapper>
            {!!statedRange_km && (
              <S.IconSet>
                <RoadIcon size={24} />
                <S.RoadIconText>
                  <b>{statedRange_km} km</b> rekkevidde for testbil* (WLTP){' '}
                </S.RoadIconText>
              </S.IconSet>
            )}
            {summerTestDate && (
              <S.IconSet>
                <WbSunny size={24} />
                <span>
                  {isFutureSummerTest ? (
                    `Testresultat kommer ${seasonSummer}`
                  ) : (
                    <>
                      <b>{summer_km} km</b> rekkevidde målt {seasonSummer}
                    </>
                  )}
                </span>
              </S.IconSet>
            )}
            {winterTestDate && (
              <S.IconSet>
                <AcUnit size={24} />
                <span>
                  {isFutureWinterTest ? (
                    `Testresultat kommer ${seasonWinter}`
                  ) : (
                    <>
                      <b>{winter_km} km</b> rekkevidde målt {seasonWinter}
                    </>
                  )}
                </span>
              </S.IconSet>
            )}
          </S.IconWrapper>
        </S.ElementInfo>
      </CardList>
    </StyledLink>
  );
};

const RelatedChargeTestCard = ({
  test: {
    image,
    name,
    slug,
    summerChargeTime_Minutes,
    winterChargeTime_Minutes,
    summerStart_Percent,
    summerEnd_Percent,
    summerTestDate,
    winterTestDate,
    winterStart_Percent,
    winterEnd_Percent,
    summerCharge_25min,
    winterCharge_25min,
    vehicle,
    ingress,
  },
}: {
  test: articleChargeReferenceType;
}) => {
  const seasonWinter = useMemo(() => winterTestDate && getSeason(winterTestDate), [winterTestDate]);
  const seasonSummer = useMemo(() => summerTestDate && getSeason(summerTestDate), [summerTestDate]);

  const isFutureWinterTest = useMemo(() => winterTestDate && isFuture(winterTestDate), [winterTestDate]);
  const isFutureSummerTest = useMemo(() => summerTestDate && isFuture(summerTestDate), [summerTestDate]);

  const summerPercent =
    summerStart_Percent && summerEnd_Percent ? `(${summerStart_Percent}-${summerEnd_Percent} %)` : null;
  const winterPercent =
    winterStart_Percent && winterEnd_Percent ? `(${winterStart_Percent}-${winterEnd_Percent} %)` : null;

  const application = useSelector((state) => state.application);

  const mainImage = image ? image.publicId && cloudinaryImage(image.publicId, application, 200) : FallbackImage;

  const testUrl = vehicle && vehicle.slug ? `/bilguiden/bilmodell/${vehicle.slug}/${slug}` : `/bilguiden/${slug}`;

  return (
    <StyledLink to={testUrl}>
      <CardList
        title={name}
        img={{
          src: mainImage,
          alt: image?.altText,
        }}
        isInternalLink
      >
        <S.ElementInfo>
          <S.IconWrapper>
            {ingress && <S.ElementSubInfo>{ingress}</S.ElementSubInfo>}
            {summerTestDate && (
              <S.IconSet>
                <WbSunny size={24} />
                <span>
                  {isFutureSummerTest ? (
                    `Testresultat kommer ${seasonSummer}`
                  ) : (
                    <>
                      <b>{summerChargeTime_Minutes} min</b> ladetid målt {seasonSummer} {summerPercent && summerPercent}
                    </>
                  )}
                </span>
              </S.IconSet>
            )}
            {winterTestDate && (
              <S.IconSet>
                <AcUnit size={24} />
                <span>
                  {isFutureWinterTest ? (
                    `Testresultat kommer ${seasonWinter}`
                  ) : (
                    <>
                      <b>{winterChargeTime_Minutes} min</b> ladetid målt {seasonWinter} {winterPercent && winterPercent}
                    </>
                  )}
                </span>
              </S.IconSet>
            )}
            {!!summerCharge_25min && (
              <S.IconSet>
                <WbSunny size={24} />
                <span>
                  <b>{summerCharge_25min} km</b> rekkevidde etter 25 min lading.
                </span>
              </S.IconSet>
            )}
            {!!winterCharge_25min && (
              <S.IconSet>
                <AcUnit size={24} />
                <span>
                  <b>{winterCharge_25min} km</b> rekkevidde etter 25 min lading.
                </span>
              </S.IconSet>
            )}
          </S.IconWrapper>
        </S.ElementInfo>
      </CardList>
    </StyledLink>
  );
};

interface Props {
  carTest: articleChargeReferenceType | articleRangeReferenceType | ChargeTestType | RangeTestType;
}

export const RelatedTestCard = ({ carTest, carTest: { type } }: Props) => {
  if (type === 'articleChargeReferenceComponent' || type === 'nafnoArticleCharge') {
    return <RelatedChargeTestCard test={carTest as articleChargeReferenceType} />;
  }
  if (type === 'articleRangeReferenceComponent' || type === 'nafnoArticleRange') {
    return <RelatedRangeTestCard test={carTest as articleRangeReferenceType} />;
  }
  return null;
};
