import React from 'react';
import BlockImageCollage from './BlockImageCollage';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface ImageSerializerProps {
  value: { images: CloudinaryImageType[] };
}

export const ImagesBlock = ({ value: { images } }: ImageSerializerProps) => <BlockImageCollage images={images} />;

export const ImagesSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: ImageSerializerProps) => (
      <ArticleBlockContentGridCol>
        <ImagesBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: ImageSerializerProps) => <ImagesBlock value={value} />;
};
