import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Info } from '@styled-icons/material-outlined/Info';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import styled from 'styled-components';
import { EvGuideInfoBox } from '../../../../types/carModelType';
import BlockContent from '../../components/block-content/BlockContent';

const InfoBox = ({ title, text }: EvGuideInfoBox) => (
  <InfoBoxWrapper>
    <IconWrapper>
      <Info width="20" height="20" />
    </IconWrapper>
    <HeaderText variant={TextVariant.Header3}>{title}</HeaderText>
    <BlockContent value={text} />
  </InfoBoxWrapper>
);

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
  padding: ${spacing.space32};
  position: relative;

  margin-top: ${spacing.space48};

  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space24} ${spacing.space16};
  }

  @media (max-width: ${breakpoints.l}) {
    margin-top: ${spacing.space40};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -17px; /* half of this element (16) plus border (1) */

  display: flex;
  justify-content: center;
  align-items: center;
  height: ${spacing.space32};
  width: ${spacing.space32};

  color: ${({ theme }) => theme?.typography?.brandTextInverted || themeLight.typography.brandTextInverted};
  background-color: ${({ theme }) => theme?.background?.brandInverted || themeLight.background.brandInverted};
  border-radius: ${spacing.space4};
`;

const HeaderText = styled(Text)`
  margin: 0;
`;

export { InfoBox };
