export function capFirst(str?: string) {
  if (!str) {
    return str;
  }
  const returnedString = str.toLowerCase();
  return returnedString.toLowerCase()[0].toUpperCase() + returnedString.slice(1);
}

export const formatImageCaption = (caption: string): string[] => {
  const imageCaption =
    caption &&
    !caption
      .trim()
      .slice(-1)
      .match(/\.|!|:|\?/)
      ? `${caption}.`
      : caption;

  const captionStrings = imageCaption?.split(/:(.*)/s).slice(0, -1) || [];

  return captionStrings && captionStrings.length > 0 ? captionStrings : ['', imageCaption];
};
