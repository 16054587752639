import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/modules/nafLocation';
import useSelector from '../redux/typedHooks';
import { LocalDepartments } from '../../../types/nafLocations';

export const enum LocationSlugs {
  lokalavdeling = 'local-departments',
  mc = 'mc-clubs',
  nafCenters = 'naf-centers',
  practiceTracks = 'practice-tracks',
}

export const useGetNafLocations = (search: LocationSlugs, counties: string[], initialTake?: number) => {
  const dispatch = useDispatch();
  const {
    data: locations,
    totalCount,
    error,
    fetchState,
    previousTake,
  } = useSelector((state) => state.nafLocation.locations[search]);
  /*
   * Either set how many to fetch based on previous take (that already exist in redux cache),
   * or use initialTake (if provided) or default to 10
   */
  const [take, setTake] = useState(previousTake || initialTake || 10);
  useEffect(() => {
    dispatch(actions.getNafLocations.request({ search, take, counties: counties.length ? counties : undefined }));
  }, [counties, take, dispatch, search]);
  const showMore = () => {
    setTake(take + 10);
  };
  useEffect(() => {
    if (initialTake) setTake(initialTake);
  }, [initialTake]);

  const nafLocationsByCounty = [
    ...new Map((locations || []).map((county) => [county.county.slug, county])).values(),
  ] as LocalDepartments[];
  const filteredLocations = nafLocationsByCounty.filter(
    // filter out empty counties
    ({ nafLocations }) => nafLocations.length > 0,
  );
  const totalReturned = filteredLocations.reduce((acc, { nafLocations }) => acc + nafLocations.length, 0);
  const totalCountInSelectedCounties =
    counties.length === 0
      ? nafLocationsByCounty.reduce((acc, { county }) => acc + county.total, 0)
      : nafLocationsByCounty
          .filter(({ county }) => counties.some((selected) => selected === county.slug))
          .reduce((acc, { county }) => acc + county.total, 0);
  const isFiltering = totalCountInSelectedCounties !== totalCount;

  return {
    nafLocationsByCounty,
    filteredLocations,
    totalCount,
    totalReturned,
    showMore,
    fetchState,
    error,
    totalCountInSelectedCounties,
    isFiltering,
  };
};
