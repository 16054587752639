import loadable from '@loadable/component';

const DirectionsService = loadable(() => import('@react-google-maps/api'), {
  resolveComponent: (components) => components.DirectionsService,
});

const DirectionsRenderer = loadable(() => import('@react-google-maps/api'), {
  resolveComponent: (components) => components.DirectionsRenderer,
});

const Marker = loadable(() => import('@react-google-maps/api'), {
  resolveComponent: (components) => components.Marker,
});

const MarkerF = loadable(() => import('@react-google-maps/api'), {
  resolveComponent: (components) => components.MarkerF,
});

const MarkerClustererF = loadable(() => import('@react-google-maps/api'), {
  resolveComponent: (components) => components.MarkerClustererF,
});

const OverlayViewF = loadable(() => import('@react-google-maps/api'), {
  resolveComponent: (components) => components.OverlayViewF,
});

const GoogleMap = loadable(() => import('@react-google-maps/api'), {
  resolveComponent: (components) => components.GoogleMap,
});

const MapWrapper = loadable(() => import('../components/google-maps/MapWrapper'));

export {
  Marker,
  GoogleMap,
  MapWrapper,
  DirectionsService,
  DirectionsRenderer,
  MarkerF,
  MarkerClustererF,
  OverlayViewF,
};
