import { createAction, createReducer, Action } from 'typesafe-actions';
import { TypedEpic } from '../types';

// Actions
export enum Actions {
  OFFLINE_ENABLED = 'application/offline/ENABLED',
  OFFLINE_DISABLED = 'application/offline/DISABLED',
  OFFLINE_CACHE_ENABLE = 'application/offline/CACHE_ENABLE',
  OFFLINE_CACHE_ENABLED = 'application/offline/CACHE_ENABLED',
  OFFLINE_CACHE_CHECK = 'application/offline/CACHE_CHECK',
  OFFLINE_CACHE_UPDATED = 'application/offline/CACHE_UPDATED',
  OFFLINE_CACHE_DISABLE = 'application/offline/CACHE_DISABLE',
  OFFLINE_CACHE_DISABLED = 'application/offline/CACHE_DISABLED',
}

interface State {
  isEnabled: boolean;
  isUpdated: boolean;
  isOffline: boolean;
  lastError: Error | null;
}

export const initialState: State = {
  isEnabled: false, // Is service worker enabled?
  isUpdated: false, // Has assets been updated in the background and reload is necessary?
  isOffline: true,
  lastError: null,
};

export const actions = {
  setOffline: createAction(Actions.OFFLINE_ENABLED)(),
  setOnline: createAction(Actions.OFFLINE_DISABLED)(),
  cacheEnable: createAction(Actions.OFFLINE_CACHE_ENABLE)(),
  cacheEnabled: createAction(Actions.OFFLINE_CACHE_ENABLED)(),
  cacheCheck: createAction(Actions.OFFLINE_CACHE_CHECK)(),
  cacheUpdated: createAction(Actions.OFFLINE_CACHE_UPDATED)(),
  cacheDisable: createAction(Actions.OFFLINE_CACHE_DISABLE)(),
  cacheDisabled: createAction(Actions.OFFLINE_CACHE_DISABLED)(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.setOffline, (state: State = initialState) => ({ ...state, state: { isOffline: false } }))
  .handleAction(actions.setOnline, (state: State = initialState) => ({ ...state, state: { isOffline: false } }))
  .handleAction(actions.cacheEnabled, (state: State = initialState) => ({ ...state, state: { isEnabled: true } }))
  .handleAction(actions.cacheDisabled, (state: State = initialState) => ({ ...state, state: { isEnabled: false } }))
  .handleAction(actions.cacheUpdated, (state: State = initialState) => ({ ...state, state: { isUpdated: true } }));

export const epics: TypedEpic[] = [];
