import { format, isSameDay, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { getTimeOfDay } from './Dates';

const monthMap: Record<number, string> = {
  0: 'januar',
  1: 'februar',
  2: 'mars',
  3: 'april',
  4: 'mai',
  5: 'juni',
  6: 'juli',
  7: 'august',
  8: 'september',
  9: 'oktober',
  10: 'november',
  11: 'desember',
};

export const createNorwegianDateString = (date: Date): string => {
  const day = date.getDate();
  const month = monthMap[date.getMonth()];
  const year = date.getFullYear().toString();
  return `${day}. ${month} ${year}`;
};

export const createNorwegianDateNumber = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1);
  const year = date.getFullYear().toString().slice(-2);
  return `${day}.${month}.${year}`;
};

export const createEventDateString = (date: Date): string => format(date, `iiii dd.LLL`, { locale: nb });

export const createEventTimeString = (from: string, to: string): string => {
  const startDateISO = parseISO(from);
  const endDateISO = parseISO(to);
  const sameDay = isSameDay(startDateISO, endDateISO);
  return `${createEventDateString(startDateISO)} ${
    !sameDay ? `— ${createEventDateString(endDateISO)}` : `(kl. ${getTimeOfDay(from)} — ${getTimeOfDay(to)})`
  }`;
};
