import React, { useEffect, useMemo, useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import type { SanityBlock } from '@sanity/client';
import { produce } from 'immer';
import styled from 'styled-components';
import BlockContent from '../../../../BlockContent';
import { Questions } from './Questions';
import { QuestionStatus, QuestionType } from './types';
import { ArticleBlockContentGridCol } from '../ArticleBlockContentGridCol';

interface QuizSerializerProps {
  value: {
    questions?: QuestionType[];
    summary?: SanityBlock[];
  };
}

const QuizBlock = ({ value: { questions, summary } }: QuizSerializerProps) => {
  const [questionsMap, setQuestionsMap] = useState<
    Record<QuestionType['_key'], QuestionType & { isAnsweredCorrectly: QuestionStatus }>
  >({});

  useEffect(() => {
    const mapped =
      (questions &&
        questions.reduce(
          (acc: Record<QuestionType['_key'], QuestionType & { isAnsweredCorrectly: QuestionStatus }>, cur) => {
            acc[cur._key] = { ...cur, isAnsweredCorrectly: QuestionStatus.UNANSWERED };
            return acc;
          },
          {},
        )) ||
      {};
    setQuestionsMap(mapped);
  }, [questions]);

  const questionsWithStatus = useMemo(() => Object.values(questionsMap) || [], [questionsMap]);
  const isUserFinished = useMemo(
    () => !questionsWithStatus.some((e) => e.isAnsweredCorrectly === QuestionStatus.UNANSWERED),
    [questionsWithStatus],
  );
  const numberOfCorrectAnswers = useMemo(
    () =>
      questionsWithStatus.reduce((acc, cur) => {
        let newAcc = acc;
        if (cur.isAnsweredCorrectly === QuestionStatus.CORRECT) {
          newAcc = acc + 1;
        }
        return newAcc;
      }, 0),
    [questionsWithStatus],
  );

  const setStatus = (id: QuestionType['_key'], status: QuestionStatus) => {
    const nextQuestionsMap = produce(questionsMap, (draftQuestionsMap) => {
      // eslint-disable-next-line no-param-reassign
      draftQuestionsMap[id].isAnsweredCorrectly = status;
    });
    setQuestionsMap(nextQuestionsMap);
  };

  return (
    <Quiz>
      {questions && <Questions questions={questionsWithStatus} setStatus={setStatus} />}
      {isUserFinished && (
        <>
          <Text variant={TextVariant.Header3}>Oppsummering</Text>
          <Text variant={TextVariant.Header2}>
            {numberOfCorrectAnswers} av {questions?.length}
          </Text>
          <BlockContent value={summary} />
        </>
      )}
    </Quiz>
  );
};

export const QuizSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: QuizSerializerProps) => (
      <ArticleBlockContentGridCol>
        <QuizBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: QuizSerializerProps) => <QuizBlock value={value} />;
};

const Quiz = styled.div`
  margin-bottom: 100px;
`;
