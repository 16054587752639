import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useScrollBlock = () => {
  useIsomorphicLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'clip';
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.height = 'initial';
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
};

interface CustomBody extends HTMLBodyElement {
  dataset: DOMStringMap & {
    scrollY?: string;
    scrollWidth?: string;
  };
}

const saveScrollPosition = () => {
  const { scrollY } = window;
  const body = document.body as CustomBody;
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

  body.dataset.scrollY = scrollY.toString();
  body.dataset.scrollWidth = scrollBarWidth.toString();

  return { scrollY, scrollBarWidth };
};

export const disableScroll = () => {
  const { scrollBarWidth } = saveScrollPosition();
  const html = document.documentElement;

  html.style.overflow = 'hidden';
  html.style.width = `calc(100% - ${scrollBarWidth}px)`;
};

export const enableScroll = () => {
  const html = document.documentElement;

  html.style.overflow = '';
  html.style.width = '';
};
