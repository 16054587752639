import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import React from 'react';
import { SectionHeader } from '@naf/section-header';
import { Grid } from '@naf/grid';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import { SiteStructureType } from '../../../../types/siteStructureType';
import { Layout, MainContent } from '../../components/layout/Layout';
import FAQLink from './FAQLink';
import { FaqAppPropertiesType } from '../../../../types/CategoryAppType';
import { ThreeColumnBlock } from '../../components/SectionBlocks/blocks/ThreeColumnBlock';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import ErrorBoundary from '../../components/error-component/ErrorBoundary';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

type Props = {
  data: SiteStructureType;
  appData: {
    properties: FaqAppPropertiesType;
  };
};

const FAQ = ({ data, appData }: Props) => {
  const { threeColumnBlock, faq: faqList, warningBlockTimeControlledWithLink } = appData.properties;

  const history = useHistory();
  const internalLink = useDocumentUrlWithNode(
    warningBlockTimeControlledWithLink && warningBlockTimeControlledWithLink.internalLink,
  );
  return (
    <Layout
      title={data.seoConfig?.title || data.name}
      description={data.seoConfig?.introduction || data.ingress || ''}
      gtmArgs={{ page_type: 'Hjelp og kontakt', pageCategory: data.slug, contentId: data.name }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.CategoryApp }}
    >
      <StyledSectionHeader title="Hjelp og kontakt" />
      <ErrorBoundary>
        <MainContent>
          {warningBlockTimeControlledWithLink && (
            <StyledUserInteraction
              variant={UserInteractionVariant.Warning}
              maxWidth={554}
              buttonLink={{
                text: warningBlockTimeControlledWithLink.linkText,
                href: warningBlockTimeControlledWithLink.externalLink?.url,
                onClick: internalLink
                  ? () => {
                      history.push(internalLink);
                      return false;
                    }
                  : undefined,
              }}
            >
              {warningBlockTimeControlledWithLink.text}
            </StyledUserInteraction>
          )}
          {threeColumnBlock?.map((block) => (
            <StyledThreeColumnBlock block={block} key={block.title} />
          ))}
          <ContentHeader variant={TextVariant.Header2}>Hva trenger du hjelp med?</ContentHeader>
          <TopicWrapper>
            {faqList?.map((faq) => (
              <FAQLink key={faq.title} faq={faq} />
            ))}
          </TopicWrapper>
        </MainContent>
      </ErrorBoundary>
    </Layout>
  );
};

const ContentHeader = styled(Text)`
  margin-top: ${spacing.space64};
`;

const StyledUserInteraction = styled(UserInteraction)`
  margin-top: ${spacing.space64};
`;

const TopicWrapper = styled(Grid)`
  grid-gap: 0 ${spacing.space32};
  max-width: 1024px;
  justify-content: space-between;
  margin-bottom: ${spacing.space80};
`;

const StyledThreeColumnBlock = styled(ThreeColumnBlock)`
  & h2 {
    margin-top: ${spacing.space64};
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  h1 {
    padding-top: ${spacing.space160};

    @media (max-width: ${breakpoints.l}) {
      padding-top: ${spacing.space120};
    }
    @media (max-width: ${breakpoints.m}) {
      padding-top: 95px;
    }
  }
`;

export default FAQ;
