import { Boom } from '@hapi/boom';
import { produce } from 'immer';
import { ofType } from 'redux-observable';
import { Observable, OperatorFunction, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { TypedEpic } from '../types';
import { NafEvents } from '../../../../types/nafEvents';

export enum Actions {
  GET_NAF_EVENTS = 'naf/centers/GET_NAF_EVENTS',
  GET_NAF_EVENTS_SUCCESS = 'naf/centers/GET_NAF_EVENTS_SUCCESS',
  GET_NAF_EVENTS_FAIL = 'naf/centers/GET_NAF_EVENTS_FAIL',
  GET_NAF_EVENTS_CANCEL = 'naf/centers/GET_NAF_EVENTS_CANCEL',
}

type FetchState = 'initial' | 'isUpdating' | 'success' | 'error' | 'cancelled';

export interface State {
  monthlyEvents: NafEvents[];
  fetchState: FetchState;
  errorState?: Error;
  validation?: Validation;
  total?: number;
}

type Validation = {
  reason: string;
  message: string;
};

export const initialState: State = {
  monthlyEvents: [],
  fetchState: 'initial',
};

export const actions = {
  getNafEvents: createAsyncAction(
    Actions.GET_NAF_EVENTS, // request payload creator
    Actions.GET_NAF_EVENTS_SUCCESS, // success payload creator
    Actions.GET_NAF_EVENTS_FAIL, // failure payload creator
    Actions.GET_NAF_EVENTS_CANCEL, // optional cancel payload creator
  )<
    { organizer?: string; take: number; postalNumber?: string },
    { items: NafEvents[]; total: number; validation?: Validation },
    Error,
    undefined
  >(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getNafEvents.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.fetchState = 'isUpdating';
    }),
  )
  .handleAction(actions.getNafEvents.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.fetchState = 'success';
      draftState.monthlyEvents = action.payload.items;
      draftState.total = action.payload.total;
      draftState.errorState = undefined;
      draftState.validation = undefined;
    }),
  )
  .handleAction(actions.getNafEvents.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.fetchState = 'error';
      draftState.errorState = action.payload;
      if ((action.payload as any).message) {
        draftState.validation = action.payload as unknown as Validation;
      }
    }),
  )
  .handleAction(actions.getNafEvents.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.fetchState = 'cancelled';
    }),
  );

const fetchNafEventsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_NAF_EVENTS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getNafEvents.request>[]
    >,
    switchMap(([action]) => {
      const url = `${apimBaseUrl}/${apimNafNoApi}/naf-locations/events${
        action?.payload.organizer ? `/${action?.payload.organizer}` : ''
      }?take=${action.payload.take}${
        action.payload.postalNumber ? `&postalNumber=${action.payload.postalNumber}` : ''
      }`;
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      return ajax<{ items: NafEvents[]; total: number; validation: Validation }>({
        url,
        headers,
      }).pipe(
        map(({ response }) => {
          if (Array.isArray(response.items)) {
            return actions.getNafEvents.success({ items: response.items, total: response.total });
          }
          return actions.getNafEvents.failure(
            new Boom(
              'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
            ),
          );
        }),
        catchError((e) => {
          if (e.response.validation) {
            return of(actions.getNafEvents.failure(e.response.validation));
          }
          return of(
            actions.getNafEvents.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          );
        }),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchNafEventsEpic];
