import React from 'react';

export const SeatbeltIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 7C8 4.79 9.79 3 12 3C14.21 3 16 4.79 16 7C16 9.21 14.21 11 12 11C9.79 11 8 9.21 8 7Z" fill="#1A1A1A" />
    <path
      d="M12 13.0005C12.4847 13.0005 13.0572 13.052 13.6695 13.1549L9.71719 20.0005H4V17.6672C4 14.5638 9.33 13.0005 12 13.0005Z"
      fill="#1A1A1A"
    />
    <path d="M20 20.0005H12.0266L15.6906 13.6542C17.9092 14.3839 20 15.7235 20 17.6672V20.0005Z" fill="#1A1A1A" />
  </svg>
);

export default SeatbeltIcon;
