import React, { createContext, useMemo, useState } from 'react';
import { CustomerInformationType } from '@dtp/membership-service-types';
import { DowngradeContextProps, DowngradeContextProviderProps, NafMember } from './types';

export const DowngradeContext = createContext<DowngradeContextProps>(undefined);

export const DowngradeContextProvider = ({ children }: DowngradeContextProviderProps) => {
  const [email, setEmail] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [members, setMembers] = useState<NafMember[]>([]);
  const [householdMember, setHouseholdMember] = useState<CustomerInformationType>(null);
  const [unpaidInvoice, setHasUnpaidInvoice] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      email,
      endDate,
      members,
      setEmail,
      setEndDate,
      setMembers,
      householdMember,
      setHouseholdMember,
      unpaidInvoice,
      setHasUnpaidInvoice,
    }),
    [email, endDate, members, setEmail, setEndDate, setMembers, householdMember, setHouseholdMember, unpaidInvoice],
  );

  return <DowngradeContext.Provider value={value}>{children}</DowngradeContext.Provider>;
};
