import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { CreditCard } from '@styled-icons/material-outlined/CreditCard';
import { Luggage } from '@styled-icons/material-outlined/Luggage';
import { Bolt } from '@styled-icons/material/Bolt';
import { Autorenew } from '@styled-icons/material/Autorenew';
import { CarRepair } from '@styled-icons/material/CarRepair';
import { CarModelType, CarVariant } from '../../../../types/carModelType';
// Ikoner som ikke finnes i @styled-icons men som brukes i designsystemet
import Nyttelast from './VehicleDataIcons/nyttelast.svg'; // "Exercise" fra Material Symbols
import Rekkevidde from './VehicleDataIcons/rekkevidde.svg'; // "RoadVariant" fra Material Design Icons
import Tilhengervekt from './VehicleDataIcons/tilhengervekt.svg'; // "RvHookup" fra Material Rounded Outline

function stringifyValue(value: number | undefined, withPrefix: boolean) {
  if (value === undefined) {
    return undefined;
  }

  if (withPrefix) {
    if (value >= 1000000) {
      return `${(value / 1000000).toLocaleString('nb-NO')} M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toLocaleString('nb-NO')} k`;
    }
  }

  return value.toLocaleString('nb-NO');
}

type ValueObject = { valueArray?: number[]; unit: string; withPrefix?: boolean };

function getValueString({ valueArray, unit, withPrefix }: ValueObject) {
  const min = valueArray?.length ? Math.min(...valueArray) : undefined;
  const max = valueArray?.length ? Math.max(...valueArray) : undefined;

  const from = stringifyValue(min, withPrefix);
  const to = stringifyValue(max, withPrefix);

  if (from && to) {
    if (from === to) {
      return `${from} ${unit}`;
    }
    return `${from}\u2014${to} ${unit}`;
  }
  if (from) {
    return `Fra ${from} ${unit}`;
  }
  if (to) {
    return `Inntil ${to} ${unit}`;
  }

  return 'Ikke oppgitt';
}

function useVariantData(variants: CarVariant[]) {
  const [prices, setPrices] = useState<number[]>([]);
  const [consumption, setConsumption] = useState<number[]>([]);
  const [ranges, setRanges] = useState<number[]>([]);
  const [payloads, setPayloads] = useState<number[]>([]);
  const [hasTrailer, setHasTrailer] = useState(false);
  const [trailerPayloads, setTrailerPayloads] = useState<number[]>([]);
  const [cargospace, setCargospace] = useState<number[]>([]);
  const [driveTrain, setDriveTrain] = useState<string[]>([]);
  const [ownWeight, setOwnWeight] = useState<number[]>([]);

  useEffect(() => {
    // Inefficient to map 7 times but we're unlikely to have so many variants it matters in practice,
    // especially since variants is effectively constant.
    setPrices(variants.filter((variant) => variant.priceNOK).map((variant) => variant.priceNOK));
    setConsumption(
      variants
        .filter((variant) => variant?.electricVehicleDetails?.electricConsumptionWLTP_kWhper100km)
        .map((variant) => variant.electricVehicleDetails.electricConsumptionWLTP_kWhper100km),
    );
    setRanges(
      variants
        .filter((variant) => variant?.electricVehicleDetails?.rangeWLTP_km)
        .map((variant) => variant.electricVehicleDetails.rangeWLTP_km),
    );
    setPayloads(
      variants
        .filter((variant) => variant?.vehicleDimensions?.maxPayloadkg)
        .map((variant) => variant.vehicleDimensions.maxPayloadkg),
    );
    setHasTrailer(variants.findIndex((variant) => variant?.trailerDetails?.trailerHitch === 'Ja') !== -1); // This value is a string for some reason
    setTrailerPayloads(
      variants
        .filter((variant) => variant?.trailerDetails?.trailerMaximumWeight_kg)
        .map((variant) => variant.trailerDetails.trailerMaximumWeight_kg),
    );
    setCargospace(
      variants
        .filter((variant) => variant?.vehicleDimensions?.maxCargospace_l)
        .map((variant) => variant.vehicleDimensions.maxCargospace_l),
    );
    setDriveTrain([
      ...new Set(variants.filter((variant) => variant?.driveTrain?.type).map((variant) => variant.driveTrain.type)),
    ]);
    setOwnWeight(
      variants
        .filter((variant) => variant?.vehicleDimensions?.weight_kg)
        .map((variant) => variant.vehicleDimensions.weight_kg),
    );
  }, [variants]);

  return { prices, consumption, ranges, payloads, hasTrailer, trailerPayloads, cargospace, driveTrain, ownWeight };
}

type VehicleDataProps = {
  data: CarModelType;
  onClick?: (section: string) => void;
};

const VehicleData = ({ data, onClick }: VehicleDataProps) => {
  const { prices, consumption, ranges, payloads, hasTrailer, trailerPayloads, cargospace, driveTrain, ownWeight } =
    useVariantData(data.vehicleVariants);

  return (
    <DataList>
      <DataEntry
        icon={<CreditCard size={24} />}
        label="Startpris"
        value={{
          valueArray: prices,
          unit: 'kr',
          withPrefix: true,
        }}
      />
      <DataEntry
        onClick={() => onClick('rangeTest')}
        icon={<Rekkevidde />}
        label="Rekkevidde"
        value={{
          valueArray: ranges,
          unit: 'km',
        }}
      />
      <DataEntry
        icon={<Bolt size={24} />}
        label="Forbruk / 100&nbsp;km"
        value={{
          valueArray: consumption,
          unit: 'kWh',
        }}
      />
      <DataEntry
        icon={<Autorenew size={24} />}
        label="Drivhjul"
        value={driveTrain.length ? driveTrain.join(', ') : 'Ikke oppgitt'}
      />
      <DataEntry
        icon={<CarRepair size={24} />}
        label="Egenvekt uten fører"
        value={{ valueArray: ownWeight, unit: 'kg' }}
      />
      <DataEntry
        onClick={() => onClick('trunkTest')}
        icon={<Luggage size={24} />}
        label="Bagasjerom"
        value={{
          valueArray: cargospace,
          unit: 'l',
        }}
      />
      <DataEntry
        icon={<Nyttelast />}
        label="Nyttelast"
        value={{
          valueArray: payloads,
          unit: 'kg',
        }}
      />
      <DataEntry
        icon={<Tilhengervekt />}
        label="Tilhengervekt"
        value={
          hasTrailer
            ? {
                valueArray: trailerPayloads,
                unit: 'kg',
              }
            : 'Utilgjengelig'
        }
      />
    </DataList>
  );
};

type EntryProps = {
  icon: React.ReactElement;
  label: string;
  value: string | ValueObject;
  onClick?: () => void;
};

const DataEntry = ({ icon, label, value, onClick }: EntryProps) => {
  const valueString = typeof value === 'string' ? value : getValueString(value);

  return (
    <Entry onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
      <Label variant={TextVariant.ArticleText}>{label}</Label>
      <Value variant={TextVariant.BodyTextHeader}>{valueString}</Value>
    </Entry>
  );
};

const DataList = styled.div`
  display: flex;
  flex-flow: row wrap;

  @media (max-width: ${breakpoints.s}) {
    flex-flow: column nowrap;
  }

  --aside: clamp(240px, 25vw, 400px);
  @media (max-width: ${breakpoints.l}) {
    --aside: 0;
  }

  --content-plus-whitespace: calc(100vw - var(--aside));
  --max-width-with-whitespace: min(calc(936px + 2 * 96px), var(--content-plus-whitespace));
  /* Margins will always be a negative number */
  --total-margins: calc((736px - var(--max-width-with-whitespace)) / 2);

  width: calc(736px - var(--total-margins));
  margin-left: max(var(--total-margins) / 2, -96px);
  margin-right: max(var(--total-margins) / 2, -96px);
  margin-bottom: ${spacing.space48};

  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const Entry = styled.div<{ onClick?: () => void }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: ${spacing.space4};
  box-sizing: border-box;
  flex: 0 0 50%;

  ${({ onClick }) => onClick && 'cursor: pointer;'}

  @media (max-width: ${breakpoints.s}) {
    /* All elements except the first one*/
    & + & {
      border-top: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding: ${spacing.space16} 0;
    }

    /* The first element only */
    &:first-of-type {
      padding-bottom: ${spacing.space16};
    }

    /* The last element only */
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @media (min-width: calc(${breakpoints.s} + 1px)) {
    /* First and second elements (first row) */
    &:first-of-type,
    &:nth-of-type(2) {
      padding-bottom: ${spacing.space16};
    }

    /* All elements in the first/left row (css is 1-indexed) */
    &:nth-of-type(2n + 1) {
      padding-right: ${spacing.space16};
    }

    /* All elements in the second/right row */
    &:nth-of-type(2n) {
      border-left: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding-left: ${spacing.space16};
    }

    /* Every element except the first two/the first row */
    &:nth-of-type(n + 3) {
      border-top: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding-top: ${spacing.space16};
      padding-bottom: ${spacing.space16};
    }

    /* The last two elements, IF there's an even amount of elements (the table is balanced) */
    &:last-of-type:nth-of-type(2n),
    &:nth-last-of-type(2):nth-of-type(2n + 1) {
      padding-bottom: 0;
    }

    /* The last element, IF there's an odd amount of elements (the table is unbalanced) */
    &:last-of-type:nth-of-type(2n + 1) {
      border-right: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      padding-bottom: 0;
      box-sizing: content-box;
      flex-basis: calc(50% - ${spacing.space16});
    }

    /* The second to last element, IF theres an odd amount of elements (the table is unbalanced).
       In which case this element is on the row above the last one.
       box-shadow because it breaks out of the actual box of the element, and thus doesn't push
       down the next line (otherwise you'd get a 1px offset between this border and the one on
       top of the last element)
    */
    &:nth-last-of-type(2):nth-of-type(2n) {
      border-bottom: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
      margin-bottom: -1px;
    }
  }
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${spacing.space24};
  height: ${spacing.space24};

  color: ${({ theme }) => theme?.componentColors?.extras?.accent || themeLight.componentColors.extras.accent};
`;

const Label = styled(Text)`
  flex-grow: 1;
  display: block;
  margin: 0;

  font-size: 0.875rem;
  line-height: 1.5rem;
`;

const Value = styled(Text)`
  flex-shrink: 0;
  margin: 0;

  font-size: 0.875rem;
  line-height: 1.5rem;
`;

export { VehicleData };
