import { Category } from '../../../types/articleType';

export const createCategoryUrl = (cat: Category | undefined): string => {
  if (typeof cat === 'undefined' || !cat) return '';

  let parentCategory: Category | undefined = cat?.parent;
  let categoryUrl = `/${cat.slug}`;

  while (parentCategory) {
    if (parentCategory.slug === 'nafno' || !parentCategory) break;

    categoryUrl = `/${parentCategory.slug}${categoryUrl}`;
    parentCategory = parentCategory.parent;
  }
  return categoryUrl;
};

// Finds which top category object belongs to, just below naf.no node, and returns slug
export const findTopCategorySlug = (cat: Category | undefined): string => {
  if (typeof cat === 'undefined' || !cat) return '';

  let parentCategory: Category | undefined = cat?.parent;
  let categoryUrl = `${cat.slug}`;

  while (parentCategory) {
    if (parentCategory.slug === 'nafno' || !parentCategory) break;

    categoryUrl = `${parentCategory.slug}`;
    parentCategory = parentCategory.parent;
  }
  return categoryUrl;
};
