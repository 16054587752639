import React, { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import useSelector from '../../../../redux/typedHooks';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import { isInternalLink } from '../../../../lib/isInternalLink';

interface LinkProps {
  value?: { href: string; blank: boolean };
  children: ReactNode | ReactNode[] | string | string[];
}

export const LinkMarks = ({ value, children }: LinkProps) => {
  const { blank, href } = value;
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const hostname = (typeof window !== 'undefined' && window.location.hostname) || undefined;

  const handleTracking: MouseEventHandler<HTMLAnchorElement> = () => {
    dataLayer?.push({
      event: 'link_click',
      link_text: Array.isArray(children) ? children.join(' ') : children.toString(),
      link_location: location.pathname,
      link_url: `${hostname}${href}`,
      link_hostname: hostname,
    });
  };
  const handleBlankExternalTracking: MouseEventHandler<HTMLAnchorElement> = (e) => {
    const url = new URL(href);
    dataLayer?.push({
      event: 'link_click',
      link_text: Array.isArray(children) ? children.join(' ') : children.toString(),
      link_location: location.pathname,
      link_url: href,
      link_hostname: url.hostname,
    });
    if (e.currentTarget.href) {
      window.open(e.currentTarget.href);
    }
  };
  const handleExternalTracking: MouseEventHandler<HTMLAnchorElement> = (e) => {
    const url = new URL(href);
    e.preventDefault();
    dataLayer?.push({
      event: 'link_click',
      link_text: Array.isArray(children) ? children.join(' ') : children.toString(),
      link_location: location.pathname,
      link_url: href,
      link_hostname: url.hostname,
    });
    if (e.currentTarget.href) {
      window.location.href = e.currentTarget.href;
    }
  };

  if (href) {
    const { isInternal, relativeLink } = isInternalLink(baseUrl, href);
    if (isInternal) {
      return blank ? (
        <Link to={relativeLink} onClick={handleTracking} target="_blank" rel="noopener noreferrer">
          {children}
        </Link>
      ) : (
        <Link to={relativeLink} onClick={handleTracking}>
          {children}
        </Link>
      );
    }
  }
  return blank ? (
    <a href={href} onClick={handleBlankExternalTracking} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <a onClick={handleExternalTracking} href={href}>
      {children}
    </a>
  );
};
