import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import loadable from '@loadable/component';
import { PointOfInterest } from '../../../../SectionBlocks/blocks/Charts/RouteCharts';
import { Map } from '../../../../SectionBlocks/blocks/Charts/Map';
import { useElPrixRouteData } from '../../../../../hooks/useElPrixRouteData';

const GoogleMapsWrapper = loadable(() => import('../../../../SectionBlocks/blocks/Charts/GoogleMapsWrapper'), {
  resolveComponent: (components) => components.GoogleMapsWrapper,
});

type Props = {
  inArticle?: boolean;
  value: {
    _key: string;
    _type: 'routeMap';
  } & (CustomRouteMapData | TestRouteMapData);
};

type TestRouteMapData = {
  useRouteFromTest: true;
  elPrixTestReference: { routepoints: PointOfInterest[]; altitudeProfile: number[] };
};
type CustomRouteMapData = { useRouteFromTest: false; routepoints: PointOfInterest[]; altitudeProfile: number[] };

const RouteMapSerializer =
  () =>
  ({ value, inArticle }: Props) =>
    (
      <GoogleMapsWrapper>
        <RenderRouteMap value={value} inArticle={inArticle || false} />
      </GoogleMapsWrapper>
    );

const RenderRouteMap = ({ value, inArticle }: Props) => {
  const { routepoints, altitudeProfile } = value.useRouteFromTest === true ? value.elPrixTestReference : value;
  const { pointsOfInterest, directions } = useElPrixRouteData({ routepoints, elevation: altitudeProfile });

  return (
    <MapContainer inArticle={inArticle}>
      <Map
        animate
        mapOptions={{
          suppressMarkers: true,
          polylineOptions: { strokeColor: nafColor.primary.kart },
          animatedPathStroke: nafColor.primary.park,
        }}
        directions={directions}
        zoom={6}
        center={{ lat: 61.26921, lng: 10.40559 }}
        style={{ height: '100%' }}
        markers={pointsOfInterest
          .filter((d) => d.includeOnChart)
          .map((d, i) => ({
            title: d.title,
            position: d.latlng,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: nafColor.signature.white,
              strokeColor: nafColor.primary.park,
              fillOpacity: 1,
              strokeWeight: 2,
              scale: 5,
            },
            infoWindowContent: `
                    <div>
                      <b>${d.title}</b>
                      <p>${i !== 0 ? `${d.kmFromStart}km` : 'Start'}${d.altitude ? ` - ${d.altitude}moh.` : ''}</p>
                    </div>`,
          }))}
      />
    </MapContainer>
  );
};

const MapContainer = styled.div<{ inArticle: boolean }>`
  height: 440px;

  ${({ inArticle }) =>
    inArticle
      ? css``
      : css`
          width: 100%;
        `}

  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export { RouteMapSerializer };
