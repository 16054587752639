import { Action, ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';

// Actions
export enum Actions {
  GET_CAR_ARGUMENTS = 'naf/carFilter/GET_CAR_ARGUMENTS',
  GET_CAR_ARGUMENTS_SUCCESS = 'naf/carFilter/GET_CAR_ARGUMENTS_SUCCESS',
  GET_CAR_ARGUMENTS_FAIL = 'naf/carFilter/GET_CAR_ARGUMENTS_FAIL',
  GET_CAR_ARGUMENTS_CANCEL = 'naf/carFilter/GET_CAR_ARGUMENTS_CANCEL',
  SET_SCROLL_TO_LAST_CARS = 'naf/carFilter/SET_SCROLL_TO_LAST_CARS',
  SET_IS_UPDATING_LIST = 'naf/carFilter/SET_IS_UPDATING_LIST',
  PURGE_FILTER = 'naf/carFilter/PURGE_FILTER',
  REFRESH_SEARCH = 'naf/carFilter/REFRESH_SEARCH',
  SET_DID_REFRESH_SEARCH = 'naf/carFilter/SET_DID_REFRESH_SEARCH',
}

export interface State {
  allFilters: any;
  userFilters: {
    [key: string]: string[];
  };
  scrollToLastCars: boolean;
  sortBy?: string;
  isFetchingFilters: boolean;
  errorState: Error | null;
  vehicleIds: string[]; // * For elastic-search. See CarSearch.tsx
  filterNumber: number;
  filterFetchedMap: Record<number, boolean>;
  isUpdatingList: boolean;
}

export const initialState: State = {
  allFilters: [],
  userFilters: { fuelCategories: ['elektrisk'] },
  scrollToLastCars: false,
  isFetchingFilters: false,
  errorState: null,
  vehicleIds: [],
  filterNumber: 1,
  filterFetchedMap: { 1: false },
  isUpdatingList: false,
};
export const actions = {
  getCarArguments: createAsyncAction(
    Actions.GET_CAR_ARGUMENTS, // request payload creator
    Actions.GET_CAR_ARGUMENTS_SUCCESS, // success payload creator
    Actions.GET_CAR_ARGUMENTS_FAIL, // failure payload creator
    Actions.GET_CAR_ARGUMENTS_CANCEL, // optional cancel payload creator
  )<string, any, Error, undefined>(),
  setScrollToLastCars: createAction(Actions.SET_SCROLL_TO_LAST_CARS)<boolean>(),
  setIsUpdatingList: createAction(Actions.SET_IS_UPDATING_LIST)<boolean>(),
  refreshSearch: createAction(Actions.REFRESH_SEARCH)(),
  setDidRefreshSearch: createAction(Actions.SET_DID_REFRESH_SEARCH)(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getCarArguments.request, (state: State = initialState) =>
    produce(state, (draftState) => {
      draftState.isFetchingFilters = true;
    }),
  )
  .handleAction(actions.getCarArguments.success, (state: State = initialState, action) =>
    produce(state, (draftState) => {
      draftState.allFilters = action.payload;
      draftState.isFetchingFilters = false;
    }),
  )
  .handleAction(actions.getCarArguments.failure, (state: State = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isFetchingFilters = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getCarArguments.cancel, (state: State = initialState) =>
    produce(state, (draftState) => {
      draftState.isFetchingFilters = false;
    }),
  )
  .handleAction(actions.setScrollToLastCars, (state: State = initialState, action) =>
    produce(state, (draftState) => {
      draftState.scrollToLastCars = action.payload;
    }),
  )
  .handleAction(actions.refreshSearch, (state: State = initialState) =>
    produce(state, (draftState) => {
      draftState.filterNumber += 1;
      draftState.filterFetchedMap[draftState.filterNumber] = false;
    }),
  )
  .handleAction(actions.setDidRefreshSearch, (state: State = initialState) =>
    produce(state, (draftState) => {
      draftState.filterFetchedMap[state.filterNumber] = true;
    }),
  )
  .handleAction(actions.setIsUpdatingList, (state: State = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingList = action.payload;
    }),
  );

const fetchFiltersEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimVehicleDataApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CAR_ARGUMENTS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getCarArguments.request>[]
    >,
    switchMap(([action]) =>
      ajax({
        url: `${apimBaseUrl}/${apimVehicleDataApi}/modelsearcharguments?query=${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getCarArguments.success(response)),
        catchError(() => of(actions.getCarArguments.failure(new Boom.Boom('Could not fetch motortests')))),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [fetchFiltersEpic];
