import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Point } from 'highcharts';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { VehicleTestData } from '../../../../../types/showcaseBlockType';
import { VehicleCategory } from '../../../../../types/testType';
import { HighChartComponent } from '../../bilguide/chart/HighChartComponent';
import { Filter } from './Filter';
import { ElPrixContextData } from '../../../../../types/ElPrixContextData';

type Props = {
  data: VehicleTestData[];
  className?: any;
  categories?: VehicleCategory[];
  tests: ElPrixContextData['tests'];
  includeFilters?: boolean;
  initialCategories?: VehicleCategory[];
};

const Plot: React.FC<Props> = ({ data, className, categories, includeFilters, tests, initialCategories }) => {
  const [filteredVehicles, setFilteredVehicles] = useState(data);

  const options = useMemo(
    (): Highcharts.Options => ({
      chart: {
        type: 'bubble',
      },
      plotOptions: {
        bubble: {
          color: nafColor.primary.park,
        },
      },
      accessibility: {
        enabled: true,
        keyboardNavigation: {
          enabled: true,
          seriesNavigation: {
            mode: 'normal',
          },
        },
      },
      yAxis: {
        min: 0,
        gridLineDashStyle: 'Dash',
        crosshair: {
          color: nafColor.primary.park,
          dashStyle: 'Dash',
        },
        tickInterval: 20,
        title: {
          text: undefined,
        },
        labels: {
          formatter: ({ value, isLast }) => `${isLast ? 'EFFEKT' : value}`,
        },
        lineWidth: 1,
        lineColor: nafColor.neutral.neutral3,
      },
      xAxis: {
        min: 0,
        title: {
          text: undefined,
        },
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        lineWidth: 1,
        lineColor: nafColor.neutral.neutral3,
        max: Math.max(...data.map(({ testData }) => testData?.chargeTest?.measuredTimeTo80PercentMinutes || 0)) + 20,
        labels: {
          formatter: ({ value, isLast }) => `${isLast ? 'MIN' : value}`,
        },
        tickInterval: 5,
        crosshair: {
          color: nafColor.primary.park,
          dashStyle: 'Dash',
        },
      },
      title: undefined,
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        useHTML: true,
        shadow: false,
        borderWidth: 0,
        formatter: function format() {
          // eslint-disable-next-line react/no-this-in-sfc
          const { name, x, y, z } = this.point as Point & { z: number };
          return `<table><tr><th>${name}</th></tr>
          ${y ? `<tr><td>10—80%: ${x} min</td></tr>` : ''}
          ${x ? `<tr><td>Gj.snitt effekt: ${y} kW</td></tr>` : ''}
          ${z ? `<tr><td>Batteristørrelse: ${z} kWt</td></tr>` : ''}
          </table>
          `;
        },
        followPointer: true,
        padding: 0,
      },
      series: [
        {
          type: 'bubble',
          data: data
            .sort((a, b) => b.testData.chargeTest.batteryCapacity - a.testData.chargeTest.batteryCapacity)
            .map((d) => ({
              name: d.name,
              // Setting x to -100 removes it from the chart, while maintaining relative size of all the bubbles
              x: filteredVehicles.includes(d) ? d.testData.chargeTest.measuredTimeTo80PercentMinutes : -1000,
              y: d.testData.chargeTest.averageChargeEfficiency,
              z: d.testData.chargeTest.batteryCapacity,
            })),
        },
      ],
    }),
    [data, filteredVehicles],
  );

  return (
    <>
      {includeFilters && categories && (
        <Filter
          tests={tests}
          data={data}
          onlyChips
          categories={categories}
          initialChips={initialCategories}
          setData={setFilteredVehicles}
        />
      )}
      <HighChartComponent containerProps={{ className }} options={options} />
    </>
  );
};

export const BatteryChargeTime = styled(Plot)`
  .highcharts-point {
    fill: rgba(255, 255, 255, 0.5) !important;
    stroke-width: 1px;
    stroke: ${nafColor.primary.park} !important;
  }

  .highcharts-axis-labels {
    font-family: questa-sans;
    font-weight: 700;
    font-size: 0.875rem;
  }

  .highcharts-point-hover {
    fill: ${nafColor.primary.park} !important;
  }

  .highcharts-tooltip > span {
    background: white !important;
    border: 1px solid ${nafColor.neutral.neutral2};
    border-radius: 2px;
    padding: ${spacing.space8};
  }

  .highcharts-tooltip > span > table > tbody > tr > th {
    text-transform: uppercase;
    margin-bottom: ${spacing.space4};
  }
`;
