import React from 'react';

import { PointOfInterest } from '../../../../SectionBlocks/blocks/Charts/RouteCharts';
import { WeatherForecasts } from '../../../../SectionBlocks/blocks/Charts/WeatherForecasts';

type Props = {
  value: {
    _key: string;
    _type: 'weatherForecasts';
  } & (CustomRouteWeatherData | TestRouteWeatherData);
};

type TestRouteWeatherData = {
  useRouteFromTest: true;
  elPrixTestReference: { routepoints: PointOfInterest[]; testDate: string };
};

type CustomRouteWeatherData = { useRouteFromTest: false; routepoints: PointOfInterest[]; testDate: string };

const WeatherForecastsSerializer =
  () =>
  ({ value }: Props) =>
    (
      <WeatherForecasts
        inArticle
        date={value.useRouteFromTest === true ? value.elPrixTestReference.testDate : value.testDate}
        places={value.useRouteFromTest === true ? value.elPrixTestReference.routepoints : value.routepoints}
      />
    );

export { WeatherForecastsSerializer };
