import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Point } from 'highcharts';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { VehicleTestData } from '../../../../../types/showcaseBlockType';
import { VehicleCategory } from '../../../../../types/testType';
import { HighChartComponent } from '../../bilguide/chart/HighChartComponent';
import { Filter } from './Filter';
import { ElPrixContextData } from '../../../../../types/ElPrixContextData';
import { Legend } from './Legend';

type Props = {
  data: VehicleTestData[];
  className?: any;
  categories?: VehicleCategory[];
  tests: ElPrixContextData['tests'];
  includeFilters?: boolean;
};

const Plot = ({ data, className, categories, includeFilters, tests }: Props) => {
  const [filteredVehicles, setFilteredVehicles] = useState(data);

  const options = useMemo((): Highcharts.Options => {
    // Config
    const groupCount = filteredVehicles.length;
    const pointWidth = 18; // Width of each bar
    const marginTop = filteredVehicles.length > 3 ? 64 : 32;
    const marginBottom = 48;
    const groupPadding = 0.2;
    const pointPadding = 0.2;
    const chartHeight = marginTop + marginBottom + pointWidth * groupCount * (1 + groupPadding + pointPadding);

    return {
      chart: {
        type: 'dumbbell',
        height: chartHeight,
        inverted: true,
      },
      accessibility: {
        enabled: true,
        keyboardNavigation: {
          enabled: true,
          seriesNavigation: {
            mode: 'normal',
          },
        },
      },
      xAxis: {
        type: 'category',
        title: undefined,
        labels: {
          style: {
            fontSize: '0.75rem',
            fontWeight: '300',
          },
        },
        lineWidth: 1,
        lineColor: nafColor.neutral.neutral3,
      },
      yAxis: {
        min: Math.min(
          ...filteredVehicles.map(({ testData }) =>
            Math.min(
              testData?.rangeTest?.measuredConsumptionKWhper100km || Infinity,
              testData.rangeTest?.statedConsumptionKWhper100km || Infinity,
            ),
          ),
        ),
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        lineWidth: 1,
        max: Math.max(
          ...filteredVehicles.map(({ testData }) =>
            Math.max(
              testData?.rangeTest?.measuredConsumptionKWhper100km || 0,
              testData?.rangeTest?.statedConsumptionKWhper100km || 0,
            ),
          ),
        ),
        title: {
          text: undefined,
        },
        lineColor: nafColor.neutral.neutral3,

        labels: {
          align: 'center',
          useHTML: true,
          style: {
            fontSize: '0.75rem',
            fontWeight: '700',
          },
          formatter: ({ isLast, value }) => (isLast ? 'kWt/100 km' : `${value}`),
        },
        tickInterval: 2,
        floor: -1,
        ceiling: 30,
      },
      title: undefined,
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        useHTML: true,
        shadow: false,
        borderWidth: 0,
        formatter: function format() {
          // eslint-disable-next-line react/no-this-in-sfc, @typescript-eslint/no-shadow
          const { name, low, high, className } = this.point as Point & {
            className?: 'measuredIsSmaller' | 'measuredIsHigher';
          };

          return `<table><tr><th>${name}</th></tr>
          <tr><td>Forbruk kWt / 100km: <span style="font-weight:700; color:${
            className === 'measuredIsSmaller' ? nafColor.primary.park : nafColor.neutral.neutral4
          }">${(Math.round(((low as number) + Number.EPSILON) * 100) / 100).toFixed(
            1,
          )}</span> \u2014 <span style="font-weight:700; color:${
            className === 'measuredIsSmaller' ? nafColor.neutral.neutral4 : nafColor.information.errorDark
          }">${(Math.round(((high as number) + Number.EPSILON) * 100) / 100).toFixed(1)}</span></td></tr>
          </table>
          `;
        },
        followPointer: false,
        padding: 0,
      },
      series: [
        {
          type: 'dumbbell',
          data: filteredVehicles
            .sort(
              (a, b) =>
                a.testData.rangeTest.measuredConsumptionKWhper100km - b.testData.rangeTest.statedConsumptionKWhper100km,
            )
            .map((d) => {
              const { measuredConsumptionKWhper100km, statedConsumptionKWhper100km } = d.testData.rangeTest;
              const measuredIsSmaller = (measuredConsumptionKWhper100km || 0) < (statedConsumptionKWhper100km || 0);
              if (measuredIsSmaller) {
                return {
                  name: d.name,
                  low: measuredConsumptionKWhper100km,
                  high: statedConsumptionKWhper100km,
                  lowColor: nafColor.primary.park,
                  className: 'measuredIsSmaller',
                  marker: {
                    fillColor: nafColor.neutral.neutral3,
                  },
                  connectorColor: nafColor.neutral.neutral3,
                };
              }
              return {
                name: d.name,
                low: statedConsumptionKWhper100km,
                high: measuredConsumptionKWhper100km,
                className: 'measuredIsHigher',
                marker: {
                  fillColor: nafColor.information.errorDark,
                },
                connectorColor: nafColor.neutral.neutral3,
                lowColor: nafColor.neutral.neutral3,
              };
            }),
        },
      ],
    };
  }, [filteredVehicles]);

  return (
    <>
      {includeFilters && categories && (
        <Filter tests={tests} data={data} onlyChips categories={categories} setData={setFilteredVehicles} />
      )}
      <Legend
        items={[
          {
            label: 'Oppgitt forbruk',
            key: 'statedConsumptionKWhper100km',
            color1: nafColor.neutral.neutral3,
            shape: 'circle',
          },
          {
            label: 'Målt forbruk',
            key: 'measuredConsumptionKWhper100km',
            shape: 'circle',
            split: true,
            color1: nafColor.information.successDark,
            color2: nafColor.information.errorDark,
          },
        ]}
      />
      <HighChartComponent containerProps={{ className }} options={options} />
    </>
  );
};

export const Dumbbell = styled(Plot)`
  .highcharts-tooltip > * {
    font-family: questa-sans;
  }

  .highcharts-axis-labels > span {
    width: auto !important;
    overflow: visible;
  }

  .highcharts-axis-labels {
    font-family: questa-sans;
  }

  .highcharts-tooltip > span {
    background: white !important;
    border: 1px solid ${nafColor.neutral.neutral2};
    border-radius: 2px;
    padding: ${spacing.space8};
  }

  .highcharts-tooltip > span > table > tbody > tr > th {
    text-transform: uppercase;
    margin-bottom: ${spacing.space4};
  }
`;
