import React from 'react';

export const MotorText = () => (
  <svg width="40" height="10" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.55542 0.921631H5.25078C5.06695 2.99622 4.88346 5.06679 4.70033 7.13334C4.78788 7.16952 4.82667 7.13859 4.84817 7.0437C5.23471 5.32839 5.62282 3.61325 6.0125 1.89829C6.0859 1.57431 6.16034 1.25085 6.2353 0.922155H9.90812C9.56737 3.651 9.22696 6.37704 8.88691 9.10029H6.70922C6.97868 7.21775 7.24761 5.3394 7.51759 3.45423H7.41274C6.92835 5.3352 6.44377 7.21722 5.95903 9.10029H2.92106C3.03325 7.2167 3.14544 5.33782 3.25815 3.45213H3.1533C2.82582 5.33415 2.498 7.2167 2.16983 9.09977H0C0.520045 6.37023 1.03852 3.64418 1.55542 0.921631Z"
      fill="black"
    />
    <path
      d="M38.4314 5.79073C38.7496 5.97946 38.941 6.24892 38.9981 6.59911C39.0899 7.16057 39.1674 7.72465 39.2513 8.28769C39.2849 8.51416 39.3195 8.74063 39.353 8.9692C39.3593 9.01166 39.3619 9.05517 39.3672 9.10393H36.3145C36.3009 8.90786 36.2867 8.71389 36.2747 8.5194C36.2411 8.01438 36.2086 7.50849 36.1772 7.00173C36.1604 6.73122 36.0482 6.6227 35.7793 6.62113C35.7017 6.62113 35.622 6.62113 35.5366 6.62113C35.4065 7.4489 35.2776 8.27248 35.1476 9.10078H32.3555C32.3874 8.89685 32.4184 8.6945 32.4504 8.49266C32.6335 7.34668 32.8168 6.20086 33.0003 5.05523C33.203 3.78901 33.406 2.5228 33.6095 1.25659C33.6441 1.0427 33.6472 1.04689 33.8679 1.00128C34.926 0.792345 36.0047 0.706499 37.0825 0.745454C37.7394 0.767997 38.3931 0.83772 38.9997 1.11242C39.5239 1.35095 39.8773 1.73155 39.9753 2.31817C40.0277 2.63691 39.9879 2.95145 39.9365 3.266C39.8851 3.58054 39.8463 3.88827 39.786 4.196C39.6393 4.94723 39.2047 5.46623 38.4964 5.75823L38.4314 5.79073ZM35.8626 4.63741C36.0645 4.63741 36.2542 4.65366 36.4393 4.63374C36.7538 4.60019 36.9342 4.44449 36.9882 4.1572C37.0479 3.83847 37.093 3.51606 37.1339 3.19418C37.1622 2.96613 37.0558 2.81201 36.8283 2.78579C36.6333 2.76378 36.4325 2.79051 36.2338 2.79628C36.1672 2.79628 36.1426 2.83298 36.1331 2.90113C36.0954 3.16796 36.0493 3.43323 36.0073 3.69954C35.9586 4.0078 35.9114 4.31762 35.8626 4.63636V4.63741Z"
      fill="black"
    />
    <path
      d="M13.3899 9.28638C12.8431 9.28167 12.2078 9.20041 11.6075 8.93881C10.5941 8.49583 10.061 7.65233 10.1412 6.55248C10.1753 6.08957 10.2686 5.63086 10.3409 5.17111C10.4369 4.56142 10.5202 3.9491 10.6419 3.34466C10.8751 2.18556 11.5855 1.42279 12.6785 1.00498C13.36 0.742856 14.0667 0.684141 14.787 0.725556C15.3343 0.75701 15.8675 0.85714 16.3665 1.09252C17.1529 1.46316 17.6425 2.06079 17.7589 2.93522C17.8114 3.31635 17.7589 3.69432 17.6992 4.07125C17.566 4.88435 17.437 5.69639 17.3065 6.51159C17.1146 7.68745 16.4499 8.49059 15.3595 8.9493C14.7634 9.20093 14.1354 9.28743 13.3899 9.28638ZM13.6164 7.07724C14.0798 7.07724 14.3776 6.83085 14.4552 6.3669C14.56 5.72208 14.6649 5.07674 14.7639 4.43088C14.8164 4.10638 14.8688 3.78187 14.9039 3.45527C14.9312 3.19839 14.8122 3.04007 14.5778 2.97087C14.0106 2.80364 13.5467 3.09879 13.456 3.68122C13.38 4.16981 13.2987 4.65788 13.219 5.14909C13.1456 5.61199 13.067 6.07437 13.0093 6.53937C12.9716 6.83714 13.1283 7.01957 13.4229 7.06361C13.4827 7.07042 13.5461 7.07147 13.6164 7.07724Z"
      fill="black"
    />
    <path
      d="M27.8986 9.28636C27.322 9.28217 26.6866 9.20091 26.0848 8.94037C25.0667 8.49948 24.5335 7.65021 24.6169 6.54564C24.6515 6.08274 24.7443 5.62403 24.8166 5.16427C24.9126 4.55458 24.9964 3.94279 25.1186 3.33834C25.3519 2.18502 26.0622 1.42382 27.1495 1.00653C27.831 0.744405 28.5377 0.684118 29.258 0.725533C29.8053 0.756987 30.3384 0.856068 30.838 1.0925C31.6244 1.46261 32.1161 2.05815 32.2336 2.93258C32.286 3.3137 32.2372 3.69168 32.1754 4.06861C32.0422 4.8817 31.9133 5.69375 31.7827 6.50894C31.5908 7.68901 30.9245 8.49476 29.8289 8.95347C29.2349 9.20091 28.6105 9.28636 27.8986 9.28636ZM28.0905 7.07722C28.5566 7.07722 28.8527 6.8303 28.9293 6.36635C29.0341 5.72154 29.139 5.0762 29.2381 4.43033C29.2905 4.10583 29.3429 3.78132 29.378 3.45472C29.4053 3.19785 29.2858 3.03953 29.0514 2.97085C28.4842 2.80414 28.0208 3.09981 27.9301 3.68172C27.8541 4.17031 27.7728 4.65838 27.6931 5.14959C27.6197 5.61249 27.5411 6.07487 27.4834 6.53987C27.4457 6.83712 27.603 7.02008 27.8976 7.06411C27.9584 7.0704 28.0218 7.07145 28.0905 7.07722Z"
      fill="black"
    />
    <path
      d="M18.6778 0.920044H25.0284L24.6751 3.13024H22.9073L21.9637 9.10185H19.1569C19.4715 7.11341 19.786 5.13231 20.1006 3.13915H18.3213C18.4419 2.39106 18.5624 1.65608 18.6778 0.920044Z"
      fill="black"
    />
  </svg>
);
