import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@naf/table';
import styled, { css } from 'styled-components';
import { Accordion } from '@naf/accordion';
import { breakpoints, spacing } from '@naf/theme';
import { Link } from 'react-router-dom';
import { ChargeTestType, RangeTestType } from '../../../../types/testType';
import { createDateWithPadding } from '../../hooks/useDateWithPadding';

interface Props {
  type: 'chargeTests' | 'rangeTests';
  tests: ChargeTestType[] | RangeTestType[];
  selectedTableFields: (keyof typeof chargeTestFields)[] | (keyof typeof rangeTestFields)[];
  isResponsive?: boolean;
}

const chargeTestFields: Record<
  string,
  { title: string; format?: 'minutes' | 'percentage' | 'date' | 'km' | 'string' }
> = {
  connectionType: { title: 'Ladetype' },
  summerTestDate: { title: 'Testdato sommer', format: 'date' },
  summerChargeTime_Minutes: { title: 'Ladetid sommer', format: 'minutes' },
  summerStart_Percent: { title: 'Startprosent sommer', format: 'percentage' },
  summerEnd_Percent: { title: 'Sluttprosent sommer', format: 'percentage' },
  summerCharge_5min: { title: 'Km ved lading i 5 minutter (sommer)', format: 'km' },
  summerCharge_10min: { title: 'Km ved lading i 10 minutter (sommer)', format: 'km' },
  summerCharge_15min: { title: 'Km ved lading i 15 minutter (sommer)', format: 'km' },
  summerCharge_20min: { title: 'Km ved lading i 20 minutter (sommer)', format: 'km' },
  summerCharge_25min: { title: 'Km ved lading i 25 minutter (sommer)', format: 'km' },
  winterTestDate: { title: 'Testdato vinter', format: 'date' },
  winterChargeTime_Minutes: { title: 'Ladetid vinter', format: 'minutes' },
  winterStart_Percent: { title: 'Startprosent vinter', format: 'percentage' },
  winterEnd_Percent: { title: 'Sluttprosent vinter', format: 'percentage' },
  winterCharge_5min: { title: 'Km ved lading i 5 minutter (vinter)', format: 'km' },
  winterCharge_10min: { title: 'Km ved lading i 10 minutter (vinter)', format: 'km' },
  winterCharge_15min: { title: 'Km ved lading i 15 minutter (vinter)', format: 'km' },
  winterCharge_20min: { title: 'Km ved lading i 20 minutter (vinter)', format: 'km' },
  winterCharge_25min: { title: 'Km ved lading i 25 minutter (vinter)', format: 'km' },
  statedTimeTo80Percent_Minutes: { title: 'Oppgitt ladetid til 80% oppladet batteri', format: 'minutes' },
  timeTo200KmChargedWinter: { title: 'Ladetid til 200 km oppladet batteri (vinter)', format: 'minutes' },
  timeTo200KmChargedSummer: { title: 'Ladetid til 200 km oppladet batteri (sommer)', format: 'minutes' },
};

const rangeTestFields: Record<string, { title: string; format?: 'km' | 'kwhPer100km' | 'string' | 'date' | 'm' }> = {
  statedRange_km: { title: 'Oppgitt rekkevidde vinter', format: 'km' },
  statedConsumption_kWhper100km: {
    title: 'Oppgitt forbruk vinter',
    format: 'kwhPer100km',
  },
  statedRangeSummer_km: { title: 'Oppgitt rekkevidde sommer', format: 'km' },
  statedConsumptionSummer_kWhper100km: {
    title: 'Oppgitt forbruk sommer',
    format: 'kwhPer100km',
  },
  summerTestDate: { title: 'Dato sommertest', format: 'date' },
  summer_km: { title: 'Målt rekkevidde sommer', format: 'km' },
  summerConsumption_kWh: { title: 'Målt forbruk sommer', format: 'kwhPer100km' },
  winterTestDate: { title: 'Dato vintertest', format: 'date' },
  winter_km: { title: 'Målt rekkevidde vinter', format: 'km' },
  winterConsumption_kWh: { title: 'Målt forbruk vinter', format: 'kwhPer100km' },
  altitudeAtStopWinter_meters: { title: 'Høydemeter ved stans vinter', format: 'm' },
  altitudeAtStopSummer_meters: { title: 'Høydemeter ved stans sommer', format: 'm' },
};

export const TestTable = ({ tests, selectedTableFields, type, isResponsive }: Props) => (
  <>
    <ExpandedTable shouldCollapse={isResponsive}>
      <TableBody>
        <TableRow>
          <TableCell as="th" align="left">
            Bilmodell
          </TableCell>
          {type === 'chargeTests' &&
            selectedTableFields &&
            !!selectedTableFields &&
            selectedTableFields.map((selectedTableField: string) => (
              <TableCell as="th" align="right" key={selectedTableField}>
                {chargeTestFields[selectedTableField].title}
              </TableCell>
            ))}
          {type === 'rangeTests' &&
            selectedTableFields &&
            !!selectedTableFields &&
            selectedTableFields.map((selectedTableField: string) => (
              <TableCell as="th" align="right" key={selectedTableField}>
                {rangeTestFields[selectedTableField].title}
              </TableCell>
            ))}
        </TableRow>
        {type === 'chargeTests' &&
          tests &&
          tests.map((test) => {
            const testUrl =
              (test.vehicle && test.vehicle.slug && `/bilguiden/bilmodell/${test.vehicle.slug}/${test.slug}`) || `#`;
            return (
              <TableRow key={test.id}>
                <TableCell>
                  <Link
                    to={testUrl}
                  >{`${test.vehicle?.brandName} ${test.vehicle?.modelName} ${test.vehicle?.vehicleName}`}</Link>
                </TableCell>
                {selectedTableFields &&
                  selectedTableFields.map((selectedTableField: keyof typeof chargeTestFields) => {
                    const value =
                      (!!(test as ChargeTestType)[selectedTableField as keyof ChargeTestType] &&
                        (test as ChargeTestType)[selectedTableField as keyof ChargeTestType]) ||
                      undefined;
                    return (
                      <TableCell
                        key={`chargetest-${test.vehicle?.vehicleName}-${test.vehicle?.modelId}-${selectedTableField}`}
                        align="right"
                      >
                        {value &&
                          chargeTestFields[selectedTableField].format === 'date' &&
                          createDateWithPadding(value as string | undefined)}
                        {value && chargeTestFields[selectedTableField].format === 'percentage' && `${value} %`}
                        {value && chargeTestFields[selectedTableField].format === 'minutes' && `${value} minutter`}
                        {value && chargeTestFields[selectedTableField].format === 'km' && `${value} km`}
                        {value && chargeTestFields[selectedTableField].format === 'string' && `${value}`}
                        {value && chargeTestFields[selectedTableField].format === undefined && `${value}`}
                      </TableCell>
                    );
                  })}
              </TableRow>
            );
          })}
        {type === 'rangeTests' &&
          tests &&
          tests.map((test) => {
            const testUrl =
              (test.vehicle && test.vehicle.slug && `/bilguiden/bilmodell/${test.vehicle.slug}/${test.slug}`) || `#`;
            return (
              <TableRow key={test.id}>
                <TableCell align="left">
                  <Link
                    to={testUrl}
                  >{`${test.vehicle?.brandName} ${test.vehicle?.modelName} ${test.vehicle?.vehicleName}`}</Link>
                </TableCell>
                {selectedTableFields &&
                  !!selectedTableFields &&
                  selectedTableFields.map((selectedTableField) => {
                    const value =
                      (!!(test as RangeTestType)[selectedTableField as keyof RangeTestType] &&
                        (test as RangeTestType)[selectedTableField as keyof RangeTestType]) ||
                      undefined;
                    return (
                      <TableCell
                        key={`rangetest-${test.vehicle?.vehicleName}-${test.vehicle?.modelId}-${selectedTableField}`}
                        align="right"
                      >
                        {value &&
                          rangeTestFields[selectedTableField].format === 'date' &&
                          createDateWithPadding(value as string | undefined)}
                        {value && rangeTestFields[selectedTableField].format === 'km' && `${value} km`}
                        {value && rangeTestFields[selectedTableField].format === 'kwhPer100km' && `${value} kWh/100km`}
                        {value && rangeTestFields[selectedTableField].format === 'm' && `${value} m`}
                        {value && rangeTestFields[selectedTableField].format === 'string' && `${value}`}
                        {value && rangeTestFields[selectedTableField].format === undefined && `${value}`}
                      </TableCell>
                    );
                  })}
              </TableRow>
            );
          })}
      </TableBody>
    </ExpandedTable>
    <CollapsedTableWrapper shouldCollapse={isResponsive}>
      {type === 'chargeTests' &&
        tests.map((test) => {
          const testUrl =
            (test.vehicle && test.vehicle.slug && `/bilguiden/bilmodell/${test.vehicle.slug}/${test.slug}`) || `#`;
          return (
            <Accordion
              shouldCollapse={isResponsive}
              key={`collapsed-chargetest-${test.slug}`}
              label={`${test.vehicle?.brandName} ${test.vehicle?.modelName}`}
            >
              <Table>
                {selectedTableFields &&
                  !!selectedTableFields &&
                  selectedTableFields.map((selectedTableField) => {
                    const value =
                      (!!(test as ChargeTestType)[selectedTableField as keyof ChargeTestType] &&
                        (test as ChargeTestType)[selectedTableField as keyof ChargeTestType]) ||
                      undefined;
                    return (
                      <TableRow>
                        <TableCell as="th" align="left">
                          {chargeTestFields[selectedTableField].title}
                        </TableCell>
                        <TableCell align="right">
                          {value &&
                            chargeTestFields[selectedTableField].format === 'date' &&
                            createDateWithPadding(value as string | undefined)}
                          {value && chargeTestFields[selectedTableField].format === 'percentage' && `${value} %`}
                          {value && chargeTestFields[selectedTableField].format === 'minutes' && `${value} minutter`}
                          {value && chargeTestFields[selectedTableField].format === 'km' && `${value} km`}
                          {value && chargeTestFields[selectedTableField].format === 'string' && `${value}`}
                          {value && chargeTestFields[selectedTableField].format === undefined && `${value}`}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <Link to={testUrl}>Gå til testen</Link>
              </Table>
            </Accordion>
          );
        })}
      {type === 'rangeTests' &&
        tests.map((test) => {
          const testUrl =
            (test.vehicle && test.vehicle.slug && `/bilguiden/bilmodell/${test.vehicle.slug}/${test.slug}`) || `#`;
          return (
            <Accordion
              shouldCollapse={isResponsive}
              key={`collapsed-rangetest-${test.slug}`}
              label={`${test.vehicle?.brandName} ${test.vehicle?.modelName}`}
            >
              <Table>
                {selectedTableFields.map((selectedTableField) => {
                  const value =
                    (!!(test as RangeTestType)[selectedTableField as keyof RangeTestType] &&
                      (test as RangeTestType)[selectedTableField as keyof RangeTestType]) ||
                    undefined;
                  return (
                    <TableRow>
                      <TableCell as="th" align="left">
                        {rangeTestFields[selectedTableField].title}
                      </TableCell>
                      <TableCell align="right">
                        {value &&
                          rangeTestFields[selectedTableField].format === 'date' &&
                          createDateWithPadding(value as string | undefined)}
                        {value && rangeTestFields[selectedTableField].format === 'km' && `${value} km`}
                        {value && rangeTestFields[selectedTableField].format === 'kwhPer100km' && `${value} kWh/100km`}
                        {value && rangeTestFields[selectedTableField].format === 'string' && `${value}`}
                        {value && rangeTestFields[selectedTableField].format === undefined && `${value}`}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <Link to={testUrl}>Gå til testen</Link>
              </Table>
            </Accordion>
          );
        })}
    </CollapsedTableWrapper>
  </>
);

const ExpandedTable = styled(Table)<{ shouldCollapse?: boolean }>`
  display: block;
  margin-bottom: ${spacing.space64};
  ${(props) =>
    props.shouldCollapse &&
    css`
      @media (max-width: ${breakpoints.l}) {
        display: none;
        margin-bottom: 0;
      }
    `}
`;

const CollapsedTableWrapper = styled.div<{ shouldCollapse?: boolean }>`
  display: none;
  margin-bottom: 0;
  ${(props) =>
    props.shouldCollapse &&
    css`
      @media (max-width: ${breakpoints.l}) {
        display: block;
        margin-bottom: ${spacing.space64};
      }
    `}
`;
