import React, { ReactNode } from 'react';

interface Props {
  value?: {
    phoneNumber: string;
  };
  children: ReactNode;
}

export const PhoneNumberLinkSerializer = ({ value: { phoneNumber }, children }: Props) => (
  <a href={`tel:${phoneNumber}`}>{children}</a>
);
