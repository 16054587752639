import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Text, TextVariant } from '@naf/text';
import React from 'react';
import styled from 'styled-components';
import { Forecast as ForecastType, WeatherType } from '../../../../../../types/routeBlockType';
import { ClearDay } from '../../../icons/ClearDay';
import { ClearNight } from '../../../icons/ClearNight';
import { PartiallyCloudyDay } from '../../../icons/PartiallyCloudyDay';
import { PartiallyCloudyNight } from '../../../icons/PartiallyCloudyNight';
import { Rain } from '../../../icons/Rain';
import { Snow } from '../../../icons/Snow';
import { Overcast } from '../../../icons/Overcast';
import { PointOfInterest } from './RouteCharts';

interface Props {
  points: PointOfInterest[];
}

export const Forecasts: React.FC<Props> = ({ points }) => (
  <Container>
    {points
      .filter((point) => point.includeOnChart && point.forecastHighest && point.forecastLowest)
      .map((point, i) => (
        <Forecast key={point.title} point={point} index={i} />
      ))}
  </Container>
);

const Forecast: React.FC<{ point: PointOfInterest; index: number }> = ({ point, index }) => {
  const { title, forecastHighest, forecastLowest, altitude, kmFromStart } = point;

  const subheader =
    (!!kmFromStart && index === 0 ? 'Start' : '') +
    (!!kmFromStart && index !== 0 ? `${kmFromStart} km` : '') +
    (!!kmFromStart && altitude !== undefined ? ' - ' : '') +
    (altitude !== undefined ? `${altitude} moh.` : '');

  return (
    <StyledForecast>
      <Top>
        <Text variant={TextVariant.DataLabel}>{title}</Text>
        <SubtleText variant={TextVariant.Tiny}>{subheader}</SubtleText>
      </Top>
      <WeatherWrapper>
        <Weather forecast={forecastHighest} label="Høyeste" />
        <Weather forecast={forecastLowest} label="Laveste" />
      </WeatherWrapper>
    </StyledForecast>
  );
};

const Weather: React.FC<{ forecast?: ForecastType; label: string }> = ({ forecast, label }) => (
  <WeatherItem>
    <SubtleText variant={TextVariant.Tiny}>{label}</SubtleText>
    <WeatherDataContainer>
      <WeatherIcon weather={forecast?.weather} />
      <Text variant={TextVariant.DataLabel}>{forecast?.temperature}°C</Text>
    </WeatherDataContainer>
  </WeatherItem>
);

const WeatherIcon: React.FC<{ weather?: WeatherType }> = ({ weather }) => {
  if (!weather) return null;
  switch (weather) {
    case 'clearsky_day':
      return <ClearDay />;
    case 'clearsky_night':
      return <ClearNight />;
    case 'cloudy_day':
      return <PartiallyCloudyDay />;
    case 'cloudy_night':
      return <PartiallyCloudyNight />;
    case 'downpour_rain':
      return <Rain />;
    case 'downpour_snow':
      return <Snow />;
    case 'overcast':
      return <Overcast />;
    default:
      return null;
  }
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.space8};

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    width: calc(100vw - 48px);
  }
`;

const StyledForecast = styled.div`
  border: 1px solid ${nafColor.neutral.neutral2};
  border-radius: 4px;

  display: flex;
  flex-flow: column nowrap;

  @media (min-width: calc(${breakpoints.m} + 1px)) {
    width: 176px;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-flow: row nowrap;
  }
`;

const Top = styled.div`
  padding: ${spacing.space8} ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    flex-grow: 1;
  }

  p {
    margin-bottom: ${spacing.space4};
  }
`;

const WeatherWrapper = styled.div`
  display: flex;
  text-align: center;

  @media (min-width: calc(${breakpoints.m} + 1px)) {
    border-top: 1px solid ${nafColor.neutral.neutral2};
  }

  @media (max-width: ${breakpoints.m}) {
    border-left: 1px solid ${nafColor.neutral.neutral2};
  }
`;

const WeatherDataContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  margin-top: ${spacing.space4};

  @media (max-width: ${breakpoints.m}) {
    flex-flow: row nowrap;
    column-gap: ${spacing.space4};
    width: 100%;
  }
`;

const WeatherItem = styled.div`
  padding: ${spacing.space8} ${spacing.space24};

  & + & {
    border-left: 1px solid ${nafColor.neutral.neutral2};
  }

  @media (max-width: ${breakpoints.m}) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    padding: ${spacing.space8} 0;
    width: 88px;
  }

  p {
    margin: 0;
  }
`;

const SubtleText = styled(Text)`
  color: ${nafColor.neutral.neutral4};
  margin: 0;
`;
