export const isInternalLink = (
  baseUrl: string,
  link?: string | null,
): { isInternal: true; relativeLink: string } | { isInternal: false; relativeLink: undefined } => {
  if (!link) {
    return { isInternal: false, relativeLink: undefined };
  }
  const isAbsolute = /^(?:[a-z+]+:)?\/\//i;
  if (!isAbsolute.test(link)) {
    // Must be relative
    return {
      isInternal: true,
      relativeLink: link.startsWith('/') ? link : `/${link}`,
    };
  }
  // Check if the absolute url is valid and points to the current hostname
  const baseUrlObject = new URL(baseUrl);
  let hrefUrlObject;
  try {
    hrefUrlObject = link.startsWith('//') ? new URL(`https:${link}`) : new URL(link);
    const isInternal = baseUrlObject && hrefUrlObject && hrefUrlObject.hostname === baseUrlObject.hostname;
    if (isInternal) {
      return {
        isInternal: true,
        relativeLink: `${hrefUrlObject.pathname}${hrefUrlObject.search}${hrefUrlObject.hash}`,
      };
    }
  } catch (e) {
    return { isInternal: false, relativeLink: undefined };
  }
  return {
    isInternal: false,
    relativeLink: undefined,
  };
};
