import { produce } from 'immer';
import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import { ofType } from 'redux-observable';
import { TypedEpic } from '../types';

type GetIdpCountryResponse = {
  countryName: string;
  additionalInfo: string;
};

export enum Actions {
  GET_IDP_COUNTRIES = 'naf/idpCountries/GET_IDP_COUNTRIES',
  GET_IDP_COUNTRIES_SUCCESS = 'naf/idpCountries/GET_IDP_COUNTRIES_SUCCESS',
  GET_IDP_COUNTRIES_FAIL = 'naf/idpCountries/GET_IDP_COUNTRIES_FAIL',
  GET_IDP_COUNTRIES_CANCEL = 'naf/idpCountries/GET_IDP_COUNTRIES_CANCEL',
}

export interface State {
  data: { countryName: string; additionalInfo: string }[];
  isUpdating: boolean;
  errorState?: any;
}

export const initialState: State = {
  data: [],
  isUpdating: false,
};

export const actions = {
  getIdpCountries: createAsyncAction(
    Actions.GET_IDP_COUNTRIES, // request payload creator
    Actions.GET_IDP_COUNTRIES_SUCCESS, // success payload creator
    Actions.GET_IDP_COUNTRIES_FAIL, // failure payload creator
    Actions.GET_IDP_COUNTRIES_CANCEL, // optional cancel payload creator
  )<undefined, GetIdpCountryResponse[], Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getIdpCountries.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getIdpCountries.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.data = action.payload;
    }),
  )
  .handleAction(actions.getIdpCountries.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getIdpCountries.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

export const getIdpCountriesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_IDP_COUNTRIES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getIdpCountries.request>[]
    >,
    switchMap(() =>
      ajax<GetIdpCountryResponse[]>({
        url: `${apimBaseUrl}/${apimNafNoApi}/ifkcountries`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getIdpCountries.success(response)),
        catchError(() =>
          of(
            actions.getIdpCountries.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente landlisten! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [getIdpCountriesEpic];
