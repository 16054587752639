// * Colors that not currently in design system theme-package.
// ! Delete this file and use colors from design system later
const GRAN_COLOR = '#267c4f';
const DARK_GREY_COLOR = '#2e2e2e';
const NAF_GUL = '#FFDB16';

export const LINK_COLOR = DARK_GREY_COLOR;
export const LINK_HOVER_COLOR = GRAN_COLOR;
export const HR_COLOR = NAF_GUL;
export const SECONDARY_HR_COLOR = GRAN_COLOR;
export const ICON_COLOR = GRAN_COLOR;

export default { LINK_COLOR, LINK_HOVER_COLOR, SECONDARY_HR_COLOR, ICON_COLOR };
