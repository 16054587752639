import React from 'react';

export const Overcast = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 20C4.98333 20 3.68767 19.475 2.613 18.425C1.53767 17.375 1 16.0917 1 14.575C1 13.275 1.39167 12.1167 2.175 11.1C2.95833 10.0833 3.98333 9.43333 5.25 9.15C5.66667 7.61667 6.5 6.375 7.75 5.425C9 4.475 10.4167 4 12 4C13.95 4 15.604 4.679 16.962 6.037C18.3207 7.39567 19 9.05 19 11C20.15 11.1333 21.1043 11.629 21.863 12.487C22.621 13.3457 23 14.35 23 15.5C23 16.75 22.5627 17.8127 21.688 18.688C20.8127 19.5627 19.75 20 18.5 20H6.5ZM6.5 18H18.5C19.2 18 19.7917 17.7583 20.275 17.275C20.7583 16.7917 21 16.2 21 15.5C21 14.8 20.7583 14.2083 20.275 13.725C19.7917 13.2417 19.2 13 18.5 13H17V11C17 9.61667 16.5127 8.43733 15.538 7.462C14.5627 6.48733 13.3833 6 12 6C10.6167 6 9.43767 6.48733 8.463 7.462C7.48767 8.43733 7 9.61667 7 11H6.5C5.53333 11 4.70833 11.3417 4.025 12.025C3.34167 12.7083 3 13.5333 3 14.5C3 15.4667 3.34167 16.2917 4.025 16.975C4.70833 17.6583 5.53333 18 6.5 18Z"
      fill="#1A1A1A"
    />
  </svg>
);
