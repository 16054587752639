import React, { useEffect, useMemo, useState } from 'react';
import { breakpoints, spacing } from '@naf/theme';
import { Input, Select, Label } from '@naf/input';
import { Chips } from '@naf/chips';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import { VehicleTestData } from '../../../../../types/showcaseBlockType';
import { VehicleCategory } from '../../../../../types/testType';
import { calculateChange } from './CombinedResultCard/calculateChange';
import { ElPrixContextData } from '../../../../../types/ElPrixContextData';
import { ChargeTest, RangeTest } from '../../../../../types/carModelType';

type Sorting = 'asc' | 'desc';
type Modifier = 'diff';

type RangeDataValue = keyof Pick<
  RangeTest,
  'statedRangeKm' | 'measuredRangeKm' | 'measuredConsumptionKWhper100km' | 'statedConsumptionKWhper100km'
>;
type ChargeDataValue = keyof Pick<ChargeTest, 'statedTimeTo80PercentMinutes' | 'measuredTimeTo80PercentMinutes'>;

type SpecValue = `${RangeDataValue | ChargeDataValue}:${Sorting}${'' | `:${Modifier}`}`;
type SpecType = { label: string; value: SpecValue };

const CONSUMPTION_SPEC: SpecType[] = [
  { value: 'statedConsumptionKWhper100km:asc', label: 'Oppgitt forbruk (lavest til høyest)' },
  { value: 'measuredConsumptionKWhper100km:asc', label: 'Målt forbruk (lavest til høyest)' },
  { value: 'measuredConsumptionKWhper100km:asc:diff', label: 'Avvik i forbruk (minus til pluss)' },
];

const RANGE_SPEC: SpecType[] = [
  { value: 'statedRangeKm:desc', label: 'Oppgitt rekkevidde (lengst til kortest)' },
  { value: 'measuredRangeKm:desc', label: 'Målt rekkevidde (lengst til kortest)' },
  { value: 'measuredRangeKm:desc:diff', label: 'Avvik i rekkevidde (pluss til minus)' },
];

const CHARGE_TIME_SPEC: SpecType[] = [
  { value: 'statedTimeTo80PercentMinutes:asc', label: 'Oppgitt ladetid (raskest til tregest)' },
  { value: 'measuredTimeTo80PercentMinutes:asc', label: 'Målt ladetid (raskest til tregest)' },
];

const getMeasuredDifference = (stated: number | undefined, measured: number | undefined) =>
  // "increaseIsPositive" and "scheme" don't actually matter here for the
  // comparison we're making in the sort, as long as they're consistent for
  // all values.
  calculateChange({ stated, measured, increaseIsPositive: true, scheme: 'percentage' })?.diff;

type RangeSpec = [RangeDataValue, Sorting, Modifier | undefined];
type ChargeSpec = [ChargeDataValue, Sorting, Modifier | undefined];
const splitSelectedSpec = (selectedSpec: SpecValue) => selectedSpec.split(':') as RangeSpec | ChargeSpec;

const sortBySelectedSpec = (a: VehicleTestData, b: VehicleTestData, selectedSpec: SpecValue) => {
  const [key, order, difference] = splitSelectedSpec(selectedSpec);

  // If an element is missing a dataset, place it at the very end
  // Otherwise, perform sorting as specified
  let aDiff: number | undefined;
  let bDiff: number | undefined;

  if (difference !== undefined) {
    // Not entirely happy with hardcoding these cases but can't find a
    // better way to do it without changing the data structure
    if (key === 'measuredRangeKm') {
      aDiff = getMeasuredDifference(a?.testData?.rangeTest?.statedRangeKm, a.testData?.rangeTest?.measuredRangeKm);
      bDiff = getMeasuredDifference(b?.testData?.rangeTest?.statedRangeKm, b.testData?.rangeTest?.measuredRangeKm);
    } else if (key === 'measuredConsumptionKWhper100km') {
      aDiff = getMeasuredDifference(
        a?.testData?.rangeTest?.statedConsumptionKWhper100km,
        a?.testData?.rangeTest?.measuredConsumptionKWhper100km,
      );
      bDiff = getMeasuredDifference(
        b?.testData?.rangeTest?.statedConsumptionKWhper100km,
        b?.testData?.rangeTest?.measuredConsumptionKWhper100km,
      );
    }
  } else if (key === 'statedTimeTo80PercentMinutes' || key === 'measuredTimeTo80PercentMinutes') {
    // TS isn't smart enough to perform narrowing between nested indices yet,
    // so we have to explicitly split on dataSet with this if statement
    aDiff = a?.testData?.chargeTest?.[key];
    bDiff = b?.testData?.chargeTest?.[key];
  } else {
    aDiff = a?.testData?.rangeTest?.[key];
    bDiff = b?.testData?.rangeTest?.[key];
  }

  // Put explicitly and implicitly missing values
  // at the very end when sorting.
  if (bDiff === undefined) return -1;
  if (aDiff === undefined) return 1;
  if (difference === undefined) {
    if (bDiff === 0) return -1;
    if (aDiff === 0) return 1;
  }

  // Perform normal sorting
  if (order === 'asc') {
    return aDiff - bDiff;
  }
  return bDiff - aDiff;
};

interface FilterProps {
  data: VehicleTestData[];
  setData: React.Dispatch<React.SetStateAction<VehicleTestData[]>>;
  categories: VehicleCategory[];
  onlyChips?: boolean;
  initialChips?: VehicleCategory[];
  // Lift values from filter from component, if necessary
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  setFilters?: React.Dispatch<React.SetStateAction<string[]>>;
  tests: ElPrixContextData['tests'];
}

export const Filter: React.FC<FilterProps> = ({
  data,
  setData,
  categories,
  onlyChips,
  initialChips,
  setSearchTerm,
  setFilters,
  tests,
}) => {
  const fuse = useMemo(() => new Fuse(data, { keys: ['name'], threshold: 0.5 }), [data]);

  const [nameFilter, setNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<string[]>(initialChips?.map(({ id }) => id) || []);
  const [selectedSpec, setSelectedSpec] = useState<SpecValue | ''>('');
  const [includedSpecs, setIncludedSpecs] = useState<SpecType[]>([]);

  const updateFilter = (value: string) => {
    if (categoryFilter.includes(value)) {
      setCategoryFilter(categoryFilter.filter((id) => id !== value));
    } else {
      setCategoryFilter([...categoryFilter, value].sort());
    }
  };

  useEffect(() => {
    if (setSearchTerm) {
      setSearchTerm(nameFilter);
    }
  }, [setSearchTerm, nameFilter]);

  useEffect(() => {
    if (setFilters) {
      setFilters(categoryFilter);
    }
  }, [setFilters, categoryFilter]);

  useEffect(() => {
    if (tests) {
      const specs: SpecType[] = [
        ...(tests.range ? RANGE_SPEC : []),
        ...(tests.consumption ? CONSUMPTION_SPEC : []),
        ...(tests.chargeTime ? CHARGE_TIME_SPEC : []),
      ];
      setIncludedSpecs(specs);
    }
  }, [tests]);

  // Apply filters
  useEffect(() => {
    let filtered = [...data];

    if (nameFilter !== '') {
      filtered = fuse.search(nameFilter).map((v) => v.item);
    }

    if (categoryFilter.length > 0) {
      // For each vehicle in the array, check whether every categoryFilter is included in its categories
      filtered = filtered.filter((vehicle) =>
        categoryFilter.every(
          (filterString) => !!vehicle.categories?.filter((category) => category.id === filterString).length,
        ),
      );
    }

    if (selectedSpec !== '') {
      filtered = filtered.sort((a, b) => sortBySelectedSpec(a, b, selectedSpec));
    }

    setData(filtered);
  }, [data, fuse, setData, nameFilter, categoryFilter, selectedSpec]);

  return (
    <SearchBar>
      {!onlyChips && (
        <InputContainer>
          <div>
            <Label>Søk</Label>
            <Input placeholder="Søk etter modell..." value={nameFilter} onChange={(v: string) => setNameFilter(v)} />
          </div>
          <div>
            <Label>Sorter på spesifikasjon</Label>
            <Select
              width="100%"
              options={includedSpecs}
              handleSelect={(e: SpecType) => {
                setSelectedSpec(e.value);
              }}
              placeholder="Velg spesifikasjon..."
            />
          </div>
        </InputContainer>
      )}
      <div>
        <Label>Filtrer på kategori</Label>
        <ChipContainer>
          {categories.map(({ category, id }) => (
            <Chips
              icon={categoryFilter.includes(id) ? 'close' : ''}
              key={id}
              variant={categoryFilter.includes(id) ? 'signature' : 'outline'}
              onClick={() => updateFilter(id)}
              text={category}
            />
          ))}
        </ChipContainer>
      </div>
    </SearchBar>
  );
};

const SearchBar = styled.div`
  margin-bottom: ${spacing.space24};
`;

export const ChipContainer = styled.div`
  button > div {
    margin-left: 0;
    margin-right: ${spacing.space8};
  }

  button:last-child > div {
    margin-right: 0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: ${spacing.space32};
  margin-bottom: ${spacing.space24};
  width: 100%;
  flex-wrap: nowrap;

  @media (max-width: ${breakpoints.m}) {
    flex-wrap: wrap;
  }
  div {
    flex-grow: 1;
    width: 100%;
  }
`;
