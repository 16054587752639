import React from 'react';
import { TextVariant, Text } from '@naf/text';
import { spacing } from '@naf/theme';
import styled from 'styled-components';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import RelatedArticleList from '../../../../related-articles/RelatedArticleList';
import { RelatedArticleType } from '../../../../../../../types/articleType';

type Props = {
  value: { articleList: RelatedArticleType[]; title: string };
};

const ArticleListSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: Props) => (
      <ArticleBlockContentGridCol>
        {value.title && (
          <StyledText variant={TextVariant.Tag} tag="p">
            {value.title}
          </StyledText>
        )}
        <StyledRelatedArticleList relatedContent={value.articleList} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: Props) => <RelatedArticleList relatedContent={value.articleList} />;
};

export default ArticleListSerializer;

const StyledText = styled(Text)``;

const StyledRelatedArticleList = styled(RelatedArticleList)`
  & .card {
    margin-bottom: 0 !important;
    padding-top: ${spacing.space32};
  }
`;
