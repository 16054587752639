import React from 'react';
import S from './Styles';
import Loader from '../../components/loader';

export const LoaderPage = () => (
  <div>
    <LoaderContent />
  </div>
);

export const LoaderContent = () => (
  <S.Wrapper>
    <Loader />
  </S.Wrapper>
);
