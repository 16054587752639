import { Action, createAction, createReducer } from 'typesafe-actions';
import { produce } from 'immer';
import xor from 'lodash/xor';
import { TypedEpic } from '../types';
import { MembershipBenefitReference } from '../../../../types/membershipBenefitReferenceType';
import { MembershipBenefitSearchFilterType } from '../../../../types/MembershipBenefitSearchFilterType';

// Actions
export enum Actions {
  UPDATE = 'membershipBenefitSearch/Update',
  QUERY = 'membershipBenefitSearch/Query',
  SELECT_TAG = 'membershipBenefitSearch/SelectTag',
  CLEAR_TAGS = 'membershipBenefitSearch/ClearTags',
  PAGINATION = 'membershipBenefitSearch/Pagination',
  SET_ERROR = 'membershipBenefitSearch/SetError',
  SET_STATE = 'membershipBenefitSearch/SetState',
}

export interface State {
  items: Record<number, { data: MembershipBenefitReference[]; meta: { isLoading: boolean } }>;
  query: string;
  total: number;
  countTotalItemsInTextSearch: number;
  availableTags: MembershipBenefitSearchFilterType[];
  tags: MembershipBenefitSearchFilterType['slug'][];
  skip: number;
  page: number;
  error: any;
  isLoading: boolean;
  numberOfLoadedElements: number;
  take: number;
  isFirstRender: boolean;
}

export const initialState: State = {
  items: {},
  query: '',
  total: 0,
  countTotalItemsInTextSearch: 0,
  availableTags: [],
  tags: [],
  skip: 0,
  page: 1,
  error: null,
  isLoading: true,
  numberOfLoadedElements: 0,
  take: 24,
  isFirstRender: true,
};

export const actions = {
  update: createAction(Actions.UPDATE)<{
    skip: number;
    total: number;
    countTotalItemsInTextSearch: number;
    availableTags: MembershipBenefitSearchFilterType[];
    items: MembershipBenefitReference[];
  }>(),
  query: createAction(Actions.QUERY)<{ query: string }>(),
  selectTag: createAction(Actions.SELECT_TAG)<{ tag: MembershipBenefitSearchFilterType }>(),
  clearTags: createAction(Actions.CLEAR_TAGS)(),
  pagination: createAction(Actions.PAGINATION)<{ take: number }>(),
  setState: createAction(Actions.SET_STATE)<{ partialState: any }>(),
  setError: createAction(Actions.SET_ERROR)<{ error: any }>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.setState, (state = initialState, action) =>
    produce(state, (draftState) => {
      Object.entries(action.payload.partialState).forEach(([key, value]) => {
        draftState[key] = value;
      });
    }),
  )
  .handleAction(actions.update, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isFirstRender = false;
      draftState.total = action.payload.total;
      draftState.availableTags = action.payload.availableTags;
      draftState.isLoading = false;
      draftState.countTotalItemsInTextSearch = action.payload.countTotalItemsInTextSearch;
      if (action.payload.skip !== 0) {
        draftState.items = {
          ...state.items,
          // The length has already been incremented by the pagination action
          [state.page]: { meta: { isLoading: false }, data: action.payload.items },
        };
        draftState.numberOfLoadedElements = (state.page - 1) * 24 + action.payload.items.length;
      } else if (action.payload.skip === 0) {
        // Split received data into pages
        const items = [...action.payload.items];
        const chunkSize = 24;
        const chunks = [];

        for (let i = 0; i < items.length; i += chunkSize) {
          chunks.push(items.slice(i, i + chunkSize));
        }

        const itemsMap = chunks.reduce((acc, cur, currentIndex) => {
          acc[currentIndex] = { meta: { isLoading: false }, data: cur };
          return acc;
        }, {});

        draftState.items = items.length > 0 ? itemsMap : { 1: { meta: { isLoading: false }, data: [] } };
        draftState.numberOfLoadedElements = action.payload.items.length;
        draftState.page = Math.ceil(action.payload.items.length / 24) || 1;
      }
    }),
  )
  .handleAction(actions.query, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.items = { 1: { meta: { isLoading: true }, data: undefined } };
      draftState.isLoading = true;
      draftState.query = action.payload.query;
      draftState.skip = 0;
      draftState.page = 1;
    }),
  )
  .handleAction(actions.selectTag, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.items = { 1: { meta: { isLoading: true }, data: undefined } };
      draftState.isLoading = true;
      draftState.tags = xor(state.tags, [action.payload.tag.slug]);
      draftState.skip = 0;
      draftState.page = 1;
      draftState.take = 24;
    }),
  )
  .handleAction(actions.clearTags, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.items = { 1: { meta: { isLoading: true }, data: undefined } };
      draftState.isLoading = true;
      draftState.tags = [];
      draftState.skip = 0;
      draftState.page = 1;
    }),
  )
  .handleAction(actions.pagination, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.items = {
        ...state.items,
        [state.page + 1]: { meta: { isLoading: true }, data: undefined },
      };
      draftState.isLoading = true;
      draftState.skip = state.page * 24;
      draftState.page = state.page + 1;
      draftState.take = action.payload.take;
    }),
  )
  .handleAction(actions.setError, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.error = action.payload.error;
    }),
  );

export const epics: TypedEpic[] = [];
