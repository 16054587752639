import React, { RefObject, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing, icons } from '@naf/theme';
import { SubdirectoryArrowRight } from '@styled-icons/material-outlined/SubdirectoryArrowRight';
import { Text, TextVariant } from '@naf/text';
import { useHistory, useLocation } from 'react-router';
import { AnchorLink } from '@naf/anchor-link';

interface TableOfContentsProps {
  tagType: string;
  parentElement?: RefObject<HTMLElement>;
  contentHeader?: string;
}

const TableOfContents = ({ tagType = 'h2', parentElement, contentHeader }: TableOfContentsProps) => {
  const history = useHistory();
  const location = useLocation();
  const [toC, setToC] = useState<Element[]>([]);

  const generateTableOfContents = useCallback(() => {
    if (typeof window !== 'undefined' && document) {
      const toCParent = parentElement?.current || document;
      let headings = [...toCParent.getElementsByTagName(tagType)];
      // Filter out headings that does not have id (cannot be linked to)
      headings = headings.filter((entry) => entry.id);
      setToC(headings);
    }
  }, [parentElement, tagType]);

  useEffect(() => {
    generateTableOfContents();
  }, [generateTableOfContents]);

  if (toC && toC.length > 0) {
    return (
      <ToCWrapper aria-label="Innholdsfortegnelse">
        <ToCHeading>
          <SubdirectoryArrowRight size={icons.m} />
          <Text variant={TextVariant.Header3}>{contentHeader || 'I denne artikkelen'}</Text>
        </ToCHeading>
        <ul>
          {toC.map((heading: Element) => {
            const headingText = (heading as HTMLElement).innerText;
            return (
              <li key={`toc-${heading.id}`}>
                <AnchorLink
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push(`${location.pathname}#${heading.id}`);
                  }}
                  href={`${location.pathname}#${heading.id}`}
                  text={headingText}
                />
              </li>
            );
          })}
        </ul>
      </ToCWrapper>
    );
  }
  return null;
};

const ToCWrapper = styled.nav`
  margin-bottom: ${spacing.space32};

  ul {
    padding-left: 0;
    margin: 0;
    li {
      list-style-type: none;
      margin-bottom: ${spacing.space8};

      @media (max-width: ${breakpoints.m}) {
        margin-bottom: ${spacing.space16};
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space48};
  }
`;

const ToCHeading = styled.div`
  display: flex;
  align-items: center;
  h3,
  svg {
    margin-bottom: ${spacing.space8};
    margin-top: 0;
    @media (max-width: ${breakpoints.m}) {
      margin-bottom: ${spacing.space16};
    }
  }
  svg {
    margin-right: ${spacing.space4};
  }
`;

export default TableOfContents;
