import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { formatImageCaption, capFirst } from '../../utils/formatImageCaption';
import utils from '../../utils';

export const ImageCaption = ({
  caption,
  captionId,
  photographer,
  copyright,
  addNewLineAfterCaption,
}: {
  caption?: string;
  captionId?: string;
  photographer?: string;
  copyright?: string;
  addNewLineAfterCaption?: boolean;
}) => {
  const captionStrings = caption && formatImageCaption(caption);

  let captionHeader = '';
  let captionString = '';

  if (captionStrings) {
    [captionHeader, captionString] = captionStrings;
  }

  const photographerAndCopyrightCaption = utils.getPhotographerAndCopyrightCaption({ photographer, copyright });

  if (!caption && !copyright && !photographer) return null;

  return captionHeader ? (
    <>
      <ImageCaptionHeader className="caption-header" tag="span" variant={TextVariant.CaptionHeader}>
        {capFirst(captionHeader)}:
      </ImageCaptionHeader>
      <Caption tag="span" variant={TextVariant.Caption} id={captionId}>
        {captionString}
        {addNewLineAfterCaption ? '\n' : ' '}
        {photographerAndCopyrightCaption}
      </Caption>
    </>
  ) : (
    <Caption tag="span" variant={TextVariant.Caption} id={captionId}>
      {captionString}
      {addNewLineAfterCaption ? '\n' : ' '}
      {photographerAndCopyrightCaption}
    </Caption>
  );
};

const Caption = styled(Text)``;

const ImageCaptionHeader = styled(Text)`
  margin: 0;
  display: block;

  @media (max-width: ${breakpoints.m}) {
    width: auto;
    display: inline-block;
    margin-right: 2px;
  }
`;
