import React from 'react';
import { TextVariant } from '@naf/text';
import * as S from './styles';
import useSelector from '../../../../../../redux/typedHooks';
import { formatDate } from '../../../../../../utils/Dates';

export const MembershipValidUntil = () => {
  const { membership } = useSelector((state) => state.myMembership.customerInformation.data);
  return (
    <S.TextWithNewLine variant={TextVariant.ArticleText}>
      {`Innbetalt kontigent refunderes ikke.\nMedlemskapet ditt vil være gyldig frem til `}
      <S.BoldText variant={TextVariant.ArticleText} tag="span">
        {formatDate(membership.validUntil)}
      </S.BoldText>
    </S.TextWithNewLine>
  );
};

export const MembershipValidUntilSerializer = () => () => <MembershipValidUntil />;
