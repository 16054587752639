import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import ResponsiveImage from '../../../../image/ResponsiveImage';

export const BlockImage = ({ image }: { image?: CloudinaryImageType }) => {
  const { publicId, caption, altText } = image || {};

  return (
    (publicId && (
      <ImageWrapper className="BlockImage">
        <ResponsiveImage imageId={publicId} altText={altText || caption} />
        {caption && (
          // hack until text support additional tag elements
          <Text tag={'figcaption' as 'span'} variant={TextVariant.Caption}>
            {caption}
          </Text>
        )}
      </ImageWrapper>
    )) ||
    null
  );
};

const ImageWrapper = styled.figure`
  margin: 64px 0 58px 0;
  width: 100%;
  img {
    width: 100%;
  }

  @media (max-width: ${breakpoints.m}) {
    img {
      max-width: 100%;
      width: auto;
    }
  }
`;

export default BlockImage;
