/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useGTMDataLayer } from './useGTMDataLayer';
import { EventPayload } from '../../../types/GTM/EventPayload';

export const useSendGTMEventOnce = (event: EventPayload | undefined, deps: any[]): void => {
  const [tagIsSent, setTagIsSent] = useState(false);
  const dataLayer = useGTMDataLayer();
  useEffect(() => {
    if (dataLayer && !tagIsSent && event) {
      dataLayer.push(event);
      setTagIsSent(true);
    }
  }, [event, deps, dataLayer, tagIsSent]);
};

export const createEventPayload = (event: EventPayload): EventPayload => ({
  ...event,
  ecommerce: {
    currency: 'NOK',
    affiliation: 'NAF.no',
    shipping_tier: 'Digitalt',
    items: event?.ecommerce?.items?.map((item) => ({
      ...item,
      item_brand: 'NAF',
      item_category: 'NAF',
      item_list_id: 'Produktside',
      item_list_name: 'Produkter',
    })),
  },
});
