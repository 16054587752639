import React from 'react';
import { TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { Routepoint } from '../../../../types/TravelSuggestionType';
import * as S from './styles';

interface RouteBoxProps {
  routePoints: Routepoint[];
  googleMapsLink: string;
}
export const RouteBox = ({ routePoints, googleMapsLink }: RouteBoxProps) => {
  if (!Array.isArray(routePoints) || routePoints.length < 2) {
    return null;
  }

  const origin = routePoints[0];
  const destination = routePoints[routePoints.length - 1];

  return (
    <S.RouteBox>
      <S.BoxHeader variant={TextVariant.Header3}>Rutekart</S.BoxHeader>
      <S.List>
        <S.ListItem>
          <S.BulletPoint />
          <S.ListItemLabel variant={TextVariant.BodyText}>{origin.title}</S.ListItemLabel>
        </S.ListItem>
        <S.ListItem>
          <S.BulletPoint />
          <S.ListItemLabel variant={TextVariant.BodyText}>{destination.title}</S.ListItemLabel>
        </S.ListItem>
      </S.List>
      <Button variant="secondary" href={googleMapsLink} target="_blank" rel="noreferrer">
        Åpne i Google Maps
        <S.MapIcon width={16} height={16} />
      </Button>
    </S.RouteBox>
  );
};
