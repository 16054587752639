import React from 'react';
import styled, { keyframes } from 'styled-components';

export const Loader = () => <Spinner />;

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
  width: 32px;
  height: 32px;
  border: 3px solid;
  border-radius: 50%;
  border-color: #267c4f #e9f3e6 #e9f3e6;
  animation: ${spin} 0.6s linear infinite;
`;

export default Loader;
