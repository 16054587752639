import { Text } from '@naf/text';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Map } from '@styled-icons/material-outlined';

export const MapContainer = styled.div`
  grid-column: 1 / -1;
  position: relative;
  margin-bottom: 48px;

  @media (max-width: ${breakpoints.s}) {
    width: 100vw;
    margin-left: -24px;
  }
`;

export const RouteBox = styled.div`
  background-color: #fff;
  padding: ${spacing.space24};
  padding-left: 0;
  position: absolute;
  top: ${spacing.space40};
  left: -${spacing.space40};
  border-radius: 4px;
  width: 232px;

  @media (max-width: ${breakpoints.s}) {
    top: initial;
    left: initial;
    bottom: -${spacing.space40};
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 16px;
    position: relative;
    bottom: 24px;
    margin: 0 16px;
  }

  :after {
    position: absolute;
    left: 12px;
    content: '';
    width: 1px;
    top: 60px;
    bottom: -50px;
  }
`;

export const BoxHeader = styled(Text)`
  margin-bottom: ${spacing.space16} !important;
  margin-left: ${spacing.space24};

  @media (max-width: ${breakpoints.s}) {
    margin-left: 0;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0 0 0 ${spacing.space24};
  margin: 0;

  @media (max-width: ${breakpoints.s}) {
    padding: 0;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 16px;

  :before {
    background-color: black;
    width: 2px;
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 3px;
  }

  :first-child:before {
    top: 12px;
  }

  :last-child:before {
    height: 12px;
  }
`;

export const ListItemLabel = styled(Text)`
  margin: 0;
`;

export const BulletPoint = styled.span`
  content: '';
  background: #1a1a1a;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: ${spacing.space8};
`;

export const MapIcon = styled(Map)`
  margin-left: 12px;
`;
