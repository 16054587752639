import React from 'react';
import { TextVariant } from '@naf/text';
import { Grid, GridCol } from '@naf/grid';
import S from './styles';
import { LoaderContent } from '../../pages/LoaderPage';
import BlockContent from '../block-content/BlockContent';
import { createNorwegianDateString } from '../../utils/createNorwegianDateString';
import { ArticleType } from '../../../../types/articleType';
import { useScrollBlock } from '../../hooks/useScrollBlock';
import { NotFound } from '../../loadable-elements/NotFound';

export const Content = ({ data, isUpdating }: { data?: ArticleType; isUpdating: boolean }) => {
  useScrollBlock();
  if (data) {
    const { name, ingress, body, meta } = data;
    return (
      <S.Container>
        <Grid>
          <GridCol s="12" m="12" l="8" xl="8">
            <S.Title variant={TextVariant.Header1}>{name}</S.Title>
            <S.Ingress variant={TextVariant.Ingress}>{ingress}</S.Ingress>
            <S.HRLine />
            {isUpdating ? (
              <LoaderContent />
            ) : (
              <S.Body>
                <BlockContent value={body} />
              </S.Body>
            )}
            {meta && meta.updatedAt && <div>{createNorwegianDateString(new Date(meta.updatedAt))}</div>}
            {meta && !meta.updatedAt && meta.publishAt && (
              <div>{createNorwegianDateString(new Date(meta.publishAt))}</div>
            )}
          </GridCol>
        </Grid>
      </S.Container>
    );
  }
  if (!data && isUpdating) {
    return <LoaderContent />;
  }
  if (!data && isUpdating === false) {
    return <NotFound />;
  }
  return null;
};
