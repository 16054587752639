import React, { FC, useState } from 'react';
import { ThemeContextAction, ThemeContextValue } from '../lib/ThemeContext';

interface Props {
  children?: React.ReactNode;
}

export const ThemeContextProvider: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState();
  return (
    <ThemeContextAction.Provider value={setTheme}>
      <ThemeContextValue.Provider value={theme}>{children}</ThemeContextValue.Provider>
    </ThemeContextAction.Provider>
  );
};
