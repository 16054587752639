import React, { useState } from 'react';
import { Input, Label } from '@naf/input';
import styled from 'styled-components';
import { Button } from '@naf/button';
import { spacing } from '@naf/theme';
import { ArticleBlockContentGridCol } from '../ArticleBlockContentGridCol';

export const InsuranceCalculatorBlock = () => {
  const [registrationNumber, setRegistrationNumber] = useState('');

  const onSubmit = () => {
    if (typeof window !== 'undefined') {
      window.location.href = `https://www.gjensidige.no/tariffering-web/open/dispatcher/naf/bil?utm_id=8z6o&utm_source=naf&utm_medium=affiliate&utm_campaign=naf-nettsider_beregn-pris-p%C3%A5-bilforsikring&regnr=${registrationNumber}&x-client-id=naf `;
    }
  };

  return (
    <Wrapper>
      <StyledLabel htmlFor="insurance-calculator-input">Registreringsnummer</StyledLabel>
      <Input
        onChange={setRegistrationNumber}
        onKeyPress={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
        placeholder="Registreringsnummer"
        id="insurance-calculator-input"
      />
      <StyledButton onClick={onSubmit}>Beregn pris på forsikring</StyledButton>
    </Wrapper>
  );
};

export const InsuranceCalculatorSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <InsuranceCalculatorBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <InsuranceCalculatorBlock />;
};

const Wrapper = styled.div`
  margin-top: ${spacing.space8};
  margin-bottom: ${spacing.space56};
`;

const StyledLabel = styled(Label)`
  font-weight: 400;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing.space24};
`;
