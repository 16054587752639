import React from 'react';
import { useHistory } from 'react-router-dom';
import { VippsLoginButton } from '../../VippsLoginButton';
import { InternalLinkType } from '../../../../../types/internalLinkType';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import useSelector from '../../../redux/typedHooks';

export interface VippsLoginBlockProps {
  value: {
    internalLink?: InternalLinkType;
    externalLink?: string;
  };
}

export const VippsLoginBlock = ({ value: { internalLink, externalLink } }: VippsLoginBlockProps) => {
  const history = useHistory();
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const resolvedInternalLink = useDocumentUrlWithNode(internalLink);
  const returnUrl = internalLink ? `${baseUrl}${resolvedInternalLink}${history.location.search}` : externalLink;
  return <VippsLoginButton returnUrl={returnUrl} />;
};
