import styled from 'styled-components';
import { GridCol } from '@naf/grid';
import { breakpoints } from '@naf/theme';
import { CardNavigation } from '@naf/cards';

export const StyledCardNavigation = styled(CardNavigation)`
  @media (max-width: ${breakpoints.xl}) {
    width: -webkit-fill-available;
    width: fill-available;
  }
`;

export const ShortCutCol = styled(GridCol)`
  a {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
