import React, { useContext } from 'react';
import { TextList, TextListItem } from '@naf/text-list';
import { DowngradeContext } from '../../../../../pages/downgrade/context/DowngradeContext';

export const MainAndHouseholdMembersList = () => {
  const { members } = useContext(DowngradeContext);
  return (
    <TextList>
      {members.map((member) => (
        <TextListItem key={member.customerId}>
          {member.fullName}
          {!member.isMainMember ? ' (hustandlemmer)' : ''}
        </TextListItem>
      ))}
    </TextList>
  );
};

export const MainAndHouseholdMembersListSerializer = () => () => <MainAndHouseholdMembersList />;
