import styled, { css } from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Text } from '@naf/text';

export const LogoArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledTopText = styled(Text)<{ $isScrolled?: boolean }>`
  font-size: 1.125rem;
  line-height: 1.375rem;
  transition: font-size 0.3s ease-in-out, line-height 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      font-size: 0.875rem;
      line-height: 1.125rem;
    `}

  @media (max-width: ${breakpoints.l}) {
    font-size: 1rem;
    line-height: 1.125rem;
  }

  @media (max-width: ${breakpoints.m}) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;

export const StyledBottomText = styled(Text)<{ $isScrolled?: boolean }>`
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 700;
  transition: font-size 0.3s ease-in-out, line-height 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      font-size: 1rem;
      line-height: 1.125rem;
    `}

  @media (max-width: ${breakpoints.l}) {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
`;

export const RoadAssistance = styled.a<{ $isScrolled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 ${spacing.space8};

  &:hover {
    span {
      color: ${nafColor.primary.park} !important;
    }
  }

  @media (max-width: ${breakpoints.l}) {
    color: ${nafColor.signature.black};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: ${spacing.space8};
  }
`;

export const SeparatorLine = styled.div<{ $isScrolled: boolean }>`
  width: 1px;
  height: 40px;
  background-color: ${nafColor.signature.yellow};
  margin: 0 ${spacing.space24};
  transition: all 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      height: 28px;
    `};

  @media (max-width: ${breakpoints.l}) {
    display: none;
  }
`;
