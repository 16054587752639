import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { iif, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, mergeMap, retryWhen, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { produce } from 'immer';

import {
  FieldValidationResultType,
  OrderType,
  PostPaymentOrderError,
  PostPaymentOrderRequest,
  PostPaymentOrderResponse,
} from '@dtp/membership-service-types';

import { TypedEpic } from '../types';

// Actions
export enum Actions {
  CREATE_ORDER = 'naf/order/CREATE_ORDER',
  CREATE_ORDER_SUCCESS = 'naf/order/CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAIL = 'naf/order/CREATE_ORDER_FAIL',
  CREATE_ORDER_CANCEL = 'naf/order/CREATE_ORDER_CANCEL',
}

export interface State {
  salesOrder?: OrderType;
  validation?: PostPaymentOrderError;
  isCreatingOrder: boolean;
  orderDetails?: PostPaymentOrderResponse;
  isCreated: boolean;
}

export const initialState: State = {
  salesOrder: {
    customerProductsRequests: [],
    returnUrl: '',
  },
  isCreated: false,
  isCreatingOrder: false,
};

export const actions = {
  createOrder: createAsyncAction(
    Actions.CREATE_ORDER, // request payload creator
    Actions.CREATE_ORDER_SUCCESS, // success payload creator
    Actions.CREATE_ORDER_FAIL, // failure payload creator
    Actions.CREATE_ORDER_CANCEL, // optional cancel payload creator
  )<[PostPaymentOrderRequest, { token?: string }], PostPaymentOrderResponse, PostPaymentOrderError, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.createOrder.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isCreatingOrder = true;
      delete draftState.validation;
    }),
  )
  .handleAction(actions.createOrder.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isCreatingOrder = false;
      draftState.isCreated = true;
      draftState.orderDetails = action.payload;
    }),
  )
  .handleAction(actions.createOrder.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isCreatingOrder = false;
    }),
  )
  .handleAction(actions.createOrder.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isCreatingOrder = false;
      draftState.isCreated = false;
      draftState.validation = action.payload;
      draftState.validation.validationMap = action.payload.validationResults.reduce(
        (acc: Record<FieldValidationResultType['field'], FieldValidationResultType>, cur) => {
          acc[cur.field] = cur;
          return acc;
        },
        {},
      );
    }),
  );

const createOrderEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = state$.value.application;
  const errorArray = [400, 422];

  return action$.pipe(
    ofType(Actions.CREATE_ORDER),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.createOrder.request>[]
    >,
    mergeMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<PostPaymentOrderResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/v2/products/payment`,
        headers,
        method: 'POST',
        body: JSON.stringify(action.payload),
        withCredentials: true,
      }).pipe(
        map(({ response }) => actions.createOrder.success(response)),
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((error) => {
              if (!errorArray.includes(error.status)) {
                return of(error);
              }
              return throwError(error);
            }),
            // Use concat map to keep the errors in order and make sure they
            // aren't executed in parallel
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => i > 10,
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                of(e).pipe(delay(500)),
              ),
            ),
          ),
        ),
        catchError((res) => {
          if (res.status === 500)
            return of(
              actions.createOrder.failure({
                isValid: false,
                salesOrder: null,
                validationResults: [
                  {
                    field: 'CustomerProductsRequests[0].Profile.PhoneNumber',
                    message:
                      'Det har oppstått en ukjent feil på serveren, forsøk å laste inn siden på nytt. Hvis dette ikke fungerer, kontakt kundesenteret.',
                    isValid: false,
                    action: null,
                  },
                ],
                validationMap: {
                  'CustomerProductsRequests[0].Profile.PhoneNumber': {
                    field: 'CustomerProductsRequests[0].Profile.PhoneNumber',
                    message:
                      'Det har oppstått en ukjent feil på serveren, forsøk å laste inn siden på nytt. Hvis dette ikke fungerer, kontakt kundesenteret.',
                    isValid: false,
                    action: null,
                  },
                },
              }),
            );
          return of(actions.createOrder.failure(res.response));
        }),
      );
    }),
  );
};

export const epics: TypedEpic[] = [createOrderEpic];
