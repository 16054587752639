import { ScrollList } from '@naf/cards';
import { breakpoints, spacing } from '@naf/theme';
import React from 'react';
import styled, { css } from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { VerticallyFadingSectionBlocks } from '.';
import { ColumnBlockType } from '../../../../types/ColumnBlockType';
import { BlockHeader } from './blocks/MultiRowArticleBlock';
import { LinkField } from './blocks/LatestArticleBlock';

type Props = {
  block: ColumnBlockType;
  activateContextThemeSwitch?: boolean;
  headerVariant?: 'large' | 'small';
};

export const ColumnBlock: React.FC<Props> = ({ block, activateContextThemeSwitch, headerVariant = 'small' }) => {
  const { columnArray, title, link, ingress } = block;
  return (
    <ColumnBlockWrapper>
      <ColumnBlockHeader $headerVariant={headerVariant}>
        <Text variant={headerVariant === 'large' ? TextVariant.Header1 : TextVariant.Header2} tag="h2">
          {title}
        </Text>
        <LinkField link={link} />
      </ColumnBlockHeader>
      {ingress && (
        <IngressText tag="p" variant={TextVariant.ArticleText}>
          {ingress}
        </IngressText>
      )}
      <ColumnWrapper>
        {columnArray.map(({ columnContent, key }) => (
          <ColumnContainer key={key}>
            <VerticallyFadingSectionBlocks
              content={columnContent}
              activateContextThemeSwitch={activateContextThemeSwitch}
            />
          </ColumnContainer>
        ))}
      </ColumnWrapper>
    </ColumnBlockWrapper>
  );
};

export const ColumnBlockWrapper = styled.div``;

export const ColumnWrapper = styled(ScrollList)`
  display: flex;
  gap: ${spacing.space32};
  justify-content: space-between;
  overflow-y: hidden;

  @media (max-width: ${breakpoints.m}) {
    overflow-x: scroll;
    max-width: 100vw;
    gap: ${spacing.space24};
  }
`;

export const ColumnBlockHeader = styled(BlockHeader)<{ $headerVariant: 'large' | 'small' }>`
  ${({ $headerVariant }) =>
    $headerVariant === 'large' &&
    css`
      margin: 0;
      margin-bottom: ${spacing.space48};
      @media (max-width: ${breakpoints.m}) {
        margin-bottom: ${spacing.space40};
      }
    `}
`;

export const IngressText = styled(Text)`
  margin-top: -${spacing.space16};
  margin-bottom: ${spacing.space24};
`;

export const ColumnContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;

  & > * + * {
    margin-top: ${spacing.space80};
  }

  @media (max-width: ${breakpoints.m}) {
    display: flex;
    flex-flow: row nowrap;

    & > * + * {
      margin-left: ${spacing.space32};
      margin-top: 0;
    }

    & > * {
      min-width: 60vw;
    }
  }

  @media (max-width: ${breakpoints.s}) {
    & > * + * {
      margin-left: ${spacing.space24};
    }

    & > * {
      min-width: 80vw;
    }
  }
`;
