import { Action, ActionType, createAsyncAction, createAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { BenefitsResponse, BenefitsType, filterableTag } from '../../../../types/benefitsType';

// Actions
export enum Actions {
  GET_BENEFITS = 'GET_BENEFITS',
  GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS',
  GET_BENEFITS_FAIL = 'GET_BENEFITS_FAIL',
  GET_BENEFITS_CANCEL = 'GET_BENEFITS_CANCEL',
  GET_MORE_BENEFITS = 'GET_MORE_BENEFITS',
  GET_MORE_BENEFITS_SUCCESS = 'GET_MORE_BENEFITS_SUCCESS',
  GET_MORE_BENEFITS_FAIL = 'GET_MORE_BENEFITS_FAIL',
  GET_MORE_BENEFITS_CANCEL = 'GET_MORE_BENEFITS_CANCEL',
  GET_BENEFIT_ARTICLE = 'GET_BENEFIT_ARTICLE',
  GET_BENEFIT_ARTICLE_SUCCESS = 'GET_BENEFIT_ARTICLE_SUCCESS',
  GET_BENEFIT_ARTICLE_FAIL = 'GET_BENEFIT_ARTICLE_FAIL',
  GET_BENEFIT_ARTICLE_CANCEL = 'GET_BENEFIT_ARTICLE_CANCEL',
  SET_FILTERED_TAGS = 'SET_FILTERED_TAGS',
}

export interface State {
  benefitsList: Record<string, BenefitsType>;
  availableTags: filterableTag[];
  selectedTags: filterableTag[]; // This comes from api
  isUpdating: boolean;
  errorState?: Error;
  total: number;
  filteredTags: Record<string, number>; // This is used to manually set filtered tags
}

export const initialState: State = {
  benefitsList: {},
  availableTags: [],
  selectedTags: [],
  isUpdating: false,
  total: 0,
  filteredTags: {},
};

export const actions = {
  getBenefits: createAsyncAction(
    Actions.GET_BENEFITS, // request payload creator
    Actions.GET_BENEFITS_SUCCESS, // success payload creator
    Actions.GET_BENEFITS_FAIL, // failure payload creator
    Actions.GET_BENEFITS_CANCEL, // optional cancel payload creator
  )<string, BenefitsResponse, Error, undefined>(),
  getMoreBenefits: createAsyncAction(
    Actions.GET_MORE_BENEFITS, // request payload creator
    Actions.GET_MORE_BENEFITS_SUCCESS, // success payload creator
    Actions.GET_MORE_BENEFITS_FAIL, // failure payload creator
    Actions.GET_MORE_BENEFITS_CANCEL, // optional cancel payload creator
  )<string, BenefitsResponse, Error, undefined>(),
  getBenefitArticle: createAsyncAction(
    Actions.GET_BENEFIT_ARTICLE, // request payload creator
    Actions.GET_BENEFIT_ARTICLE_SUCCESS, // success payload creator
    Actions.GET_BENEFIT_ARTICLE_FAIL, // failure payload creator
    Actions.GET_BENEFIT_ARTICLE_CANCEL, // optional cancel payload creator
  )<string, BenefitsType, Error, undefined>(),
  setFilteredTags: createAction(Actions.SET_FILTERED_TAGS)<Record<string, number>>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getBenefits.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getBenefits.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.total = action.payload.total;
      draftState.availableTags = action.payload.availableTags;
      draftState.selectedTags = action.payload.selectedTags;
      draftState.benefitsList = action.payload.items.reduce((acc: Record<string, BenefitsType>, cur: BenefitsType) => {
        if (cur.slug) {
          acc[cur.slug] = cur;
        } else {
          acc[cur.title] = cur;
        }
        return acc;
      }, {});
    }),
  )
  .handleAction(actions.getBenefits.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getBenefits.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  )
  .handleAction(actions.getMoreBenefits.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getMoreBenefits.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.total = action.payload.total;
      draftState.benefitsList = action.payload.items.reduce(
        (acc: Record<string, BenefitsType>, cur: BenefitsType) => {
          if (cur.slug) {
            acc[cur.slug] = cur;
          } else {
            acc[cur.title] = cur;
          }
          return acc;
        },
        { ...state.benefitsList },
      );
    }),
  )
  .handleAction(actions.getMoreBenefits.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getMoreBenefits.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  )
  .handleAction(actions.getBenefitArticle.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getBenefitArticle.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.benefitsList = {
        [action.payload?.slug]: action.payload,
      };
    }),
  )
  .handleAction(actions.getBenefitArticle.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getBenefitArticle.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  )
  .handleAction(actions.setFilteredTags, (state: State = initialState, action) =>
    produce(state, (draftState) => {
      draftState.filteredTags = action.payload;
    }),
  );

const fetchBenefitsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_BENEFITS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getBenefits.request>[]
    >,
    switchMap(([action]: { payload: string }[]) =>
      ajax<BenefitsResponse>({
        url: `${apimBaseUrl}/${apimNafNoApi}/benefits?${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getBenefits.success(response)),
        catchError(() => of(actions.getBenefits.failure(new Boom.Boom('Could not get benefits')))),
      ),
    ),
  );
};

const fetchMoreBenefitsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MORE_BENEFITS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMoreBenefits.request>[]
    >,
    switchMap(([action]: { payload: string }[]) =>
      ajax<BenefitsResponse>({
        url: `${apimBaseUrl}/${apimNafNoApi}/benefits?${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getMoreBenefits.success(response)),
        catchError(() => of(actions.getMoreBenefits.failure(new Boom.Boom('Could not get benefits')))),
      ),
    ),
  );
};

const fetchBenefitArticleEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_BENEFIT_ARTICLE),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getBenefitArticle.request>[]
    >,
    switchMap(([action]: { payload: string }[]) =>
      ajax<BenefitsType>({
        url: `${apimBaseUrl}/${apimNafNoApi}/commonarticles/${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getBenefitArticle.success(response)),
        catchError(() => of(actions.getBenefitArticle.failure(new Boom.Boom('Could not get benefit article')))),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [fetchBenefitsEpic, fetchMoreBenefitsEpic, fetchBenefitArticleEpic];
