import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { QualityTypes } from '@cloudinary/url-gen/types/types';
import { useCallback, useEffect, useState } from 'react';
import { useCloudinary } from './useCloudinary';
import { useDevicePixelRatio } from './useDevicePixelRatio';
import { useWindowResize } from './useWindowResize';

type Props = { imageId?: string; initialDimensions?: { width: number; height: number }; quality?: QualityTypes };

export const useResponsiveImage = ({ imageId, initialDimensions, quality = 'auto:best' }: Props) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();

  const imageFunction = useCallback(
    (image: string, dimensions?: { width: number; height: number }) =>
      dimensions
        ? cld
            .image(image)
            .resize(fill().width(dimensions?.width).height(dimensions?.height).gravity(autoGravity()))
            .delivery(dpr(devicePixelRatio))
            .quality(quality)
            .format('auto')
        : cld.image(image).delivery(dpr(devicePixelRatio)).quality(quality).format('auto'),
    [cld, devicePixelRatio, quality],
  );

  const [mainImage, setMainImage] = useState(imageId ? imageFunction(imageId, initialDimensions) : undefined);

  const setResponsiveImage = useCallback(() => {
    if (imageId && setMainImage && imageFunction) {
      setMainImage(imageFunction(imageId));
    }
  }, [imageId, setMainImage, imageFunction]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });
  return mainImage;
};
