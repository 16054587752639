import React from 'react';
import styled from 'styled-components';

const RoadIcon = ({ size, fill = 'currentColor' }: { size: number; fill?: string }) => (
  <RoadIconWrapper>
    <svg width={size} height={size} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0987 0.8C14.9987 0.3 14.5987 0 14.0987 0H9.99874L10.1987 3H7.79874L7.99874 0H3.79874C3.29874 0 2.89874 0.4 2.79874 0.8L0.0987431 14.8C-0.00125694 15.4 0.498743 16 1.09874 16H6.99874L7.29874 11H10.6987L10.9987 16H16.7987C17.3987 16 17.8987 15.4 17.7987 14.8L15.0987 0.8ZM7.39874 9L7.59874 5H10.1987L10.3987 9H7.39874Z"
        fill={fill}
      />
    </svg>
  </RoadIconWrapper>
);

const RoadIconWrapper = styled.div`
  display: inline-flex;
`;

export default RoadIcon;
