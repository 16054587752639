import { Action, combineReducers } from '@reduxjs/toolkit';
import { combineEpics, Epic } from 'redux-observable';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

// Modules
import * as application from './modules/application';
import * as globalSettings from './modules/globalSettings';
import * as offline from './modules/offline';
import * as services from '../services';
import * as carFilter from './modules/carFilter';
import * as cars from './modules/cars';
import * as documents from './modules/documents';
import * as user from './modules/user';
import * as articles from './modules/articles';
import * as localArticles from './modules/localArticles';
import * as myNaf from './modules/myNaf';
import * as sitestructure from './modules/sitestructure';
import * as becomeAMember from './modules/becomeAMember';
import * as myMembership from './modules/myMembership';
import * as myInvoices from './modules/myInvoices';
import * as order from './modules/order';
import * as loggedInActivationOrder from './modules/loggedInActivationOrder';
import * as householdMembers from './modules/householdMembers';
import * as myVehicles from './modules/myVehicles';
import * as myMessages from './modules/myMessages';
import * as benefits from './modules/benefits';
import * as internationalDrivingPermit from './modules/internationalDrivingPermit';
import * as idpCountries from './modules/idpCountries';
import * as frontPage from './modules/frontPage';
import * as centers from './modules/centers';
import * as centerProducts from './modules/centerProducts';
import * as nafLocation from './modules/nafLocation';
import * as nafEvents from './modules/nafEvents';
import * as faq from './modules/faq';
import * as membershipBenefitSearch from './modules/membershipBenefitSearch';
import * as routePlanner from './modules/routePlanner';
import * as internalSiteSearch from './modules/internalSiteSearch';
import * as myProfile from './modules/myProfile';

// Register modules
const modules = {
  application,
  globalSettings,
  offline,
  carFilter,
  cars,
  documents,
  user,
  articles,
  localArticles,
  myNaf,
  sitestructure,
  becomeAMember,
  myMembership,
  myInvoices,
  order,
  loggedInActivationOrder,
  householdMembers,
  myVehicles,
  myMessages,
  benefits,
  internationalDrivingPermit,
  idpCountries,
  frontPage,
  centers,
  centerProducts,
  nafLocation,
  nafEvents,
  faq,
  membershipBenefitSearch,
  routePlanner,
  internalSiteSearch,
  myProfile,
};

export const createReducer = () =>
  combineReducers(
    mapValues(
      pickBy(modules, (v: any) => !!v.reducers),
      (v: any) => v.reducers,
    ) as {
      [Q in {
        [P in keyof typeof modules]: (typeof modules)[P] extends { reducers: any } ? P : never;
      }[keyof typeof modules]]: (typeof modules)[Q]['reducers'];
    },
  );
export const rootReducer = createReducer();

export const rootEpic = combineEpics(
  ...Object.values(
    mapValues(
      pickBy(modules, (v: any) => !!v.epics),
      (v: any) => v.epics,
    ),
  ).reduce<TypedEpic[]>((acc, cur) => acc.concat(Object.values(cur)), []),
);

// Types
export type RootState = ReturnType<typeof rootReducer>;
export type TypedEpic<CustomState = RootState, CustomDependencies = typeof services> = Epic<
  Action,
  Action,
  CustomState,
  CustomDependencies
>;
export type ActionTypes = {
  [Q in {
    [P in keyof typeof modules]: (typeof modules)[P] extends { ActionTypes: string } ? P : never;
  }[keyof typeof modules]]: (typeof modules)[Q]['ActionTypes'];
};
