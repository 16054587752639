import { UseFormReturn } from 'react-hook-form';
import React from 'react';
import { FormField } from '@dtp/membership-service-types';
import loadable from '@loadable/component';
import S from './Styles';

const FormFieldController = loadable(() => import('./FormFieldController'), {
  resolveComponent: (components) => components.FormFieldController,
});

interface Props {
  fieldComponents: FormField[];
  control: UseFormReturn['control'];
  handleSubmit: UseFormReturn['handleSubmit'];
  errors: UseFormReturn['formState']['errors'];
  watch?: UseFormReturn['watch'];
  onSubmit?: (values: Record<string, string | string[] | boolean | undefined>) => any;
  clearErrors?: UseFormReturn['clearErrors'];
  setValue?: UseFormReturn['setValue'];
  setError?: UseFormReturn['setError'];
}

export const Form = ({
  onSubmit,
  fieldComponents,
  control,
  watch,
  handleSubmit,
  errors,
  clearErrors,
  setValue,
  setError,
}: Props) => (
  <S.StyledForm onSubmit={() => onSubmit && handleSubmit(onSubmit)} encType="multipart/form-data">
    <FormFieldController
      fieldComponents={fieldComponents}
      control={control}
      errors={errors}
      watch={watch}
      clearErrors={clearErrors}
      setValue={setValue}
      setError={setError}
    />
  </S.StyledForm>
);
