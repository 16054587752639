import { useCallback, useContext, useEffect } from 'react';
import { themeJustSuper, themeLight, themeVisualBreak } from '@naf/theme';
import { useLocation } from 'react-router';
import { useSiteStructure } from './useSiteStructure';
import { ThemeVariants } from '../../../types/themes';
import { ArticleType, ProductArticleType } from '../../../types/articleType';
import { ThemeContextAction } from '../lib/ThemeContext';
import useSelector from '../redux/typedHooks';

export const useTheme = (initialTheme?: ThemeVariants | null) => {
  const { pathname } = useLocation();
  const setTheme = useContext(ThemeContextAction);
  const slug = pathname.split('/').slice(-1)[0];
  const document = useSelector((state) => state.documents.mappedData[slug]);
  const documentData: ArticleType | ProductArticleType | undefined = document?.data as ArticleType | undefined;
  const { data: siteStructureData } = useSiteStructure(slug) || {};
  const { mappedCategories } = useSelector((state) => state.sitestructure);

  const handleSwitchTheme = useCallback(
    (theme?: ThemeVariants | null) => {
      if (theme === ThemeVariants.Standard) {
        setTheme(themeLight);
      } else if (theme === ThemeVariants.VisualBreak) {
        setTheme(themeVisualBreak);
      } else if (theme === ThemeVariants.JustSuper) {
        setTheme(themeJustSuper);
      } else {
        setTheme(themeLight);
      }
    },
    [setTheme],
  );

  // Set initial theme from server data
  useEffect(() => {
    if (handleSwitchTheme && initialTheme) {
      handleSwitchTheme(initialTheme);
    }
  }, [handleSwitchTheme, initialTheme]);

  useEffect(() => {
    // Set theme from document data
    if (documentData?.theme) {
      handleSwitchTheme(documentData.theme);
    }
    // Set theme from category data
    else if (!documentData && siteStructureData?.theme) {
      handleSwitchTheme(siteStructureData.theme);
    }
    // Set theme from mapped categories from state
    else if (!siteStructureData && documentData?.category) {
      const { data: categoryData } = mappedCategories[documentData.category.slug];
      handleSwitchTheme(categoryData?.theme || null);
    }
    // Subarticle - set theme from mapped categories of parent
    else if (!siteStructureData && documentData && !documentData.category && documentData.parent?.category?.slug) {
      const { data: categoryData } = mappedCategories[documentData.parent.category.slug];
      handleSwitchTheme(categoryData?.theme || null);
    } else {
      handleSwitchTheme(null);
    }
  }, [documentData, siteStructureData, handleSwitchTheme, mappedCategories]);
};
