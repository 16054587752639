import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { ImageCaption } from '../../../../../ImageCaption';
import { DoubleImageSerializerProps } from './DoubleImageSerializerProps';
import ResponsiveImage from '../../../../../image/ResponsiveImage';

export const DoubleImageBlock = ({
  value: { imageRight, imageLeft, captionForBothImages },
}: DoubleImageSerializerProps) => {
  const {
    publicId: leftImagePublicId,
    caption: captionLeft,
    altText: leftImageAltText,
    copyright: copyrightLeft,
    photographer: photographerLeft,
  } = imageLeft || {};

  const {
    publicId: rightImagePublicId,
    caption: captionRight,
    altText: rightImageAltText,
    copyright: copyrightRight,
    photographer: photographerRight,
  } = imageRight || {};

  const shouldShowLeftCaption = captionLeft || copyrightLeft || photographerLeft;
  const shouldShowRightCaption = captionRight || copyrightRight || photographerRight;
  const hasSamePhotographer = photographerLeft && photographerRight && photographerLeft === photographerRight;

  const photographerValue = hasSamePhotographer
    ? photographerLeft
    : [photographerLeft, photographerRight].filter(Boolean).join(', ');

  return (
    <DoubleImageGrid>
      {captionForBothImages ? (
        <CaptionWrapper>
          <ImageCaption
            caption={captionForBothImages}
            copyright={copyrightLeft}
            photographer={photographerValue || undefined}
          />
        </CaptionWrapper>
      ) : (
        (shouldShowLeftCaption || shouldShowRightCaption) && (
          <CaptionWrapper>
            {shouldShowLeftCaption ? (
              <ImageCaption
                caption={`Foto 1:${captionLeft ? ` ${captionLeft}` : ''}`}
                copyright={copyrightLeft}
                photographer={photographerLeft || undefined}
              />
            ) : null}
            {shouldShowRightCaption ? (
              <ImageCaption
                caption={`Foto 2:${captionRight ? ` ${captionRight}` : ''}`}
                copyright={copyrightRight}
                photographer={photographerRight || undefined}
              />
            ) : null}
          </CaptionWrapper>
        )
      )}
      {leftImagePublicId ? (
        <LeftImageWrapper>
          <ResponsiveImage imageId={leftImagePublicId} altText={leftImageAltText || captionLeft} />
        </LeftImageWrapper>
      ) : null}
      {rightImagePublicId ? (
        <RightImageWrapper>
          <ResponsiveImage imageId={rightImagePublicId} altText={rightImageAltText || captionRight} />
        </RightImageWrapper>
      ) : null}
    </DoubleImageGrid>
  );
};

const DoubleImageGrid = styled.figure`
  grid-column: 1 / -1;
  display: grid;
  grid-gap: 0 32px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  margin: ${spacing.space32} 0;

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const CaptionWrapper = styled.div`
  margin: 0;
  line-height: 0;
  width: 100%;
  grid-column: 1 / 3;
  align-self: end;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    grid-row: 3;
  }
`;

const LeftImageWrapper = styled.div`
  margin: 0;
  line-height: 0;
  width: 100%;
  grid-column: 3 / 8;

  img {
    width: 100%;
    border-radius: 4px;
  }

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -2;
    order: 2;
    margin-top: -${spacing.space8};
    z-index: 2;
    margin-bottom: ${spacing.space16};
  }
`;

const RightImageWrapper = styled.div`
  margin: 0;
  line-height: 0;
  width: 100%;
  grid-column: 8 / 13;

  img {
    width: 100%;
    border-radius: 4px;
  }

  @media (max-width: ${breakpoints.m}) {
    order: 1;
    grid-column: 2 / -1;
  }
`;
