import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import useSelector from '../redux/typedHooks';

export const useOpenInCms = () => {
  const location = useLocation();
  const slug = location.pathname.split('/').pop();
  const category = useSelector((state) => (slug ? state.sitestructure.mappedCategories[slug]?.data : undefined));
  const document = useSelector((state) => (slug ? state.documents.mappedData[slug]?.data : undefined));
  const isEditor = useSelector((state) => state.application.isEditor);
  const cmsEnv = useSelector((state) => state.application.cmsEnv);

  useEffect(() => {
    if (category && isEditor && cmsEnv) {
      toast(category.name, {
        toastId: category.id || undefined,
        autoClose: false,
        onClick: () => {
          window.open(`${cmsEnv}/nafno/desk/__edit__${category.id}%2Ctype%3DnafnoSiteStructure`);
        },
      });
    }
    if (document && isEditor && cmsEnv) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toast(document?.name || document?.title, {
        toastId: document.id || undefined,
        autoClose: false,
        onClick: () => {
          if (document.id && document.type) {
            window.open(`${cmsEnv}/nafno/desk/__edit__${document.id}%2Ctype%3D${document.type}`);
          }
        },
      });
    }
  }, [category, document, isEditor, cmsEnv]);
};
