import React, { useMemo } from 'react';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import styled, { css } from 'styled-components';
import { ChargeCurveWrapper } from './ChargeCurveWrapper';
import { RangeBars } from './RangeBars';
import { ScatterPlot } from './ScatterPlot';
import { Table } from './Table';
import { TestResultBlock } from '../../../../../types/testResultBlock';
import BlockContent from '../../block-content/BlockContent';
import { Dumbbell } from './Dumbbell';
import { BatteryChargeTime } from './BatteryChargeTime';

type Props = {
  block: TestResultBlock;
};

export const Results: React.FC<Props> = ({ block }) => {
  const { data, chartType, allCategories, includeFilters, category } = block;
  const { testedVehicles, season, tests } = data;

  const allCategoriesWithVehicles = useMemo(
    () => allCategories?.filter(({ id }) => testedVehicles.some((d) => d.categories?.some((v) => v.id === id))),
    [allCategories, testedVehicles],
  );

  let chartData = testedVehicles;

  switch (chartType) {
    case 'barChart':
      chartData = chartData.filter(
        ({ testData }) => testData?.rangeTest?.statedRangeKm && testData?.rangeTest?.measuredRangeKm,
      );
      return (
        <ResultWrapper block={block}>
          <RangeBars
            tests={tests}
            includeFilters={includeFilters}
            categories={allCategoriesWithVehicles}
            data={chartData}
            initialCategories={category ? [category] : undefined}
          />
        </ResultWrapper>
      );
    case 'bubbleChart':
      chartData = chartData.filter((d) => d.price && d?.testData?.rangeTest?.measuredRangeKm);
      return (
        <ResultWrapper block={block}>
          <ScatterPlot
            includeFilters={includeFilters}
            categories={allCategoriesWithVehicles}
            data={chartData}
            tests={tests}
            initialCategories={category ? [category] : undefined}
          />
        </ResultWrapper>
      );
    case 'chargeCurve':
      chartData = chartData.filter((d) => d.testData?.chargeTest?.readings);
      return (
        <ResultWrapper block={block}>
          <ChargeCurveWrapper data={chartData} season={season} />
        </ResultWrapper>
      );
    case 'table':
      chartData = chartData.filter(({ testData }) => testData?.rangeTest);
      return (
        <ResultWrapper block={block}>
          <Table data={chartData} />
        </ResultWrapper>
      );

    case 'dumbbell':
      chartData = chartData.filter(
        ({ testData }) =>
          testData?.rangeTest?.statedConsumptionKWhper100km && testData?.rangeTest?.measuredConsumptionKWhper100km,
      );
      return (
        <ResultWrapper block={block}>
          <Dumbbell
            includeFilters={includeFilters}
            categories={allCategoriesWithVehicles}
            data={chartData}
            tests={tests}
          />
        </ResultWrapper>
      );
    case 'batteryChargeTime':
      chartData = chartData.filter(
        ({ testData }) =>
          testData?.chargeTest?.measuredTimeTo80PercentMinutes &&
          testData?.chargeTest?.batteryCapacity &&
          testData?.chargeTest?.averageChargeEfficiency,
      );
      return (
        <ResultWrapper block={block}>
          <BatteryChargeTime
            includeFilters={includeFilters}
            categories={allCategoriesWithVehicles}
            data={chartData}
            tests={tests}
            initialCategories={category ? [category] : undefined}
          />
        </ResultWrapper>
      );

    default:
      return null;
  }
};

const ResultWrapper: React.FC<Props & { children: React.ReactNode }> = ({ block, children }) => {
  const { title, content, chartType, caption } = block;

  if (chartType === 'table') {
    return (
      <div>
        <Heading variant={TextVariant.Header2}>{title}</Heading>
        <BlockContent value={content} />
        <ChartWrapper as="div" $asTable>
          {children}
        </ChartWrapper>
        <Caption asTable>{caption}</Caption>
      </div>
    );
  }
  return (
    <ChartWrapper>
      <Heading variant={TextVariant.Header2}>{title}</Heading>
      <BlockContent value={content} />
      {children}
      <figcaption>
        <Caption className="highcharts-description">{caption}</Caption>
      </figcaption>
    </ChartWrapper>
  );
};

export const ChartWrapper = styled.figure<{ $asTable?: boolean }>`
  ${({ $asTable }) =>
    $asTable
      ? css`
          @media (max-width: ${breakpoints.m}) {
            overflow-x: auto;
          }
        `
      : null};

  margin: 0;
  width: 100%;
`;

const Heading = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space4};
`;

const Caption = styled(Text)<{ asTable?: boolean }>`
  font-style: italic;
  margin-top: ${({ asTable }) => (asTable ? spacing.space24 : '0')};
  margin-bottom: 0;
`;
