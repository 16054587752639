import styled from 'styled-components';
import { breakpoints } from '@naf/theme';

export const ArticleBlockContentGridCol = styled.div`
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;
