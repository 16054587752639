import { useCallback, useEffect, useState } from 'react';

export interface DevicePixelRatioOptions {
  /**
   * Default DPR to use if browser does not support the `devicePixelRatio`
   * property, or when rendering on server
   *
   * @defaultValue `2`
   */
  defaultDpr?: number;

  /**
   * Whether or not to round the number down to the closest integer
   *
   * @defaultValue `true`
   */
  round?: boolean;

  /**
   * Maximum DPR to return (set to `2` to only generate 1 and 2)
   *
   * @defaultValue `3`
   */
  maxDpr?: number;
}

/**
 * Returns the current device pixel ratio (DPR) given the passed options
 *
 * @param options
 * @returns current device pixel ratio
 */
export function getDevicePixelRatio(options?: DevicePixelRatioOptions): number {
  const { defaultDpr = 3, maxDpr = 5, round = true } = options || {};
  const hasDprProp = typeof window !== 'undefined';
  const dpr = hasDprProp ? window.devicePixelRatio : defaultDpr;
  return Math.min(Math.max(3, round ? Math.floor(dpr) : dpr), maxDpr);
}

export function useDevicePixelRatio(options?: DevicePixelRatioOptions) {
  const dpr = getDevicePixelRatio(options);
  const [currentDpr, setCurrentDpr] = useState(dpr);
  const updateDpr = useCallback(() => setCurrentDpr(getDevicePixelRatio(options)), [options]);

  useEffect(() => {
    if (typeof window !== 'undefined' && 'matchMedia' in window) {
      const mediaMatcher = window.matchMedia(`screen and (resolution: ${currentDpr}dppx)`);
      if (mediaMatcher?.addEventListener) {
        mediaMatcher.addEventListener('change', updateDpr);
        return () => {
          mediaMatcher.removeEventListener('change', updateDpr);
        };
      }
    }
    return () => {};
  }, [currentDpr, options, updateDpr]);

  return currentDpr;
}
