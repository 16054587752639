export enum formIds {
  FEEDBACK_FORM = '7D8E95EB-E98C-4EF4-8F48-05EFD5DA75DF',
  MONTHLY_OR_YEARLY_FEEDBACK_FORM = '96fca18c-3ed9-4c6b-9d05-677983c20678',
}

interface FieldValueType {
  fieldName: string;
  value: string;
}

interface FieldValueValidation extends FieldValueType {
  isValid: boolean;
}

export interface FormValidationResult {
  result: {
    formId: formIds;
    currentValues: FieldValueType[];
    fieldValidations: FieldValueValidation[];
    fields: any;
    isValid: boolean;
  };
}

export interface FormType {
  formId: formIds;
  fieldValues: FieldValueType[];
}
