import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { SiteStructureType } from '../../../../types/siteStructureType';
import { CategoryAppType } from '../../../../types/CategoryAppType';

// Actions
export enum Actions {
  GET_SITESTRUCTURE = 'naf/sitestructure/GET_SITESTRUCTURE',
  GET_SITESTRUCTURE_SUCCESS = 'naf/sitestructure/GET_SITESTRUCTURE_SUCCESS',
  GET_SITESTRUCTURE_FAIL = 'naf/sitestructure/GET_SITESTRUCTURE_FAIL',
  GET_SITESTRUCTURE_CANCEL = 'naf/sitestructure/GET_SITESTRUCTURE_CANCEL',

  GET_CATEGORYAPP = 'naf/sitestructure/GET_CATEGORYAPP',
  GET_CATEGORYAPP_SUCCESS = 'naf/sitestructure/GET_CATEGORYAPP_SUCCESS',
  GET_CATEGORYAPP_FAIL = 'naf/sitestructure/GET_CATEGORYAPP_FAIL',
  GET_CATEGORYAPP_CANCEL = 'naf/sitestructure/GET_CATEGORYAPP_CANCEL',
}

export interface DataState<T> {
  data?: T;
  meta: {
    isUpdating: boolean;
    isEnriched: boolean;
    fetchedAt?: string;
    isLoggedIn?: boolean;
  };
  error?: Error;
}

type CategoryAppKey = `${SiteStructureType['slug']}/${string}`;

export interface State {
  isUpdating: boolean;
  errorState?: Error;
  mappedCategories: Record<SiteStructureType['slug'], DataState<SiteStructureType>>;
  globalTags: Record<SiteStructureType['slug'], DataState<SiteStructureType>>;
  categoryApps: Record<CategoryAppKey, DataState<CategoryAppType>>;
}

export const initialState: State = {
  mappedCategories: {},
  categoryApps: {},
  isUpdating: false,
  globalTags: {},
};

export interface GetCateogryAppRequest {
  slug: SiteStructureType['slug'];
  app: string;
}

export function getCategoryAppKey(request: GetCateogryAppRequest): CategoryAppKey {
  return `${request.slug}/${request.app}`;
}

export const actions = {
  getSiteStructure: createAsyncAction(
    Actions.GET_SITESTRUCTURE, // request payload creator
    Actions.GET_SITESTRUCTURE_SUCCESS, // success payload creator
    Actions.GET_SITESTRUCTURE_FAIL, // failure payload creator
    Actions.GET_SITESTRUCTURE_CANCEL, // optional cancel payload creator
  )<string, [SiteStructureType, { id: string }], [Error, { id: string }], [undefined, { id: string }]>(),
  getCategoryApp: createAsyncAction(
    Actions.GET_CATEGORYAPP, // request payload creator
    Actions.GET_CATEGORYAPP_SUCCESS, // success payload creator
    Actions.GET_CATEGORYAPP_FAIL, // failure payload creator
    Actions.GET_CATEGORYAPP_CANCEL, // optional cancel payload creator
  )<
    [GetCateogryAppRequest, { token?: string }],
    [CategoryAppType, GetCateogryAppRequest],
    [Error, GetCateogryAppRequest],
    [undefined, GetCateogryAppRequest]
  >(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getSiteStructure.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedCategories[action.payload] = {
        data: draftState.mappedCategories[action.payload]?.data || undefined,
        meta: {
          isUpdating: true,
          isEnriched: false,
          fetchedAt: draftState.mappedCategories[action.payload]?.meta.fetchedAt || undefined,
        },
      };
    }),
  )
  .handleAction(actions.getSiteStructure.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedCategories[action.meta.id] = {
        meta: { isUpdating: false, isEnriched: true, fetchedAt: new Date().toISOString() },
        data: { ...draftState.mappedCategories[action.meta.id]?.data, ...action.payload },
      };
    }),
  )
  .handleAction(actions.getSiteStructure.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedCategories[action.meta.id] = {
        meta: { isUpdating: false, isEnriched: false },
        error: action.payload,
      };
    }),
  )
  .handleAction(actions.getSiteStructure.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedCategories[action.meta.id] = {
        ...draftState.mappedCategories[action.meta.id],
        ...{ meta: { isUpdating: false, isEnriched: false }, error: action.payload },
      };
    }),
  )
  .handleAction(actions.getCategoryApp.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      const key = getCategoryAppKey(action.payload);
      draftState.categoryApps[key] = {
        data: draftState.categoryApps[key]?.data || undefined,
        meta: {
          isUpdating: true,
          isEnriched: false,
          fetchedAt: draftState.categoryApps[key]?.meta.fetchedAt || undefined,
          isLoggedIn: !!action.meta.token,
        },
      };
    }),
  )
  .handleAction(actions.getCategoryApp.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      const key = getCategoryAppKey(action.meta);
      draftState.categoryApps[key] = {
        meta: {
          ...draftState.categoryApps[key].meta,
          isUpdating: false,
          isEnriched: true,
          fetchedAt: new Date().toISOString(),
        },
        data: { ...draftState.categoryApps[key]?.data, ...action.payload },
      };
    }),
  )
  .handleAction(actions.getCategoryApp.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      const key = getCategoryAppKey(action.meta);
      draftState.categoryApps[key] = {
        meta: { isUpdating: false, isEnriched: false },
        error: action.payload,
      };
    }),
  )
  .handleAction(actions.getCategoryApp.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      const key = getCategoryAppKey(action.meta);
      draftState.categoryApps[key] = {
        ...draftState.categoryApps[key],
        ...{ meta: { isUpdating: false, isEnriched: false }, error: action.payload },
      };
    }),
  );

export const fetchSiteStructureEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_SITESTRUCTURE),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getSiteStructure.request>[]
    >,
    mergeMap(([action]: { payload: string }[]) =>
      ajax<SiteStructureType>({
        url: `${apimBaseUrl}/${apimNafNoApi}/sitestructure/${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getSiteStructure.success(response, { id: action.payload })),
        catchError(() =>
          of(actions.getSiteStructure.failure(new Boom.Boom('Could not get sitestructure'), { id: action.payload })),
        ),
      ),
    ),
  );
};

export const fetchCategoryAppEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_CATEGORYAPP),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getCategoryApp.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = { 'Ocp-Apim-Subscription-Key': apimContentHub };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<CategoryAppType>({
        url: `${apimBaseUrl}/${apimNafNoApi}/sitestructure/category/${action.payload.slug}/app/${action.payload.app}`,
        headers,
      }).pipe(
        map(({ response }) => actions.getCategoryApp.success(response, action.payload)),
        catchError(() =>
          of(actions.getCategoryApp.failure(new Boom.Boom('Could not get category app'), action.payload)),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchSiteStructureEpic, fetchCategoryAppEpic];
