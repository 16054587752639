import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 31px 31px 0 rgba(5, 46, 28, 0.1);
  border-radius: ${spacing.space8};
  margin-bottom: ${spacing.space48};
`;

const AlreadyAMember = styled.div`
  display: flex;
  height: ${spacing.space56};
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background-color: ${nafColor.primary.dew};
  border-radius: ${spacing.space8} ${spacing.space8} 0 0;
  padding: 0 ${spacing.space8};

  span {
    @media (max-width: ${breakpoints.s}) {
      font-size: 0.9375rem;
    }
  }

  button {
    margin-right: ${spacing.space8};
    background: none;
    border: none;
    font-weight: 700;
    text-decoration: underline;
    color: ${nafColor.signature.black};

    &:hover {
      color: ${nafColor.signature.black};
      cursor: pointer;
    }

    @media (max-width: ${breakpoints.s}) {
      font-size: 0.9375rem;
    }
  }
`;

const MainContentWrapper = styled.div`
  padding: ${spacing.space48} ${spacing.space24};

  @media (min-width: ${breakpoints.l}) {
    padding: ${spacing.space80};
  }
`;

const Header = styled(Text)`
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: ${spacing.space12};
  font-size: 1.75rem;
  font-weight: 900;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  > button {
    margin: ${spacing.space8};
  }

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
    width: 100%;

    > button {
      width: 100%;
      margin: ${spacing.space8} 0;
    }
  }
`;

const TextWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  padding: ${spacing.space24} 0;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  text-align: center;
  font-style: italic;
`;

export default {
  Box,
  AlreadyAMember,
  MainContentWrapper,
  Header,
  ButtonWrapper,
  TextWrapper,
};
