import React from 'react';
import styled from 'styled-components';
import { Accordion } from '@naf/accordion';
import { Table, TableBody, TableRow, TableCell } from '@naf/table';
import { breakpoints } from '@naf/theme';
import { mapUnitInData as UMapper, numberWithSpaces } from '../../../utils';
import { terms as T, termsMapper as TMapper } from '../../../utils/bilguide';
import { LINK_COLOR, LINK_HOVER_COLOR } from '../../../styles/colors';

interface Props {
  data: any;
}

const renderDataRow = (d: any[]) => {
  const term = d[0];
  const value = d[1];

  // Remove hurtiglader and ladekabel info for now, due to unreliable data
  if (term === 'chargingCable' || term === 'connectionType' || term === 'fastCharger') return null;

  let unit = '';
  if (!!value && value !== 'tbi' && term.includes('_')) {
    unit = ` ${UMapper(term)}`;
  }
  if (TMapper(term) === 'Pris' || term === 'trailerPrice') {
    unit = ',-';
  }
  return (
    !!value &&
    value !== 'tbi' && (
      <TableRow key={`${value}-fragment-${term}}`}>
        <TableCell as="th">{TMapper(term)}</TableCell>
        <StyledTableCell as="td">
          {typeof value !== 'number' ? value : `${numberWithSpaces(value)}${unit}`}
        </StyledTableCell>
      </TableRow>
    )
  );
};

// ? Need to be represented with table component from design system
const CarVariantData = ({ data }: Props) => {
  const { vehicleVariantSpecification } = data;
  const generalData = vehicleVariantSpecification[T.GENERAL];

  // TODO: Map these
  const electricEmissionData = vehicleVariantSpecification[T.ELECTRIC_EMISSION_DETAILS];
  const sizesData = vehicleVariantSpecification[T.SIZE_AND_WEIGHT];
  const equipmentData = vehicleVariantSpecification[T.EQUIPMENT];

  return (
    <div>
      {generalData && (
        <Accordion label={TMapper(T.GENERAL)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(generalData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}
      {electricEmissionData && (
        <Accordion label={TMapper(T.ELECTRIC_EMISSION_DETAILS)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(electricEmissionData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}
      {sizesData && (
        <Accordion label={TMapper(T.SIZE_AND_WEIGHT)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(sizesData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}
      {equipmentData && (
        <Accordion label={TMapper(T.EQUIPMENT)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(equipmentData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}

      <Credit>
        Opplysningene er hentet og gjengitt med samtykke fra Opplysningsrådet for veitrafikken AS,&nbsp;
        <StyledTextLink href="https://ofv.no/" target="_blank" rel="noreferrer">
          ofv.no
        </StyledTextLink>
      </Credit>
    </div>
  );
};

const StyledTableCell = styled(TableCell)`
  @media (max-width: ${breakpoints.s}) {
    padding-left: 10px;
  }
`;

const Credit = styled.p`
  font-size: 1rem;
`;

const StyledTextLink = styled.a`
  cursor: pointer;
  color: ${LINK_COLOR};
  text-decoration: underline;
  &:hover {
    color: ${LINK_HOVER_COLOR};
    text-decoration: none;
  }
`;

export default CarVariantData;
