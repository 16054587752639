import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: inherit;
`;

export { SpinnerWrapper };
