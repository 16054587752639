import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@naf/button';
import { TextVariant } from '@naf/text';
import { Dropdown } from '@naf/dropdown';
import { articleChargeReferenceType, articleRangeReferenceType } from '../../../../types/articleType';
import { RelatedTestCard } from './RelatedTestCard';
import { ChargeTestType, RangeTestType } from '../../../../types/testType';
import S from './StyledRelatedCars';

interface Props {
  value: {
    type: 'rangeTestsComponent' | 'chargeTestsComponent';
    rangetests?: RangeTestType[];
    chargetests?: ChargeTestType[];
    initialNumberOfElements?: number;
  };
}

interface DropdownValue {
  label: string;
  value: string;
  property: string;
  optionNumber: number;
  ascending: boolean;
}

const dropdownRangeContent: Record<string, DropdownValue> = {
  statedRange_km_high: {
    label: 'Lengst rekkevidde',
    value: 'statedRange_km_high',
    property: 'statedRange_km',
    optionNumber: 1,
    ascending: false,
  },
  statedRange_km_low: {
    label: 'Kortest rekkevidde',
    value: 'statedRange_km_low',
    property: 'statedRange_km',
    optionNumber: 2,
    ascending: true,
  },
  summer_km_high: {
    label: 'Lengst rekkevidde sommer',
    value: 'summer_km_high',
    property: 'summer_km',
    optionNumber: 3,
    ascending: false,
  },
  summer_km_low: {
    label: 'Kortest rekkevidde sommer',
    value: 'summer_km_low',
    property: 'summer_km',
    optionNumber: 4,
    ascending: true,
  },
  winter_km_high: {
    label: 'Lengst rekkevidde vinter',
    value: 'winter_km_high',
    property: 'winter_km',
    optionNumber: 5,
    ascending: false,
  },
  winter_km_low: {
    label: 'Kortest rekkevidde vinter',
    value: 'winter_km_low',
    property: 'winter_km',
    optionNumber: 6,
    ascending: true,
  },
  Alfabetisk: {
    label: 'Alfabetisk',
    value: 'Alfabetisk',
    property: 'name',
    optionNumber: 7,
    ascending: true,
  },
};

const dropdownChargeContent: Record<string, DropdownValue> = {
  summerChargeTime_minutes_low: {
    label: 'Kortest ladetid sommer',
    value: 'summerChargeTime_minutes_low',
    property: 'summerChargeTime_Minutes',
    optionNumber: 1,
    ascending: true,
  },
  summerChargeTime_minutes_high: {
    label: 'Lengst ladetid sommer',
    value: 'summerChargeTime_minutes_high',
    property: 'summerChargeTime_Minutes',
    optionNumber: 2,
    ascending: false,
  },
  winterChargeTime_minutes_low: {
    label: 'Kortest ladetid vinter',
    value: 'winterChargeTime_minutes_low',
    property: 'winterChargeTime_Minutes',
    optionNumber: 3,
    ascending: true,
  },
  winterChargeTime_minutes_high: {
    label: 'Lengst ladetid vinter',
    value: 'winterChargeTime_minutes_high',
    property: 'winterChargeTime_Minutes',
    optionNumber: 4,
    ascending: false,
  },
  Alfabetisk: {
    label: 'Alfabetisk',
    value: 'Alfabetisk',
    property: 'name',
    optionNumber: 5,
    ascending: true,
  },
};

const RelatedCarList = ({ value }: Props) => {
  const testType = value.type;
  const isRangeType = testType && testType === 'rangeTestsComponent';
  const isChargeType = testType && testType === 'chargeTestsComponent';
  const sortDropdownContent = isRangeType ? dropdownRangeContent : dropdownChargeContent;

  const options = Object.values(sortDropdownContent).sort((e1, e2) => e1.optionNumber - e2.optionNumber);
  const [sortType, setSortType] = useState<DropdownValue>(
    isRangeType ? dropdownRangeContent.statedRange_km_high : dropdownChargeContent.summerChargeTime_minutes_low,
  );

  const [sortedCarList, setSortedCarList] = useState(value.rangetests || value.chargetests || []);

  useEffect(() => {
    if (value.initialNumberOfElements) {
      setShowMaxItems(value.initialNumberOfElements);
    }
  }, [value.initialNumberOfElements]);

  const sortArray = useCallback(
    (type: DropdownValue, list: (RangeTestType | ChargeTestType)[]) => {
      const sortProperty = sortDropdownContent[type.value];

      // Split original list to avoid sorting items that does not have property
      const unsortableItems: any[] = [];
      const sortedItems: any[] = [];

      list?.forEach((item: any) => (item[sortProperty.property] ? sortedItems.push(item) : unsortableItems.push(item)));

      if (sortedItems) {
        if (sortProperty.property === 'name') {
          sortedItems.sort((a, b) => {
            const x = `${a[sortProperty.property]}`.toUpperCase();
            const y = `${b[sortProperty.property]}`.toUpperCase();
            if (x === y) return 0;

            return x > y ? 1 : -1;
          });
        } else
          sortedItems.sort((a, b) =>
            sortProperty.ascending
              ? a[sortProperty.property] - b[sortProperty.property]
              : b[sortProperty.property] - a[sortProperty.property],
          );
      }
      // Merge lists with undefined items at the bottom
      setSortedCarList([...sortedItems, ...unsortableItems]);
    },
    [sortDropdownContent],
  );

  useEffect(() => {
    sortArray(sortType, value.rangetests || value.chargetests || []);
  }, [sortArray, sortType, sortDropdownContent, value.chargetests, value.rangetests]);

  const handleSort = (selectedValue: DropdownValue) => {
    setSortType(selectedValue);
  };

  // Show default 5 to begin with
  const [showMaxItems, setShowMaxItems] = useState<number>(value.initialNumberOfElements || 5);
  const [currentScrollTop, setCurrentScrollTop] = useState<number>(0);

  const vehicleCounts = sortedCarList.length;

  useEffect(() => {
    window.scrollTo(0, currentScrollTop);
  }, [currentScrollTop]);

  const onShowMoreBtnClick = () => {
    if (vehicleCounts > showMaxItems + 5) {
      setShowMaxItems(showMaxItems + 5);
    } else setShowMaxItems(showMaxItems + (vehicleCounts - showMaxItems));

    setCurrentScrollTop(window.scrollY);
  };

  return (
    <>
      <S.ListHeader tag="h2" variant={TextVariant.Header2}>
        Testresultat
      </S.ListHeader>
      {testType && (
        <>
          <S.DropdownWrapper>
            <Dropdown
              options={options}
              justify="right"
              selected={sortType}
              handleSelect={(selectedValue: DropdownValue) => handleSort(selectedValue)}
            />
          </S.DropdownWrapper>
          {isRangeType && sortedCarList && (
            <S.ElementRelatedCarList>
              {(sortedCarList as unknown as articleRangeReferenceType[])
                .slice(0, showMaxItems)
                .map((rangeTest: articleRangeReferenceType) => (
                  <S.ElementRelatedCarListChild key={`relatedRangeTest-${rangeTest.name}`}>
                    <RelatedTestCard carTest={rangeTest} />
                  </S.ElementRelatedCarListChild>
                ))}
            </S.ElementRelatedCarList>
          )}
          {isChargeType && sortedCarList && (
            <S.ElementRelatedCarList>
              {(sortedCarList as unknown as articleChargeReferenceType[])
                .slice(0, showMaxItems)
                .map((chargeTest: articleChargeReferenceType) => (
                  <S.ElementRelatedCarListChild key={`relatedChargeTest-${chargeTest.name}`}>
                    <RelatedTestCard carTest={chargeTest} />
                  </S.ElementRelatedCarListChild>
                ))}
            </S.ElementRelatedCarList>
          )}
        </>
      )}
      {showMaxItems < vehicleCounts ? (
        <S.ShowMoreButtonWrapper>
          {showMaxItems < vehicleCounts && (
            <Button variant="outline" onClick={onShowMoreBtnClick}>
              Vis flere
            </Button>
          )}
          <p>
            Viser {showMaxItems} av {vehicleCounts}
          </p>
        </S.ShowMoreButtonWrapper>
      ) : (
        <p>
          Viser {vehicleCounts} av {vehicleCounts}
        </p>
      )}
    </>
  );
};

export default RelatedCarList;
