import React from 'react';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { YoutubeEmbed, YoutubeVideo } from '../../../../YoutubeEmbed';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface YoutubeEmbedSerializerProps {
  value: YoutubeVideo;
}
export const YoutubeSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: YoutubeEmbedSerializerProps) => (
      <ArticleBlockContentGridCol>
        <YoutubeEmbed embed={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: YoutubeEmbedSerializerProps) => (
    <Wrapper>
      <YoutubeEmbed embed={value} />
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  margin: ${spacing.space8} 0 ${spacing.space24} 0;
`;
