import React from 'react';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface CodeProps {
  value: { language: string; code: string };
}

export const CodeTypesBlock = ({ value: { language, code } }: CodeProps) => (
  <pre data-language={language}>
    <code>{code}</code>
  </pre>
);

export const CodeTypesSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: CodeProps) => (
      <ArticleBlockContentGridCol>
        <CodeTypesBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: CodeProps) => <CodeTypesBlock value={value} />;
};
