import React from 'react';
import ElPrixDesktop from '../../../../../../assets/decorations/elprix/ElPrixDesktop.svg';
import ElPrixMobile from '../../../../../../assets/decorations/elprix/ElPrixMobile.svg';
import * as S from './Styles';

export const ElPrix = ({ isScrolled }: { isScrolled?: boolean }) => (
  <S.ElPrixWrapper>
    <S.DesktopWrapper $isScrolled={isScrolled}>
      <ElPrixDesktop />
    </S.DesktopWrapper>
    <S.MobileWrapper>
      <ElPrixMobile />
    </S.MobileWrapper>
  </S.ElPrixWrapper>
);
