import { spacing } from '@naf/theme';
import React from 'react';
import styled from 'styled-components';

export const RelatedModelSkeleton = () => (
  <Container>
    <div>
      <Categories />
      <Title />
    </div>
    <ImagePlaceholder />
  </Container>
);

const Container = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.border.subtle};
  justify-content: space-between;
  padding: ${spacing.space16} ${spacing.space16} ${spacing.space16} ${spacing.space24};
`;

const Categories = styled.div`
  height: 12px;
  width: 64px;
  background: ${({ theme }) => theme.border.subtle};
  margin-bottom: 4px;
  border-radius: 2px;
`;

const Title = styled.div`
  height: 16px;
  width: 164px;
  border-radius: 2px;
  background: ${({ theme }) => theme.border.subtle};
`;

const ImagePlaceholder = styled.div`
  height: 37.5px;
  width: 50px;
  background: ${({ theme }) => theme.border.subtle};
`;
