import { Snippets } from './Snippets';

export const TagManager = {
  dataScript(dataLayer: any) {
    const initScript: (Element & { nonce?: string }) | null = document.querySelector('#tag-manager-mount');
    const script: Element & { nonce?: string } = document.createElement('script');
    script.innerHTML = dataLayer;
    script.nonce = initScript?.nonce;
    return script;
  },
  gtm(args: {
    dataLayer: any;
    dataLayerName: keyof Window;
    auth: string;
    preview: string;
    events: Record<string, any>;
    id: string;
  }) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const currentNoScript: (Element & { nonce?: string }) | null = document.getElementById(
        'google-server-tagging-no-script',
      );
      const initScript: (Element & { nonce?: string }) | null = document.querySelector('#tag-manager-mount');
      if (currentNoScript === null) {
        const noscript: Element & { nonce?: string } = document.createElement('noscript');
        noscript.id = 'google-server-tagging-no-script';
        noscript.nonce = initScript?.nonce;
        noscript.innerHTML = snippets.iframe;
        return noscript;
      }
      currentNoScript.nonce = initScript?.nonce;
      return currentNoScript;
    };

    const script = () => {
      const currentScript: (Element & { nonce?: string }) | null = document.getElementById('google-server-tagging');
      const initScript: (Element & { nonce?: string }) | null = document.querySelector('#tag-manager-mount');
      if (currentScript === null || currentScript.nonce === null) {
        const insertedScript: Element & { nonce?: string } = document.createElement('script');
        insertedScript.innerHTML = snippets.script;
        insertedScript.nonce = initScript?.nonce;
        return insertedScript;
      }
      currentScript.nonce = initScript?.nonce;
      return currentScript;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },
  initialize({
    gtmId,
    events = {},
    dataLayer,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
  }: {
    dataLayer: any;
    dataLayerName: keyof Window;
    auth?: string;
    preview?: string;
    events?: Record<string, any>;
    gtmId: string;
  }) {
    const gtm = this.gtm({
      id: gtmId,
      events,
      dataLayer: undefined,
      dataLayerName,
      auth,
      preview,
    });
    if (dataLayer) document.head.appendChild(gtm.dataScript);
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
  dataLayer({ dataLayer, dataLayerName = 'dataLayer' }: { dataLayer: any; dataLayerName: keyof Window }) {
    if (window[dataLayerName]) return window[dataLayerName].push(dataLayer);
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);
    document.head.insertBefore(dataScript, document.head.childNodes[0]);
    return undefined;
  },
};
