import React from 'react';
import RelatedCarList from '../../../../related-cars/RelatedCarList';
import { ChargeTestType } from '../../../../../../../types/testType';
import { TestTable } from '../../../../test-table/TestTable';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface ChargeTestSerializerProps {
  value: {
    type: 'chargeTestsComponent';
    viewMode?: 'table' | 'list';
    chargetests: ChargeTestType[];
    initialNumberOfElements: number;
    selectedTableFields: (keyof ChargeTestType)[];
    isResponsive?: boolean;
  };
}

const ChargeTestBlock = ({ value }: ChargeTestSerializerProps) => {
  if (value.viewMode === 'table') {
    return (
      <TestTable
        type="chargeTests"
        tests={value.chargetests}
        selectedTableFields={value.selectedTableFields}
        isResponsive={value.isResponsive}
      />
    );
  }
  return <RelatedCarList value={value} />;
};

export const ChargeTestSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: ChargeTestSerializerProps) => (
      <ArticleBlockContentGridCol>
        <ChargeTestBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: ChargeTestSerializerProps) => <ChargeTestBlock value={value} />;
};
