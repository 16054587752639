import React, { useEffect, useState } from 'react';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface HighChartsSerializerProps {
  value: { jsonStr: string; _key: string };
}
export const HighChartsBlock = ({ value: { jsonStr, _key } }: HighChartsSerializerProps) => {
  const [Highcharts, setHighcharts] = useState<any>();
  const [HighchartsReact, setHighchartsReact] = useState<any>();

  useEffect(() => {
    const initHighcharts = async () => {
      const dynamicallyImportedHighcharts = await import('highcharts');
      const { default: dynamicallyImportedMore } = await import('highcharts/highcharts-more');
      const { HighchartsReact: dynamicallyImportedHighchartsReact } = await import('highcharts-react-official');

      setHighchartsReact(dynamicallyImportedHighchartsReact);

      if (dynamicallyImportedHighcharts && dynamicallyImportedMore) {
        dynamicallyImportedMore(dynamicallyImportedHighcharts);
      }
      setHighcharts(dynamicallyImportedHighcharts);
    };
    initHighcharts();
  }, []);

  try {
    if (jsonStr && _key && HighchartsReact && Highcharts) {
      const options = JSON.parse(jsonStr);
      return <HighchartsReact key={_key} highcharts={Highcharts} options={options} />;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed to load highcharts options', e);
  }
  return null;
};

export const HighChartsSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: HighChartsSerializerProps) => (
      <ArticleBlockContentGridCol>
        <HighChartsBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: HighChartsSerializerProps) => <HighChartsBlock value={value} />;
};
