import xor from 'lodash/xor';
import qs from 'qs';
import { FilterType, UrlParamsType } from '../../../types/urlParamsType';
import { replaceQueryWindowHistoryState } from './replaceQueryWindowState';
import { getURLParams } from '../lib/getUrlParams';

export const updateUrlQueryValues = {
  updateCombinedFilterValue: (filterName: keyof FilterType, tagName: string): void => {
    const params = getURLParams();
    if (typeof params.filter !== 'undefined' && typeof params.filter[filterName] !== 'undefined') {
      const newArray = Array.from(params.filter[filterName] as string[]);
      const mutatedNewArray = xor(newArray, [tagName]);
      params.filter[filterName] = mutatedNewArray;
      if (mutatedNewArray.length === 0) {
        delete params.filter[filterName];
      }
    } else if (typeof params.filter !== 'undefined' && typeof params.filter[filterName] === 'undefined') {
      params.filter[filterName] = [tagName];
    } else if (typeof params.filter === 'undefined') {
      params.filter = {};
      params.filter[filterName] = [tagName];
    }
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateExclusiveFilterValue: (filterName: keyof FilterType, tagName: string): void => {
    const params = getURLParams();
    if (typeof params.filter !== 'undefined' && typeof params.filter[filterName] !== 'undefined') {
      let newArray = [];
      if ((params.filter[filterName] as string[])[0] !== tagName) {
        newArray = [tagName];
        params.filter[filterName] = newArray;
      }
      if (newArray.length === 0) {
        delete params.filter[filterName];
      }
    } else if (typeof params.filter !== 'undefined' && typeof params.filter[filterName] === 'undefined') {
      params.filter[filterName] = [tagName];
    } else if (typeof params.filter === 'undefined') {
      params.filter = {};
      params.filter[filterName] = [tagName];
    }
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateSortByValue: (newValue: UrlParamsType['sortBy']): void => {
    const params = getURLParams();
    params.sortBy = newValue;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateQueryValue: (newValue: UrlParamsType['query']): void => {
    const params = getURLParams();
    if (newValue && newValue.length > 0) params.query = newValue;
    else delete params.query;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateCampaignCode: (newValue: UrlParamsType['campaignCode']): void => {
    const params = getURLParams();
    params.campaignCode = newValue;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  removeCampaignCode: (): void => {
    const params = getURLParams();
    delete params.campaignCode;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateWizardStep: (newValue: string): void => {
    const params = getURLParams();
    params.wizardStep = newValue;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateRedirect: (newValue: string): void => {
    const params = getURLParams();
    params.redirect = newValue;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateTagsValue: (newValue: UrlParamsType['tags']): void => {
    const params = getURLParams();
    if (newValue && newValue.length > 0) params.tags = newValue;
    else delete params.tags;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateSearchQuery: (newValue: UrlParamsType['search']): void => {
    const params = getURLParams();
    if (newValue && newValue.length > 0) params.search = newValue;
    else delete params.search;
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
  updateGenericParam: (newValue: string, paramName: string): void => {
    const params = getURLParams();
    if (newValue && newValue.length > 0) params[paramName] = newValue;
    else delete params[paramName];
    replaceQueryWindowHistoryState(qs.stringify(params, { addQueryPrefix: true }));
  },
};
