import React from 'react';
import styled from 'styled-components';

const MotorIcon = ({ size }: { size?: number }) => (
  <MotorIconWrapper size={size}>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_911_13422)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 0.938135C6.78613 0.938135 0.938135 6.78613 0.938135 14C0.938135 21.2139 6.78613 27.0619 14 27.0619C21.2139 27.0619 27.0619 21.2139 27.0619 14C27.0619 6.78613 21.2139 0.938135 14 0.938135ZM0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
          fill="black"
        />
        <path
          d="M15.9967 8.29199L14.0142 16.4201L13.9233 16.8504H13.6217L13.6676 16.4201L14.2672 8.29199H7.95864L5.69434 19.7081H9.32623L10.5093 13.5931L10.7397 12.2561H11.0413L10.9639 13.5931L10.6407 19.7081H15.9967L17.6254 13.5931L17.9639 12.2561H18.2646L18.0801 13.5931L17.3274 19.7081H21.0457L22.3053 8.29199H15.9967Z"
          fill="#C9BD2D"
        />
      </g>
      <defs>
        <clipPath id="clip0_911_13422">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </MotorIconWrapper>
);

const MotorIconWrapper = styled.div<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-content: center;
  height: intrinsic;
  svg {
    ${({ size }) => (size && `width: ${size}px`) || '18px'};
    height: intrinsic;
  }
`;

export default MotorIcon;
