import React from 'react';
import { createStore, GlobalState, StateMachineProvider } from 'little-state-machine';

import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';
import { AppInsightsContextProvider } from './AppInsightsContextProvider';
import { useAnchors } from '../hooks/useAnchors';
import { useVirtualPageViewTracking } from '../hooks/useVirtualPageViewTracking';
import { useGTMInitializer } from '../hooks/useGTMInitializer';
import { ThemeContextProvider } from './ThemeContextProvider';
import { ThemedApp } from './ThemedApp';
import { ThemeVariants } from '../../../types/themes';

export type FormNames = {
  [key in keyof GlobalState]?: GlobalState[key];
};

createStore(
  {
    membershipForm: {},
    membersForm: {},
    householdMemberForm: {},
    internationalDrivingPermitForm: {},
    idpCustomerForm: {},
    addonsForm: {},
  },
  {},
);

export const App = ({ initialTheme }: { initialTheme?: ThemeVariants | null }) => {
  useGTMInitializer();
  useVirtualPageViewTracking();
  useAnchors();

  return (
    <AppInsightsContextProvider>
      <Auth0ProviderWithHistory>
        <StateMachineProvider>
          <ThemeContextProvider>
            <ThemedApp initialTheme={initialTheme} />
          </ThemeContextProvider>
        </StateMachineProvider>
      </Auth0ProviderWithHistory>
    </AppInsightsContextProvider>
  );
};
