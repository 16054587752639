import { nafColor } from '@nafcore/theme';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface SimpleTextLinkProps {
  href?: string;
  children: ReactNode;
  onClick?: () => void;
}

const SimpleTextLink = ({ href, children, onClick }: SimpleTextLinkProps) => (
  <StyledTextLink href={href} target="blank" rel="noreferrer" onClick={onClick}>
    {children}
  </StyledTextLink>
);

const StyledTextLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => (theme?.typography?.textLink ? theme.typography.textLink : `#1a1a1a`)};
  text-decoration: underline;
  &:hover {
    color: ${nafColor.primary.park};
    text-decoration: none;
  }
`;

export default SimpleTextLink;
