import { Action, ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import { TravelSuggestionSnapshot, Region } from '../../../../types/TravelSuggestionType';
import { TypedEpic } from '../types';

// Actions
export enum Actions {
  GET_TRAVEL_SUGGESTIONS = 'naf/TRAVEL_SUGGESTIONS',
  GET_TRAVEL_SUGGESTIONS_SUCCESS = 'naf/GET_TRAVEL_SUGGESTIONS_SUCCESS',
  GET_TRAVEL_SUGGESTIONS_FAIL = 'naf/GET_TRAVEL_SUGGESTIONS_FAIL',
  GET_TRAVEL_SUGGESTIONS_CANCEL = 'naf/GET_TRAVEL_SUGGESTIONS_CANCEL',
  GET_REGIONS = 'naf/GET_REGIONS',
  GET_REGIONS_SUCCESS = 'naf/GET_REGIONS_SUCCESS',
  GET_REGIONS_FAIL = 'naf/GET_REGIONS_FAIL',
  GET_REGIONS_CANCEL = 'naf/GET_REGIONS_CANCEL',
  SET_SELECTED_REGIONS = 'naf/SET_SELECTED_REGIONS',
}

export interface State {
  travelSuggestions: {
    items: TravelSuggestionSnapshot[];
    isUpdating: boolean;
    errorState?: any;
  };
  regions: {
    items: Region[];
    selectedRegions: string[];
    isUpdating: boolean;
    errorState?: any;
  };
}

export const initialState: State = {
  travelSuggestions: {
    items: [],
    isUpdating: false,
  },
  regions: {
    items: [],
    selectedRegions: [],
    isUpdating: false,
  },
};

export const actions = {
  getTravelSuggestions: createAsyncAction(
    Actions.GET_TRAVEL_SUGGESTIONS, // request payload creator
    Actions.GET_TRAVEL_SUGGESTIONS_SUCCESS, // success payload creator
    Actions.GET_TRAVEL_SUGGESTIONS_FAIL, // failure payload creator
    Actions.GET_TRAVEL_SUGGESTIONS_CANCEL, // optional cancel payload creator
  )<string[], TravelSuggestionSnapshot[], Error, undefined>(),
  getRegions: createAsyncAction(
    Actions.GET_REGIONS, // request payload creator
    Actions.GET_REGIONS_SUCCESS, // success payload creator
    Actions.GET_REGIONS_FAIL, // failure payload creator
    Actions.GET_TRAVEL_SUGGESTIONS_CANCEL, // optional cancel payload creator
  )<undefined, Region[], Error, undefined>(),
  setSelectedRegions: createAction(Actions.SET_SELECTED_REGIONS, (selectedRegions: string[]) => ({
    selectedRegions,
  }))(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getTravelSuggestions.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.travelSuggestions.isUpdating = true;
    }),
  )
  .handleAction(actions.getTravelSuggestions.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.travelSuggestions.isUpdating = false;
      draftState.travelSuggestions.errorState = false;
      draftState.travelSuggestions.items = action.payload;
    }),
  )
  .handleAction(actions.getTravelSuggestions.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.travelSuggestions.isUpdating = false;
      draftState.travelSuggestions.errorState = action.payload;
    }),
  )
  .handleAction(actions.getTravelSuggestions.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.travelSuggestions.isUpdating = false;
    }),
  )
  .handleAction(actions.getRegions.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.regions.isUpdating = true;
    }),
  )
  .handleAction(actions.getRegions.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.regions.isUpdating = false;
      draftState.regions.errorState = false;
      draftState.regions.items = action.payload;
    }),
  )
  .handleAction(actions.getRegions.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.regions.isUpdating = false;
      draftState.regions.errorState = action.payload;
    }),
  )
  .handleAction(actions.getRegions.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.regions.isUpdating = false;
    }),
  )
  .handleAction(actions.setSelectedRegions, (state, action) => ({
    ...state,
    regions: {
      ...state.regions,
      selectedRegions: action.payload.selectedRegions,
    },
  }));

export const getTravelSuggestionsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_TRAVEL_SUGGESTIONS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getTravelSuggestions.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };

      const regionsQueryParam = `region=${action.payload.join(',')}`;

      return ajax<{ travelSuggestions: TravelSuggestionSnapshot[] }>({
        url: `${apimBaseUrl}/${apimNafNoApi}/travel-suggestions?${regionsQueryParam}`,
        headers,
        method: 'GET',
      }).pipe(
        map(({ response }) => actions.getTravelSuggestions.success(response.travelSuggestions)),
        catchError(() =>
          of(
            actions.getTravelSuggestions.failure(
              new Boom(
                'Beklager, vi har motorproblemer... Kunne ikke hente reiseforslag! Kontakt kundesenteret hvis problemet vedvarer!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const getRegionsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_REGIONS),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.getRegions.request>[]>,
    switchMap(() => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      return ajax<{ regions: Region[] }>({
        url: `${apimBaseUrl}/${apimNafNoApi}/travel-suggestions/regions`,
        headers,
        method: 'GET',
      }).pipe(
        map(({ response }) => actions.getRegions.success(response.regions)),
        catchError(() =>
          of(
            actions.getRegions.failure(
              new Boom(
                'Beklager, vi har motorproblemer... Kunne ikke hente regioner! Kontakt kundesenteret hvis problemet vedvarer!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [getTravelSuggestionsEpic, getRegionsEpic];
