import styled, { css } from 'styled-components';
import { NafThemeType, breakpoints, spacing, themeLight } from '@naf/theme';
import { Button } from '@naf/button';

export const ScrollContainer = styled.div`
  padding-top: ${spacing.space24};
  display: flex;
  align-items: center;
  gap: ${spacing.space4};

  @media (max-width: ${breakpoints.l}) {
    display: none;
  }
`;

export const ScrollButton = styled(Button)<{ $direction: 'back' | 'forward' }>`
  border-radius: 40px !important;
  width: 40px;
  height: 40px;
  padding: 0;
  ${({ $direction }) =>
    $direction === 'back'
      ? css`
          margin-right: ${spacing.space20};
        `
      : css`
          margin-left: ${spacing.space20};
        `}
`;

export const Dot = styled.div<{ $inView: boolean }>`
  width: ${spacing.space8};
  height: ${spacing.space8};
  border-radius: 40px;
  flex-shrink: 0;
  background: ${({ theme, $inView }) => {
    const activeTheme: NafThemeType = theme.componentColors ? theme : themeLight;
    return $inView ? activeTheme.componentColors.navigation.active : activeTheme.componentColors.navigation.inactive;
  }};
`;
