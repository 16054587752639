import React, { FC, useEffect } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { SanityBlock } from '@dtp/membership-service-types/dist/sanity/sanity';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@naf/button';
import BlockContent from '../../../BlockContent';
import { StyledUserInteraction } from './StyledUserInteraction';

export const Confirmation: FC<
  Partial<StepWizardChildProps> & {
    confirmationPageBody?: SanityBlock[];
    getValues: UseFormReturn['getValues'];
    onSubmit: SubmitHandler<FieldValues>;
    buttonText?: string;
    isSuccess: boolean | undefined;
    isSubmitting: boolean;
    errorState?: string | null;
  }
> = ({
  previousStep,
  nextStep,
  confirmationPageBody,
  buttonText,
  onSubmit,
  getValues,
  isSuccess,
  isSubmitting,
  errorState,
}) => {
  const onConfirm = async () => {
    const data = getValues();
    try {
      onSubmit(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  useEffect(() => {
    if (isSuccess && nextStep) {
      nextStep();
    }
  }, [isSuccess, nextStep]);

  return confirmationPageBody ? (
    <>
      <BlockContent value={confirmationPageBody} />
      {errorState && (
        <StyledUserInteraction title="Beklager!" variant="error">
          {errorState}
        </StyledUserInteraction>
      )}
      {previousStep && (
        <StyledButton variant="outline" onClick={previousStep} disabled={isSubmitting}>
          Gå tilbake
        </StyledButton>
      )}
      <StyledButton isLoading={isSubmitting} onClick={onConfirm} disabled={isSubmitting}>
        {buttonText}
      </StyledButton>
    </>
  ) : null;
};

const StyledButton = styled(Button)`
  margin-right: 10px;
  margin-bottom: 10px;
`;
