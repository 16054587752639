import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Check } from '@styled-icons/material/Check';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import useSelector from '../../../../../redux/typedHooks';
import { actions } from '../../../../../redux/modules/myMembership';
import { useAuth0Token } from '../../../../../hooks/useAuth0Token';
import { ProductID } from '../../../../../lib/ProductID';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

const ActiveChargingTagsBlock = () => {
  const dispatch = useDispatch();
  const [didFetch, setDidFetfch] = useState(false);
  const { simpleToken } = useAuth0Token();

  const chargingTagData = useSelector((state) => state.myMembership.customerInformation.data.services)?.filter(
    (e) => e.product.id === ProductID.ChargingTag,
  );

  useEffect(() => {
    if (!chargingTagData && simpleToken && !didFetch) {
      setDidFetfch(true);
      dispatch(actions.getMyMembership.request(simpleToken, {}));
    }
  }, [simpleToken, dispatch, chargingTagData, didFetch]);

  // TODO: Update component when API/CRM handles charging tags as it is supposed to.

  const [chargingTagService] = chargingTagData;

  // TODO: Fix this when charging tag data is rewritten
  const chargingTags = chargingTagService?.data?.split(',') || [];

  return chargingTags && chargingTags.length > 0 ? (
    <>
      <Header variant={TextVariant.ArticleTextHeader}>
        {chargingTagData.length > 1
          ? `Du har ${chargingTags.length} aktive ladebrikker.`
          : `Du har ${chargingTags.length} aktiv ladebrikke.`}
      </Header>
      <ChargingTagList>
        {chargingTags &&
          chargingTags.map((tagName) => (
            <ChargingTagItem key={tagName}>
              <StyledCheck>
                <Check size={16} />
              </StyledCheck>
              <TextWrapper>{tagName} - aktiv</TextWrapper>
            </ChargingTagItem>
          ))}
      </ChargingTagList>
    </>
  ) : (
    <Header>Du har ingen aktiverte ladebrikker.</Header>
  );
};

export const ActiveChargingTagsSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <ActiveChargingTagsBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <ActiveChargingTagsBlock />;
};

const Header = styled(Text)`
  margin-bottom: ${spacing.space16};
`;

const ChargingTagList = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: ${spacing.space32};
  > *:first-child {
    padding-top: 0;
  }
  > *:not(:last-child) {
    border-bottom: 1px solid ${nafColor.neutral.neutral2};
  }
`;

const ChargingTagItem = styled.li`
  display: flex;
  align-items: baseline;
  padding-bottom: ${spacing.space16};
  padding-top: ${spacing.space16};
`;

const StyledCheck = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: ${nafColor.primary.kart};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 12px;

  svg {
    color: ${nafColor.primary.spruce};
  }
`;

const TextWrapper = styled.div`
  flex-direction: column;

  p {
    margin: 0;
  }
`;
