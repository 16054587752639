import qs from 'qs';
import { UrlParamsType } from '../../../types/urlParamsType';

export const getURLParams = (): UrlParamsType => {
  if (typeof window !== 'undefined') {
    const parsedLocation = new URL(window.location.href);
    let params: UrlParamsType = {};
    if (parsedLocation && !!parsedLocation.search) {
      params = qs.parse(parsedLocation.search, { ignoreQueryPrefix: true });
    }
    return params;
  }
  return {};
};
