import type { ProductType, GetProductsResponse } from '@dtp/membership-service-types';
import { produce } from 'immer';
import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import { ofType } from 'redux-observable';
import { TypedEpic } from '../types';

export enum Actions {
  GET_INTERNATIONAL_DRIVING_PERMIT = 'naf/internationDrivingPermit/GET_INTERNATIONAL_DRIVING_PERMIT',
  GET_INTERNATIONAL_DRIVING_PERMIT_SUCCESS = 'naf/internationDrivingPermit/GET_INTERNATIONAL_DRIVING_PERMIT_SUCCESS',
  GET_INTERNATIONAL_DRIVING_PERMIT_FAIL = 'naf/internationDrivingPermit/GET_INTERNATIONAL_DRIVING_PERMIT_FAIL',
  GET_INTERNATIONAL_DRIVING_PERMIT_CANCEL = 'naf/internationDrivingPermit/GET_INTERNATIONAL_DRIVING_PERMIT_CANCEL',
}

export interface State {
  data: {
    eligibleProducts: Record<ProductType['productNumber'], ProductType> | null;
    ownedProducts: Record<ProductType['productNumber'], ProductType> | null;
  };
  isUpdating: boolean;
  errorState?: any;
}

export const initialState: State = {
  data: {
    eligibleProducts: null,
    ownedProducts: null,
  },
  isUpdating: false,
};

export const actions = {
  getInternationalDrivingPermit: createAsyncAction(
    Actions.GET_INTERNATIONAL_DRIVING_PERMIT, // request payload creator
    Actions.GET_INTERNATIONAL_DRIVING_PERMIT_SUCCESS, // success payload creator
    Actions.GET_INTERNATIONAL_DRIVING_PERMIT_FAIL, // failure payload creator
    Actions.GET_INTERNATIONAL_DRIVING_PERMIT_CANCEL, // optional cancel payload creator
  )<string | undefined, GetProductsResponse, Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getInternationalDrivingPermit.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getInternationalDrivingPermit.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.data.eligibleProducts = action.payload.customerProducts.eligibleProducts.reduce(
        (acc: Record<ProductType['productNumber'], ProductType>, cur) => {
          acc[cur.productNumber] = cur;
          return acc;
        },
        {},
      );
      draftState.data.ownedProducts = action.payload.customerProducts.ownedProducts.reduce(
        (acc: Record<ProductType['productNumber'], ProductType>, cur) => {
          acc[cur.productNumber] = cur;
          return acc;
        },
        {},
      );
    }),
  )
  .handleAction(actions.getInternationalDrivingPermit.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getInternationalDrivingPermit.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

export const getInternationDrivingPermitEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_INTERNATIONAL_DRIVING_PERMIT),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getInternationalDrivingPermit.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.payload) {
        headers.Authorization = `Bearer ${action.payload}`;
      }
      return ajax<GetProductsResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/v2/products`,
        headers,
      }).pipe(
        map(({ response }) => actions.getInternationalDrivingPermit.success(response)),
        catchError(() =>
          of(
            actions.getInternationalDrivingPermit.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [getInternationDrivingPermitEpic];
