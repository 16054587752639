import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { RelatedModel } from './RelatedModel';
import useSelector from '../../redux/typedHooks';

type Props = {
  currentSlug: string;
};

export const NeighbouringModelNav: React.FC<Props> = ({ currentSlug }) => {
  const { mappedData } = useSelector((state) => state.cars);
  const modelSlugs = Object.keys(mappedData);
  const testedModelSlugs = modelSlugs.filter((slug) => mappedData[slug].data.tested);

  if (testedModelSlugs.length === 0) return null;

  const currentModelIndex = testedModelSlugs.findIndex((slug) => slug === currentSlug);
  const previousSlug = testedModelSlugs[currentModelIndex === 0 ? testedModelSlugs.length - 1 : currentModelIndex - 1];
  const nextSlug = testedModelSlugs[(currentModelIndex + 1) % testedModelSlugs.length];

  return (
    <Container>
      {mappedData?.[previousSlug]?.data && (
        <RelatedModel variant="previous" model={mappedData[previousSlug].data} badge="Forrige" />
      )}
      {mappedData?.[nextSlug]?.data && <RelatedModel variant="next" model={mappedData[nextSlug].data} badge="Neste" />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-left: -24px;
  width: calc(100% + 48px);
  border-left: 1px solid ${({ theme }) => theme.border.subtle};
  border-right: 1px solid ${({ theme }) => theme.border.subtle};

  a:first-child {
    border-right: 1px solid ${({ theme }) => theme.border.subtle};
  }

  @media (max-width: ${breakpoints.m}) {
    border: 0;
  }
`;
