import React, { useState } from 'react';
import styled from 'styled-components';
import { animation, breakpoints, spacing } from '@naf/theme';
import { ChevronRight } from '@styled-icons/material/ChevronRight';
import { Close } from '@styled-icons/material/Close';
import { ListAlt } from '@styled-icons/material/ListAlt';
import { SubdirectoryArrowRight } from '@styled-icons/material/SubdirectoryArrowRight';
import { Text, TextVariant } from '@naf/text';
import { Link } from 'react-router-dom';
import { nafColor } from '@nafcore/theme';
import { ScrollSection, SectionKey } from './ModelDetailPage';
import { BackToTopButton } from './BackToTopButton';
import { RelatedModels } from './RelatedModels';

type Props = {
  sections: ScrollSection[];
  sectionInView: SectionKey;
  navigateToSection: (key: SectionKey) => void;
  modelName: string;
  slug: string;
};

export const SectionsNavigation: React.FC<Props> = ({
  sections,
  sectionInView,
  navigateToSection,
  modelName,
  slug,
}) => {
  const [open, toggleOpen] = useState<boolean>(false);

  return (
    <>
      <Cover open={open} />
      <Nav open={open} sections={sections.length}>
        <MenuButtons>
          <CustomBreadCrumb open={open}>
            <Link to="/elbil">Elbil</Link> <ChevronRight size={16} />
            <Link to="/bilguiden/elbiler-i-norge">Elbilguiden</Link>
          </CustomBreadCrumb>
          {sections.map(({ key, label }) =>
            key === 'model' ? (
              <VehicleCrumb
                id="vehicle"
                type="button"
                key={key}
                onClick={() => {
                  toggleOpen(false);
                  navigateToSection(key);
                }}
              >
                <SubdirectoryArrowRight width={24} height={24} />
                <Text tag="p" variant={TextVariant.Header3}>
                  {modelName}
                </Text>
              </VehicleCrumb>
            ) : (
              <Button
                id="nav-button"
                $inView={sectionInView === key}
                type="button"
                key={key}
                onClick={() => {
                  toggleOpen(false);
                  navigateToSection(key);
                }}
              >
                {label}
              </Button>
            ),
          )}
        </MenuButtons>
        <RelatedModels inSidebar currentSlug={slug} />
      </Nav>
      <MenuButton onClick={() => toggleOpen(!open)}>
        {open ? <Close height={24} width={24} /> : <ListAlt height={24} width={24} />}
      </MenuButton>
      <BackToTopButton show={!open} onClick={() => navigateToSection(sections[0].key)} />
    </>
  );
};

const VehicleCrumb = styled.button`
  display: flex;
  align-items: flex-start;
  font-variant-numeric: lining-nums;
  border: 0;
  text-align: left;
  background: transparent;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};
  padding: ${spacing.space8} ${spacing.space4} ${spacing.space8} ${spacing.space24};
  color: ${({ theme }) => theme.typography.defaultText};

  > p {
    margin: 0;
    margin-left: ${spacing.space8};
  }

  svg {
    margin-top: 4px;
    min-width: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.typography.textLink};
    background: ${({ theme }) => theme.componentColors.cta.disabled};
  }
`;

const Cover = styled.div<{ open: boolean }>`
  background: ${nafColor.primary.spruce};
  position: absolute;
  height: 0;
  width: 100vw;

  @media (max-width: ${breakpoints.l}) {
    height: ${({ open }) => (open ? '100vh' : '0vh')};
    opacity: ${({ open }) => (open ? '0.3' : '0')};
    transition: opacity 0.3s;
    transition-timing-function: ${animation.easeIn};
  }
`;

const Nav = styled.nav<{ open: boolean; sections: number }>`
  display: flex;
  flex-direction: column;
  background: white;
  overflow-y: scroll;
  top: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};
  position: sticky;
  min-width: 240px;
  width: 25vw;
  max-width: 400px;
  height: calc(100vh - 80px);

  #nav-button,
  #vehicle {
    display: flex;
  }

  @media (max-width: ${breakpoints.l}) {
    position: relative;
    overflow-y: hidden;
    max-width: unset;
    height: unset;
    top: 0;
    width: 100vw;
    height: ${({ open, sections }) => (open ? `${(sections + 1) * 44 + 62}px` : '61px')};
    transition: height 0.3s;
  }
`;

const MenuButtons = styled.div``;

const MenuButton = styled.button`
  display: none;
  position: absolute;
  background: white;
  bottom: -20px;
  right: 17px;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 1px solid ${({ theme }) => theme.border.subtle};

  @media (max-width: ${breakpoints.l}) {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      min-width: 24px;
      min-height: 24px;
      fill: ${({ theme }) => theme.typography.defaultText};
    }
  }
`;

const Button = styled.button<{ $inView: boolean }>`
  border: 0;
  font-variant-numeric: lining-nums;
  text-align: left;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};
  padding: ${spacing.space8} ${spacing.space16} ${spacing.space8} ${spacing.space24};
  cursor: pointer;

  color: ${({ theme }) => theme.typography.defaultText};
  &:hover {
    color: ${({ theme }) => theme.typography.textLink};
    background: ${({ theme }) => theme.componentColors.cta.disabled};
  }
`;

const CustomBreadCrumb = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${spacing.space16} ${spacing.space24};
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};

  * + * {
    margin-left: ${spacing.space8};
  }

  @media (max-width: ${breakpoints.l}) {
    border-bottom: 1px solid ${({ theme, open }) => (open ? theme.border.subtle : 0)};
    border-top: 1px solid ${({ theme }) => theme.border.subtle};
  }
`;
