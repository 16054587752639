import React from 'react';

const DownloadArrowIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70034 5.02667L6.00034 8.66667L2.30701 5.03333L3.25367 4.08667L5.33367 6.16667V0H6.66701V6.16667L8.75367 4.08667L9.70034 5.02667ZM11.3337 10H0.666992V11.3333H11.3337V10Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default DownloadArrowIcon;
