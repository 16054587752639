import React from 'react';
import BlockImage from './BlockImage';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import { ArticleTextWideImage } from '../../../../ArticleImage/ArticleTextWideImage';

interface ImageSerializerProps {
  value: { image: CloudinaryImageType; mobileImage?: CloudinaryImageType };
}

const Image = ({ value: { image } }: ImageSerializerProps) => <BlockImage image={image} />;

export const WideImageSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value: { image, mobileImage } }: ImageSerializerProps) => (
      <ArticleTextWideImage image={image} mobileImage={mobileImage} />
    );
  }
  return ({ value }: ImageSerializerProps) => <Image value={value} />;
};
