import React from 'react';

export const ChildSeatIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9338 7.38251C17.2522 6.38559 17.5119 4.50226 16.5124 3.18579C15.513 1.86933 13.6259 1.60918 12.3074 2.6061C10.989 3.60302 10.7293 5.48635 11.7288 6.80281C12.7411 8.13616 14.6133 8.39426 15.9338 7.38251ZM18.2886 3.44238L16.46 16.959C16.2641 18.4066 14.9594 19.414 13.5389 19.2144L8.40473 18.4931L8.16878 20.2371L13.303 20.9585C15.6647 21.2904 17.8457 19.6063 18.1714 17.1995L20 3.68284L18.2886 3.44238ZM8.93654 15.0133L4 18.825L5.05494 20.2439L8.46281 17.6126L12.8012 18.2222C14.2217 18.4218 15.5264 17.4143 15.7222 15.9667L16.4006 10.9525C16.5469 9.87117 15.8018 8.86898 14.7407 8.71989L14.7151 8.71629C14.4241 8.67541 14.1311 8.71422 13.8665 8.80144C13.6298 8.87482 13.4044 8.99421 13.217 9.1545L11.8362 10.3378C10.7911 11.2307 9.0175 11.7724 7.5994 11.5553L7.34457 13.4389C8.9704 13.6674 10.9721 13.0511 12.3057 12.2166L11.8716 15.4257L8.93654 15.0133Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default ChildSeatIcon;
