import { nafColor } from '@nafcore/theme';
import { Text, TextVariant } from '@naf/text';
import { spacing, themeLight } from '@naf/theme';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RelatedArticleType } from '../../../../../../types/articleType';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { createNorwegianDateString } from '../../../../utils/createNorwegianDateString';
import { StyledLink } from '../../../styled-link/StyledLink';

export const HoverWrap = styled.span`
  background-size: 0 2px;
  background-position: 0 100%;
  background-image: linear-gradient(
    90deg,
    ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active},
    ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active}
  );
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
  text-decoration: none;
  display: inline;
`;

const StyledArrow = styled(ArrowForward)`
  transition: 0.4s;
`;

const StyledText = styled(Text)`
  margin: 0;
`;

const PublishedText = styled(Text)`
  color: ${nafColor.neutral.neutral6};
  margin: ${spacing.space8} 0 0;
`;

const CustomStyledLink = styled(StyledLink)`
  display: inline-block;

  &:hover {
    ${HoverWrap} {
      background-size: 100% 2px;
    }
    ${StyledArrow} {
      transform: translateX(${spacing.space12});
    }
  }
`;

const LatestArticleItemWrapper = styled.div`
  display: block;
`;

export const LatestArticleItem = ({
  article,
  setCurrentArticle,
}: {
  article: RelatedArticleType;
  setCurrentArticle: Dispatch<SetStateAction<RelatedArticleType>>;
}) => {
  const itemUrl = useDocumentUrlWithNode(article) || '';

  const [hovered, setHovered] = useState(false);
  const mouseEnterHandler = () => setHovered(true);
  const mouseLeaveHandler = () => setHovered(false);
  const onTimeout = useCallback(() => setCurrentArticle(article), [article, setCurrentArticle]);

  useEffect(() => {
    const timer = hovered ? window.setTimeout(onTimeout, 200) : undefined;
    return () => {
      clearTimeout(timer);
    };
  }, [hovered, onTimeout]);

  return (
    <LatestArticleItemWrapper>
      <CustomStyledLink to={itemUrl} onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
        <StyledText tag="h3" variant={TextVariant.Header3}>
          <HoverWrap>{article.name}</HoverWrap>
          <StyledArrow size={18} />
        </StyledText>
      </CustomStyledLink>
      {article.updatedAt ? (
        <PublishedText tag="p" variant={TextVariant.BodyText}>
          {createNorwegianDateString(new Date(article.updatedAt))}
        </PublishedText>
      ) : null}
    </LatestArticleItemWrapper>
  );
};
