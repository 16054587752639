import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { ArticleResponse, ArticleType, Category } from '../../../../types/articleType';

// Actions
export enum Actions {
  GET_LOCAL_ARTICLES = 'GET_LOCAL_ARTICLES', // request payload creator
  GET_LOCAL_ARTICLES_SUCCESS = 'GET_LOCAL_ARTICLES_SUCCESS', // success payload creator
  GET_LOCAL_ARTICLES_FAIL = 'GET_LOCAL_ARTICLES_FAIL', // failure payload creator
  GET_LOCAL_ARTICLES_CANCEL = 'GET_LOCAL_ARTICLES_CANCEL',
  GET_MORE_LOCAL_ARTICLES = 'GET_MORE_LOCAL_ARTICLES',
  GET_MORE_LOCAL_ARTICLES_SUCCESS = 'GET_MORE_LOCAL_ARTICLES_SUCCESS',
  GET_MORE_LOCAL_ARTICLES_FAIL = 'GET_MORE_LOCAL_ARTICLES_FAIL',
  GET_MORE_LOCAL_ARTICLES_CANCEL = 'GET_MORE_LOCAL_ARTICLES_CANCEL',
}

export interface State {
  categories?: Category[];
  pages: Record<number, { isLoading: boolean; data?: Record<ArticleType['slug'], ArticleType> }>;
  total: number;
  totalFetchedArticles: number;
  isLoadingMore: boolean;
  numberOfLoadedPages: number;
  isUpdating: boolean;
  errorState?: Error;
}

export const initialState: State = {
  pages: {},
  total: 0,
  totalFetchedArticles: 0,
  isLoadingMore: false,
  numberOfLoadedPages: 0,
  isUpdating: false,
};

export const actions = {
  getLocalArticles: createAsyncAction(
    Actions.GET_LOCAL_ARTICLES, // request payload creator
    Actions.GET_LOCAL_ARTICLES_SUCCESS, // success payload creator
    Actions.GET_LOCAL_ARTICLES_FAIL, // failure payload creator
    Actions.GET_LOCAL_ARTICLES_CANCEL, // optional cancel payload creator
  )<string, ArticleResponse, Error, undefined>(),
  getMoreLocalArticles: createAsyncAction(
    Actions.GET_MORE_LOCAL_ARTICLES, // request payload creator
    Actions.GET_MORE_LOCAL_ARTICLES_SUCCESS, // success payload creator
    Actions.GET_MORE_LOCAL_ARTICLES_FAIL, // failure payload creator
    Actions.GET_MORE_LOCAL_ARTICLES_CANCEL, // optional cancel payload creator
  )<string, ArticleResponse, Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getLocalArticles.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
      draftState.pages = { 1: { data: null, isLoading: true } };
      draftState.total = 0;
      draftState.isLoadingMore = true;
      draftState.numberOfLoadedPages = 0;
    }),
  )
  .handleAction(actions.getLocalArticles.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.total = action.payload.total;
      draftState.totalFetchedArticles += action.payload.items.length;
      draftState.categories = action.payload.categories;
      draftState.isLoadingMore = false;
      draftState.numberOfLoadedPages = 1;
      draftState.pages = {
        1: {
          data: action.payload.items.reduce((acc: Record<ArticleType['slug'], ArticleType>, cur) => {
            acc[cur.slug] = { ...cur, stateMeta: { isEnriched: false, fetchedAt: new Date().toISOString() } };
            return acc;
          }, {}),
          isLoading: false,
        },
      };
    }),
  )
  .handleAction(actions.getLocalArticles.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
      delete draftState.pages[draftState.numberOfLoadedPages + 1];
    }),
  )
  .handleAction(actions.getLocalArticles.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      delete draftState.pages[draftState.numberOfLoadedPages + 1];
    }),
  )
  .handleAction(actions.getMoreLocalArticles.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isLoadingMore = true;
      draftState.pages = {
        [draftState.numberOfLoadedPages + 1]: {
          isLoading: true,
        },
      };
    }),
  )
  .handleAction(actions.getMoreLocalArticles.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isLoadingMore = false;
      draftState.totalFetchedArticles += action.payload.items.length;
      draftState.pages[state.numberOfLoadedPages + 1] = {
        isLoading: false,
        data: action.payload.items.reduce((acc: Record<ArticleType['slug'], ArticleType>, cur) => {
          acc[cur.slug] = { ...cur, stateMeta: { isEnriched: false, fetchedAt: new Date().toISOString() } };
          return acc;
        }, {}),
      };
      draftState.numberOfLoadedPages += 1;
    }),
  )
  .handleAction(actions.getMoreLocalArticles.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isLoadingMore = false;
      delete draftState.pages[draftState.numberOfLoadedPages + 1];
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getMoreLocalArticles.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isLoadingMore = false;
      delete draftState.pages[draftState.numberOfLoadedPages + 1];
    }),
  );

const fetchLocalArticlesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_LOCAL_ARTICLES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getLocalArticles.request>[]
    >,
    switchMap(([action]: { payload: string }[]) =>
      ajax<ArticleResponse>({
        url: `${apimBaseUrl}/${apimNafNoApi}/articles/${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getLocalArticles.success(response)),
        catchError(() => of(actions.getLocalArticles.failure(new Boom.Boom('Could not get list of articles')))),
      ),
    ),
  );
};

const fetchMoreLocalArticlesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MORE_LOCAL_ARTICLES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMoreLocalArticles.request>[]
    >,
    switchMap(([action]: { payload: string }[]) =>
      ajax<ArticleResponse>({
        url: `${apimBaseUrl}/${apimNafNoApi}/articles?${action.payload}&includeCategories=false`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getMoreLocalArticles.success(response)),
        catchError(() => of(actions.getMoreLocalArticles.failure(new Boom.Boom('Could not get more articles')))),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [fetchLocalArticlesEpic, fetchMoreLocalArticlesEpic];
