import React from 'react';

const FacebookIcon = () => (
  <svg width="12" height="25" viewBox="0 0 12 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.63157 24.2105H7.47364V12H10.8421L11.1578 7.89473H7.36838C7.36838 7.89473 7.36838 6.31578 7.36838 5.57894C7.36838 4.63157 7.57891 4.21052 8.52627 4.21052C9.26311 4.21052 11.1578 4.21052 11.1578 4.21052V0C11.1578 0 8.42101 0 7.78944 0C4.21051 0 2.5263 1.57894 2.5263 4.63157C2.5263 7.26315 2.5263 7.89473 2.5263 7.89473H0V12H2.63157V24.2105Z"
      fill="white"
    />
  </svg>
);

export default FacebookIcon;
