import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 450px;
`;

export default { Wrapper };
