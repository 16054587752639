import React, { useContext } from 'react';
import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { DowngradeContext } from '../../../../../pages/downgrade/context/DowngradeContext';

interface TextWithHouseholdMemberProps {
  value: {
    text: string;
  };
}

export const TextWithHouseholdMember = ({ value: { text } }: TextWithHouseholdMemberProps) => {
  const { householdMember } = useContext(DowngradeContext);

  return (
    <Text variant={TextVariant.ArticleText}>
      {text}{' '}
      <BoldText tag="span" variant={TextVariant.ArticleText}>
        {householdMember?.fullName}
      </BoldText>
      .
    </Text>
  );
};

export const TextWithHouseholdMemberSerializer =
  () =>
  ({ value }: TextWithHouseholdMemberProps) =>
    <TextWithHouseholdMember value={value} />;

export const BoldText = styled(Text)`
  font-weight: 700;
`;
