import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { Boom } from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { CenterType } from '../../../../types/centerType';
import { CenterProductsType } from '../../../../types/centerProductsType';

// Actions
export enum Actions {
  GET_CENTERS = 'naf/centers/GET_CENTERS',
  GET_CENTERS_SUCCESS = 'naf/centers/GET_CENTERS_SUCCESS',
  GET_CENTERS_FAIL = 'naf/centers/GET_CENTERS_FAIL',
  GET_CENTERS_CANCEL = 'naf/centers/GET_CENTERS_CANCEL',
  GET_CENTER_PRODUCTS = 'naf/centers/GET_PRODUCTS',
  GET_CENTER_PRODUCTS_SUCCESS = 'naf/centers/GET_PRODUCTS_SUCCESS',
  GET_CENTER_PRODUCTS_FAIL = 'naf/centers/GET_PRODUCTS_FAIL',
  GET_CENTER_PRODUCTS_CANCEL = 'naf/centers/GET_PRODUCTS_CANCEL',
}

export interface State {
  centerList: {
    data: CenterType[] | null;
    isUpdating: boolean;
    error: Error | null;
  };
  centerProducts: {
    data: CenterProductsType[] | null;
    isUpdating: boolean;
    error: Error | null;
  };
}

export const initialState: State = {
  centerList: {
    data: null,
    isUpdating: false,
    error: null,
  },
  centerProducts: {
    data: null,
    isUpdating: false,
    error: null,
  },
};

export const actions = {
  getCenters: createAsyncAction(
    Actions.GET_CENTERS, // request payload creator
    Actions.GET_CENTERS_SUCCESS, // success payload creator
    Actions.GET_CENTERS_FAIL, // failure payload creator
    Actions.GET_CENTERS_CANCEL, // optional cancel payload creator
  )<undefined, CenterType[], Error, undefined>(),
  getCenterProducts: createAsyncAction(
    Actions.GET_CENTER_PRODUCTS, // request payload creator
    Actions.GET_CENTER_PRODUCTS_SUCCESS, // success payload creator
    Actions.GET_CENTER_PRODUCTS_FAIL, // failure payload creator
    Actions.GET_CENTER_PRODUCTS_CANCEL, // optional cancel payload creator
  )<number | undefined, CenterProductsType[], Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getCenters.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.centerList.isUpdating = true;
      draftState.centerList.error = undefined;
    }),
  )
  .handleAction(actions.getCenters.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.centerList.isUpdating = false;
      draftState.centerList.data = action.payload;
    }),
  )
  .handleAction(actions.getCenters.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.centerList.isUpdating = false;
      draftState.centerList.error = action.payload;
    }),
  )
  .handleAction(actions.getCenters.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.centerList.isUpdating = false;
    }),
  )
  .handleAction(actions.getCenterProducts.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.centerProducts.isUpdating = true;
      draftState.centerProducts.error = undefined;
    }),
  )
  .handleAction(actions.getCenterProducts.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.centerProducts.isUpdating = false;
      draftState.centerProducts.data = action.payload;
    }),
  )
  .handleAction(actions.getCenterProducts.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.centerProducts.isUpdating = false;
      draftState.centerProducts.error = action.payload;
    }),
  )
  .handleAction(actions.getCenterProducts.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.centerProducts.isUpdating = false;
    }),
  );

const fetchCentersEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CENTERS),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.getCenters.request>[]>,
    switchMap(() =>
      ajax<CenterType[]>({
        url: `${apimBaseUrl}/${apimNafNoApi}/naf-centers`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getCenters.success(response)),
        catchError(() =>
          of(
            actions.getCenters.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente senterlisten! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      ),
    ),
  );
};

const fetchCenterProductsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CENTER_PRODUCTS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getCenterProducts.request>[]
    >,
    switchMap(([action]: { payload?: number }[]) => {
      let url = `${apimBaseUrl}/${apimNafNoApi}/naf-centers/products`;
      if (action.payload) {
        url += `?centerId=${action.payload}`;
      }
      return ajax<CenterProductsType[]>({
        url,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getCenterProducts.success(response)),
        catchError(() =>
          of(
            actions.getCenterProducts.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchCentersEpic, fetchCenterProductsEpic];
