import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@naf/button';
import { GridRow } from '@naf/grid';
import { nafColor } from '@nafcore/theme';
import { Select } from '@naf/input';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { Bolt } from '@styled-icons/material/Bolt';
import { DirectionsCar } from '@styled-icons/material/DirectionsCar';
import { VehicleTestData, emptyVehicle } from '../../../../../types/showcaseBlockType';
import RoadIcon from '../../icons/Road';

type Props = {
  data: VehicleTestData[];
};

type Option = { value: string; label: string };

const percentageDiff = ({ given, measured }: { given: number | null; measured: number | null }) => {
  if (!given || !measured) {
    return null;
  }
  const percent = ((given - measured) / given) * 100;
  return -Math.round((percent + Number.EPSILON) * 100) / 100;
};

export const Table: React.FC<Props> = ({ data }) => {
  const [tableRows, setTableRows] = useState<VehicleTestData[]>([emptyVehicle, emptyVehicle]);

  const options = useMemo<Option[]>(() => data.map((d) => ({ value: d.vehicleId, label: d.name })), [data]);

  const addEmpty = () => {
    setTableRows([...tableRows, emptyVehicle]);
  };

  return (
    <ScrollContainer>
      <TableContainer>
        <Tr columns={3}>
          <Th>
            <DirectionsCar size={24} color={nafColor.primary.park} />
            <StyledText>Bilmodell</StyledText>
          </Th>
          <Th $center>
            <RoadIcon fill={nafColor.primary.park} size={24} />
            <StyledText>Rekkevidde (km)</StyledText>
          </Th>
          <Th>
            <Bolt fill={nafColor.primary.park} size={24} />
            <StyledText>Forbruk (kWh / 100km)</StyledText>
          </Th>
        </Tr>
        <Tr columns={3}>
          <div />
          <Td>
            <Cell $left>WLTP</Cell>
            <Cell $center>Målt</Cell>
            <Cell $right>Avvik</Cell>
          </Td>
          <Td>
            <Cell>WLTP</Cell>
            <Cell $center>Målt</Cell>
            <Cell>Avvik</Cell>
          </Td>
        </Tr>
        {tableRows.map((row, i) => {
          const { slug, testData, name, vehicleId } = row;

          const { statedRangeKm, measuredRangeKm, statedConsumptionKWhper100km, measuredConsumptionKWhper100km } =
            testData.rangeTest;

          const rangeDiff = percentageDiff({
            given: statedRangeKm,
            measured: measuredRangeKm,
          });
          const consumptionDiff = percentageDiff({
            given: statedConsumptionKWhper100km,
            measured: measuredConsumptionKWhper100km,
          });

          return (
            <Tr columns={3} key={slug || i}>
              <ModelColumn>
                <StyledSelect
                  width="100%"
                  selected={{ value: vehicleId, label: name }}
                  placeholder="Velg testbil..."
                  options={options.filter((d) => !tableRows.map((v) => v?.vehicleId).includes(d.value))}
                  handleSelect={(e: { value: string; label: string }) => {
                    const cpy = [...tableRows];

                    // Remove vehicle from row
                    if (e.value === '' && tableRows[i].vehicleId !== '') {
                      cpy.splice(i, 1);
                      setTableRows([...cpy]);

                      // Modify row
                    } else if (e.value !== tableRows[i]?.vehicleId) {
                      cpy.splice(i, 1, data.find((vehicle) => vehicle.vehicleId === e.value) || emptyVehicle);
                      setTableRows([...cpy]);
                    }
                  }}
                />
              </ModelColumn>
              <Td>
                <Cell $left>{show(statedRangeKm)}</Cell>
                <Cell $center>{show(measuredRangeKm)}</Cell>
                <Cell $value={rangeDiff} $right>
                  {show(rangeDiff ? `${rangeDiff}%` : null)}
                </Cell>
              </Td>
              <Td>
                <Cell>{show(statedConsumptionKWhper100km)}</Cell>
                <Cell $center>{show(measuredConsumptionKWhper100km)}</Cell>
                <Cell $value={consumptionDiff ? -consumptionDiff : null}>
                  {show(consumptionDiff ? `${consumptionDiff}%` : null)}
                </Cell>
              </Td>
            </Tr>
          );
        })}
        <AddButton variant="secondary" onClick={addEmpty}>
          Legg til bil
        </AddButton>
      </TableContainer>
    </ScrollContainer>
  );
};

const show = (item: any) => {
  if (item === null || item === undefined || item === 0) return <>&#8211;</>;
  return item;
};

const borderColor = nafColor.neutral.neutral3;

const ScrollContainer = styled.div`
  @media (max-width: ${breakpoints.m}) {
    width: calc(100vw - 48px);
    padding-right: 0 ${spacing.space24};
  }
`;

const TableContainer = styled.div`
  min-width: 736px;
`;

const ModelColumn = styled.div<{ $center?: boolean }>`
  padding: ${spacing.space16} 0;
`;

const StyledText = styled(Text)`
  margin: 0;
  margin-left: ${spacing.space8};
`;

const StyledSelect = styled(Select)`
  height: auto;
  width: initial;

  > div {
    border-width: 0 !important;
    padding: 0 ${spacing.space12} 0 0 !important;
  }
  > div > div {
    border-width: 0 !important;
  }
`;

const Th = styled.div<{ $center?: boolean }>`
  padding: ${spacing.space16};
  align-items: center;
  display: flex;
  ${({ $center }) =>
    $center
      ? css`
          border-left: 1px solid ${borderColor};
          border-right: 1px solid ${borderColor};
        `
      : null}
`;

const Tr = styled(GridRow)`
  width: 100%;
  column-gap: 0;
  grid-gap: 0 !important; // Needed for correct display in articles
  border-bottom: 1px solid ${borderColor};
`;

const Td = styled.div<{ $center?: boolean }>`
  text-align: right;
  display: flex;
  height: 100%;
  ${({ $center }) =>
    $center
      ? css`
          border-left: 1px solid ${borderColor};
          border-right: 1px solid ${borderColor};
        `
      : null}
`;

const Cell = styled.div<{ $left?: boolean; $center?: boolean; $right?: boolean; $value?: number | null }>`
  display: flex;
  width: 33%;
  padding: ${spacing.space16} 0;
  justify-content: center;
  align-items: center;
  border-left: ${({ $center, $left }) => ($center || $left ? '1px' : '0px')} solid ${borderColor};
  border-right: ${({ $center, $right }) => ($center || $right ? '1px' : '0px')} solid ${borderColor};
  ${({ $value }) =>
    $value
      ? css`
          color: ${$value > 0 ? nafColor.information.successDark : nafColor.information.errorDark};
        `
      : null}
`;

const AddButton = styled(Button)`
  margin-top: ${spacing.space16};
`;
