import React, { useMemo, useState } from 'react';
import { nafColor } from '@nafcore/theme';
import styled from 'styled-components';
import { renderToStaticMarkup } from 'react-dom/server';
import { VehicleTestData } from '../../../../../types/showcaseBlockType';
import { VehicleCategory } from '../../../../../types/testType';
import { HighChartComponent } from '../../bilguide/chart/HighChartComponent';
import { Filter } from './Filter';
import { ElPrixContextData } from '../../../../../types/ElPrixContextData';
import { Legend } from './Legend';
import { RangeTest } from '../../../../../types/carModelType';
import { getSlugForLabel } from './utils';

type Props = {
  data: VehicleTestData[];
  categories?: VehicleCategory[];
  tests: ElPrixContextData['tests'];
  includeFilters?: boolean;
  initialCategories?: VehicleCategory[];
  className?: any;
};

type Keys = keyof RangeTest;
type SeriesData = (
  | { key: Keys; color: string; labelColor: string; name: string; stack: string }
  | { key: 'diff'; name: string; stack: string }
)[];

const Plot: React.FC<Props> = ({ data, categories, tests, includeFilters, initialCategories, className }) => {
  const [filteredVehicles, setFilteredVehicles] = useState(data);
  const seriesData: SeriesData = useMemo(
    () => [
      {
        name: 'Oppgitt rekkevidde (km)',
        key: 'statedRangeKm',
        stack: 'stated',
        color: nafColor.neutral.neutral2,
        labelColor: nafColor.signature.black,
      },
      { name: 'Avvik', key: 'diff', stack: 'measured' },
      {
        name: 'Målt rekkevidde (km)',
        key: 'measuredRangeKm',
        stack: 'measured',
        color: nafColor.primary.park,
        labelColor: nafColor.neutral.neutral2,
      },
    ],
    [],
  );

  const options = useMemo((): Highcharts.Options => {
    // Config
    const groupCount = filteredVehicles.length * 2; // *2 since each has two bar groups
    const pointWidth = 24; // Width of each bar
    const marginTop = filteredVehicles.length > 3 ? 64 : 32;
    const marginBottom = 48;
    const groupPadding = 0.2;
    const pointPadding = 0.2;
    const chartHeight = marginTop + marginBottom + pointWidth * groupCount * (1 + groupPadding + pointPadding);

    return {
      chart: {
        height: chartHeight,
        type: 'bar',
      },
      accessibility: {
        enabled: true,
        keyboardNavigation: {
          enabled: false,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          states: {
            inactive: { opacity: 1 },
            hover: {
              enabled: false,
            },
          },
        },
        bar: {
          pointWidth,
          pointPadding,
          groupPadding,
          dataLabels: {
            enabled: true,
            borderRadius: 0,
            borderWidth: 0,
            style: {
              textOutline: '0px',
            },
          },
        },
      },
      credits: { enabled: false },
      tooltip: { enabled: false },
      title: undefined,
      xAxis: {
        categories: filteredVehicles.map((d) => d.name),
        title: { text: null },
        labels: {
          useHTML: true,
          formatter: (item) =>
            renderToStaticMarkup(
              <CustomLabel
                href={`/bilguiden/bilmodell/${getSlugForLabel(data, item.value.toString())}#rekkeviddetester`}
              >
                {item.value}
              </CustomLabel>,
            ),
        },
      },
      yAxis: {
        max: Math.max(
          ...data
            .map(({ testData }) => [testData.rangeTest.statedRangeKm || 0, testData.rangeTest.measuredRangeKm || 0])
            .flat(),
          550,
        ),
        min: 0,
        tickInterval: 50,
        lineWidth: 1,
        lineColor: nafColor.neutral.neutral3,
        gridLineDashStyle: 'Dash',
        title: undefined,
        labels: {
          formatter: ({ isLast, value }) => (isLast ? 'KM' : `${value}`),
          style: {
            fontWeight: '700',
            fontSize: '0.75rem',
          },
        },
      },
      series: seriesData.map((series) =>
        series.key === 'diff'
          ? {
              name: series.name,
              data: filteredVehicles.map(({ testData }) => {
                const diff = Math.round(
                  (testData.rangeTest.statedRangeKm || 0) - (testData.rangeTest.measuredRangeKm || 0),
                );
                if (diff === 0) return null;
                return {
                  y: Math.abs(diff),
                  color: diff < 0 ? nafColor.information.succesLight : nafColor.information.errorLight,
                  dataLabels: {
                    color: diff < 0 ? nafColor.information.successDark : nafColor.information.errorDark,
                    align: Math.abs(diff) <= 20 ? 'left' : 'center',
                    x: Math.abs(diff) <= 20 ? Math.abs(diff) - 2 : 0,
                    format: `${diff < 0 ? '+' : '-'}{y}`,
                  },
                };
              }),
              stack: series.stack,
              type: 'bar',
            }
          : {
              names: series.name,
              data: filteredVehicles.map(({ testData }) => ({
                y: Math.round(
                  Math.min(testData.rangeTest?.[series.key] as number, testData.rangeTest?.statedRangeKm as number),
                ),
                dataLabels: {
                  formatter: () => Math.round(testData.rangeTest?.[series.key] as number),
                },
              })),
              color: series.color,
              stack: series.stack,
              dataLabels: {
                color: series.labelColor,
                align: 'left',
              },
              type: 'bar',
            },
      ),
    };
  }, [data, filteredVehicles, seriesData]);

  return (
    <>
      {includeFilters && categories && (
        <Filter
          tests={tests}
          data={data}
          onlyChips
          categories={categories}
          initialChips={initialCategories}
          setData={setFilteredVehicles}
        />
      )}
      <Legend
        items={[
          { label: 'WLTP Rekkevidde (km)', color1: nafColor.neutral.neutral2, key: 'wltp', shape: 'square' },
          { label: 'Målt rekkevidde (km)', color1: nafColor.primary.park, key: 'measured', shape: 'square' },
          {
            label: 'Avvik (km)',
            key: 'diff',
            split: true,
            color1: nafColor.information.errorDark,
            color2: nafColor.information.successDark,
            shape: 'square',
          },
        ]}
      />
      <HighChartComponent containerProps={{ className }} options={options} />
    </>
  );
};

export const RangeBars = styled(Plot)`
  .highcharts-axis-labels {
    font-family: questa-sans;
    font-size: 0.875rem !important;
  }
  .highcharts-data-label {
    font-family: questa-sans;
    font-weight: 500;
    font-size: 1rem;
  }
`;

const CustomLabel = styled.a`
  display: block;
  text-align: right;
`;
