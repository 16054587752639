export const trackCookieBannerClick = (consentOption: string, consentStatus?: string) => {
  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'consentSelected',
      consentOption,
      consentStatus,
      consentPage: window.location.pathname,
      consentPageUrl: window.location.href,
    });
  }, 1500);
};

export const trackCustomizedCookiesClick = (
  consentOption: string,
  preferences: boolean,
  statistics: boolean,
  marketing: boolean,
) => {
  setTimeout(() => {
    const consentStatus = `preferences:${preferences}|statistics:${statistics}|marketing:${marketing}`;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'consentSelected',
      consentOption,
      consentStatus,
      consentPage: window.location.pathname,
      consentPageUrl: window.location.href,
    });
  }, 1500);
};
