import React, { useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import styled from 'styled-components';

export const DataWrapperBlock = ({ value }: { value: { chartId: string; title: string; ariaLabel: string } }) => {
  const { chartId, title, ariaLabel } = value;
  const [height, setHeight] = useState<number>(100);

  const ref = useRef<HTMLIFrameElement | null>(null);
  const onMessage = (event: MessageEvent) => {
    if (
      typeof event.data['datawrapper-height'] !== 'undefined' &&
      ref.current?.contentWindow === event.source &&
      typeof ref.current !== 'undefined'
    ) {
      const heights = event.data['datawrapper-height'] as Record<string, number>;
      if (typeof heights[chartId] !== 'undefined') {
        setHeight(heights[chartId]);
      }
    }
  };

  useEventListener('message', onMessage);

  return (
    <Iframe
      ref={ref}
      title={title}
      aria-label={ariaLabel || 'Visualisering'}
      id={`datawrapper-chart-${chartId}`}
      src={`https://datawrapper.dwcdn.net/${chartId}`}
      height={height}
    />
  );
};

const Iframe = styled.iframe<{ height: number }>`
  width: 0;
  min-width: 100% !important;
  border: none;
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  border: 0;
`;
