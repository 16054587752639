export const toCloudinaryPath = (path?: string, size?: string) => {
  let width = '1150';
  switch (size) {
    case 'Large':
      width = '1150';
      break;
    case 'Small':
      width = '750';
      break;
    case 'Thumbnail':
      width = '330';
      break;
    default:
      break;
  }

  if (path && path.startsWith('//bildata.ofv.no/bildedata/cars/')) {
    return path.replace(
      '//bildata.ofv.no/bildedata/cars/',
      `//res.cloudinary.com/nafmedier/image/upload/dpr_3.0/q_auto:best/f_auto/c_scale,w_${width}/v1/ofv/cars/`,
    );
  }

  return path;
};
