import React from 'react';
import RelatedCarList from '../../../../related-cars/RelatedCarList';
import { RangeTestType } from '../../../../../../../types/testType';
import { TestTable } from '../../../../test-table/TestTable';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

interface RangeTestSerializerProps {
  value: {
    type: 'rangeTestsComponent';
    viewMode?: 'table' | 'list';
    rangetests: RangeTestType[];
    initialNumberOfElements: number;
    selectedTableFields: (keyof RangeTestType)[];
    isResponsive?: boolean;
    key: string;
  };
}

export const RangeTestBlock = ({ value }: RangeTestSerializerProps) => {
  if (value.viewMode === 'table') {
    return (
      <TestTable
        type="rangeTests"
        key={value.key}
        tests={value.rangetests}
        selectedTableFields={value.selectedTableFields}
        isResponsive={value.isResponsive}
      />
    );
  }
  return <RelatedCarList value={value} />;
};

export const RangeTestSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: RangeTestSerializerProps) => (
      <ArticleBlockContentGridCol>
        <RangeTestBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: RangeTestSerializerProps) => <RangeTestBlock value={value} />;
};
