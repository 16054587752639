import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelector from '../../../../../redux/typedHooks';
import { actions } from '../../../../../redux/modules/myMembership';
import { useAuth0Token } from '../../../../../hooks/useAuth0Token';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

export const NumberOfUsedRoadAssistanceBlock = () => {
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const roadSideAssistance = useSelector(
    (state) => state.myMembership.customerInformation.data.membership?.roadSideAssistance,
  );
  const fetchState = useSelector((state) => state.myMembership.customerInformation.meta.fetchState);

  useEffect(() => {
    if (simpleToken && fetchState === 'initial') {
      dispatch(actions.getCustomerInformation.request(simpleToken));
    }
  }, [simpleToken, dispatch, fetchState]);

  return (
    (roadSideAssistance && (
      <span>
        Du har benyttet {roadSideAssistance.usedRoadsideAssistancesInPeriod} av{' '}
        {roadSideAssistance.totalFreeRoadsideAssistancesInPeriod} assistanser.
      </span>
    )) ||
    null
  );
};

export const NumberOfUsedRoadAssistanceSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <NumberOfUsedRoadAssistanceBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <NumberOfUsedRoadAssistanceBlock />;
};
