import React from 'react';
import { ButtonLink } from '@naf/button-link';
import { Text, TextVariant } from '@naf/text';
import { Link } from 'react-router-dom';
import useSelector from '../../redux/typedHooks';
import { LinkListItem } from '../../../../types/globalSettingsType';
import { isInternalLink } from '../../lib/isInternalLink';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import S from './styles';

export const FooterLink = ({ link }: { link: LinkListItem }) => {
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const { isInternal, relativeLink } = isInternalLink(baseUrl, link.href);
  const refUrl = useDocumentUrlWithNode(link.reference) || relativeLink || '';
  const isPhoneLink = link.href && link.href.startsWith('tel:');

  return (
    <S.ListElement key={link.key}>
      {link.descriptionTitle && (
        <Text tag="p" variant={TextVariant.ArticleText}>
          {link.descriptionTitle}
        </Text>
      )}
      {(link.type === 'internalLink' && link.reference) || (isInternal && !isPhoneLink) ? (
        <ButtonLink
          target={link.blank ? '_blank' : '_self'}
          variant="inverted"
          text={link.title}
          as={Link}
          to={refUrl}
        />
      ) : (
        <ButtonLink href={link.href} target={link.blank ? '_blank' : '_self'} variant="inverted" text={link.title} />
      )}
    </S.ListElement>
  );
};
