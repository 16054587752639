import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { Input, Label } from '@naf/input';
import { Loader } from '@naf/loader';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonLink } from '@naf/button-link';
import { useAuth0Token } from '../../../../../hooks/useAuth0Token';
import { useVehicleInspection } from '../../../../../hooks/useVehicleInspection';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

export const VehicleInspectionBlock = () => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const { simpleToken } = useAuth0Token();
  const [licensePlate, setLicensePlate] = useState<string>();
  const { vehicleInspection, lookup, isLoading: isLoadingSearch, error: errorSearch } = useVehicleInspection();

  const { manufactureName, modelName, year, nextInspectionDate, callToAction } = vehicleInspection || {};

  const handleSearch = () => {
    if (licensePlate) {
      lookup({ token: simpleToken, licensePlateNumber: licensePlate });
    }
  };

  const createDate = (date?: string) => {
    let inspectionDay = '00';
    let inspectionMonth = '00';
    let inspectionYear = '0000';
    if (date) {
      inspectionDay = date.slice(8, 10);
      inspectionMonth = date.slice(5, 7);
      inspectionYear = date.slice(0, 4);
    }
    return `${inspectionDay}.${inspectionMonth}.${inspectionYear}`;
  };

  return (
    <PvcTest>
      <Text variant={TextVariant.Header2}>Når må bilen til EU-kontroll?</Text>
      <Label htmlFor="registrationNumber">Skriv inn bilens reg. nr.</Label>
      <Search>
        <Input
          placeholder="Reg. nr."
          id="registrationNumber"
          type="text"
          onChange={(number: string) => setLicensePlate(number)}
          onKeyUp={(e: KeyboardEvent) => {
            if (e.key === 'Enter') handleSearch();
          }}
        />
        <Button type="button" variant="secondary" onClick={() => handleSearch()}>
          Sjekk
        </Button>
      </Search>
      {isLoadingSearch && (
        <SpinnerWrapper>
          <Loader />
        </SpinnerWrapper>
      )}
      {errorSearch && !isLoadingSearch && (
        <LookupResult>
          <Text>Vi klarte ikke å finne kjøretøyet. Sjekk om registreringsnummeret stemmer.</Text>
        </LookupResult>
      )}
      {!isLoadingSearch && !errorSearch && vehicleInspection && (
        <LookupResult>
          <Text variant={TextVariant.Header3}>
            {manufactureName} {modelName}, {year}
          </Text>
          <InlineText>Neste frist for godkjent EU-kontroll: </InlineText>
          <InlineText variant={TextVariant.ArticleTextHeader}>{createDate(nextInspectionDate)}</InlineText>
          <VehicleAlerts>
            {callToAction &&
              (callToAction.action === 'Booking' && callToAction.booking ? (
                <>
                  <Text variant={TextVariant.Header2}>Bestill time til EU-kontroll i dag!</Text>
                  <PriceBlock>
                    <Text variant={TextVariant.ArticleTextHeader}>
                      Medlemspris: {callToAction.booking.memberPrice},-
                    </Text>
                    <Text>Ikke-medlem: {callToAction.booking.nonMemberPrice},-</Text>
                  </PriceBlock>
                  <Text>Det blir fort fullt hos våre NAF sentre. Det lønner seg derfor å være tidlig ute.</Text>
                  <StyledButton
                    href={callToAction.booking?.link.data.href}
                    target={callToAction.booking.link.data.blank ? '_blank' : '_self'}
                  >
                    Finn ledig time
                  </StyledButton>
                </>
              ) : (
                <>
                  <Text>Ønsker du å bli varslet når fristen nærmer seg?</Text>
                  {callToAction.link && callToAction.action === 'ActivateAlerts' && (
                    <ButtonLink
                      onClick={() => {
                        history.push(`/mitt-naf/${callToAction.link?.data.slug}`);
                      }}
                      text="Aktiver varslinger i Mine Kjøretøy"
                    />
                  )}
                  {callToAction.link && callToAction.action === 'LogIn' && (
                    <ButtonLink
                      onClick={() => {
                        loginWithRedirect({
                          appState: { returnTo: `/mitt-naf/${callToAction.link?.data.slug}` },
                        });
                      }}
                      text="Logg inn for å aktivere varsling i Mine Kjøretøy"
                    />
                  )}
                </>
              ))}
          </VehicleAlerts>
        </LookupResult>
      )}
    </PvcTest>
  );
};

export const VehicleInspectionSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <VehicleInspectionBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <VehicleInspectionBlock />;
};

const PvcTest = styled.div`
  margin: ${spacing.space32} 0 ${spacing.space48};

  h2 {
    margin: 0 0 ${spacing.space24} !important;
  }

  p {
    margin: 0 !important;
  }

  @media (max-width: ${breakpoints}) {
    margin: ${spacing.space64} 0;
  }
`;

const Search = styled.div`
  display: flex;

  button {
    margin-left: ${spacing.space16};
  }
`;

const LookupResult = styled.div`
  margin-top: ${spacing.space24};

  h3,
  h5,
  p {
    margin: 0;
  }

  @media (max-width: ${breakpoints.s}) {
    button {
      width: 100%;
    }
  }
`;

const SpinnerWrapper = styled.div`
  margin-top: ${spacing.space24};
  width: fit-content;

  @media (max-width: ${breakpoints.s}) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const InlineText = styled(Text)`
  display: inline-flex;
  white-space: pre;
`;

const VehicleAlerts = styled.div`
  margin-top: ${spacing.space24};

  h2 {
    margin-bottom: ${spacing.space12};
  }

  h5 {
    margin: 0;
  }

  p {
    margin: 0;
    margin-bottom: ${spacing.space4};
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing.space20};
`;

const PriceBlock = styled.div`
  margin-bottom: ${spacing.space16};

  h5,
  p {
    margin: 0;
  }
`;
