import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ImageCarousel } from '@naf/image-carousel';
import { breakpoints, spacing } from '@naf/theme';
import { BilguideImage, CarModelType } from '../../../../types/carModelType';
import { toCloudinaryPath } from '../../components/bilguide/cloudinary/cloudinary';
import { CarImageTypes, LARGE_IMAGES } from '../../components/bilguide/carModelImages/CarModelImages';
import { CloudinaryImageType } from '../../../../types/blockTypes';
import utils from '../../utils';

type Props = {
  ofvImages: BilguideImage[] | null;
  sanityImages: CloudinaryImageType[] | null;
};

function prepareCarouselImages(ofvImages: Props['ofvImages'], sanityImages: Props['sanityImages']) {
  const ofv = ofvImages
    ? ofvImages.reduce((acc: [], cur: CarModelType['vehicleModelPictures'][0]) => {
        if (LARGE_IMAGES.includes(cur.type as CarImageTypes) && cur.path) {
          return [
            ...acc,
            {
              src: toCloudinaryPath(cur.path, 'Large') || '',
              description: cur.source,
              altText: cur.alternateText,
            },
          ];
        }
        return acc;
      }, [])
    : [];

  const sanity = sanityImages
    ? sanityImages.map((cur) => ({
        src: cur?.srcSet.jpgLarge,
        description: `${cur?.caption ? `${cur?.caption}\u000A` : ''}${utils.getPhotographerAndCopyrightCaption({
          copyright: cur?.metadata.vipxjbh3flq1nvycznex,
          photographer: cur?.metadata.rbzvposxghrdnm69guww,
        })}`,
        altText: cur?.altText,
      }))
    : [];

  return [...sanity, ...ofv];
}

const ModelImageCarousel = ({ ofvImages, sanityImages }: Props) => {
  const carouselImages = useMemo(() => prepareCarouselImages(ofvImages, sanityImages), [ofvImages, sanityImages]);

  if (!carouselImages.length) {
    return null;
  }

  return (
    <CarouselWrapper>
      <ImageCarousel images={carouselImages} />
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  --aside: clamp(240px, 25vw, 400px);
  @media (max-width: ${breakpoints.l}) {
    --aside: 0;
  }

  --content-plus-whitespace: calc(100vw - var(--aside));
  --max-width-with-whitespace: min(calc(936px + 2 * 96px), var(--content-plus-whitespace));
  /* Margins will always be a negative number */
  --total-margins: calc((736px - var(--max-width-with-whitespace)) / 2);

  width: calc(736px - var(--total-margins));
  margin-top: ${spacing.space64};
  margin-bottom: ${spacing.space24};
  margin-left: max(var(--total-margins) / 2, -96px);
  margin-right: max(var(--total-margins) / 2, -96px);

  @media (max-width: ${breakpoints.m}) {
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};
    width: calc(100% + ${spacing.space48});
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space48};
  }
`;

export { ModelImageCarousel };
