import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { StepWizardChildProps } from 'react-step-wizard';
import { FormField } from '@dtp/membership-service-types';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { SanityBlock } from '@dtp/membership-service-types/dist/sanity/sanity';
import { Form } from '../../../../Form';
import BlockContent from '../../../BlockContent';
import { StyledUserInteraction } from './StyledUserInteraction';
import { useQueryParams } from '../../../../../hooks/useQueryParameters';

export const FormStep: FC<
  Partial<StepWizardChildProps> & {
    fieldComponents: FormField[];
    textField?: SanityBlock[];
    title?: string;
    body?: SanityBlock[];
    control: UseFormReturn['control'];
    errors: UseFormReturn['formState']['errors'];
    clearErrors: UseFormReturn['clearErrors'];
    handleSubmit: UseFormReturn['handleSubmit'];
    getValues: UseFormReturn['getValues'];
    setValue: UseFormReturn['setValue'];
    watch: UseFormReturn['watch'];
    buttonText?: string;
    onSubmit: SubmitHandler<FieldValues>;
    shouldConfirm: boolean;
    hasReceiptPage: boolean;
    isSubmitting: boolean;
    errorState?: string | null;
    isSuccess: boolean;
    setError: UseFormReturn['setError'];
  }
> = ({
  nextStep,
  fieldComponents,
  textField,
  title,
  body,
  control,
  getValues,
  setValue,
  watch,
  errors,
  clearErrors,
  handleSubmit,
  buttonText,
  onSubmit,
  shouldConfirm,
  hasReceiptPage,
  isSubmitting,
  errorState,
  isSuccess,
  setError,
}) => {
  const onNext = async () => {
    if (nextStep && hasReceiptPage) {
      if (!shouldConfirm) {
        const data = getValues();
        try {
          await onSubmit(data);
          nextStep();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      } else {
        nextStep();
      }
    } else {
      const data = getValues();
      onSubmit(data);
    }
  };

  const queryParams = useQueryParams();
  const getQueryParamInitialValue = useMemo(
    () => (fieldId: string) =>
      queryParams && queryParams[fieldId] && typeof queryParams[fieldId] === 'string'
        ? (queryParams[fieldId] as string)
        : null,
    [queryParams],
  );

  useEffect(() => {
    if (fieldComponents && setValue) {
      fieldComponents.forEach((fieldComponent) => {
        if (fieldComponent.key === 'fieldComponent') {
          const queryInitialValue = getQueryParamInitialValue(fieldComponent.id);
          setValue(fieldComponent.id, fieldComponent.initialValue || queryInitialValue);
        } else if (fieldComponent.key === 'groupFields') {
          fieldComponent?.fieldComponents?.forEach((groupField) => {
            const queryInitialValue = getQueryParamInitialValue(fieldComponent.id);
            setValue(groupField.id, groupField.initialValue || queryInitialValue);
          });
        }
      });
    }
  }, [getQueryParamInitialValue, fieldComponents, setValue, queryParams]);

  return (
    <>
      <Text variant={TextVariant.Header2}>{title}</Text>
      {body ? <BlockContent value={body} /> : null}
      <Form
        fieldComponents={fieldComponents}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        watch={watch}
        setValue={setValue}
        setError={setError}
      />
      {textField && textField.length !== 0 && (
        <TextField>
          <BlockContent value={textField} />
        </TextField>
      )}
      {!isSuccess && (
        <SubmitButton
          type="submit"
          onClick={handleSubmit(onNext)}
          disabled={Object.values(errors).length !== 0}
          isLoading={isSubmitting}
        >
          {buttonText}
        </SubmitButton>
      )}
      {errorState && (
        <StyledUserInteraction
          title="Oops, nå kjørte vi oss fast"
          button={{
            type: 'submit',
            onClick: handleSubmit(onNext),
            children: 'Prøv igjen',
          }}
          variant="error"
        >
          {errorState}
        </StyledUserInteraction>
      )}
      {isSuccess && !errorState && !shouldConfirm && !hasReceiptPage && (
        <StyledUserInteraction title="Skjema er sendt inn">
          Takk for din henvendelse. Du får snart en bekreftelse på epost.
        </StyledUserInteraction>
      )}
    </>
  );
};

const SubmitButton = styled(Button)`
  margin-top: ${spacing.space40};
`;

const TextField = styled.span`
  p {
    margin-top: ${spacing.space40} !important;
    margin-bottom: 0 !important;
  }
`;
