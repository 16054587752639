import { MainAndHouseholdMembersListSerializer } from './components/MainAndHouseholdMembersList';
import { MembershipOverviewSerializer } from './components/MembershipOverview';
import { AccordionSerializer } from './components/Accordion';
import { NumberOfUsedRoadAssistanceSerializer } from './components/NumberOfRoadAssistance';
import { ActiveChargingTagsSerializer } from './components/ActiveChargingTags';
import { ChargeTestSerializer } from './components/ChargeTest';
import { TableSerializer } from './components/Table';
import { IframeSerializer } from './components/Iframe';
import { ImageSerializer } from './components/Image';
import { ImagesSerializer } from './components/Images';
import { RangeTestSerializer } from './components/RangeTest';
import { TestCollectionSerializer } from './components/TestCollection';
import { VideoSerializer } from './components/Video';
import { QuizSerializer } from './components/Quiz';
import { QuoteSerializer } from './components/Quote';
import { SocialProofSerializer } from './components/SocialProof';
import { PodcastSerializer } from './components/Podcast';
import { InternalLinkSerializer } from './components/InternalLink';
import { ExternalLinkSerializer } from './components/ExternalLink';
import { CtaButtonLinkSerializer } from './components/CtaButtonLink';
import { CtaFileLinkSerializer } from './components/CtaFileLink';
import { CampaignBlockSerializer } from './components/CampaignBlock';
import { CarLoanCalculatorSerializer } from './components/CarLoanCalculator';
import { InsuranceCalculatorSerializer } from './components/InsuranceCalculator';
import { IdpCountriesListSerializer } from './components/IdpCountriesList';
import { HighChartsSerializer } from './components/HighCharts';
import { VehicleInspectionSerializer } from './components/VehicleInspection';
import { OilChangeCalculatorSerializer } from './components/oilChangeCalculator';
import { WindShieldWiperChangeCalculatorSerializer } from './components/windshieldWiperChangeCalculator';
import { AnchorSerializer } from './components/Anchor';
import { CodeTypesSerializer } from './components/CodeTypes';
import { MembershipValidUntilSerializer } from './components/MembershipValidUntil/MembershipValidUntil';
import { ParallaxSerializer } from './components/Parallax';
import { PortraitsWithTextSerializer } from './components/portraitsWithText';
import { ReferencedComponentSerializer } from './components/ReferencedComponentSerializer';
import { CtaGlobalBlockSerializer } from './components/CtaGlobalBlock';
import { DocumentsBlockSerializer } from './components/DocumentsBlock';
import { ImageCarouselSerializer } from './components/ImageCarouselSerializer';
import { WideImageSerializer } from './components/WideImageSerializer';
import { InfoBoxSerializer } from './components/InfoBox';
import { DoubleImageSerializer } from './components/DoubleImage/DoubleImageSerializer';
import NumberedListSerializer from './components/NumberedListSerializer';
import ArticleListSerializer from './components/ArticleListSerializer';
import { FeaturedVehicleResultSerializer } from './components/FeaturedVehicleResultSerializer/FeaturedVehicleResultSerializer';
import { TestResultSerializer } from './components/TestResultSerializer';
import TravelSuggestionSerializer from './components/TravelSuggestionSerializer';
import { WeatherForecastsSerializer } from './components/WeatherForecastsSerializer';
import { AltitudeChartSerializer } from './components/AltitudeChartSerializer';
import { RouteMapSerializer } from './components/routeMapSerializer';
import { MembershipValidUntilGreenBadgeSerializer } from './components/MembershipValidUntilGreenBadge/MembershipValidUntilGreenBadge';
import { YoutubeSerializer } from './components/YoutubeSerializer';
import { FormSerializer } from '../Form/FormSerializer';
import { DataWrapperSerializer } from './components/DataWrapper/DataWrapper';
import { InformationUserInteractionSerializer } from './components/InformationUserInteraction';
import { TextWithEndDateSerializer } from './components/TextWithEndDate';
import { TextWithEmailSerializer } from './components/TextWithEmail';
import { GreenBoxWithMembersListAndEndDateSerializer } from './components/GreenBoxWithMembersListAndEndDate/GreenBoxWithMembersListAndEndDate';
import { TextWithHouseholdMemberSerializer } from './components/TextWithHouseholdMember';
import { VippsLoginBlock } from '../../../SectionBlocks/blocks/VippsLogin';
import { InfoMessageBoxSerializer } from './components/InfoMessageBox/InfoMessageBox';
import { CtaButtonInternalLinkSerializer } from './components/CtaButtonInternalLink';
import { AccordionListBlockSerializer } from './components/AccordionList';
import { PayWallSerializer } from './components/PayWall';
import { WarningUserInteractionSerializer } from './components/WarningUserInteraction';

export const types = (context: 'article' | 'stripped' = 'stripped') => ({
  accordeon: AccordionSerializer(context), // TODO: consistency in APIs, accordeonComponent in articles / accordeon in product
  accordionList: AccordionListBlockSerializer(context),
  accordeonComponent: AccordionSerializer(context),
  activeChargingTags: ActiveChargingTagsSerializer(context),
  altitudeChart: AltitudeChartSerializer(),
  anchor: AnchorSerializer(context),
  articleList: ArticleListSerializer(context),
  bodyFeaturedVehicleTestResult: FeaturedVehicleResultSerializer(context),
  bodyTestResult: TestResultSerializer(context),
  campaignBlockComponent: CampaignBlockSerializer(context),
  carLoanCalculator: CarLoanCalculatorSerializer(context),
  chargeTestsComponent: ChargeTestSerializer(context),
  code: CodeTypesSerializer(context),
  ctaButtonLinkComponent: CtaButtonLinkSerializer(context),
  ctaButtonInternalLinkComponent: CtaButtonInternalLinkSerializer(context),
  ctaExternalLinkComponent: ExternalLinkSerializer(context),
  ctaFileLinkComponent: CtaFileLinkSerializer(context),
  ctaGlobalBlock: CtaGlobalBlockSerializer(context),
  ctaInternalLink: InternalLinkSerializer(context),
  ctaInternalLinkComponent: InternalLinkSerializer(context),
  dataTableComponent: TableSerializer(context),
  dataWrapperComponent: DataWrapperSerializer(context),
  documentsBlock: DocumentsBlockSerializer(context),
  doubleImage: DoubleImageSerializer(context),
  dynamicForm: FormSerializer(context),
  highcharts: HighChartsSerializer(context),
  ifkCountriesList: IdpCountriesListSerializer(context),
  iframeComponent: IframeSerializer(context),
  imageCarousel: ImageCarouselSerializer(context),
  imageComponent: ImageSerializer(context),
  imagesComponent: ImagesSerializer(context),
  infoBox: InfoBoxSerializer(context),
  insuranceCalculator: InsuranceCalculatorSerializer(context),
  membershipValidUntil: MembershipValidUntilSerializer(),
  membershipValidUntilGreenBadge: MembershipValidUntilGreenBadgeSerializer(),
  membershipOverview: MembershipOverviewSerializer(),
  informationUserInteraction: InformationUserInteractionSerializer(),
  warningUserInteraction: WarningUserInteractionSerializer(),
  textWithEndDate: TextWithEndDateSerializer(),
  textWithEmail: TextWithEmailSerializer(),
  textWithHouseholdMember: TextWithHouseholdMemberSerializer(),
  mainAndHouseholdMembersList: MainAndHouseholdMembersListSerializer(),
  greenBoxWithMembersListAndEndDate: GreenBoxWithMembersListAndEndDateSerializer(),
  numberOfUsedRoadAssistance: NumberOfUsedRoadAssistanceSerializer(context),
  numberedListWithImage: NumberedListSerializer(context),
  oilChangeCalculator: OilChangeCalculatorSerializer(context),
  podcastEpisode: PodcastSerializer(context),
  portraitsWithText: PortraitsWithTextSerializer(context),
  quizComponent: QuizSerializer(context),
  quoteComponent: QuoteSerializer(context),
  socialProofComponent: SocialProofSerializer(context),
  rangeTestsComponent: RangeTestSerializer(context),
  referencedComponent: ReferencedComponentSerializer(context),
  routeMap: RouteMapSerializer(),
  scrollableTextBlocksWithBackgroundImages: ParallaxSerializer(context),
  testCollectionComponent: TestCollectionSerializer(context),
  travelSuggestion: TravelSuggestionSerializer(),
  vehicleInspection: VehicleInspectionSerializer(context),
  videoComponent: VideoSerializer(context),
  weatherForecasts: WeatherForecastsSerializer(),
  wideImage: WideImageSerializer(context),
  windshieldWiperChangeCalculator: WindShieldWiperChangeCalculatorSerializer(context),
  youtubeEmbed: YoutubeSerializer(context),
  vippsLogin: VippsLoginBlock,
  infoMessageBox: InfoMessageBoxSerializer(),
  paywallReference: PayWallSerializer(context),
});
