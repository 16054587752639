import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { MyNafFrontpageType } from '../../../../types/myNafType';

// Actions
export enum Actions {
  GET_MYNAF_FRONTPAGE = 'naf/mynaf/GET_FRONTPAGE',
  GET_MYNAF_FRONTPAGE_SUCCESS = 'naf/mynaf/GET_FRONTPAGE_SUCCESS',
  GET_MYNAF_FRONTPAGE_FAIL = 'naf/mynaf/GET_FRONTPAGE_FAIL',
  GET_MYNAF_FRONTPAGE_CANCEL = 'naf/mynaf/GET_FRONTPAGE_CANCEL',
}

export interface State {
  myNafFrontpage: Record<MyNafFrontpageType['slug'], MyNafFrontpageType>;
  isUpdating: boolean;
  errorState?: Error;
  total: number;
}

export const initialState: State = {
  myNafFrontpage: {},
  isUpdating: false,
  total: 0,
};

export const actions = {
  getMyNafFrontpage: createAsyncAction(
    Actions.GET_MYNAF_FRONTPAGE, // request payload creator
    Actions.GET_MYNAF_FRONTPAGE_SUCCESS, // success payload creator
    Actions.GET_MYNAF_FRONTPAGE_FAIL, // failure payload creator
    Actions.GET_MYNAF_FRONTPAGE_CANCEL, // optional cancel payload creator
  )<[string, { token?: string }], MyNafFrontpageType, Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getMyNafFrontpage.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getMyNafFrontpage.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.myNafFrontpage = { [action.payload?.slug]: action.payload };
    }),
  )
  .handleAction(actions.getMyNafFrontpage.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getMyNafFrontpage.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

const fetchMyNafFrontpageEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MYNAF_FRONTPAGE),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMyNafFrontpage.request>[]
    >,
    switchMap(([action]: { payload: string; meta?: { token?: string } }[]) =>
      ajax<MyNafFrontpageType>({
        url: `${apimBaseUrl}/${apimNafNoApi}/mynaf/${action.payload}`,
        headers: {
          'Ocp-Apim-Subscription-Key': apimContentHub,
          ...(action.meta?.token ? { Authorization: `Bearer ${action.meta.token}` } : {}),
        },
      }).pipe(
        map(({ response }) => actions.getMyNafFrontpage.success(response)),
        catchError(() => of(actions.getMyNafFrontpage.failure(new Boom.Boom('Could not get my naf frontpage')))),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [fetchMyNafFrontpageEpic];
