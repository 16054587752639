import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { ScrollableTextBlocksWithBackgroundImagesType } from '../../../../../../../types/ScrollableTextBlocksWithBackgroundImagesType';
import { ScrollableTextBlocksWithBackgroundImages } from '../../../../SectionBlocks/blocks/Parallax/ScrollableTextBlocksWithBackgroundImages';

interface ParallaxProps {
  value: ScrollableTextBlocksWithBackgroundImagesType;
}

export const ParallaxBlock = ({ value }: ParallaxProps) => <ScrollableTextBlocksWithBackgroundImages block={value} />;

export const ParallaxSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: ParallaxProps) => (
      <ArticleParallaxContentGridCol className="parallax">
        <ParallaxBlock value={value} />
      </ArticleParallaxContentGridCol>
    );
  }
  return ({ value }: ParallaxProps) => <ParallaxBlock value={value} />;
};

const ArticleParallaxContentGridCol = styled.div`
  grid-column: 1 / -1;
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;
