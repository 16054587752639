import React, { memo } from 'react';
import { AdvancedImage, responsive } from '@cloudinary/react';
import { QualityTypes } from '@cloudinary/url-gen/types/types';
import { CloudinaryImage } from '@cloudinary/url-gen';
import styled from 'styled-components';
import { useResponsiveImage } from '../../hooks/useResponsiveImage';

type Props = {
  altText: string;
  quality?: QualityTypes;
  className?: string;
  image?: CloudinaryImage;
  imageId?: string;
};

const ResponsiveImage = memo(({ imageId, image, quality, altText, className }: Props) => {
  const cldImage = useResponsiveImage({ imageId, quality });
  const displayedImage = image || cldImage;
  if (!displayedImage) return null;
  return (
    <StyledAdvancedImage
      cldImg={displayedImage}
      plugins={[responsive({ steps: 200 })]}
      alt={altText}
      className={className}
    />
  );
});

export default ResponsiveImage;

const StyledAdvancedImage = styled(AdvancedImage)`
  width: 100%;
`;
