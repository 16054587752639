import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';
import React from 'react';
import styled from 'styled-components';

export const BreadcrumbLoader = () => (
  <Wrapper>
    <ContentLoader
      height={16}
      viewBox="0 0 300 16"
      preserveAspectRatio="xMidYMid meet"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
      uniqueKey="breadcrumb-loader"
    >
      <rect x="0" y="0" rx="0" ry="0" width="108" height="16" />
      <rect x="118" y="0" rx="0" ry="0" width="60" height="16" />
      <rect x="185" y="0" rx="0" ry="0" width="60" height="16" />
    </ContentLoader>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: auto;
`;
