import React from 'react';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import { SanityBlock } from '@dtp/membership-service-types/dist/sanity/sanity';
import BlockContent from '../../../../BlockContent';
import { BlockContentContainer } from './styles';

interface InfoMessageBoxProps {
  value: {
    text: SanityBlock[];
  };
}

export const InfoMessageBox = ({ value: { text } }: InfoMessageBoxProps) => (
  <UserInteraction variant={UserInteractionVariant.Info}>
    <BlockContentContainer>
      <BlockContent value={text} />
    </BlockContentContainer>
  </UserInteraction>
);

export const InfoMessageBoxSerializer =
  () =>
  ({ value }: InfoMessageBoxProps) =>
    <InfoMessageBox value={value} />;
