import styled, { css } from 'styled-components';
import { breakpoints } from '@naf/theme';

export const DesktopWrapper = styled.div<{ $isScrolled?: boolean }>`
  display: flex;
  transition: transform 0.3s ease-in-out;
  margin-left: -16px;
  margin-top: -2px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.l}) {
    ${({ $isScrolled }) =>
      $isScrolled &&
      css`
        transform: scale(0.7);
      `}
  }

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: flex;
    margin-top: -2px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    display: flex;
  }
`;
